import classNames from 'classnames';

import styles from './BillingCard.module.scss';

type Props = {
  title: string;
  className?: string;
};

const BillingCard = ({ title, className, children }: React.PropsWithChildren<Props>) => {
  const cardClasses = classNames(styles.card, className);

  return (
    <div className={cardClasses}>
      <h3 className={styles.heading}>{title}</h3>
      {children}
    </div>
  );
};

export default BillingCard;
