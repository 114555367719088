import React from 'react';
import classNames from 'classnames';

import CustomCheckboxWrapper from './CustomCheckboxWrapper';
import styles from './CustomCheckboxWithIcon.module.scss';

export type ICustomCheckboxWithIcon = (
  | { iconType: 'regular' }
  | {
      iconType: 'image';
      size: number; // size of the svg image
    }
) & {
  value: string;
  name?: string;
  heading: string;
  text?: string;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  disabled?: boolean;
  valuePropName?: 'checked';
};

type Props = ICustomCheckboxWithIcon & {
  selected?: boolean;
  className?: string;
  onChange?: (value: string | boolean) => void;
  layout?: 'vertical' | 'horizontal';
  classes?: {
    icon?: string;
  };
};

const CustomCheckboxWithIcon: React.FunctionComponent<Props> = (props) => {
  const { className, icon: Icon, heading, text, layout = 'vertical', classes, ...rest } = props;
  const wrapperClasses = classNames(styles.wrapper, styles[layout]);

  return (
    <CustomCheckboxWrapper className={className} {...rest}>
      <div className={wrapperClasses}>
        <div className={styles['icon-wrapper']}>
          {props.iconType === 'image' ? (
            <Icon width={props.size} height={props.size} />
          ) : (
            <i className={classNames(styles.icon, classes?.icon)}>
              <Icon />
            </i>
          )}
        </div>
        <div className={styles.info}>
          <strong className={styles.heading}>{heading}</strong>
          {text && <div className={styles.text}>{text}</div>}
        </div>
      </div>
    </CustomCheckboxWrapper>
  );
};

export default CustomCheckboxWithIcon;
