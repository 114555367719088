import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoGoogleCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 228"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#fbbc05"
      d="m166.985 181.72-26.136 45.432H72.4a17.04 17.04 0 0 1-14.782-8.568L2.254 122.055a17.05 17.05 0 0 1 0-16.945l21.07-36.72 26.005 45.186 39.204 68.143z"
    />
    <path
      fill="#ea4335"
      d="M253.277 122.055 197.913 218.6a17.02 17.02 0 0 1-14.783 8.566h-42.296l26.136-45.433 39.232-68.156-39.232-68.143h52.07l34.222 59.677a16.94 16.94 0 0 1 .015 16.945"
    />
    <path
      fill="#4285f4"
      d="M219.052 45.433H88.533L49.33 113.576l-26.005-45.2L57.62 8.568A17.02 17.02 0 0 1 72.4 0h110.745a17.06 17.06 0 0 1 14.78 8.568z"
    />
    <path fill="#e0e0e0" d="m166.985 45.433 39.204 68.143-39.22 68.143H88.547L49.33 113.576l39.204-68.143z" />
    <circle cx={127.766} cy={113.576} r={34.079} fill="#fff" />
    <path
      fill="#fff"
      d="M57.619 9.975A17.02 17.02 0 0 1 72.4 1.41h110.745a17.06 17.06 0 0 1 14.78 8.566l20.329 35.458h.812L197.926 8.568A17.02 17.02 0 0 0 183.146 0H72.4a17.04 17.04 0 0 0-14.782 8.568L23.324 68.376l.405.71z"
      opacity={0.2}
    />
    <path fill="#1a237e" fillOpacity={0.2} d="m49.33 113.576 39.203-68.143h130.52l-.813-1.422H88.533L49.33 112.154" />
    <path
      fill="#fff"
      d="m2.254 106.52 21.07-36.737 26.005 45.202 39.204 68.141h77.624l.828-1.422H88.533L49.33 113.576l-26.005-45.2-21.07 36.721a17.15 17.15 0 0 0-2.221 9.191c.109-2.73.87-5.394 2.221-7.769"
      opacity={0.2}
    />
    <path
      fill="#bf360c"
      d="M141.661 225.728h-69.26a17.04 17.04 0 0 1-14.782-8.566l-55.365-96.53a17.1 17.1 0 0 1-2.221-7.768c-.148 3.212.622 6.4 2.221 9.191L57.62 218.6a17.02 17.02 0 0 0 14.782 8.566h68.448z"
      opacity={0.2}
    />
    <path
      fill="#3e2723"
      d="m207.001 113.576-39.204-68.143h-.812l39.217 68.143zm46.276 7.057-55.364 96.544a17.02 17.02 0 0 1-14.783 8.566h-41.469l-.827 1.422h42.296a17.06 17.06 0 0 0 14.783-8.566l55.364-96.544a17.05 17.05 0 0 0 2.222-9.191 17.1 17.1 0 0 1-2.222 7.769"
      opacity={0.2}
    />
    <path
      fill="#fff"
      d="m219.052 46.842 34.225 59.677a17.1 17.1 0 0 1 2.222 7.77c.148-3.213-.623-6.401-2.222-9.192L219.052 45.42h-52.067l.828 1.422zm-52.067 136.3L207 113.575h-.799l-39.217 68.143-26.136 45.433h.812z"
      opacity={0.2}
    />
  </svg>
);
const Memo = memo(SvgLogoGoogleCloud);
export default Memo;
