import { useParams, Navigate } from 'react-router-dom';

import { useEscrow } from 'src/context/escrow';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import { EscrowStatusEnum } from 'src/graphql/schema';

const getRedirectRoutes = (escrowStatus: EscrowStatusEnum) => {
  switch (escrowStatus) {
    case EscrowStatusEnum.Draft:
      return [EscrowRoutesEnum.Preview, EscrowRoutesEnum.Deposits, EscrowRoutesEnum.Certificate];
    case EscrowStatusEnum.AdminReview:
      return [EscrowRoutesEnum.Preview, EscrowRoutesEnum.Deposits, EscrowRoutesEnum.Certificate];
    case EscrowStatusEnum.Onboarding:
      return [EscrowRoutesEnum.Deposits, EscrowRoutesEnum.Certificate];
    case EscrowStatusEnum.PendingTermination:
      return [EscrowRoutesEnum.Certificate];
    case EscrowStatusEnum.Terminated:
      return [EscrowRoutesEnum.Certificate];
    case EscrowStatusEnum.Signed:
      return [EscrowRoutesEnum.Certificate];
    default:
      return [];
  }
};

// eslint-disable-next-line react/display-name
export const withEscrowGuard = (Component: React.ElementType) => (props?: Record<string, unknown>) => {
  const { escrow } = useEscrow();
  const { escrowId, '*': activePath } = useParams();

  const redirectRoutes = getRedirectRoutes(escrow?.status);

  if (redirectRoutes.includes(activePath as EscrowRoutesEnum))
    return <Navigate to={`/escrows/${escrowId}/${EscrowRoutesEnum.Basics}`} />;

  return <Component {...props} />;
};
