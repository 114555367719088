import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgIconDepositor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 56 56"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#009CB7"
      fillRule="evenodd"
      d="M14 2C7.373 2 2 7.373 2 14v28c0 6.627 5.373 12 12 12h28c.282 0 .56-.01.837-.029l11.134-11.133c.02-.277.029-.556.029-.838V14c0-6.627-5.373-12-12-12H14Z"
      clipRule="evenodd"
    />
    <path fill="#00778B" d="M42.695 44.58c0-1.04.844-1.884 1.884-1.884H54L42.696 54v-9.42Z" />
    <path
      fill="#fff"
      d="M33.495 33.717a2.122 2.122 0 0 0-1.571-.618l-3.037.108c.419-.798.294-1.81-.377-2.48l-2.302-2.303a1.873 1.873 0 0 1-.545-1.205 4.944 4.944 0 0 0-4.54-4.604 2.113 2.113 0 0 0-.587-1.856l-2.06-2.06a.705.705 0 0 0-.997 0l-5.98 5.98a.705.705 0 0 0 0 .997l2.06 2.06a2.11 2.11 0 0 0 1.634.614l.212.378a16.212 16.212 0 0 0 2.674 3.53l4.45 4.45a4.958 4.958 0 0 0 4.187 1.395l5.583-.797a2.117 2.117 0 0 0 1.195-3.588ZM15.552 26.74a.706.706 0 0 1-.997 0l-1.562-1.562 4.984-4.984 1.562 1.562a.706.706 0 0 1 0 .996l-3.987 3.988ZM32.1 35.91l-5.583.798a3.541 3.541 0 0 1-2.99-.997l-4.45-4.45a14.801 14.801 0 0 1-2.443-3.223l-.14-.25.055-.052 3.762-3.762.653.042a3.532 3.532 0 0 1 3.292 3.293c.051.797.39 1.547.955 2.112l2.303 2.303a.706.706 0 0 1-.997.997l-3.542-3.543a.705.705 0 0 0-.997.997l3.876 3.876c.413.414.986.639 1.57.618l4.55-.161a.706.706 0 0 1 .126 1.402Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M37.97 21.848a.745.745 0 0 0-.998.333l-2.978 5.956a.745.745 0 0 0 1.332.666l2.978-5.956a.744.744 0 0 0-.333-.999Zm-5.76.14a.744.744 0 0 0-1.053 0l-2.978 2.978a.744.744 0 0 0 0 1.053l2.978 2.978a.744.744 0 1 0 1.053-1.053l-2.451-2.451 2.45-2.452a.744.744 0 0 0 0-1.053Zm7.88 0a.744.744 0 0 1 1.052 0l2.978 2.978c.291.29.291.762 0 1.052l-2.978 2.979a.744.744 0 1 1-1.052-1.053l2.451-2.452-2.451-2.451a.744.744 0 0 1 0-1.053Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgIconDepositor);
export default Memo;
