import { useEscrow } from 'src/context/escrow';

import EditableAgreementChangesContainer from './EditableAgreementChangesContainer';
import ViewAgreementChangesContainer from './ViewAgreementChangesContainer';

const AgreementSuggestChangesContainer = () => {
  const { ui } = useEscrow();

  if (ui.isSuggestChangesMode) return <EditableAgreementChangesContainer />;

  return <ViewAgreementChangesContainer />;
};

export default AgreementSuggestChangesContainer;
