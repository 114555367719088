import React, { Fragment } from 'react';

import { message, TextBlock } from 'src/components/Misc';
import {
  DepositNotificationsTypeSelect,
  DisruptedNotificationSelect,
  DepositInfoDisplaySelect,
  StorageRegionSelect,
} from 'src/components/Escrow/Notifications';
import { useEscrow } from 'src/context/escrow';
import {
  useUpdateDepositSettingsMutation,
  EscrowPartyTypeEnum,
  AgreementFormEnum,
  IDepositSettingsInput,
} from 'src/graphql/schema';
import { getApiError, ClientError } from 'src/utils/errors';
import styles from 'src/components/Escrow/Escrow.module.scss';

const EscrowNotificationsEditContainer: React.FunctionComponent = () => {
  const { escrow } = useEscrow();
  const [updateDepositSettings] = useUpdateDepositSettingsMutation();

  const onSettingsChange = async (depositSettings: IDepositSettingsInput) => {
    try {
      const { data } = await updateDepositSettings({
        variables: {
          escrowId: escrow.id,
          depositSettings,
        },
      });

      if (data?.updateDepositSettings?.success) {
        message.success('Saved!');
      } else {
        throw new ClientError(data?.updateDepositSettings?.errors?.[0]);
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const isTripartite = escrow.agreement?.agreementForm === AgreementFormEnum.Tripartite;

  return (
    <Fragment>
      {isTripartite ? (
        <div className={styles.block}>
          <DepositInfoDisplaySelect
            selectedDisplay={escrow.showDepositInformation}
            onChange={(showDepositInformation) => onSettingsChange({ showDepositInformation })}
          />

          {escrow.showDepositInformation && (
            <Fragment>
              <DepositNotificationsTypeSelect
                partyType={EscrowPartyTypeEnum.Beneficiary}
                selectedType={escrow.beneficiaryDepositNotification as string | undefined}
                onChange={(beneficiaryDepositNotification) =>
                  onSettingsChange({ beneficiaryDepositNotification } as IDepositSettingsInput)
                }
                textBlock={{
                  as: 'h2',
                  titleColor: 'light-blue',
                }}
              />
              {/* Beneficiary disrupt notifications */}
              <DisruptedNotificationSelect
                partyType={EscrowPartyTypeEnum.Beneficiary}
                selectedDisruptionNotification={escrow.beneficiaryDisruptionNotification}
                selectedGracePeriod={escrow.beneficiaryDisruptionNotificationGracePeriod}
                onChangeDisruptionNotification={(beneficiaryDisruptionNotification) =>
                  onSettingsChange({ beneficiaryDisruptionNotification })
                }
                onChangeGracePeriod={(value) =>
                  onSettingsChange({
                    beneficiaryDisruptionNotificationGracePeriod: value === 'none' ? null : value,
                  })
                }
                textBlock={{
                  as: 'h2',
                }}
              />
            </Fragment>
          )}
        </div>
      ) : (
        <TextBlock
          className={`${styles.block} ${styles['bipartite-message']}`}
          title="Deposit Information"
          text="No deposit notifications will be sent to the beneficiary of a bipartite escrow."
        />
      )}

      <div className={styles.block}>
        {/* Depositor deposit notifications type  */}
        <DepositNotificationsTypeSelect
          partyType={EscrowPartyTypeEnum.Depositor}
          selectedType={escrow.depositorDepositNotification as string | undefined}
          onChange={(depositorDepositNotification) =>
            onSettingsChange({ depositorDepositNotification } as IDepositSettingsInput)
          }
          textBlock={{
            as: 'h2',
            titleColor: 'light-blue',
          }}
        />
        {/* Depositor disrupt notifications */}
        <DisruptedNotificationSelect
          partyType={EscrowPartyTypeEnum.Depositor}
          selectedDisruptionNotification={escrow.depositorDisruptionNotification}
          selectedGracePeriod={escrow.depositorDisruptionNotificationGracePeriod}
          onChangeDisruptionNotification={(depositorDisruptionNotification) =>
            onSettingsChange({ depositorDisruptionNotification })
          }
          onChangeGracePeriod={(value) =>
            onSettingsChange({ depositorDisruptionNotificationGracePeriod: value === 'none' ? null : value })
          }
          textBlock={{
            as: 'h2',
          }}
        />
      </div>

      <StorageRegionSelect
        selectedRegion={escrow.storageRegion as string | undefined}
        onChange={(storageRegion) => onSettingsChange({ storageRegion })}
      />
    </Fragment>
  );
};

export default EscrowNotificationsEditContainer;
