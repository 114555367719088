import { CheckOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { ImageBackups } from 'src/components/Svg/Images';

import styles from '../CancelPlanOffer.module.scss';

type Props = {
  title: string;
  description: string;
  features: string[];
  className?: string;
};

const OtherOfferCard = ({ title, description, features, className }: Props) => {
  const itemClasses = classNames(styles.item, className);

  return (
    <div className={itemClasses}>
      <div className={`d-flex align-items-center ${styles['item-header']}`}>
        <ImageBackups width={24} />
        <h4>{title}</h4>
      </div>
      <p className={styles['item-text']}>{description}</p>
      <ul className={`ck-custom-list ${styles.features}`}>
        {features.map((feature, index) => (
          <li className="d-flex align-items-center" key={index}>
            <CheckOutlined className={styles['icon-check']} /> {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OtherOfferCard;
