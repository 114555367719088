import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconChartPie = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.667 7.334V2.709a5.336 5.336 0 0 1 4.626 4.625zm-1.155 1.12 3.588 3.887a5.333 5.333 0 1 1-3.766-9.633v5.293c0 .175.068.334.178.453m2.011.213h3.77a5.3 5.3 0 0 1-1.213 2.77zm5.144-.664a6.667 6.667 0 1 1-13.333 0A6.667 6.667 0 0 1 14.667 8z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconChartPie);
export default Memo;
