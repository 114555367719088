import { Dropdown, Button } from 'antd';
import { EllipsisOutlined, ExpandAltOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from './CustodianItem.module.scss';

const actionKeys = ['remove', 'view'] as const;

type Props = {
  onClick: (key: (typeof actionKeys)[number]) => void;
  canRemove?: boolean;
};

const items = (canRemove?: boolean) => [
  {
    label: (
      <div className={styles['dropdown-item']}>
        <ExpandAltOutlined className={styles.icon} />
        <strong>View</strong>
      </div>
    ),
    key: 'view',
  },
  ...(canRemove
    ? [
        {
          label: (
            <div className={`${styles['dropdown-item']} ${styles.remove}`}>
              <DeleteOutlined className={styles.icon} />
              <strong>Remove</strong>
            </div>
          ),
          key: 'remove',
        },
      ]
    : []),
];

const CustodianItemDropdown = ({ onClick, canRemove }: Props) => {
  return (
    <Dropdown
      arrow
      menu={{
        items: items(canRemove),
        onClick: (e) => {
          e.domEvent.preventDefault();
          onClick(e.key as (typeof actionKeys)[number]);
        },
      }}
      trigger={['click']}
      placement="bottomLeft"
      overlayClassName="ck-custodian-item__dropdown"
    >
      <Button type="text" onClick={(e) => e.preventDefault()}>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

export default CustodianItemDropdown;
