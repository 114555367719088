import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSourceCode = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M40 14.196h-8.235v-5.22L24.07 0H0v40h31.765v-4.627H40V14.196Zm-15.294-9.84 3.392 3.958h-3.392V4.357Zm4.706 33.291H2.352V2.353h20v8.314h7.06v3.53H4.706v21.176h24.706v2.274Zm8.235-4.627H7.06V16.549h30.588v16.47Z"
      />
      <path
        fill="currentColor"
        d="M4.707 4.784H20v2.353H4.707V4.784ZM4.707 9.49h12.94v2.353H4.708V9.49ZM21.098 18.823h2.353v11.765h-2.353V18.823ZM27.92 30.117l4.06-5.411-4.06-5.412-1.881 1.412 3 4-3 4 1.882 1.412ZM18.51 28.706l-3-4 3-4-1.883-1.412-4.059 5.412 4.06 5.412 1.881-1.412Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSourceCode);
export default Memo;
