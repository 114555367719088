import { Fragment } from 'react';

import { SuggestChangesPartyPreview, ISuggestChangesPreview } from 'src/components/Escrow/Contacts';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';
import { useContactsSuggestChanges } from 'src/context/suggest-changes';
import {
  getOrganizationSuggestedChanges,
  getSignatorySuggestedChanges,
  getNewSuggestedContacts,
  getContactsSuggestedChanges,
} from 'src/utils/destructs/suggest-changes.destructs';
import { ModalConfirmation } from 'src/components/Misc';
import { useEscrow } from 'src/context/escrow';

import { IPartyPreview } from '../Main/EscrowPartyPreviewContainer';

type Props = {
  parties: { [key in EscrowPartyTypeEnum]: IPartyPreview };
  currentCompanyRole: EscrowPartyTypeEnum;
};

const SuggestChangesPreviewContainer: React.FunctionComponent<Props> = ({ parties, currentCompanyRole }) => {
  const { suggestedChanges, existingUsers } = useContactsSuggestChanges();
  const { ui, setConfirmModalOpen } = useEscrow();

  return (
    <Fragment>
      {Object.entries(parties).map(([role, { organization, signatory, contacts, isOwnOrganization }]) => (
        <SuggestChangesPartyPreview
          key={role}
          role={role as EscrowPartyTypeEnum}
          isOwnOrganization={isOwnOrganization}
          organization={organization}
          signatory={signatory}
          contacts={contacts}
          {...(suggestedChanges && {
            suggestedChanges: {
              status: suggestedChanges?.status,
              currentCompanyRole: currentCompanyRole,
              creator: suggestedChanges?.creator,
              receiver: suggestedChanges?.receiver,
              organizationItems: getOrganizationSuggestedChanges(suggestedChanges?.payload, role),
              signatoryItems: getSignatorySuggestedChanges(suggestedChanges?.payload, role),
              newContacts: getNewSuggestedContacts(suggestedChanges?.payload, role, existingUsers),
              byContactId: (id: string) => getContactsSuggestedChanges(suggestedChanges?.payload, role, id),
            } as ISuggestChangesPreview,
          })}
        />
      ))}

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="New updates has been sent for review!"
        text="Codekeeper will now review these changes and apply them where acceptable. The updated escrow will be sent for your review."
        onClose={() => setConfirmModalOpen(false)}
      />
    </Fragment>
  );
};

export default SuggestChangesPreviewContainer;
