import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconRefresh = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <g clipPath="url(#a)">
        <path
          fill="#009CB7"
          d="M18.355 5.644A8.96 8.96 0 0 0 11.995 3 8.99 8.99 0 0 0 3 12c0 4.973 4.019 9 8.994 9 4.2 0 7.7-2.869 8.702-6.75h-2.341a6.74 6.74 0 0 1-6.36 4.5c-3.727 0-6.755-3.026-6.755-6.75s3.028-6.75 6.754-6.75c1.869 0 3.535.776 4.75 2.003l-3.624 3.622H21V3z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
const Memo = memo(SvgIconRefresh);
export default Memo;
