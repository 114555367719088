import { SaasEscrows, SaasEnvironments, saasRouteElements } from 'src/pages/Saas';
import { SaasModalContainer } from 'src/containers/Saas';
import { JourneyModal } from 'src/components/Journey/ModalLayout';

import type { RouteObject } from 'react-router-dom';

type InferPath<T> = T extends { path: infer P extends string } ? { path: `saas-${P}` } : never;

export const saasEnvironmentsRoute: RouteObject & InferPath<{ path: 'environments' }> = {
  path: 'saas-environments',
  element: <SaasEnvironments />,
};

export const saasEscrowsRoutes: RouteObject & InferPath<{ path: 'escrows' }> = {
  path: 'saas-escrows',
  element: <SaasEscrows />,
  children: [
    {
      element: <JourneyModal />,
      children: [
        {
          element: <SaasModalContainer />,
          path: ':id/*',
          children: saasRouteElements,
        },
      ],
    },
  ],
};
