import { useState, useEffect } from 'react';

const requiredCountries = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Cyprus',
  'Czech Republic',
  'Germany',
  'Denmark',
  'Estonia',
  'Spain',
  'Finland',
  'France',
  'Greece',
  'Croatia',
  'Hungary',
  'Ireland',
  'Italy',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Sweden',
  'Slovenia',
  'Slovakia',
];

const useRegistrationNum = (
  country: string,
): {
  isRegistrationNumRequired: boolean;
} => {
  const [isRegistrationNumRequired, setIsRegistrationNumRequired] = useState(false);

  useEffect(() => {
    if (requiredCountries.includes(country)) return setIsRegistrationNumRequired(true);
    setIsRegistrationNumRequired(false);
  }, [country]);

  return {
    isRegistrationNumRequired,
  };
};

export { useRegistrationNum };
