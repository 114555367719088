import { Dropdown, Button, Tooltip } from 'antd';
import { MoreOutlined, ExpandAltOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from './BillingPlan.module.scss';

const actionKeys = ['details', 'cancel'] as const;

type Props = {
  onClick: (key: (typeof actionKeys)[number]) => void;
  disable?: {
    [key in (typeof actionKeys)[number]]?: string;
  };
};

const CancelPlanItem = ({ disable }: { disable: Props['disable'] }) => {
  if (disable?.cancel) {
    return (
      <Tooltip placement="bottom" title={disable.cancel}>
        <div className={`${styles['dropdown-item']} ${styles.disabled}`}>
          <DeleteOutlined className={styles.icon} />
          <strong>Cancel Plan</strong>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={`${styles['dropdown-item']} ${styles.danger}`}>
      <DeleteOutlined className={styles.icon} />
      <strong>Cancel Plan</strong>
    </div>
  );
};

const items = (disable: Props['disable']) => [
  {
    label: (
      <div className={styles['dropdown-item']}>
        <ExpandAltOutlined className={styles.icon} />
        <strong>See Pricing Details</strong>
      </div>
    ),
    key: actionKeys[0],
  },
  {
    label: <CancelPlanItem disable={disable} />,
    key: actionKeys[1],
    disabled: Boolean(disable?.cancel),
  },
];

const BillingPlanDropdown = ({ disable, onClick }: Props) => {
  return (
    <Dropdown
      arrow
      menu={{
        items: items(disable),
        onClick: (e) => onClick(e.key as (typeof actionKeys)[number]),
      }}
      trigger={['click']}
      placement="bottomLeft"
      overlayClassName="ck-plan__dropdown"
    >
      <Button type="text" shape="circle" className={styles['toggle-dropdown']}>
        <MoreOutlined className={styles['icon-more']} />
      </Button>
    </Dropdown>
  );
};

export default BillingPlanDropdown;
