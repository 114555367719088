import { Form, Input, Button } from 'antd';

import styles from './BillingForms.module.scss';

type Props = {
  onSubmit: (values: { code: string }) => void;
  onCancel: () => void;
  isLoading?: boolean;
};

const BillingCouponForm = ({ onSubmit, onCancel, isLoading = false }: Props) => {
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item name="code" rules={[{ required: true }]}>
        <Input placeholder="Enter coupon code" />
      </Form.Item>
      <div className={`d-flex justify-content-end ${styles['btn-group']}`}>
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Apply
        </Button>
      </div>
    </Form>
  );
};

export default BillingCouponForm;
