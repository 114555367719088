import dayjs from 'dayjs';

import { SaasProviderStatusEnum, ISaasProvider } from 'src/graphql/schema';
import { providerTextHelper } from 'src/components/Saas/Provider/saas-providers.enum';

import type { IProviderCardItem } from 'src/components/Saas/Provider';

export function getProvider(provider: Partial<ISaasProvider>): IProviderCardItem {
  const completedDate = dayjs(provider.completedAt).format('DD/MM/YYYY');

  return {
    id: provider.id || '',
    name: provider.platform || '',
    status: provider.status as SaasProviderStatusEnum,
    description:
      provider.status === SaasProviderStatusEnum.SetupCompleted
        ? providerTextHelper[SaasProviderStatusEnum.SetupCompleted].replace(/{date}/, completedDate)
        : providerTextHelper[provider.status!],
  };
}
