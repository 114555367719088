import { capitalize } from 'lodash';

import { IconFolderCheck, IconMultiFolderCheck, IconFolderRemove } from 'src/components/Svg/Icons';

import type { ICustomCheckboxWithIcon } from 'src/components/Form';
import type { EscrowPartyTypeEnum } from 'src/graphql/schema';

export const escrowNotificationsTypeOptions = (partyType: EscrowPartyTypeEnum): ICustomCheckboxWithIcon[] => [
  {
    heading: 'First deposit only',
    text: `${capitalize(partyType)} gets escrow activation confirmation email with deposited asset info.`,
    value: 'first_deposit',
    iconType: 'regular',
    icon: IconFolderCheck,
  },
  {
    heading: 'Each deposit',
    text: `${capitalize(partyType)} gets escrow activation confirmation email with deposited asset info + email sent on each successful deposit.`,
    value: 'each_deposit',
    iconType: 'regular',
    icon: IconMultiFolderCheck,
  },
  {
    heading: 'No notifications',
    text: `${capitalize(partyType)} gets escrow activation confirmation email without deposited asset info.`,
    value: 'no_deposit_notifications',
    iconType: 'regular',
    icon: IconFolderRemove,
  },
];

export const escrowNotificationsDictionary = (
  partyType: EscrowPartyTypeEnum,
): { [key: string]: ICustomCheckboxWithIcon } =>
  escrowNotificationsTypeOptions(partyType).reduce((acc, item) => ({ ...acc, [item.value]: item }), {});

export const disruptedNotificationsOptions = [
  {
    label: 'No notifications',
    value: 'no_distruption_notifications',
  },
  {
    label: 'Immediate Notification',
    value: 'immediate_notification',
  },
];

export const disruptedNotificationsDictionary: { [key: string]: string } = disruptedNotificationsOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item.label }),
  {},
);

export const gracePeriodOptions = [
  {
    label: 'No grace period',
    value: 'none',
  },
  {
    label: 'Notification after a grace period of 24 hours',
    value: 'twenty_four_hours',
  },
  {
    label: 'Notification after a grace period of 7 days',
    value: 'seven_days',
  },
  {
    label: 'Notification after a grace period of 30 days',
    value: 'thirty_days',
  },
];

export const gracePeriodDictionary: { [key: string]: string } = gracePeriodOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item.label }),
  {},
);
