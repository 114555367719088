import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoAdyen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="tiny-ps"
    viewBox="0 0 1579 1578"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#0abf53"
      fillRule="evenodd"
      d="M1578.81 315.05V1577.5H316.65C142.52 1577.5 0 1434.95 0 1260.77V907.81c0-174.18 142.52-316.73 316.65-316.73h341.57v502.27c0 49.75 40.68 90.49 90.48 90.49h171.88V484.72c0-49.75-40.68-90.49-90.47-90.49H22.62V.57h1239.54c174.19 0 316.65 142.55 316.65 314.48"
      className="shp0"
    />
  </svg>
);
const Memo = memo(SvgLogoAdyen);
export default Memo;
