import { IJourneyMenuItem } from 'src/components/Journey/Menu';
import { SaasRoutesEnum } from 'src/pages/Saas';
import { IconProvider, IconCertificate } from 'src/components/Svg/Icons';

export const saasMenuItems: Array<IJourneyMenuItem & { path: SaasRoutesEnum }> = [
  {
    path: SaasRoutesEnum.Providers,
    label: 'Providers',
    icon: IconProvider,
  },
  {
    path: SaasRoutesEnum.Certificate,
    label: 'Certificate',
    icon: IconCertificate,
    isDisabled: true,
  },
];
