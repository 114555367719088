import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSettingO = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 6.083a1.917 1.917 0 1 0 0 3.834 1.917 1.917 0 0 0 0-3.834M4.584 8a3.417 3.417 0 1 1 6.833 0 3.417 3.417 0 0 1-6.833 0"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m5.91 2.37.974.586c.324.195.695.297 1.073.297h.088c.377 0 .746-.102 1.07-.295l.97-.582 1.74 1.003-.02 1.136c-.005.378.091.75.28 1.078l.044.075c.188.327.462.596.791.779l.991.552.005 2.003-.994.55c-.33.183-.605.453-.794.78l-.043.075c-.189.327-.286.7-.28 1.077l.02 1.139-1.734 1.006-.975-.585a2.1 2.1 0 0 0-1.072-.298h-.088c-.377 0-.747.103-1.07.296l-.971.582-1.74-1.003.02-1.133a2.1 2.1 0 0 0-.275-1.07l-.047-.08a2.1 2.1 0 0 0-.795-.786l-.989-.55-.004-2.004.993-.55c.33-.184.605-.453.794-.78l.044-.075c.188-.327.285-.7.279-1.078l-.02-1.138zM5.198 1.05a1.42 1.42 0 0 1 1.44.01l1.018.611c.09.055.194.083.3.083h.088c.105 0 .209-.028.3-.082l1.012-.607c.44-.264.99-.268 1.435-.012l1.825 1.052c.446.257.717.736.709 1.25l-.02 1.187a.6.6 0 0 0 .078.301l.044.076c.052.092.129.167.221.218l1.034.575c.449.25.727.722.728 1.235l.005 2.101c.001.517-.279.993-.73 1.243l-1.037.574a.6.6 0 0 0-.222.219l-.043.075a.6.6 0 0 0-.078.301l.02 1.187c.008.514-.262.992-.706 1.25l-1.818 1.055a1.42 1.42 0 0 1-1.44-.01l-1.018-.611a.6.6 0 0 0-.3-.084h-.088a.6.6 0 0 0-.3.083l-1.012.607c-.441.264-.99.268-1.436.012l-1.824-1.052a1.42 1.42 0 0 1-.71-1.251l.02-1.182a.6.6 0 0 0-.076-.3l-.046-.08a.6.6 0 0 0-.223-.22l-1.032-.575A1.42 1.42 0 0 1 .59 9.054L.584 6.952a1.42 1.42 0 0 1 .73-1.242l1.037-.574a.6.6 0 0 0 .222-.219l.044-.075a.6.6 0 0 0 .078-.301l-.02-1.188a1.42 1.42 0 0 1 .705-1.249z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSettingO);
export default Memo;
