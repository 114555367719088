import { ApolloLink, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import fetchTokenStrategy from 'src/utils/fetch-token-strategy';

import { ClientChangesRequestedDocument, IClientChangesRequestedQuery } from '../schema';

import { suggestChangesVar } from './cache';

const fetchToken = async <TUri extends string, TOptions extends RequestInit>(uri: TUri, options: TOptions) => {
  const token = await fetchTokenStrategy();
  const optionsHeaders = options.headers as Record<string, string>;

  optionsHeaders['Authorization'] = `Bearer ${token}`;

  const impersonatedUserID = localStorage.getItem('impersonatedUserID');

  if (impersonatedUserID) {
    optionsHeaders['X-Impersonated-User-ID'] = impersonatedUserID;
  }

  return fetch(uri, options);
};

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        suggestedChanges: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        clientChangesRequested: {
          read() {
            return suggestChangesVar();
          },
        },
        company: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        billing: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    SuggestChange: {
      keyFields: ['id', 'partyStatus'],
    },
    SuggestChangePage: {
      keyFields: [
        ['nodes', ['id']],
        ['nodes', ['partyStatus']],
      ],
    },
    Backup: {
      fields: {
        backupAssets: {
          merge(_, incoming) {
            return incoming;
          },
        },
        backupMembers: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    Depositor: {
      fields: {
        contacts: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    Beneficiary: {
      fields: {
        contacts: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    AssetReportGroup: {
      fields: {
        assetReports: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    BackupAssetReportGroup: {
      fields: {
        backupAssetReports: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    SaasEnvironmentPolicy: {
      merge: true,
    },
    BackupPolicy: {
      merge: true,
    },
    CustodianProfile: {
      fields: {
        policy: {
          merge(_, incoming) {
            return incoming;
          },
        },
        custodianOperations: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

export const apolloClient = new ApolloClient({
  cache: apolloCache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      graphQLErrors?.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: `${import.meta.env.VITE_BACKEND_URL}/graphql`,
      fetch: fetchToken,
    }),
  ]),
  resolvers: {
    Asset: {
      removalStatus({ id }) {
        const dataRequestDeletedAssets = apolloCache.readQuery({
          query: ClientChangesRequestedDocument,
        }) as IClientChangesRequestedQuery;

        const assets =
          dataRequestDeletedAssets.clientChangesRequested.deposits?.payload?.['deleted_assets'].map(
            ({ id }: { id: string }) => id,
          ) || [];

        if (!assets.length) return '';

        if (assets.includes(id)) {
          return dataRequestDeletedAssets.clientChangesRequested.deposits?.status;
        }
      },
    },
  },
});

const writeInitialData = async () => {};

writeInitialData();

apolloClient.onResetStore(writeInitialData);
