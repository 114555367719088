import React from 'react';
import { ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './AlertActionMessage.module.scss';

const bgColors = ['yellow', 'orange', 'red', 'purple', 'green', 'blue', 'gray', 'light-blue'] as const;
const colors = ['dark-green', 'red'] as const;

export type Props = (
  | { iconType?: 'warn' | 'success' }
  | {
      iconType: 'custom';
      iconColor: (typeof colors)[number];
      icon:
        | React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>
        | React.ForwardRefExoticComponent<Pick<AntdIconProps, keyof AntdIconProps>>;
    }
) &
  (
    | {
        children: React.ReactNode;
        text?: undefined;
      }
    | { text: string; children?: undefined }
  ) & {
    bgColor: (typeof bgColors)[number];
    color?: (typeof colors)[number] | (string & NonNullable<unknown>);
    className?: string;
  };

const AlertActionMessage: React.FunctionComponent<Props> = (props) => {
  const { className, iconType, bgColor, color = 'dark-green' } = props;
  const alertClasses = classNames(styles.alert, className, styles[`bg--${bgColor}`], styles[`color--${color}`]);
  const iconClasses = classNames(styles.icon, {
    [styles.warn]: iconType === 'warn',
    [styles.success]: iconType === 'success',
    [styles[`color--${iconType === 'custom' && props.iconColor}`]]: iconType === 'custom' && props.iconColor,
  });
  const iconTypeEnums = {
    warn: ExclamationCircleFilled,
    success: CheckCircleFilled,
    custom: iconType === 'custom' && props?.icon && props.icon,
  };
  const Icon = iconType && iconType in iconTypeEnums ? iconTypeEnums[iconType] : null;

  return (
    <div className={alertClasses}>
      <p>
        {Icon && <Icon className={iconClasses} />}
        {props.text && props.text}
        {props.children && props.children}
      </p>
    </div>
  );
};

export default AlertActionMessage;
