import { Tag } from 'antd';

import styles from './IntegrationTag.module.scss';

const IntegrationTag = ({
  color,
  text,
  isCustom,
  isManual,
}: {
  color: string;
  text: string;
  isCustom: boolean;
  isManual: boolean;
}) => {
  return (
    <div className={styles.IntegrationTag}>
      <Tag
        className={styles.customTag}
        style={isCustom || isManual ? { color: '#164995' } : { color: '#00889F' }}
        color={color}
      >
        {text}
      </Tag>
    </div>
  );
};

export default IntegrationTag;
