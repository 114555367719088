import { Fragment } from 'react';

import {
  DepositNotificationsTypeSelect,
  DisruptedNotificationSelect,
  DepositInfoDisplaySelect,
  StorageRegionSelect,
} from 'src/components/Escrow/Notifications';
import { useEscrow } from 'src/context/escrow';
import { useNotificationsSuggestChanges } from 'src/context/suggest-changes';
import { EscrowStatusEnum, EscrowPartyTypeEnum, AgreementFormEnum, DepositNotificationEnum } from 'src/graphql/schema';
import styles from 'src/components/Escrow/Escrow.module.scss';

const EditableNotificationsChangesContainer = () => {
  const { escrow } = useEscrow();
  const {
    state,
    updateDepositDisplay,
    updateBeneficiaryDepositNotification,
    updateBeneficiaryDisruptNotification,
    updateBeneficiaryDisruptNotificationGrace,
    updateDepositorDepositNotification,
    updateDepositorDisruptNotification,
    updateDepositorDisruptNotificationGrace,
    updateStorageRegion,
  } = useNotificationsSuggestChanges();

  const isTripartite = escrow.agreement?.agreementForm === AgreementFormEnum.Tripartite;

  return (
    <Fragment>
      {isTripartite && (
        <div className={styles.block}>
          <DepositInfoDisplaySelect
            selectedDisplay={state.showDepositInformation!}
            onChange={(showDepositInformation) => updateDepositDisplay(showDepositInformation)}
          />
          {/* Beneficiary disrupt notifications */}
          {state.showDepositInformation && (
            <Fragment>
              <DepositNotificationsTypeSelect
                partyType={EscrowPartyTypeEnum.Beneficiary}
                selectedType={state.beneficiaryDepositNotification!}
                onChange={(depositNotification) =>
                  updateBeneficiaryDepositNotification(depositNotification as DepositNotificationEnum)
                }
                textBlock={{
                  as: 'h4',
                  titleColor: 'dark-blue',
                }}
              />
              <DisruptedNotificationSelect
                partyType={EscrowPartyTypeEnum.Beneficiary}
                selectedDisruptionNotification={state.beneficiaryDisruptionNotification}
                selectedGracePeriod={state.beneficiaryDisruptionNotificationGracePeriod}
                onChangeDisruptionNotification={(beneficiaryDisruptionNotification) =>
                  updateBeneficiaryDisruptNotification(beneficiaryDisruptionNotification)
                }
                onChangeGracePeriod={(beneficiaryDisruptionNotificationGracePeriod) =>
                  updateBeneficiaryDisruptNotificationGrace(beneficiaryDisruptionNotificationGracePeriod)
                }
                textBlock={{
                  as: 'h4',
                  titleColor: 'dark-blue',
                }}
              />
            </Fragment>
          )}
        </div>
      )}

      <div className={styles.block}>
        {/* Depositor deposit notifications type */}
        <DepositNotificationsTypeSelect
          partyType={EscrowPartyTypeEnum.Depositor}
          selectedType={state.depositorDepositNotification!}
          onChange={(depositNotification) =>
            updateDepositorDepositNotification(depositNotification as DepositNotificationEnum)
          }
          textBlock={{
            as: 'h4',
            titleColor: 'dark-blue',
          }}
        />
        {/* Depositor disrupt notifications */}
        <DisruptedNotificationSelect
          partyType={EscrowPartyTypeEnum.Depositor}
          selectedDisruptionNotification={state.depositorDisruptionNotification}
          selectedGracePeriod={state.depositorDisruptionNotificationGracePeriod}
          onChangeDisruptionNotification={(depositorDisruptionNotification) =>
            updateDepositorDisruptNotification(depositorDisruptionNotification)
          }
          onChangeGracePeriod={(depositorDisruptionNotificationGracePeriod) =>
            updateDepositorDisruptNotificationGrace(depositorDisruptionNotificationGracePeriod)
          }
          textBlock={{
            as: 'h2',
          }}
        />
      </div>

      {escrow.status === EscrowStatusEnum.Onboarding && (
        <StorageRegionSelect
          selectedRegion={state.storageRegion!}
          onChange={(storageRegion) => updateStorageRegion(storageRegion)}
        />
      )}
    </Fragment>
  );
};

export default EditableNotificationsChangesContainer;
