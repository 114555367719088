import { Table, Button, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useSuspenseQuery } from '@apollo/client';
import { useTransition } from 'react';

import { InvoicesLayout } from 'src/components/Invoices';
import { withSuspense } from 'src/hoc';
import {
  useInvoicePdfLazyQuery,
  InvoicesDocument,
  SubscriptionNextDateDocument,
  InvoiceStatusEnum,
} from 'src/graphql/schema';
import formatPrice from 'src/utils/currency-code-to-symbol';
import { LabelText } from 'src/components/Label';

import type { IInvoicesQuery, ISubscriptionNextDateQuery } from 'src/graphql/schema';
import type { TableColumnsType } from 'antd';

import { labelStatusMap } from './model/invoices.enum';

type IInvoiceRow = {
  id: string;
  date: string;
  amount: string;
  status: InvoiceStatusEnum;
};

const BillingInvoicesContainer = () => {
  const [isPending, startTransition] = useTransition();
  const { data: dataInvoices, fetchMore } = useSuspenseQuery<IInvoicesQuery>(InvoicesDocument);
  const { data: dataSubscriptions } = useSuspenseQuery<ISubscriptionNextDateQuery>(SubscriptionNextDateDocument);
  const [getInvoicePdf, { loading: loadingPdf }] = useInvoicePdfLazyQuery();

  const onLoadMore = async () => {
    startTransition(() => {
      fetchMore({
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          invoices: {
            ...fetchMoreResult.invoices,
            nodes: [...previousResult.invoices.nodes, ...fetchMoreResult.invoices.nodes],
            nextOffset: fetchMoreResult.invoices.nextOffset,
          },
        }),
        variables: {
          offset: dataInvoices.invoices.nextOffset,
        },
      });
    });
  };

  const columns: TableColumnsType<IInvoiceRow> = [
    {
      title: 'Invoice #',
      dataIndex: 'id',
      render: (id: string) => '#' + id,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: InvoiceStatusEnum) => {
        const { text, bgColor } = labelStatusMap[status];

        return <LabelText text={text} bgColor={bgColor} />;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: string) => {
        const handleDownload = async () => {
          const { data } = await getInvoicePdf({ variables: { id } });
          window.location.href = data?.invoicePdf?.downloadUrl ?? '';
        };

        return (
          <Button type="link" onClick={handleDownload}>
            Download
          </Button>
        );
      },
    },
  ];

  const invoicesData: IInvoiceRow[] = dataInvoices.invoices.nodes.map((invoice) => ({
    id: invoice.id,
    date: dayjs(invoice.date).format('MMMM DD, YYYY'),
    amount: formatPrice(invoice.currencyCode, invoice.total, true),
    status: invoice.status,
  }));

  const nextBillingDatesToUnix =
    dataSubscriptions.billing?.subscriptions?.map((subscription) => dayjs(subscription.nextBillingAt).unix()) ?? [];

  const nextBillingDate = nextBillingDatesToUnix.length ? dayjs.unix(Math.min(...nextBillingDatesToUnix)) : undefined;

  return (
    <InvoicesLayout date={nextBillingDate}>
      <Table
        className="ck-invoice__table"
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        columns={columns}
        dataSource={invoicesData}
        pagination={false}
        loading={loadingPdf}
        rowHoverable={false}
        rowKey="id"
      />

      {dataInvoices.invoices.nextOffset && (
        <Button type="link" onClick={onLoadMore} loading={isPending}>
          Load More
        </Button>
      )}
    </InvoicesLayout>
  );
};

const InvoicesSkeleton = () => (
  <Skeleton
    active
    paragraph={{
      rows: 4,
    }}
  />
);

export default withSuspense(BillingInvoicesContainer, InvoicesSkeleton);
