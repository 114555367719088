import { Modal } from 'antd';
import { useReadQuery } from '@apollo/client';
import { useRef, useState } from 'react';

import { useMigrations } from 'src/context/migrations';
import {
  MigrationConnectForm,
  IMigrationConnectForm,
  MigrationConnectFailure,
  MigrationConnectSuccess,
} from 'src/components/Migration';
import {
  useMigrateLegacyRepoMutation,
  useMigrateLegacyReposMutation,
  useOauthReposConnLazyQuery,
} from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';
import { removeLegacyReposCache } from 'src/graphql/client/cache';
import { message } from 'src/components/Misc';

import type { IIntegrationsQuery, ILegacyRepo } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';
import type { FormInstance } from 'antd/lib';

const MigrationsConnectContainer = ({ queryRef }: { queryRef: QueryRef<IIntegrationsQuery> }) => {
  const formRef = useRef<FormInstance>(null);
  const [activeStep, setActiveStep] = useState<'submit' | 'success' | 'failure'>('submit');
  const [currentFailedRepoIds, setCurrentFailedRepoIds] = useState<string[]>([]);
  const [showRepositoryField, setShowRepositoryField] = useState(false);

  const { data: integrationsData } = useReadQuery<IIntegrationsQuery>(queryRef);
  const { ui, legacy } = useMigrations();
  const [migrateSingleRepo, { loading: loadingSingleRepo }] = useMigrateLegacyRepoMutation();
  const [migrateMultipleRepos, { loading: loadingMultipleRepos }] = useMigrateLegacyReposMutation();
  const [getOauthReposConn, { loading: loadingOptions, data: oauthReposData }] = useOauthReposConnLazyQuery();

  const singleLegacyItemPlatform = legacy.repos.find((repo) => repo.id === ui.selectedRepoId)?.platform ?? '';
  const multiLegacyItemsPlatforms =
    ui.selectedRepoIds?.map((id) => legacy.repos.find((repo) => repo.id === id)?.platform || '') ?? [];
  const repositoryOptions =
    oauthReposData?.oauthReposConn.repositories.map(({ id, fullName }) => ({
      value: id,
      label: fullName ?? '',
    })) ?? [];

  const getFilteredIntegrationOptions = (platforms: string[]) => {
    const set = new Set(platforms);
    const integrationOptions =
      integrationsData.integrations.nodes.map(({ id, provider, accountName }) => ({
        value: id,
        label: `${provider} - ${accountName}`,
      })) ?? [];

    return integrationOptions.filter(({ label }) => set.has(label.split(' - ')[0]));
  };

  const onSubmitSingle = async (integrationId: string, oauthRepoId?: string) => {
    try {
      const { data } = await migrateSingleRepo({
        variables: {
          integrationId,
          legacyRepoId: ui.selectedRepoId ?? '',
          ...(oauthRepoId && { oauthRepoId }),
        },
      });
      if (data?.migrateLegacyRepo?.success) {
        setActiveStep('success');
        removeLegacyReposCache(ui?.selectedRepoId ?? '', { ...legacy.variables });
      } else throw new ClientError(data?.migrateLegacyRepo?.errors?.[0]);
    } catch (error: unknown) {
      // Need to switch to oauth repos, if integrations fails to connect
      if (error instanceof Error) {
        if (error.message === "Couldn't find a matching repository") {
          setShowRepositoryField(true);
          getOauthReposConn({
            variables: {
              integrationId,
            },
          });
        } else getApiError(error, message.error);
      }
    }
  };

  const onSubmitMultiple = async (integrationId: string) => {
    try {
      const { data } = await migrateMultipleRepos({
        variables: {
          integrationId,
          legacyRepoIds: ui.selectedRepoIds ?? [],
        },
      });
      if (data?.migrateLegacyRepos?.success) {
        const migratedRepoIds = data.migrateLegacyRepos.migratedLegacyRepos.map(({ id }) => id);

        // Remove migrated repos from cache
        removeLegacyReposCache(migratedRepoIds, { ...legacy.variables });

        // Show success or failure state
        if (migratedRepoIds.length === ui.selectedRepoIds?.length) setActiveStep('success');
        else {
          const failedRepoIds = ui.selectedRepoIds?.filter((id) => !migratedRepoIds.includes(id));

          ui.removeSelection();
          setActiveStep('failure');
          setCurrentFailedRepoIds(failedRepoIds ?? []);
        }
      } else {
        const error = data?.migrateLegacyRepos?.errors?.[0];

        // If was selected only one migration, show the next type of message
        if (error === "Couldn't find matching repositories") {
          setActiveStep('failure');
          setCurrentFailedRepoIds(ui?.selectedRepoIds ?? []);
        } else throw new ClientError(error);
      }
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  const onSubmit = async ({ integrationId, oauthRepoId }: IMigrationConnectForm) => {
    if (ui.selection === 'single') {
      await onSubmitSingle(integrationId, oauthRepoId);
    } else if (ui.selection === 'multiple') {
      await onSubmitMultiple(integrationId);
    }
  };

  const handleClose = () => {
    ui.setInitial();
    formRef.current?.resetFields();
    setActiveStep('submit');
    setCurrentFailedRepoIds([]);
    setShowRepositoryField(false);
  };

  const currentFailedRepoItems = currentFailedRepoIds.reduce(
    (acc, repoId) => {
      const repo = legacy.repos.find((repo) => repo.id === repoId);

      if (repo) {
        acc.push({
          id: repo.id,
          title: getFailedRepoName(repo),
        });
      }

      return acc;
    },
    [] as { id: string; title: string }[],
  );

  const stepMap = {
    submit: {
      component: MigrationConnectForm,
      props: {
        onSubmit,
        integrationOptions:
          ui.selection === 'single'
            ? getFilteredIntegrationOptions([singleLegacyItemPlatform])
            : getFilteredIntegrationOptions(multiLegacyItemsPlatforms),
        repositoryOptions,
        isLoading: loadingSingleRepo || loadingMultipleRepos,
        loadingOptions,
        ref: formRef,
        showRepositoryField,
      },
    },
    success: {
      component: MigrationConnectSuccess,
      props: {
        onClick: handleClose,
      },
    },
    failure: {
      component: MigrationConnectFailure,
      props: {
        items: currentFailedRepoItems,
        onClick: handleClose,
      },
    },
  };

  const getActiveStep = <TComponent extends React.ElementType, TProps extends Record<string, unknown>>(
    key: typeof activeStep,
  ): {
    component: TComponent;
    props: TProps;
  } => ({
    component: stepMap[key].component as TComponent,
    props: stepMap[key].props as unknown as TProps,
  });

  const ActiveStep = getActiveStep(activeStep).component;

  return (
    <Modal open={ui.isConnectModalOpened} onCancel={handleClose} footer={null} className="ck-connect-integration">
      <ActiveStep {...getActiveStep(activeStep).props} />
    </Modal>
  );
};

function getFailedRepoName(repo: ILegacyRepo) {
  return [repo.title, repo.escrow.depositor?.companyName, repo.escrow.beneficiary?.companyName]
    .filter(Boolean)
    .join(' - ');
}

export default MigrationsConnectContainer;
