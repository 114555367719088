import dayjs from 'dayjs';

import { getEvent } from 'src/utils/helpers';
import { IActivity } from 'src/graphql/schema';

import styles from './ProfileActivity.module.scss';

type Props = IActivity;

const ProfileActivityRow = (props: Props) => {
  return (
    <div className={styles.row} data-testid="activity-item">
      <p className={styles.text}>
        {getEvent(
          props as {
            klass: string;
            eventType: string;
            payload: { depositor: { companyName: string }; beneficiary: { companyName: string } };
          },
        )}
      </p>
      <span className={styles.date}>{dayjs(props.createdAt, 'YYYYMMDD').fromNow()}</span>
    </div>
  );
};

export default ProfileActivityRow;
