import { redirect } from 'react-router-dom';

import { EscrowModalContainer } from 'src/containers/Escrow';
import { EscrowNew, escrowRouteElements, EscrowRoutesEnum } from 'src/pages/Escrow';
import { JourneyModal } from 'src/components/Journey/ModalLayout';

import Escrows from '../pages/Escrows/Escrows';

import type { RouteObject } from 'react-router-dom';

export const escrowRoutes: RouteObject = {
  path: 'escrows',
  element: <Escrows />,
  children: [
    {
      element: <JourneyModal />,
      children: [
        {
          element: <EscrowModalContainer />,
          children: [
            {
              path: 'new',
              element: <EscrowNew />,
            },
            {
              loader: ({ params }) => {
                const { escrowId = null, '*': splat = '' } = params;

                if (escrowId && !splat) return redirect(`/escrows/${escrowId}/${EscrowRoutesEnum.Basics}`);

                const path = splat?.split('/').length > 1 ? splat?.split('/')[0] : splat;

                if (!Object.values(EscrowRoutesEnum).includes(path as EscrowRoutesEnum)) {
                  return redirect('/404');
                }

                return null;
              },
              path: ':escrowId/*',
              children: escrowRouteElements,
            },
          ],
        },
      ],
    },
  ],
};
