import { forwardRef, Fragment, useImperativeHandle, useRef, ElementRef, useEffect, useId } from 'react';
import { Form, Input, Select, Row, Col } from 'antd';

import { IContactDetail } from '../';

import { optionsRole } from './escrow-contacts.enum';
import styles from './EscrowContactsForm.module.scss';

export interface IAddContactForm {
  name: string;
  email: string;
  role: string;
  phone?: string;
}

export type Props = {
  onSubmit: (data: IAddContactForm) => Promise<void>;
  initialValues?: IContactDetail;
  isDynamic?: boolean;
};

type Ref = { onSubmit: (() => void) | null };

const AddNewContactForm = forwardRef<Ref, Props>(({ onSubmit, initialValues, isDynamic = false }, ref) => {
  const [form] = Form.useForm<IAddContactForm>();
  const formRef = useRef<ElementRef<typeof Form<IAddContactForm>>>(null);
  const formId = useId();

  useEffect(() => {
    if (initialValues) {
      const { name, email, phone, role } = initialValues;

      form.setFieldsValue({
        name,
        email,
        role,
        phone: phone || '',
      });
    }
  }, [form, initialValues]);

  useImperativeHandle(ref, () => ({ onSubmit: formRef.current && formRef.current.submit }), []);

  return (
    <Form
      form={form}
      layout="vertical"
      className="ck-escrow-contacts__form"
      onFinish={onSubmit}
      ref={formRef}
      aria-label="add-new-contact-form"
    >
      <Row className={styles['row-md']}>
        <Col span={12} className={styles['col-md']}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]} htmlFor={`name-${formId}`}>
            <Input disabled={isDynamic} id={`name-${formId}`} />
          </Form.Item>
        </Col>

        <Col span={12} className={styles['col-md']}>
          <Form.Item name="role" label="Role" rules={[{ required: true }]} htmlFor={`role-${formId}`}>
            <Select placeholder="Select role" options={optionsRole} id={`role-${formId}`} />
          </Form.Item>
        </Col>

        <Col span={12} className={styles['col-md']}>
          <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]} htmlFor={`email-${formId}`}>
            <Input disabled={isDynamic} id={`email-${formId}`} />
          </Form.Item>
        </Col>

        <Col span={12} className={styles['col-md']}>
          <Form.Item
            name="phone"
            label={
              <Fragment>
                Phone Number<sub>optional</sub>
              </Fragment>
            }
            rules={[{ required: false }]}
            htmlFor={`phone-${formId}`}
          >
            <Input disabled={isDynamic} id={`phone-${formId}`} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

AddNewContactForm.displayName = 'AddNewContactForm';

export default AddNewContactForm;
