import { useCallback, useEffect } from 'react';

import { useNotificationsSuggestChangesSlice } from 'src/slices';
import { useEscrow } from 'src/context/escrow';
import { useSuggestedChanges } from 'src/hooks/use-client-suggested-changes';
import {
  SuggestedChangeChangeTypeEnum,
  useCreateSuggestedChangeEscrowMutation,
  useClientChangesRequestedQuery,
  INotificationChangeInput,
  IClientChangesRequestedQuery,
  ISuggestedChangesQuery,
  SuggestedChangeStatusEnum,
  EscrowPartyTypeEnum,
  IDepositSettingsInput,
} from 'src/graphql/schema';
import { writeNewSuggestChanges, suggestChangesVar } from 'src/graphql/client/cache';

import { createRequiredContext } from '../createRequiredContext';

const [useNotificationsSuggestChanges, NotificationsSuggestChangesProvider] = createRequiredContext<
  Omit<ReturnType<typeof useNotificationsSuggestChangesSlice>, 'setInitialData'> & {
    isLoading: boolean;
    suggestedChanges: IClientChangesRequestedQuery['clientChangesRequested']['notifications'];
    sendSuggestedChanges: () => Promise<boolean>;
  }
>();

const NotificationsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { escrow } = useEscrow();
  const { state, setInitialData, ...actions } = useNotificationsSuggestChangesSlice();

  useEffect(() => {
    const initialSettings: IDepositSettingsInput = {
      beneficiaryDepositNotification: escrow.beneficiaryDepositNotification,
      showDepositInformation: escrow.showDepositInformation,
      storageRegion: escrow.storageRegion,
      beneficiaryDisruptionNotification: escrow.beneficiaryDisruptionNotification,
      beneficiaryDisruptionNotificationGracePeriod: escrow.beneficiaryDisruptionNotificationGracePeriod,
      depositorDepositNotification: escrow.depositorDepositNotification,
      depositorDisruptionNotification: escrow.depositorDisruptionNotification,
      depositorDisruptionNotificationGracePeriod: escrow.depositorDisruptionNotificationGracePeriod,
    };

    setInitialData(initialSettings);
  }, []);
  const [createSuggestedChanges] = useCreateSuggestedChangeEscrowMutation();

  const onCompletedSuggestedChanges = useCallback((suggestedChanges: ISuggestedChangesQuery['suggestedChanges']) => {
    const availableChanges = suggestedChanges.nodes.filter(
      ({ partyStatus }) =>
        partyStatus === SuggestedChangeStatusEnum.Pending || partyStatus === SuggestedChangeStatusEnum.Accepted,
    );

    if (availableChanges.length) {
      writeNewSuggestChanges(suggestChangesVar)('notifications', {
        id: availableChanges[0].id,
        creator: availableChanges[0].creatorType,
        status: availableChanges[0].partyStatus as SuggestedChangeStatusEnum,
        payload: availableChanges[0].payload,
        receiver: availableChanges[0].receiverType as EscrowPartyTypeEnum,
      });
    }
  }, []);

  const { loading } = useSuggestedChanges({
    escrowId: escrow.id,
    changeType: SuggestedChangeChangeTypeEnum.NotificationSuggestedChange,
    onCompleted: onCompletedSuggestedChanges,
  });

  const { data: changesRequestedData } = useClientChangesRequestedQuery();

  const sendSuggestedChanges = useCallback(async () => {
    const notificationSuggestedChange: INotificationChangeInput = Object.entries(state).reduce((acc, [key, value]) => {
      if (value !== escrow[key as keyof typeof escrow])
        return {
          ...acc,
          [key]: value,
        };

      return acc;
    }, {});

    try {
      const { data } = await createSuggestedChanges({
        variables: {
          escrowId: escrow.id,
          suggestedChangeParams: {
            notificationSuggestedChange,
          },
        },
      });

      if (data?.createSuggestedChangeEscrow?.errors?.length) {
        throw Error(data?.createSuggestedChangeEscrow?.errors[0]);
      } else {
        writeNewSuggestChanges(suggestChangesVar)('notifications', {
          id: data?.createSuggestedChangeEscrow?.suggestedChange?.id,
          creator: data?.createSuggestedChangeEscrow?.suggestedChange?.creatorType,
          status: data?.createSuggestedChangeEscrow?.suggestedChange?.partyStatus as SuggestedChangeStatusEnum,
          payload: data?.createSuggestedChangeEscrow?.suggestedChange?.payload,
          receiver: data?.createSuggestedChangeEscrow?.suggestedChange?.receiverType as EscrowPartyTypeEnum,
        });
        return true;
      }
    } catch (e: unknown) {
      throw Error(String(e));
    }
  }, [state]);

  const providerValue = {
    state,
    isLoading: loading,
    suggestedChanges: changesRequestedData?.clientChangesRequested.notifications,
    sendSuggestedChanges,
    ...actions,
  };

  return <NotificationsSuggestChangesProvider value={providerValue}>{children}</NotificationsSuggestChangesProvider>;
};

export { useNotificationsSuggestChanges, NotificationsContextProvider };
