import { LazyLoad } from 'src/components/Misc';
const ProvidersContainer = () => <LazyLoad loader={() => import('src/containers/Saas/Providers/ProvidersContainer')} />;
const SaasCertificateContainer = () => (
  <LazyLoad loader={() => import('src/containers/Saas/Certificate/SaasCertificateContainer')} />
);

export enum SaasRoutesEnum {
  Providers = 'providers',
  Certificate = 'certificate',
}

export const saasRouteElements = [
  {
    path: SaasRoutesEnum.Providers,
    element: <ProvidersContainer />,
  },
  {
    path: SaasRoutesEnum.Certificate,
    element: <SaasCertificateContainer />,
  },
];
