import React, { Fragment } from 'react';
import { Select } from 'antd';

import { TextBlock } from 'src/components/Misc';

import { escrowLiabilityOptions } from '../escrowAgreement.enum';
import styles from '../../Escrow.module.scss';

type Props = {
  selectedValue: string | null;
  onChange: (value: string) => void;
};

const EscrowLiabilitySelect: React.FunctionComponent<Props> = ({ selectedValue, onChange }) => {
  return (
    <Fragment>
      <TextBlock
        className={styles['mb--20']}
        title="Escrow Agreement Liability SLA"
        titleColor="dark-blue"
        as="h4"
        text="By default, the escrow agreement excludes liability in the same way other SCM platforms do (for instance, Github, Bitbucket).&#10;To accommodate any regulatory or compliance requirements for increased limited liability, it's possible to apply a liability SLA."
      />

      <div className={`${styles['select-form-item']} ${styles['mb--40']}`}>
        <Select
          className="w-100"
          placeholder="Select liability"
          popupClassName="ck-liability__dropdown"
          value={selectedValue}
          onChange={onChange}
          options={escrowLiabilityOptions}
        />
      </div>
    </Fragment>
  );
};

export default EscrowLiabilitySelect;
