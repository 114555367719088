import {
  DisruptionNotificationEnum,
  DisruptionNotificationGracePeriodEnum,
  BackupStatusEnum,
  BackupAssetStatusEnum,
} from 'src/graphql/schema';
import { BackupRoutesEnum } from 'src/pages/Backup';

export const versionsOptions = [
  {
    label: '1 version',
    value: '1',
  },
  {
    label: '3 versions',
    value: '3',
  },
  {
    label: 'Unlimited (needs Advanced Deposits)',
    value: 'unlimited',
  },
];

export const versionsOptionsDict = versionsOptions.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {},
) as Record<string, string>;

export const disruptedNotificationsOptions = [
  {
    label: 'No notifications',
    value: DisruptionNotificationEnum.NoDistruptionNotifications,
  },
  {
    label: 'Immediate Notification',
    value: DisruptionNotificationEnum.ImmediateNotification,
  },
];

export const disruptedNotificationsOptionsDict = disruptedNotificationsOptions.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {},
) as Record<string, string>;

export const gracePeriodOptions = [
  {
    label: 'No grace period',
    value: 'none',
  },
  {
    label: 'Notification after a grace period of 24 hours',
    value: DisruptionNotificationGracePeriodEnum.TwentyFourHours,
  },
  {
    label: 'Notification after a grace period of 7 days',
    value: DisruptionNotificationGracePeriodEnum.SevenDays,
  },
  {
    label: 'Notification after a grace period of 30 days',
    value: DisruptionNotificationGracePeriodEnum.ThirtyDays,
  },
];

export const gracePeriodOptionsDict = gracePeriodOptions.reduce((acc, { value, label }) => {
  return { ...acc, [value]: `Immediate ${label.replace('Notification', 'notification')}` };
}, {}) as Record<string, string>;

export const integrationLabelStatusEnum = {
  [BackupStatusEnum.Draft]: {
    text: 'Draft',
    bgColor: 'orange',
  },
  [BackupStatusEnum.Active]: {
    text: 'Active',
    bgColor: 'green',
  },
  [BackupStatusEnum.Stopped]: {
    text: 'Stopped',
    bgColor: 'gray',
  },
  [BackupStatusEnum.Removed]: {
    text: 'Removed',
    bgColor: 'red',
  },
} as const;

export const assetStatusLabelOptions = {
  [BackupAssetStatusEnum.Completed]: 'Completed',
  [BackupAssetStatusEnum.Failed]: 'Failed',
  [BackupAssetStatusEnum.Pending]: 'In progress',
};

export const alertMessagesTypeEnum = {
  [BackupStatusEnum.Active]: {
    iconType: 'success',
    bgColor: 'green',
    text: 'This backup is active.',
  },
  [BackupStatusEnum.Draft]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Please configure your backup settings.',
  },
  [BackupStatusEnum.Stopped]: {
    iconType: undefined,
    bgColor: 'gray',
    text: 'Backup process has been stopped.',
  },
  [BackupStatusEnum.Removed]: {
    iconType: undefined,
    bgColor: 'red',
    color: 'red',
    text: 'This backup has been removed.',
  },
  [BackupRoutesEnum.Members]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Assign team member permissions to manage your backup.',
  },
  [BackupRoutesEnum.Assets]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Add the assets to be backed up.',
  },
} as const;
