import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  INITIAL = 'initial',
  CONNECT = 'connect',
  SELECT_SINGLE = 'select_single',
  SELECT_MULTIPLE = 'select_multiple',
  HIDE_MODAL = 'hide_modal',
  REMOVE_SELECTION = 'remove_selection',
}

type IActionReducer =
  | IAction<ActionEnum.INITIAL>
  | IAction<ActionEnum.CONNECT>
  | IAction<ActionEnum.HIDE_MODAL>
  | IAction<ActionEnum.SELECT_SINGLE, { selectedRepoId: string }>
  | IAction<ActionEnum.SELECT_MULTIPLE, { selectedRepoIds: string[] }>
  | IAction<ActionEnum.REMOVE_SELECTION>;

export type IMigrationsUiState = {
  isConnectModalOpened: boolean;
  selectedRepoId?: null | string;
  selectedRepoIds?: string[];
  selection: 'none' | 'single' | 'multiple';
};

const initialState: IMigrationsUiState = {
  isConnectModalOpened: false,
  selectedRepoId: null,
  selectedRepoIds: [],
  selection: 'none',
};

const reducer = (state: IMigrationsUiState, action: IActionReducer): IMigrationsUiState => {
  switch (action.type) {
    case ActionEnum.INITIAL:
      return initialState;
    case ActionEnum.CONNECT:
      return {
        ...state,
        isConnectModalOpened: true,
      };

    case ActionEnum.SELECT_SINGLE:
      return {
        selection: 'single',
        isConnectModalOpened: true,
        selectedRepoId: action.payload.selectedRepoId,
      };
    case ActionEnum.SELECT_MULTIPLE:
      return {
        selection: 'multiple',
        isConnectModalOpened: true,
        selectedRepoIds: action.payload.selectedRepoIds,
      };
    case ActionEnum.HIDE_MODAL:
      return {
        ...state,
        isConnectModalOpened: false,
      };
    case ActionEnum.REMOVE_SELECTION:
      return {
        ...state,
        selection: 'none',
      };
    default:
      return state;
  }
};

export const useMigrationsUiSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showConnectModal = () =>
    dispatch({
      type: ActionEnum.CONNECT,
      payload: {},
    });

  const selectSingle = (selectedRepoId: string) => {
    dispatch({
      type: ActionEnum.SELECT_SINGLE,
      payload: { selectedRepoId },
    });
  };

  const selectMultiple = (selectedRepoIds: string[]) => {
    dispatch({
      type: ActionEnum.SELECT_MULTIPLE,
      payload: { selectedRepoIds },
    });
  };

  const hideModal = () => dispatch({ type: ActionEnum.HIDE_MODAL, payload: {} });

  const setInitial = () => dispatch({ type: ActionEnum.INITIAL, payload: {} });

  const removeSelection = () => dispatch({ type: ActionEnum.REMOVE_SELECTION, payload: {} });

  return {
    ...state,
    showConnectModal,
    selectSingle,
    selectMultiple,
    hideModal,
    setInitial,
    removeSelection,
  };
};
