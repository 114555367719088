import { JourneyMenu, IJourneyMenuItem } from 'src/components/Journey/Menu';

type Props = {
  basePath: string;
  saasId: string;
  items: IJourneyMenuItem[];
};

const SaasMenu = ({ basePath, saasId, items }: Props) => {
  return (
    <JourneyMenu>
      {items.map((item) => (
        <JourneyMenu.Item key={item.path} slug={saasId} basePath={basePath} {...item} />
      ))}
    </JourneyMenu>
  );
};

export default SaasMenu;
