import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageEmptyIntegration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 28"
    role="img"
    className={props.className}
    {...props}
  >
    <rect width={12.526} height={12.526} fill="#DCE0E6" rx={3} />
    <rect width={12.526} height={12.526} y={15.474} fill="#AEB8C2" rx={3} />
    <rect width={12.526} height={12.526} x={15.474} fill="#AEB8C2" rx={3} />
    <rect width={12.526} height={12.526} x={15.474} y={15.474} fill="#DCE0E6" rx={3} />
  </svg>
);
const Memo = memo(SvgImageEmptyIntegration);
export default Memo;
