import { Fragment } from 'react';

import styles from '../EscrowContacts.module.scss';

type Props = {
  heading: string;
  headingLabel?: string;
  text: string;
};

const InfoContentBlock: React.FunctionComponent<Props> = ({ heading, headingLabel, text }) => {
  return (
    <Fragment>
      <h2 className={styles.heading}>
        {heading} {headingLabel && <sub>{headingLabel}</sub>}
      </h2>
      <p className={styles.text}>{text}</p>
    </Fragment>
  );
};

export default InfoContentBlock;
