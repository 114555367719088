import classNames from 'classnames';

import { ImageEmptyBox } from 'src/components/Svg/Images';

import styles from './EmptySaas.module.scss';

type Props = {
  action: React.ReactNode;
  className?: string;
  type: 'environment' | 'provider';
};

const contentTypeEnum = {
  environment: {
    title: 'Set Up Your First SaaS Escrow',
    text: "Set up your first SaaS escrow to protect your application's  environment. Customize it to fit your situation.",
  },
  provider: {
    title: "Selected SaaS Escrow doesn't have any providers yet",
    text: 'Set up your first provider.',
  },
};

const EmptySaas = ({ type, action, className }: Props) => {
  const blockClasses = classNames(styles.block, className);

  return (
    <div className={blockClasses}>
      <ImageEmptyBox className={styles.icon} />
      <h3 className={styles.title}>{contentTypeEnum[type].title}</h3>
      <p className={styles.description}>{contentTypeEnum[type].text}</p>
      {action}
    </div>
  );
};

export default EmptySaas;
