type Props = {
  label: string;
  value: React.ReactNode | string;
};

const OrganisationDetailsInfoRow: React.FunctionComponent<Props> = ({ label, value }) => {
  return (
    <div className="d-flex organisation-details__row">
      <p className="organisation-details__label">{label}</p>:<p className="ml-4 mb-0 break-spaces">{value}</p>
    </div>
  );
};

export default OrganisationDetailsInfoRow;
