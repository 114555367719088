import { Modal } from 'antd';
import { useState } from 'react';

import {
  useCreateCustomIntegrationMutation,
  IntegrationsDocument,
  ICustomIntegrationInputAttributes,
  CustomIntegrationProviderEnum,
} from 'src/graphql/schema/index.ts';
import { IStaticIntegration } from 'src/shared/entities';
import { renderLogo } from 'src/utils/renderLogo';
import { message } from 'src/components/Misc';

import ConfirmationIntegration from './ConfirmationIntegration';
import CredentialsForm from './CredentialsForm';
import { ICustomIntegrationValues } from './CredentialsForm/CredentialsForm';

type Props = {
  isVisible: boolean;
  toggleModal: () => void;
  integration: IStaticIntegration;
};

export default function CredentialsModal({ isVisible, toggleModal, integration }: Props) {
  const [createCustomIntegration, { loading }] = useCreateCustomIntegrationMutation({
    refetchQueries: [IntegrationsDocument],
  });

  const [isConnected, setIsConnected] = useState(false);

  const handleSubmission = async (values: ICustomIntegrationValues) => {
    const customIntegrationInput: ICustomIntegrationInputAttributes = {
      accountName: values.accountName,
      provider: integration.provider as CustomIntegrationProviderEnum,
      username: values.username,
      password: values.password,
    };

    if (integration.provider === 'aws') {
      customIntegrationInput.region = values.region;
    }

    if (integration.accountUrlRequired) {
      customIntegrationInput.accountUrl = values.accountUrl;
    }

    try {
      const { data } = await createCustomIntegration({
        variables: {
          customIntegrationInput,
        },
      });

      if (data?.createCustomIntegration?.success) {
        setIsConnected(true);
      } else {
        message.error("Integration wasn't connected!");
        toggleModal();
      }
    } catch (error) {
      console.log(error);
      message.error("Integration wasn't connected!");
      toggleModal();
    }
  };

  const Icon = renderLogo(integration.provider);

  return (
    <Modal
      className="non-auth-modal"
      open={isVisible}
      onCancel={toggleModal}
      destroyOnClose={true}
      afterClose={() => setIsConnected(false)}
      footer={null}
    >
      <Icon className="non-auth-modal__logo" />
      <p className="non-auth-modal__title">
        {integration.name} {isConnected && 'is successfully connected!'}
      </p>

      {!isConnected && <p className="non-auth-modal__details">Enter your {integration.name} Access Details</p>}

      {isConnected ? (
        <ConfirmationIntegration integration={integration} />
      ) : (
        <CredentialsForm
          integration={integration}
          onFinish={handleSubmission}
          submitButtonText="Activate"
          loading={loading}
        />
      )}
    </Modal>
  );
}
