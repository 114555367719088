import { useState } from 'react';
import { Button } from 'antd';

import { AddContactForm, IAddContactForm } from 'src/components/Escrow/Contacts';
import { useCreateContactMutation, ContactTypeEnum } from 'src/graphql/schema';
import { ClientError } from 'src/utils/errors';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';
import { updateContactsCache } from 'src/graphql/client/cache';
import { useEscrow } from 'src/context/escrow';
import { message } from 'src/components/Misc';

import type { EscrowPartyTypeEnum, IContact } from 'src/graphql/schema';

type Props = {
  role: EscrowPartyTypeEnum;
  showInitForm?: boolean;
};

const BipartiteAddNewContactContainer: React.FunctionComponent<Props> = ({ role, showInitForm = false }) => {
  const [isVisibleForm, setIsVisibleForm] = useState(false);

  const { escrow } = useEscrow();

  const organization = escrow[role];
  const escrowPartyId = organization?.id || '';

  const [createContact] = useCreateContactMutation({
    update: (_, { data }) => {
      const newContacts = [...(organization?.contacts ?? [])];
      if (data?.createContact?.contact) newContacts.push(data.createContact.contact as IContact);
      updateContactsCache(escrow.id, role, organization, newContacts);
    },
  });

  const onSubmitNewContact = async ({ email, name, phone, role }: IAddContactForm) => {
    try {
      const { data } = await createContact({
        variables: {
          escrowPartyId,
          contactInputParams: {
            contactType: role as ContactTypeEnum,
            email,
            name,
            phone,
          },
        },
      });

      if (data?.createContact?.errors?.length) {
        throw new ClientError(data?.createContact?.errors[0]);
      } else {
        message.success('Contact added!');
      }
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
      throw e;
    }
  };
  const handleAddClick = () => setIsVisibleForm(true);
  const onCancel = () => setIsVisibleForm(false);

  if (!escrowPartyId) return null;

  return (
    <div className={styles.block}>
      {isVisibleForm || showInitForm ? (
        <AddContactForm
          type={role}
          onCancel={onCancel}
          onSubmitContact={onSubmitNewContact}
          withExistingSelect={false}
          hideDiscard={showInitForm}
        />
      ) : (
        <div className={styles['action-row']}>
          <Button ghost className={styles['btn-outlined-primary']} onClick={handleAddClick}>
            + Add new contact
          </Button>
        </div>
      )}
    </div>
  );
};

export default BipartiteAddNewContactContainer;
