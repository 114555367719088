import { Header } from 'src/components/Layout';

import DropdownNotificationContainer from './DropdownNotificationContainer';
import DropdownProfileMenuContiner from './DropdownProfileMenuContiner';

const HeaderContainer: React.FunctionComponent = () => {
  return (
    <Header>
      <DropdownNotificationContainer />
      <DropdownProfileMenuContiner />
    </Header>
  );
};

export default HeaderContainer;
