import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFactory = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M35.293 24.33V9.084l-9.414 2.353V9.085l-11.758 2.94v4.215h-1.43l-.933-8.075V5.88c0-.652.53-1.182 1.181-1.182h4.708a3.53 3.53 0 0 0 3.525-3.525V0h-2.344v1.172c0 .652-.53 1.182-1.181 1.182H16.26c.131-.37.204-.768.204-1.182V0H14.12v1.172c0 .651-.53 1.182-1.181 1.182H8.231a3.529 3.529 0 0 0-3.525 3.525v2.286l-.933 8.075H0v16.7h23.619c.57 3.986 4.007 7.06 8.149 7.06C36.307 40 40 36.307 40 31.768c0-3.279-1.927-6.116-4.707-7.439Zm-2.344-12.243V23.62a8.208 8.208 0 0 0-7.07 2.4V13.856l7.07-1.768ZM8.232 4.697h1.386c-.132.37-.204.767-.204 1.182V7.06H7.051V5.879c0-.652.53-1.182 1.181-1.182ZM6.923 9.404h2.619l.79 6.836H6.133l.79-6.836Zm7.198 21.192H2.344V18.584H14.12v12.012Zm2.344 0V13.855l7.07-1.768v18.509h-7.07Zm15.303 7.06a5.895 5.895 0 0 1-5.89-5.888 5.895 5.895 0 0 1 5.89-5.89 5.895 5.895 0 0 1 5.888 5.89 5.895 5.895 0 0 1-5.888 5.888Z"
      />
      <path
        fill="currentColor"
        d="m33.688 28.19-1.92 1.92-1.92-1.92-1.658 1.657 1.92 1.92-1.92 1.92 1.657 1.658 1.92-1.92 1.92 1.92 1.658-1.657-1.92-1.92 1.92-1.92-1.657-1.658ZM4.707 21.182h2.344v2.343H4.707v-2.343ZM9.414 21.182h2.344v2.343H9.414v-2.343ZM18.828 16.475h2.344v2.343h-2.344v-2.343ZM18.828 21.182h2.344v2.343h-2.344v-2.343ZM28.242 16.475h2.344v2.343h-2.344v-2.343ZM28.242 21.182h2.344v2.343h-2.344v-2.343ZM18.828 25.889h2.344v2.343h-2.344V25.89ZM4.707 25.889h2.344v2.343H4.707V25.89ZM9.414 25.889h2.344v2.343H9.414V25.89Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFactory);
export default Memo;
