import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgIconLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 567 567"
    role="img"
    className={props.className}
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="url(#b)" d="M284.493 33.982 41.153 213.883v132.595l243.34-186.535 162.984 123.66L528 221.695z" />
      <path
        fill="url(#c)"
        d="M528 221.695 284.597 408.147 120.6 285.337l-79.447 61.14 243.514 188.227v-.316L528 354.289z"
      />
    </g>
    <defs>
      <linearGradient id="b" x1={71.944} x2={474.829} y1={147.852} y2={335.72} gradientUnits="userSpaceOnUse">
        <stop stopColor="#009DB8" />
        <stop offset={0.169} stopColor="#009DB8" />
        <stop offset={0.69} stopColor="#009DB8" />
        <stop offset={0.92} stopColor="#00709D" />
        <stop offset={1} stopColor="#00709D" />
      </linearGradient>
      <linearGradient id="c" x1={94.226} x2={497.111} y1={232.662} y2={420.53} gradientUnits="userSpaceOnUse">
        <stop stopColor="#00709D" />
        <stop offset={0.08} stopColor="#00709D" />
        <stop offset={0.241} stopColor="#009DB8" />
        <stop offset={0.831} stopColor="#009DB8" />
        <stop offset={1} stopColor="#009DB8" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h566.929v566.929H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIconLogo);
export default Memo;
