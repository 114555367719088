import { CustodianProfileStatusEnum as ApiCustodianProfileStatusEnum } from 'src/graphql/schema';

enum OtherCustodianProfileStatusEnum {
  TerminationRequested = 'termination_requested',
}

export type ICustodianProfileStatus = ApiCustodianProfileStatusEnum | OtherCustodianProfileStatusEnum;

export const CustodianProfileStatusEnum = {
  ...ApiCustodianProfileStatusEnum,
  ...OtherCustodianProfileStatusEnum,
} as const;
