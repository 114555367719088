import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { Fragment } from 'react';

import styles from './MigrationConnect.module.scss';

type Props = {
  onClick: () => void;
};

const MigrationConnectSuccess = ({ onClick }: Props) => {
  return (
    <Fragment>
      <h3 className={styles.title}>
        Migration successful! <CheckCircleFilled style={{ color: '#33C99C' }} />
      </h3>
      <p className={styles.text}>Your migration to the OAuth integration method was successful.</p>

      <div className={styles['btn-confirm']}>
        <Button type="primary" onClick={onClick}>
          Confirm
        </Button>
      </div>
    </Fragment>
  );
};

export default MigrationConnectSuccess;
