import { Modal } from 'antd';
import { Outlet, useOutletContext } from 'react-router-dom';

const JourneyModal = () => {
  const outletContext = useOutletContext();

  return (
    <Modal className="ck-journey__modal" open={true} closable={false} footer={null} width="100%" centered>
      <Outlet context={outletContext} />
    </Modal>
  );
};

export default JourneyModal;
