import { useNavigate, useParams } from 'react-router-dom';

import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { useBackup } from 'src/context/backup';
import { BackupStatusEnum } from 'src/graphql/schema';
import { BackupRoutesEnum } from 'src/pages/Backup';

const BackupNavigationContainer = () => {
  const navigate = useNavigate();
  const { id, '*': activePath } = useParams<{
    id: string;
    '*': BackupRoutesEnum;
  }>();
  const {
    backup,
    ui: { isEditSettingsMode, isEditAccessMode },
  } = useBackup();

  if (isEditSettingsMode || isEditAccessMode) return null;

  const routes = [
    BackupRoutesEnum.Platform,
    BackupRoutesEnum.Members,
    BackupRoutesEnum.Settings,
    BackupRoutesEnum.Assets,
  ];

  if (backup.status !== BackupStatusEnum.Draft) routes.push(BackupRoutesEnum.Summary);

  if (
    (backup.status === BackupStatusEnum.Active || backup.status === BackupStatusEnum.Stopped) &&
    (backup.policy.enableReports || backup.reportsEnabled)
  )
    routes.push(BackupRoutesEnum.Reports);

  const activeRouteIndex = routes.findIndex((route) => route === activePath);

  const goToPreviousRoute = () => {
    if (activeRouteIndex > 0) {
      navigate(`/backups/${id}/${routes[activeRouteIndex - 1]}`);
    }
  };

  const goToNextRoute = () => {
    if (activeRouteIndex < routes.length - 1) {
      navigate(`/backups/${id}/${routes[activeRouteIndex + 1]}`);
    }
  };

  if (activePath && !Object.values(BackupRoutesEnum).includes(activePath)) return null;

  return (
    <JourneyNavigation
      isPrevHidden={!activeRouteIndex}
      isNextHidden={activeRouteIndex === routes.length - 1}
      onPrev={goToPreviousRoute}
      onNext={goToNextRoute}
    />
  );
};

export default BackupNavigationContainer;
