import { InfoDetailBlock } from 'src/components/InfoDetailBlock';

export interface ISignatoryDetail {
  name: string;
  email: string;
}

type Props = {
  type: 'beneficiary' | 'depositor';
  className?: string;
  suggestionList?: React.ReactNode;
} & ISignatoryDetail;

const SignatoryDetailBlock: React.FunctionComponent<Props> = ({ type, name, email, className, suggestionList }) => {
  return (
    <InfoDetailBlock
      className={className}
      title={`${type} Signatory Details`}
      text="The legal representative which will sign the escrow agreement. This person will receive an electronic signature request."
      items={[
        { label: 'Signatory Name', value: name },
        { label: 'Signatory Email', value: email },
      ]}
      secondaryText={`To make the escrow legally binding we will send an email to the listed representative for an electronic signature upon approval of the escrow agreement. If an alternative signature method or a hardcopy agreement is required, please <a target="_blank" href="https://codekeeper.co/contact.html">contact our support</a>.`}
      suggestionList={suggestionList}
    />
  );
};

export default SignatoryDetailBlock;
