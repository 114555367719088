import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconBarChart = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <g fill="currentColor" clipPath="url(#clip0)">
        <path d="M32.938 37.656v-9.413h-7.05v9.413h-2.363V18.83h-7.05v18.826h-2.363V14.124h-7.05v23.532H2.345V.004H.002V40h39.996v-2.344h-7.06Zm-23.533 0V16.467h2.363v21.19H9.405Zm9.413 0V21.174h2.363v16.482h-2.363Zm9.413 0v-7.07h2.363v7.07h-2.363Z" />
        <path d="m20.892 11.76 6.246 6.247-1.664 1.664 1.503 1.503h8.313V12.86l-1.505-1.502-1.662 1.663-8.311-8.31h-9.894L9.208 0 4.221 4.985l6.776 6.776h9.894ZM9.207 3.315l3.74 3.74h9.894l9.282 9.282.824-.825v3.32h-3.319l.824-.824-8.589-8.59h-9.894L7.536 4.985l1.671-1.67Z" />
      </g>
    </svg>
  );
};
const Memo = memo(SvgIconBarChart);
export default Memo;
