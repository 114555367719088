import { AlertActionMessage } from 'src/components/Alert';
import { useCustodianProfile } from 'src/context/custodian';
import { CustodianProfileStatusEnum } from 'src/graphql/schema';

import type { IAlertActionMessageProps } from 'src/components/Alert';

const CustodianAlertBannerContainer = () => {
  const { custodianProfile, isTerminationRequested } = useCustodianProfile();

  let alertMessage: IAlertActionMessageProps | undefined;

  switch (custodianProfile.status) {
    case CustodianProfileStatusEnum.Draft:
      alertMessage = {
        iconType: 'warn',
        bgColor: 'yellow',
        text: 'Configure your Custodian profile by adding all the relevant assets and operational information.',
        color: 'dark-green',
      };
      break;
    case CustodianProfileStatusEnum.AccessSetup:
      alertMessage = {
        iconType: 'warn',
        bgColor: 'yellow',
        text: 'Please provide access for Codekeeper to all providers in your Custodian profile.',
        color: 'dark-green',
      };
      break;
    case CustodianProfileStatusEnum.Active:
      alertMessage = {
        iconType: 'success',
        bgColor: 'green',
        text: 'The information in this profile is covered by your Custodian.',
        color: 'dark-green',
      };
      break;
    case CustodianProfileStatusEnum.Terminated:
      alertMessage = {
        bgColor: 'red',
        text: 'You are viewing a terminated Custodian profile.',
        color: 'red',
      };
  }

  if (isTerminationRequested)
    alertMessage = {
      bgColor: 'red',
      text: "This Custodian profile is pending termination. Please revoke Codekeeper's access to your providers.",
      color: 'red',
    };

  if (!alertMessage) return null;

  return <AlertActionMessage {...alertMessage} />;
};

export default CustodianAlertBannerContainer;
