import { useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { CustodianProfileDocument } from 'src/graphql/schema';
import { useCustodianAssetsSlice, useCustodianOperationsSlice } from 'src/slices';

import { createRequiredContext } from '../createRequiredContext';

import type { ICustodianProfile, ICustodianProfileQuery } from 'src/graphql/schema';

const [useCustodianProfile, CustodianProfileProvider] = createRequiredContext<{
  custodianProfile: ICustodianProfile;
  refetch: () => void;
  isTerminationRequested: boolean;
  ui: {
    isSubmitModalOpen: boolean;
    isTerminateModalOpen: boolean;
    showSubmitModal: () => void;
    hideSubmitModal: () => void;
    showTerminateModal: () => void;
    hideTerminateModal: () => void;
    assets: ReturnType<typeof useCustodianAssetsSlice>;
    operations: ReturnType<typeof useCustodianOperationsSlice>;
  };
}>();

const CustodianProfileContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isTerminateModalOpen, setIsTerminateModalOpen] = useState(false);

  const { id = '' } = useParams();
  const uiAssets = useCustodianAssetsSlice();
  const uiOperations = useCustodianOperationsSlice();
  const { data, refetch } = useSuspenseQuery<ICustodianProfileQuery>(CustodianProfileDocument, {
    variables: {
      id,
    },
    skip: !id,
  });

  const showSubmitModal = () => setIsSubmitModalOpen(true);
  const hideSubmitModal = () => setIsSubmitModalOpen(false);
  const showTerminateModal = () => setIsTerminateModalOpen(true);
  const hideTerminateModal = () => setIsTerminateModalOpen(false);

  const providerValue = {
    custodianProfile: data?.custodianProfile as ICustodianProfile,
    isTerminationRequested: Boolean(data?.custodianProfile?.custodianTermination),
    refetch,
    ui: {
      isSubmitModalOpen,
      isTerminateModalOpen,
      showSubmitModal,
      hideSubmitModal,
      showTerminateModal,
      hideTerminateModal,
      assets: uiAssets,
      operations: uiOperations,
    },
  };

  return <CustodianProfileProvider value={providerValue}>{children}</CustodianProfileProvider>;
};

export { useCustodianProfile, CustodianProfileContextProvider };
