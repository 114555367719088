import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoOkta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#007dc1"
      d="M12 2C6.49 2 2 6.458 2 12s4.459 10 10 10 10-4.459 10-10S17.51 2 12 2m0 15c-2.77 0-5-2.23-5-5s2.23-5 5-5 5 2.23 5 5-2.23 5-5 5"
    />
  </svg>
);
const Memo = memo(SvgLogoOkta);
export default Memo;
