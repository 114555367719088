import { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Space } from 'antd';

import { countries, ICountry } from 'src/shared/entities';
import { useRegistrationNum } from 'src/hooks/use-registration-num.ts';

import type { IBillingQuery } from 'src/graphql/schema';

import styles from './BillingForms.module.scss';

const { Option } = Select;

export interface IBillingCompanyForm {
  company: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  email: string;
  registrationNumber: string;
}

type Props = {
  data: IBillingQuery;
  onSubmit: (values: IBillingCompanyForm) => void;
  isLoading: boolean;
};

const BillingCompanyForm = ({ data, onSubmit, isLoading }: Props) => {
  const [form] = Form.useForm();
  const countryField = Form.useWatch('country', form);
  const [country, setCountry] = useState<ICountry>();

  const { isRegistrationNumRequired } = useRegistrationNum(countryField);

  const savedCompany = data.billing?.billingAddress || null;

  useEffect(() => {
    if (savedCompany) {
      savedCompany.country && handleCountryChange(savedCompany.country);

      form.setFieldsValue({
        company: savedCompany.companyName || '',
        city: savedCompany.city || '',
        zip: savedCompany.zip || '',
        state: savedCompany.state || '',
        country: savedCompany.country || '',
        countryPrefix: country?.code,
        firstName: savedCompany.firstName || '',
        lastName: savedCompany.lastName || '',
        address1: savedCompany.addressLine1 || '',
        address2: savedCompany.addressLine2 || '',
        address3: savedCompany.addressLine3 || '',
        email: savedCompany.email || '',
        registrationNumber: savedCompany.companyRegistrationNumber || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCompany, form]);

  const handleCountryChange = (selectedCountry: ICountry['name']) => {
    const country = countries.find((c) => selectedCountry === c.name);
    setCountry(country);
  };

  return (
    <Form layout="vertical" name="basic" form={form} onFinish={onSubmit}>
      <Form.Item label="Company Name" name="company" rules={[{ required: true }]}>
        <Input placeholder="Company" />
      </Form.Item>
      <Form.Item label="First Name" name="firstName">
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last Name" name="lastName">
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item label="Email" name="email" rules={[{ type: 'email' }]}>
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item label="Address 1" name="address1" rules={[{ required: true }]}>
        <Input placeholder="Address 1" name="address1" />
      </Form.Item>
      <Form.Item label="Address 2" name="address2">
        <Input placeholder="Address 2" name="address2" />
      </Form.Item>
      <Form.Item label="Address 3" name="address3">
        <Input placeholder="Address 3" name="address3" />
      </Form.Item>
      <Form.Item label="State" name="state">
        <Input placeholder="State" />
      </Form.Item>
      <Form.Item label="City" name="city" rules={[{ required: true }]}>
        <Input placeholder="City" />
      </Form.Item>
      <Form.Item label="Zip" name="zip" rules={[{ required: true }]}>
        <Input placeholder="zip" />
      </Form.Item>

      <div className="mb-4">
        <Form.Item label="Country" name="country" rules={[{ required: true }]}>
          <Select onChange={handleCountryChange} placeholder="Country">
            {countries.map((item: ICountry) => (
              <Option key={item.name} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {isRegistrationNumRequired && (
          <div className={`d-flex ${styles['vat-row']}`}>
            <div className="ant-col ant-form-item-label">
              <label htmlFor="registrationNumber" className="ant-form-item-required">
                Tax / VAT ID
              </label>
            </div>
            <Space.Compact>
              <Form.Item className={styles['vat-prefix']}>
                <Input
                  name={'countryPrefix'}
                  contentEditable={false}
                  placeholder="Prefix"
                  value={country?.code}
                  style={{
                    borderRadius: 5,
                  }}
                />
              </Form.Item>
              <Form.Item
                name="registrationNumber"
                className={styles['vat-input']}
                rules={[{ required: true, message: "'Tax / VAT ID' is required" }]}
              >
                <Input
                  placeholder="Tax / VAT ID"
                  style={{
                    borderRadius: 5,
                  }}
                />
              </Form.Item>
            </Space.Compact>
          </div>
        )}
      </div>

      <div className="text-right">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default BillingCompanyForm;
