import { Button } from 'antd';
import { Fragment } from 'react';

import { useCancelationPlan } from 'src/context/billing';
import { LostBenefit } from 'src/components/Billing/Cancel/Plan';

const ReviewChangesContainer = () => {
  const {
    ui: { setStep, setInitialData },
    exclusiveOfferApplied,
  } = useCancelationPlan();

  const onContinueCancelation = () => setStep(exclusiveOfferApplied ? 'other-offer' : 'exclusive-offer');

  return (
    <Fragment>
      <LostBenefit />

      <div className="d-flex justify-content-end gap-12">
        <Button onClick={onContinueCancelation}>Continue Cancelation</Button>
        <Button type="primary" onClick={setInitialData}>
          Keep Plan
        </Button>
      </div>
    </Fragment>
  );
};

export default ReviewChangesContainer;
