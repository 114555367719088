import { Form, Input, Button } from 'antd';

export type IUserFeedbackForm = {
  feedback: string;
};

type Props = {
  onSubmit: (values: IUserFeedbackForm) => Promise<void>;
  isLoading?: boolean;
};

const UserFeedbackForm = ({ onSubmit, isLoading }: Props) => {
  const [form] = Form.useForm<IUserFeedbackForm>();

  const handleSubmit = async (values: IUserFeedbackForm) => {
    await onSubmit(values);
    form.resetFields();
  };

  return (
    <Form onFinish={handleSubmit} form={form}>
      <Form.Item name="feedback" rules={[{ required: true, message: 'Please input your feedback!' }]}>
        <Input.TextArea rows={6} placeholder="Write your feedback" />
      </Form.Item>

      <div className="text-center">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Share
        </Button>
      </div>
    </Form>
  );
};

export default UserFeedbackForm;
