import { Alert } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const ResetPasswordAlert = () => (
  <Alert
    message={
      <div className="invite-login-screen__alert-msg">
        <LockOutlined style={{ color: '#FAAD14', fontSize: 24 }} />
        <p>
          A new version of Codekeeper has been released. Please reset your password to log in.
          <span> Note: only required at first login</span>
        </p>
      </div>
    }
    type="warning"
    className="invite-login-screen__alert"
  />
);

export default ResetPasswordAlert;
