import React from 'react';
import classNames from 'classnames';

import styles from './LabelText.module.scss';

export const bgColors = ['orange', 'red', 'purple', 'green', 'light-blue', 'dark-blue', 'gray', 'custom'] as const;

export type Props = {
  text: string;
  icon?: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  bgColor: (typeof bgColors)[number];
  className?: string;
  size?: 'small' | 'medium';
  shape?: 'rounded' | 'square';
  withBorder?: boolean;
};

const LabelText: React.FunctionComponent<Props> = ({
  text,
  icon: Icon,
  bgColor,
  className,
  size = 'medium',
  shape = 'square',
  withBorder = true,
}) => {
  const labelClasses = classNames(
    styles.label,
    className,
    styles[`bg--${bgColor}`],
    styles[`size--${size}`],
    styles[`shape--${shape}`],
    {
      [styles['with--border']]: withBorder,
    },
  );

  return (
    <span className={labelClasses}>
      {text}
      {Icon && <Icon />}
    </span>
  );
};

export default LabelText;
