import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageMasterCard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" className={props.className} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={7} cy={12} r={7} fill="#ea001b" />
      <circle cx={17} cy={12} r={7} fill="#ffa200" fillOpacity={0.8} />
    </g>
  </svg>
);
const Memo = memo(SvgImageMasterCard);
export default Memo;
