import { ProfileOutlined, WarningOutlined, MailOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { IconChangesRequested, IconGroup, IconSigned } from 'src/components/Svg/Icons';
import { EscrowStatusFilterEnum } from 'src/graphql/schema';

export type IHomeTab = {
  key: EscrowStatusFilterEnum;
  name: string;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element> | React.ComponentType;
};

export const homeTabs: IHomeTab[] = [
  {
    key: EscrowStatusFilterEnum.All,
    name: 'All items',
    icon: ProfileOutlined,
  },
  {
    key: EscrowStatusFilterEnum.DraftAndOnboarding,
    name: 'Drafts & \nReview',
    icon: IconGroup,
  },
  {
    key: EscrowStatusFilterEnum.Signed,
    name: 'Deposits & \nActivation',
    icon: IconSigned,
  },
  {
    key: EscrowStatusFilterEnum.ChangesSuggested,
    name: 'Changes \nRequested',
    icon: IconChangesRequested,
  },
  {
    key: EscrowStatusFilterEnum.Invited,
    name: 'Invitations',
    icon: MailOutlined,
  },
  {
    key: EscrowStatusFilterEnum.TerminationRequested,
    name: 'Termination \nRequest',
    icon: CloseCircleOutlined,
  },
  {
    key: EscrowStatusFilterEnum.Error,
    name: 'Errors',
    icon: WarningOutlined,
  },
];

export const dateOptions = [
  {
    value: '1',
    label: 'Last 24 hours',
  },
  {
    value: '2',
    label: 'Last 7 days',
  },
  {
    value: '3',
    label: 'Last 30 days',
  },
  {
    value: '4',
    label: 'Last 6 months',
  },
  {
    value: '5',
    label: '1 year',
  },
  {
    value: '6',
    label: 'All time',
  },
];
