import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { EditFilled } from '@ant-design/icons';

import styles from './SuggestChangesList.module.scss';

type Props = {
  status: ('accepted' | 'pending') | (string & NonNullable<unknown>);
  className?: string;
};

const SuggestChangesSingle: React.FunctionComponent<PropsWithChildren<Props>> = ({ status, children, className }) => {
  const changesClassNames = classNames(styles.container, styles[status], className);

  return (
    <div className={changesClassNames}>
      <ul className={styles.list}>
        <li>
          <EditFilled className={styles['image-icon']} />
          <div>{children}</div>
        </li>
      </ul>
    </div>
  );
};

export default SuggestChangesSingle;
