import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconEditCircle = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm5.699-16.254-1.44-1.445a1.017 1.017 0 0 0-1.444-.002l-7.112 7.13 2.881 2.893 7.112-7.13c.193-.194.297-.451.297-.725a1.01 1.01 0 0 0-.294-.721zM5.996 17.564a.344.344 0 0 0 .424.423l3.204-.912-2.713-2.707-.914 3.196z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconEditCircle);
export default Memo;
