import Share1 from 'src/assets/images/integrations/share1.svg';
import Share2 from 'src/assets/images/integrations/share2.svg';
import Share3 from 'src/assets/images/integrations/share3.svg';

export function ShareFileInfoContent() {
  return (
    <div>
      <div className="d-flex non-auth-modal__step-container">
        <div className="d-flex align-items-start">
          <span className="non-auth-modal__step">1</span>
          <span className="non-auth-modal__step-text">Provide us with email to be invited to the upload folder.</span>
        </div>

        <img className="non-auth-modal__step-img" src={Share1} alt="" />
      </div>
      <div className="d-flex non-auth-modal__step-container">
        <div className="d-flex align-items-start">
          <span className="non-auth-modal__step">2</span>
          <span className="non-auth-modal__step-text">
            We will setup the secure folder, and you will receive an invite in your mailbox.
          </span>
        </div>
        <img className="non-auth-modal__step-img" src={Share2} alt="" />
      </div>
      <div className="d-flex non-auth-modal__step-container">
        <div className="d-flex align-items-center">
          <span className="non-auth-modal__step">3</span>
          <span className="non-auth-modal__step-text">
            Please upload the escrow deposit to this folder. Anything you upload there will be automatically deposited
            into the escrow and released if a release event is triggered.
          </span>
        </div>
        <img className="non-auth-modal__step-img" src={Share3} alt="" />
      </div>
    </div>
  );
}
