import { useState, useCallback } from 'react';

export const useLocalStorage = <TKey extends string, TValue>(key: TKey, initialValue: TValue) => {
  const [value, setValue] = useState<TValue>(() => {
    const storedValue = localStorage.getItem(key);

    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  const setStoredValue = useCallback(
    (newValue: TValue) => {
      setValue(newValue);
      localStorage.setItem(key, JSON.stringify(newValue));
    },
    [key],
  );

  return [value, setStoredValue] as const;
};
