import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoTwilio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 256"
    role="img"
    className={props.className}
    {...props}
  >
    <g fill="#cf272d">
      <path d="M127.86 222.304c-52.005 0-94.164-42.159-94.164-94.163 0-52.005 42.159-94.163 94.164-94.163 52.004 0 94.162 42.158 94.162 94.163s-42.158 94.163-94.162 94.163m0-222.023C57.245.281 0 57.527 0 128.141S57.245 256 127.86 256c70.614 0 127.859-57.244 127.859-127.859S198.474.281 127.859.281" />
      <path d="M133.116 96.297c0-14.682 11.903-26.585 26.586-26.585s26.585 11.903 26.585 26.585c0 14.684-11.902 26.586-26.585 26.586s-26.586-11.902-26.586-26.586M133.116 159.983c0-14.682 11.903-26.586 26.586-26.586s26.585 11.904 26.585 26.586c0 14.683-11.902 26.586-26.585 26.586s-26.586-11.903-26.586-26.586M69.431 159.983c0-14.682 11.904-26.586 26.586-26.586 14.683 0 26.586 11.904 26.586 26.586 0 14.683-11.903 26.586-26.586 26.586s-26.586-11.903-26.586-26.586M69.431 96.298c0-14.683 11.904-26.585 26.586-26.585 14.683 0 26.586 11.902 26.586 26.585s-11.903 26.586-26.586 26.586-26.586-11.902-26.586-26.586" />
    </g>
  </svg>
);
const Memo = memo(SvgLogoTwilio);
export default Memo;
