import { Button, Divider, Modal } from 'antd';

import { renderLogo } from 'src/utils/renderLogo';
import { IStaticIntegration } from 'src/shared/entities';

import Steps from './Steps';

type CustomModalProps = {
  isVisible: boolean;
  toggleModal: () => void;
  modalInfoText: string;
  integration: IStaticIntegration;
  toggleCredentialsModal: () => void;
  toggleAccessTokenModal?: () => void;
  toggleShareFileModal: () => void;
};

export default function CustomModal({
  isVisible,
  toggleModal,
  integration,
  toggleCredentialsModal,
  toggleShareFileModal,
  modalInfoText,
}: CustomModalProps) {
  const handleModals = () => {
    if (integration.provider === 'share_file_https') {
      toggleShareFileModal();
    } else {
      toggleCredentialsModal();
    }
  };

  const Icon = renderLogo(integration.provider);

  return (
    <Modal className="non-auth-modal" open={isVisible} onCancel={toggleModal} footer={null}>
      <Icon className="non-auth-modal__logo" />
      <p className="non-auth-modal__title">{integration.name}</p>
      <p className="non-auth-modal__info">{modalInfoText}</p>

      <Divider />

      <p className="non-auth-modal__setup">{integration.name} setup instructions:</p>

      <Steps provider={integration.provider} />

      <Button
        className="non-auth-modal__btn"
        type="primary"
        onClick={() => {
          toggleModal();
          handleModals();
        }}
      >
        Enter Access Details
      </Button>
    </Modal>
  );
}
