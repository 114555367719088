import { Modal } from 'antd';

import { ProviderView } from 'src/components/Saas/Provider';
import { useSaasEscrow } from 'src/context/saas';

const ProviderViewContainer = () => {
  const { environment, ui: { provider } = {}, editProvider, hideProvider } = useSaasEscrow();

  const providerData = environment?.saasProviders?.find((item) => item.id === provider?.id);

  return (
    <Modal open={provider?.modalType === 'view'} footer={null} onCancel={hideProvider} width={650}>
      {providerData && (
        <ProviderView
          environmentStatus={environment.status}
          data={{
            id: providerData.id,
            role: providerData.role,
            name: providerData.platform,
            status: providerData.status,
            description: providerData.description,
            email: providerData.contactEmail,
            cost: providerData.monthlyCost,
            continuityPeriod: providerData.protectionPeriod,
            completedAt: providerData.completedAt,
          }}
          onEdit={editProvider}
        />
      )}
    </Modal>
  );
};

export default ProviderViewContainer;
