import { Fragment, useState } from 'react';

import { message } from 'src/components/Misc';
import { BillingCoupon, BillingCouponForm } from 'src/components/Billing';
import { useApplyCouponMutation, BillingDocument } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

const AddCouponContainer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [applyCoupon, { loading }] = useApplyCouponMutation({
    refetchQueries: [BillingDocument],
  });

  const onSubmit = async ({ code: couponCode }: { code: string }) => {
    try {
      const { data } = await applyCoupon({
        variables: {
          couponCode,
        },
      });

      if (data?.applyCoupon?.errors?.length) {
        const error =
          data.applyCoupon.errors[0] === 'Coupon does not exist'
            ? 'Sorry, this code is not valid or not applicable.'
            : data.applyCoupon.errors[0];
        throw new ClientError(error);
      } else {
        message.success('Your coupon code has been successfully applied!');
        setIsOpen(false);
      }
    } catch (error) {
      getApiError(error, message.error);
    }
  };

  const toggleForm = () => setIsOpen(!isOpen);

  return (
    <Fragment>
      <BillingCoupon className={isOpen ? 'mb-4' : ''} onAddCoupon={toggleForm} />

      {isOpen && <BillingCouponForm isLoading={loading} onSubmit={onSubmit} onCancel={toggleForm} />}
    </Fragment>
  );
};

export default AddCouponContainer;
