import { CustodianMenu } from 'src/components/Custodian/Menu';
import { custodianMenuItems } from 'src/components/Custodian/Menu/custodianMenu.enum';
import { useCustodianProfile } from 'src/context/custodian';
import { CustodianIntegrationStatusEnum, CustodianProfileStatusEnum } from 'src/graphql/schema';
import { CustodianRoutesEnum } from 'src/pages/Custodian';

const CustodianMenuContainer = () => {
  const {
    custodianProfile: { id: profileId, status, custodianAssets, custodianOperations, custodianIntegrations },
  } = useCustodianProfile();

  let menuItems = [...custodianMenuItems];

  switch (status) {
    case CustodianProfileStatusEnum.Draft:
      menuItems[0] = {
        ...menuItems[0],
        isRequired: !custodianAssets.length,
      };
      menuItems[1] = {
        ...menuItems[1],
        isDisabled: !custodianAssets.length,
        isRequired: Boolean(custodianAssets.length) && !custodianOperations.length,
      };
      break;
    case CustodianProfileStatusEnum.AccessSetup: {
      const isRequiredIntegrations = custodianIntegrations.some(
        ({ status }) => status === CustodianIntegrationStatusEnum.Pending,
      );

      menuItems = menuItems.map((item, index) => {
        if (index < 3)
          return {
            ...item,
            isDisabled: false,
            isRequired: item.path === CustodianRoutesEnum.Integrations ? isRequiredIntegrations : false,
          };
        else return item;
      });
      break;
    }
    case CustodianProfileStatusEnum.Active:
      menuItems = menuItems.map((item) => ({
        ...item,
        isDisabled: false,
        isRequired: false,
      }));
      break;
    case CustodianProfileStatusEnum.Terminated:
      menuItems = menuItems.map((item) => ({
        ...item,
        isDisabled: item.path === CustodianRoutesEnum.Certificate,
        isRequired: false,
      }));
      break;
  }

  return <CustodianMenu basePath="custodian-profiles" items={menuItems} profileId={profileId} />;
};

export default CustodianMenuContainer;
