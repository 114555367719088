import { Button, Divider, List } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

import { IStaticIntegration } from 'src/shared/entities';

type Props = {
  integration: IStaticIntegration;
};

const ConfirmationIntegration = ({ integration }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <CheckCircleFilled
        className="mb-3"
        style={{
          fontSize: '72px',
          color: '#33c99c',
        }}
      />

      <p className="non-auth-modal__text-small">You can use this integration to deposit more assets into escrow.</p>

      <Divider />

      <p className="non-auth-modal__text-next">Next Steps:</p>

      <List>
        <List.Item className="non-auth-modal__text-next-step">
          1. Go to <strong>&apos;Escrows&apos;</strong>
        </List.Item>

        <List.Item className="non-auth-modal__text-next-step">
          2. Select the Escrow you would like to deposit to.
        </List.Item>

        <List.Item className="non-auth-modal__text-next-step">
          3. Click the <strong>&apos;Deposits&apos;</strong> tab.
        </List.Item>

        <List.Item className="non-auth-modal__text-next-step">
          4. Click &quot;Add new assets&quot;, then select the <b>Custom Deposit</b> method. Choose your{' '}
          <b>{integration.name}</b> integration and add the repository details.
        </List.Item>

        <List.Item className="non-auth-modal__text-blue">
          Repeat this for all the repositories you would like to deposit into the escrow(s) on your account.
        </List.Item>
      </List>

      <Button className="non-auth-modal__btn" type="primary" onClick={() => navigate('/escrows')}>
        Go to Escrows
      </Button>
    </div>
  );
};

export default ConfirmationIntegration;
