import { Popover } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import Gitlab2 from 'src/assets/images/integrations/gitlab2.svg';
import Amazon1 from 'src/assets/images/integrations/amazon1.svg';
import Amazon3 from 'src/assets/images/integrations/amazon3.svg';
import Amazon4 from 'src/assets/images/integrations/amazon4.svg';
import Amazon52 from 'src/assets/images/integrations/amazon52.svg';
import Amazon6 from 'src/assets/images/integrations/amazon6.png';

import Step from './Step';

export function AmazonInfoContent() {
  return (
    <>
      <Step icon={Amazon1} stepNumber={1}>
        Navigate to IAM / Users / Policies / Create Policy.
      </Step>
      <Step icon={Gitlab2} stepNumber={2}>
        Create an access policy for your bucket s3 with ListBucket and GetObject access level.&nbsp;
        <Popover
          trigger="hover"
          overlayStyle={{ width: 360 }}
          content={
            <code>
              Example policy:
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;&ldquo;Version&ldquo;: &ldquo;2012-10-17&ldquo;,
              <br />
              &nbsp;&nbsp;&ldquo;Statement&ldquo;: [<br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Sid&ldquo;: &ldquo;VisualEditor0&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Effect&ldquo;: &ldquo;Allow&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Action&ldquo;: [<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;s3:GetObject&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;s3:ListBucket&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;],
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Resource&ldquo;: [<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;arn:aws:s3:::YOUR-BUCKET/*&ldquo;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;arn:aws:s3:::YOUR-BUCKET&ldquo;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <br />
              &nbsp;&nbsp;]
              <br />
              {'}'}
            </code>
          }
        >
          <InfoCircleFilled style={{ color: '#00A0B6' }} />
        </Popover>
      </Step>
      <Step icon={Amazon3} stepNumber={3}>
        Navigate to IAM / Users / Policies / Add users.
      </Step>
      <Step icon={Amazon4} stepNumber={4}>
        Create a user with the &ldquo;Access key - Programmatic access&ldquo; credential type. You can copy the policy
        attached above.
      </Step>
      <Step icon={Amazon52} stepNumber={5}>
        Attach previously created access policy to the user.
      </Step>
      <Step icon={Amazon6} stepNumber={6}>
        Retrieve the details of this access key, and enter it in the Codekeeper app using the button below.
      </Step>
    </>
  );
}
