import { PropsWithChildren, useEffect, useCallback } from 'react';
import { get, omit, isEmpty } from 'lodash';

import { useContactsSuggestChangesSlice, IViewState } from 'src/slices/contactsSuggestChangesSlice';
import { getOnlyModifiedFields } from 'src/utils/getOnlyModifiedFields';
import {
  EscrowPartyTypeEnum,
  SuggestedChangeStatusEnum,
  ISuggestedChangesQuery,
  useCreateSuggestedChangeEscrowMutation,
  useClientChangesRequestedQuery,
  SuggestedChangeChangeTypeEnum,
  IClientChangesRequestedQuery,
} from 'src/graphql/schema';
import { IContactDetail } from 'src/components/Escrow/Contacts';
import { useSuggestedChanges } from 'src/hooks/use-client-suggested-changes';
import { writeNewSuggestChanges, suggestChangesVar } from 'src/graphql/client/cache';

import { createRequiredContext } from '../createRequiredContext';

const [useContactsSuggestChanges, ContactsProvider] = createRequiredContext<
  Omit<ReturnType<typeof useContactsSuggestChangesSlice>, 'setInitialData'> & {
    suggestedChanges: IClientChangesRequestedQuery['clientChangesRequested']['parties'];
    createSuggestedChanges: (role: EscrowPartyTypeEnum | Array<EscrowPartyTypeEnum>) => Promise<boolean>;
    isLoading: boolean;
  }
>();

type Props = {
  isSuggestChangesMode: boolean;
  escrowId: string;
  parties: IViewState;
  mainPartyRole: EscrowPartyTypeEnum;
  existingUsers: { [key: string]: IContactDetail[] };
};

const ContactsSuggestChangesProvider = ({
  isSuggestChangesMode,
  escrowId,
  parties,
  mainPartyRole,
  existingUsers,
  children,
}: PropsWithChildren<Props>) => {
  const onCompletedSuggestedChanges = useCallback((suggestedChanges: ISuggestedChangesQuery['suggestedChanges']) => {
    const availableChanges = suggestedChanges.nodes.filter(
      ({ partyStatus }) =>
        partyStatus === SuggestedChangeStatusEnum.Pending || partyStatus === SuggestedChangeStatusEnum.Accepted,
    );

    const clonedParties = structuredClone(parties);

    if (availableChanges.length) {
      const creator = availableChanges[0].creatorType;

      writeNewSuggestChanges(suggestChangesVar)('parties', {
        id: availableChanges[0].id,
        creator: availableChanges[0].creatorType,
        status: availableChanges[0].partyStatus as SuggestedChangeStatusEnum,
        payload: availableChanges[0].payload,
        receiver: availableChanges[0].receiverType as EscrowPartyTypeEnum,
      });

      setInitialData(clonedParties, existingUsers[creator]);
    } else {
      setInitialData(clonedParties, existingUsers[mainPartyRole]);
    }
  }, []);

  const { loading } = useSuggestedChanges({
    escrowId,
    changeType: SuggestedChangeChangeTypeEnum.ContactSuggestedChange,
    onCompleted: onCompletedSuggestedChanges,
  });
  useEffect(() => {
    const clonedParties = structuredClone(parties);

    if (!isSuggestChangesMode) setInitialData(clonedParties, existingUsers[mainPartyRole]);
  }, [isSuggestChangesMode]);
  const [createSuggestedChange] = useCreateSuggestedChangeEscrowMutation();
  const { view, suggestedChangesForReview, setInitialData, ...rest } = useContactsSuggestChangesSlice();
  const { data: changesRequestedData } = useClientChangesRequestedQuery();

  const setPartyContactChangeInput = (role: string) => {
    const organizationModifiedFields = getOnlyModifiedFields(
      omit(view?.[role]?.organization, ['address']),
      omit(parties?.[role].organization, ['address']),
    );
    const addressModifiedFields = getOnlyModifiedFields(
      get(view, `${role}.organization.address`)!,
      get(parties, `${role}.organization.address`),
    );
    const signatoryModifiedFields = getOnlyModifiedFields(
      get(view, `${role}.signatory`)!,
      get(parties, `${role}.signatory`),
    );
    const editedContacts = suggestedChangesForReview?.[role]?.editedContacts;
    const deletedContacts = suggestedChangesForReview?.[role]?.deletedContacts;
    const newContacts = suggestedChangesForReview?.[role]?.newContacts?.slice().map((contact) => {
      if (contact.userId?.includes('new-static-')) {
        return omit(contact, ['userId']);
      }

      return contact;
    });
    return {
      ...(organizationModifiedFields.name ? { companyName: organizationModifiedFields.name } : {}),
      ...(organizationModifiedFields.registrationNumber
        ? { companyRegistrationNumber: organizationModifiedFields.registrationNumber }
        : {}),
      ...(organizationModifiedFields.website ? { companyWebsite: organizationModifiedFields.website } : {}),
      ...(addressModifiedFields && { ...addressModifiedFields }),
      ...(!isEmpty(signatoryModifiedFields)
        ? {
            signatureEmail: signatoryModifiedFields?.email,
            signatureName: signatoryModifiedFields?.name,
          }
        : {}),
      ...(editedContacts?.length && { editedContacts }),
      ...(newContacts?.length && { newContacts }),
      ...(deletedContacts?.length && { deletedContacts }),
    };
  };

  const createSuggestedChanges = useCallback(
    async (dataRole: EscrowPartyTypeEnum | Array<EscrowPartyTypeEnum>) => {
      let contactSuggestedChange;

      if (Array.isArray(dataRole)) {
        contactSuggestedChange = dataRole.reduce<Record<string, object>>((acc, currRole) => {
          const data = setPartyContactChangeInput(currRole);
          if (!isEmpty(data)) acc[currRole] = data;
          return {
            ...acc,
          };
        }, {});
      } else {
        const data = setPartyContactChangeInput(dataRole);
        if (!isEmpty(data))
          contactSuggestedChange = {
            [dataRole]: setPartyContactChangeInput(dataRole),
          };
      }

      try {
        const { data } = await createSuggestedChange({
          variables: {
            escrowId,
            suggestedChangeParams: {
              contactSuggestedChange,
            },
          },
        });

        if (data?.createSuggestedChangeEscrow?.errors?.length) {
          throw Error(data?.createSuggestedChangeEscrow?.errors[0]);
        } else {
          writeNewSuggestChanges(suggestChangesVar)('parties', {
            id: data?.createSuggestedChangeEscrow?.suggestedChange?.id,
            creator: data?.createSuggestedChangeEscrow?.suggestedChange?.creatorType,
            status: data?.createSuggestedChangeEscrow?.suggestedChange?.partyStatus as SuggestedChangeStatusEnum,
            payload: data?.createSuggestedChangeEscrow?.suggestedChange?.payload,
            receiver: data?.createSuggestedChangeEscrow?.suggestedChange?.receiverType as EscrowPartyTypeEnum,
          });
          return true;
        }
      } catch (e: unknown) {
        throw Error(String(e));
      }
    },
    [setPartyContactChangeInput],
  );

  const providerValues = {
    view,
    suggestedChanges: changesRequestedData?.clientChangesRequested.parties,
    createSuggestedChanges,
    suggestedChangesForReview,
    isLoading: loading,
    ...rest,
  };

  return <ContactsProvider value={providerValues}>{view && children}</ContactsProvider>;
};

export { useContactsSuggestChanges, ContactsSuggestChangesProvider };
