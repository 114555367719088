import { IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

export enum BackupStatusIndicatorEnum {
  CONNECT = 'connect',
  CONFIG = 'config',
  BACKUP_INITIATED = 'backup-initiated',
  STOPPED = 'stopped',
  REMOVED = 'removed',
}

export const statusIndicatorItems: IJourneyStatusIndicatorItem[] = [
  {
    id: BackupStatusIndicatorEnum.CONNECT,
    label: 'Connect Platform',
  },
  {
    id: BackupStatusIndicatorEnum.CONFIG,
    label: 'Configuration',
  },
  {
    id: BackupStatusIndicatorEnum.BACKUP_INITIATED,
    label: 'Backup Initiated',
  },
  {
    id: BackupStatusIndicatorEnum.STOPPED,
    label: 'Backup Stopped',
    isHidden: true,
  },
  {
    id: BackupStatusIndicatorEnum.REMOVED,
    label: 'Backup Removed',
    isHidden: true,
  },
];
