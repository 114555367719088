import { Fragment, useEffect, useState, useId } from 'react';
import { Button, Form, Input, Select, Row, Col } from 'antd';
import { capitalize } from 'lodash';

import { countries } from 'src/shared/entities';

import { CountrySelectorType, IOrganizationDetail } from '../';

import styles from './EscrowContactsForm.module.scss';

export interface IOrganizationForm {
  name: string;
  website?: string;
  registrationNumber?: string;
  street: string;
  streetNumber?: string;
  country: string;
  city: string;
  state: string;
  zip: string;
}

export type Props = {
  type: 'beneficiary' | 'depositor';
  onSubmit: (data: IOrganizationForm) => void;
  onCancel: () => void;
  initialValues?: IOrganizationDetail;
};

const OrganizationForm: React.FunctionComponent<Props> = ({ type, onSubmit, onCancel, initialValues }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const formId = useId();

  const countriesOptions: CountrySelectorType[] = countries.map((country) => ({
    label: country.name,
    value: country.name,
  }));

  useEffect(() => {
    if (initialValues) {
      const { name, website, registrationNumber, address } = initialValues;
      form.setFieldsValue({
        name,
        website,
        registrationNumber,
        street: address?.street || '',
        streetNumber: address?.streetNumber || '',
        country: address?.country || '',
        city: address?.city || '',
        state: address?.region || '',
        zip: address?.postalCode || '',
      });
    }
  }, [form, initialValues]);

  const handleSubmit = async (e: IOrganizationForm) => {
    setIsLoading(true);

    try {
      onSubmit(e);
      onCancel();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {capitalize(type)} Organization Details<sub>Required</sub>
      </h3>
      <p className={styles.text}>The {type}&apos;s organization details.</p>

      <Form form={form} layout="vertical" className="ck-escrow-contacts__form" onFinish={handleSubmit}>
        <Row className={styles.row}>
          <Col span={12} className={styles.col}>
            <Form.Item name="name" label="Organization Name" rules={[{ required: true }]} htmlFor={`name-${formId}`}>
              <Input id={`name-${formId}`} />
            </Form.Item>
            <Form.Item name="website" label="Website" rules={[{ required: false }]} htmlFor={`website-${formId}`}>
              <Input id={`website-${formId}`} />
            </Form.Item>
            <Form.Item
              name="registrationNumber"
              label={
                <Fragment>
                  Registration Number<sub>optional</sub>
                </Fragment>
              }
              rules={[{ required: false }]}
              htmlFor={`registrationNumber-${formId}`}
            >
              <Input id={`registrationNumber-${formId}`} />
            </Form.Item>
          </Col>

          <Col span={12} className={`${styles.col} ${styles.divider}`}>
            <Form.Item name="street" label="Street Address" rules={[{ required: true }]} htmlFor={`street-${formId}`}>
              <Input id={`street-${formId}`} />
            </Form.Item>
            <Form.Item
              name="streetNumber"
              label={
                <Fragment>
                  Number / Suite / Unit / Floor <sub>optional</sub>
                </Fragment>
              }
              rules={[{ required: false }]}
              htmlFor={`streetNumber-${formId}`}
            >
              <Input id={`streetNumber-${formId}`} />
            </Form.Item>

            <Row wrap={true} className={styles['row-sm']}>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item name="city" label="City" rules={[{ required: true }]} htmlFor={`city-${formId}`}>
                  <Input id={`city-${formId}`} />
                </Form.Item>
              </Col>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item
                  name="state"
                  label="State/Province/Region"
                  rules={[{ required: false }]}
                  htmlFor={`state-${formId}`}
                >
                  <Input id={`state-${formId}`} />
                </Form.Item>
              </Col>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item name="zip" label="Zip/Postal code" rules={[{ required: true }]} htmlFor={`zip-${formId}`}>
                  <Input id={`zip-${formId}`} />
                </Form.Item>
              </Col>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item name="country" label="Country" rules={[{ required: true }]} htmlFor={`country-${formId}`}>
                  <Select
                    placeholder="Select country"
                    options={countriesOptions}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    id={`country-${formId}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className={styles['action-list']}>
          <div className={styles['action-list-right']}>
            {initialValues && (
              <div className={styles['action-item']}>
                <Button type="default" onClick={onCancel}>
                  Discard
                </Button>
              </div>
            )}
            <div className={styles['action-item']}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save details
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OrganizationForm;
