import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoCodecommit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" role="img" className={props.className} {...props}>
    <path fill="#4b612c" d="M25.5 78.7 16 74V25.9l9.5-4.7 13 29.5z" />
    <path fill="#759c3e" d="m56.8 29.6-31.3-8.4v57.5L56.8 70z" />
    <path fill="#4b612c" d="m71.2 65.7 7.3 2.4 4.6-15.8-4.6-15.8-7.3 1z" />
    <path fill="#3c4929" d="m78.5 36.9-7.3.9-4.3-.9 7.5-15.6z" />
    <path fill="#4b612c" d="m66.9 36.9 7.5-1V21.2l-7.5 2z" />
    <path fill="#3c4929" d="m43.1 31.6 6.9-7.2 18 9.9-6.6 1.1z" />
    <path fill="#b7ca9d" d="m78.5 63.1-7.3-.8-4.3.8 7.5 15.7z" />
    <path fill="#4b612c" d="m66.9 63.1 7.5 1v14.7l-7.5-2z" />
    <path fill="#759c3e" d="m68 34.3-18-4.4V9l18 9.1zM84 26l-9.6-4.8v14.7l9.6 2.3z" />
    <path fill="#b7ca9d" d="M43.1 68.4 50 82.1l18-16.4-6.6-1.1z" />
    <path fill="#759c3e" d="m68 65.7-18 4.4V91l18-9zM84 74.1l-9.6 4.7V64.1l9.6-2.3z" />
    <path fill="#4b612c" d="M31.9 81.9 50 91l8.3-40.9L50 9l-18.1 9z" />
    <g fill="#759c3e">
      <path d="M60.2 85.9 50 91V9.3l10.2 5.1zM78.5 76.8l5.5-2.7V26l-5.5-1.2z" />
    </g>
  </svg>
);
const Memo = memo(SvgLogoCodecommit);
export default Memo;
