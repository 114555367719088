import { Fragment } from 'react';
import { Dropdown, Badge, Divider } from 'antd';
import { BellFilled } from '@ant-design/icons';

import { IActivitiesQuery } from 'src/graphql/schema';

import NotificationMenuItems from './NotificationMenuItems';

type Props = {
  activities: IActivitiesQuery['activities']['nodes'];
  markAsRead: (isVisible: boolean) => void;
  isLoading?: boolean;
  notificationsCount: number;
};

const DropdownNotificationMenu: React.FunctionComponent<Props> = ({
  activities,
  isLoading,
  markAsRead,
  notificationsCount,
}) => {
  return (
    <Fragment>
      <Dropdown
        menu={{ items: NotificationMenuItems({ activities, isLoading }) }}
        placement="bottomRight"
        arrow
        trigger={['click']}
        onOpenChange={(menuVisible) => markAsRead(menuVisible)}
      >
        <span className="d-flex">
          <Badge count={notificationsCount}>
            <BellFilled className="cursor-pointer nav__icon" />
          </Badge>
        </span>
      </Dropdown>

      <Divider type="vertical" className="h-50" />
    </Fragment>
  );
};

export default DropdownNotificationMenu;
