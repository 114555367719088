import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageAwaitingList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 86 102"
    role="img"
    className={props.className}
    {...props}
  >
    <g filter="url(#image-awaiting-list_svg__a)">
      <path
        fill="#FAFDFF"
        stroke="#C0EAF0"
        strokeLinejoin="round"
        strokeWidth={0.976}
        d="M18.037 6h36.14l15.625 15.56v56.684c0 4.283-3.488 7.756-7.79 7.756H18.038c-4.302 0-7.789-3.473-7.789-7.756V13.756c0-4.283 3.487-7.756 7.79-7.756Z"
      />
      <path fill="#C0EAF0" stroke="#C0EAF0" strokeLinejoin="round" strokeWidth={0.976} d="M69.804 22h-16V6z" />
      <rect width={28} height={3} x={18.525} y={19} fill="#D5F1F3" rx={1.5} />
      <rect width={43} height={3} x={18.525} y={28} fill="#D5F1F3" rx={1.5} />
      <rect width={42.453} height={3.064} x={18.523} y={36.645} fill="#D5F1F3" rx={1.532} />
      <rect width={42.453} height={3.064} x={18.523} y={45.398} fill="#D5F1F3" rx={1.532} />
      <rect width={42} height={3} x={18.525} y={54} fill="#D5F1F3" rx={1.5} />
      <rect width={42} height={3} x={18.525} y={63} fill="#D5F1F3" rx={1.5} />
      <rect width={42} height={3} x={18.525} y={72} fill="#D5F1F3" rx={1.5} />
    </g>
    <g filter="url(#image-awaiting-list_svg__b)">
      <circle cx={12} cy={12} r={12} fill="#4C86DD" transform="matrix(-1 0 0 1 80.752 47)" />
    </g>
    <path
      fill="#fff"
      d="M63.852 60.402a.7.7 0 1 0 1.4 0 .7.7 0 0 0-1.4 0M68.052 59.002a.7.7 0 1 0 1.4 0 .7.7 0 0 0-1.4 0M72.252 57.602a.7.7 0 1 0 1.4 0 .7.7 0 0 0-1.4 0"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M63.852 60.402a.7.7 0 1 0 1.4 0 .7.7 0 0 0-1.4 0M68.052 59.002a.7.7 0 1 0 1.4 0 .7.7 0 0 0-1.4 0M72.252 57.602a.7.7 0 1 0 1.4 0 .7.7 0 0 0-1.4 0"
    />
    <defs>
      <filter
        id="image-awaiting-list_svg__a"
        width={80.048}
        height={100.492}
        x={0.002}
        y={0.633}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4.879} />
        <feGaussianBlur stdDeviation={4.879} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.138854 0 0 0 0 0.297987 0 0 0 0 0.3875 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8399_156481" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_8399_156481" result="shape" />
      </filter>
      <filter
        id="image-awaiting-list_svg__b"
        width={33.545}
        height={33.545}
        x={51.979}
        y={44.136}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1.909} />
        <feGaussianBlur stdDeviation={2.386} />
        <feColorMatrix values="0 0 0 0 0.546944 0 0 0 0 0.720972 0 0 0 0 0.745833 0 0 0 0.51 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8399_156481" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_8399_156481" result="shape" />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(SvgImageAwaitingList);
export default Memo;
