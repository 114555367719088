import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconTripartite = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 33 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M11.133 15.729H21.68v2.344H11.133v-2.344ZM11.133 11.041H21.68v2.344H11.133v-2.344Z"
      />
      <path
        fill="currentColor"
        d="M29.297 32.969V27.89H17.578v-2.344h8.789V0H12.002L6.446 5.556v19.99h8.788v2.345H3.516v5.078H0V40h9.375v-7.031H5.859v-2.735h9.375v2.735H11.72V40h9.375v-7.031h-3.516v-2.735h9.375v2.735h-3.515V40h9.375v-7.031h-3.516ZM12.8 2.517v3.837H8.963L12.8 2.517Zm-4.01 6.18h6.354V2.345h8.879v20.86H8.79V8.697ZM7.03 37.658H2.344v-2.344H7.03v2.343Zm11.719 0h-4.688v-2.344h4.688v2.343Zm11.719 0H25.78v-2.344h4.688v2.343Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconTripartite);
export default Memo;
