import env from 'env';

import styles from './CancelPlanBanner.module.scss';

const CancelPlanBanner = () => {
  return (
    <div className={styles.banner}>
      Changes and/or cancelation pending. They will automatically be applied based on the terms of your account. To
      resubscribe to your current plan, please contact{' '}
      <strong>
        <a href={`mailto: ${env.CODEKEEPER_SERVICE_EMAIL}`}>{env.CODEKEEPER_SERVICE_EMAIL}</a>
      </strong>
    </div>
  );
};

export default CancelPlanBanner;
