import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoSharefile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    role="img"
    className={props.className}
    {...props}
  >
    <g fill="#000" clipPath="url(#logo-sharefile_svg__a)">
      <path d="M20 6.418V5h-6.666L15 2.5l-1.418-.709-3.085 4.627zM0 13.582v1.417L6.666 15 5 17.5l1.418.709 3.085-4.627zM1.418 4.29 0 5l3.334 5H0v1.418h6.17zM18.582 15.709 19.999 15l-3.332-5h3.332V8.582H13.83zM8.582 19.29 10 20l3.333-5L15 17.5l1.418-.709-3.085-4.627zM11.419.709 10 0 6.666 5 5 2.5l-1.418.709 3.084 4.627z" />
    </g>
    <defs>
      <clipPath id="logo-sharefile_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgLogoSharefile);
export default Memo;
