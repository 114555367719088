import { Form, Input, Button, Checkbox } from 'antd';

import { StepsEnum } from '../../InviteSteps.enum';

export default function InviteForm({ step, setStep }: { step: StepsEnum; setStep: (step: StepsEnum) => void }) {
  const [form] = Form.useForm();

  const onFinish = () => {
    if (step === 'password') {
      setStep('account');
    } else if (step === 'account') {
      setStep('company');
    }
  };

  return (
    <>
      <h2 className="invite-screen__title mb-5">
        {step === 'password' ? 'Set your password' : 'Sign in to your account'}
      </h2>

      <Form layout="vertical" name="basic" onFinish={onFinish} form={form}>
        {step === 'account' && (
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
        )}

        <Form.Item label="Password" name="password">
          <p className="invite-screen__password-subtitle">
            Your password must be at least 8 characters, and include at least one <br /> lowecase letter, one uppercase
            letter and a special character.
          </p>
          <Input.Password placeholder="Your password" />
        </Form.Item>

        {step === 'password' && (
          <Form.Item label="Confirm password" name="confirmedPassword">
            <Input.Password placeholder="Your password" />
          </Form.Item>
        )}

        {step === 'account' && (
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        )}
        <Form.Item className="d-flex justify-content-center mt-5">
          <Button type="primary" className="invite-screen__btn" htmlType="submit">
            {step === 'account' ? 'Log In' : 'Next'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
