import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconBell = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M5.2 11.335a.8.8 0 0 1 .8-.8h4a.8.8 0 0 1 .8.8v.667a2.8 2.8 0 1 1-5.6 0v-.667Zm1.607.8a1.2 1.2 0 0 0 2.386 0H6.807Z"
        clipRule="evenodd"
      />
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M8 2.8a3.867 3.867 0 0 0-3.867 3.866v2.482c0 .33-.131.647-.365.881l-.301.302v.202h9.066v-.16l-.147-.09a.802.802 0 0 1-.151-.118 1.093 1.093 0 0 1-.222-.33 1.83 1.83 0 0 1-.146-.687V6.665A3.867 3.867 0 0 0 8 2.799ZM2.533 6.665a5.467 5.467 0 0 1 10.934 0v2.402l.086.052a.8.8 0 0 1 .15.118c.276.275.43.648.43 1.037v.39c0 .81-.656 1.468-1.466 1.468H3.333c-.81 0-1.466-.657-1.466-1.467v-.39c0-.39.154-.763.43-1.038l.236-.237V6.666Z"
        clipRule="evenodd"
      />
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M11.373.862a.8.8 0 0 1 1.12-.158 7.467 7.467 0 0 1 2.355 2.988.8.8 0 0 1-1.467.638 5.867 5.867 0 0 0-1.85-2.348.8.8 0 0 1-.158-1.12ZM4.627.862a.8.8 0 0 1-.158 1.12A5.867 5.867 0 0 0 2.62 4.33a.8.8 0 0 1-1.467-.638A7.467 7.467 0 0 1 3.507.704a.8.8 0 0 1 1.12.158Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconBell);
export default Memo;
