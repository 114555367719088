import { Fragment, useState, useEffect } from 'react';
import { Button } from 'antd';

import { InvitationDetailBlock, IInviteDetail, PartyInvitationForm, IPartyInvitationForm } from '..';

import styles from './EscrowContactsEditable.module.scss';

type Props = {
  type: 'beneficiary' | 'depositor';
  data?: IInviteDetail;
  onSubmit: (data: IPartyInvitationForm) => Promise<void>;
};

const EditableSignatoryDetail: React.FunctionComponent<Props> = ({ type, data, onSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);

  const toggleEdit = () => setIsEdit((prevIsEdit) => !prevIsEdit);

  useEffect(() => {
    if (!data) setIsEdit(true);
  }, [data]);

  return (
    <div>
      {isEdit ? (
        <PartyInvitationForm type={type} onSubmit={onSubmit} onCancel={toggleEdit} initialValues={data} />
      ) : (
        <Fragment>
          {data && <InvitationDetailBlock className={styles['mb-20']} type={type} {...data} />}

          <div className={styles['edit-row']}>
            <Button ghost className={styles['edit-btn']} onClick={toggleEdit}>
              Edit details
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default EditableSignatoryDetail;
