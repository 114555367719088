import classNames from 'classnames';

import styles from './JourneyLayout.module.scss';

const JourneyLayoutMain = ({
  children,
  className,
  withPadding = false,
}: React.PropsWithChildren<{ className?: string; withPadding?: boolean }>) => {
  const wrapperClasses = classNames(styles['main-outlet__wrapper'], className, {
    [styles['with--padding']]: withPadding,
  });

  return (
    <div className={styles['main-outlet']}>
      <div className={wrapperClasses}>{children}</div>
    </div>
  );
};

export default JourneyLayoutMain;
