import { Dropdown, Spin } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';

import ProfileMenuItems from './ProfileMenuItems';

type Props = {
  user: { name: string; image: string };
  signOut: () => void;
  isImpersonating: boolean;
  stopImpersonating: () => void;
  isLoading?: boolean;
};

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const DropdownProfileMenu: React.FunctionComponent<Props> = ({
  user,
  signOut,
  isImpersonating,
  stopImpersonating,
  isLoading = false,
}) => {
  const { name: userName, image } = user;

  return (
    <Dropdown
      menu={{ items: ProfileMenuItems({ userName, signOut, isImpersonating, stopImpersonating }) }}
      placement="bottomRight"
      arrow
      trigger={['click']}
    >
      {isLoading ? (
        <Spin indicator={antIcon} />
      ) : (
        <div className="d-flex align-items-center nav__dropdown">
          <img src={image} alt="avatar" className="nav__avatar mr-2" />

          <p className="nav__name mb-0">{isImpersonating ? `${userName} (impersonating)` : userName}</p>

          <DownOutlined />
        </div>
      )}
    </Dropdown>
  );
};

export default DropdownProfileMenu;
