type Props = {
  children: React.ReactNode;
  icon: string;
  stepNumber: number;
};

export default function Step({ children, icon, stepNumber }: Props) {
  return (
    <div className="d-flex non-auth-modal__step-container">
      <div className="d-flex align-items-start">
        {stepNumber && <span className="non-auth-modal__step">{stepNumber}</span>}
        <span className="non-auth-modal__step-text">{children}</span>
      </div>
      <img className="non-auth-modal__step-img" src={icon} alt="Step Icon" />
    </div>
  );
}
