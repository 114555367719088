/* eslint-disable jsx-a11y/aria-role */
import {
  ContactDetailBlock,
  OrganizationDetailBlock,
  SignatoryDetailBlock,
  IContactDetail,
  IOrganizationDetail,
  ISignatoryDetail,
} from 'src/components/Escrow/Contacts';
import { SuggestChangesList } from 'src/components/SuggestChanges';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';

import InfoContentBlock from '../InfoBlocks/InfoContentBlock';
import styles from '../EscrowContacts.module.scss';

export type ISuggestChangesPreview = {
  status: 'pending' | 'accepted';
  currentCompanyRole: 'beneficiary' | 'depositor';
  creator: 'beneficiary' | 'depositor';
  receiver: 'beneficiary' | 'depositor';
  organizationItems?: { label: string; value: string }[];
  signatoryItems?: { label: string; value: string }[];
  newContacts?: IContactDetail[];
  byContactId: (id: string) => { label: string; value: string }[];
};

type Props = {
  role: EscrowPartyTypeEnum;
  isOwnOrganization?: boolean;
  organization: IOrganizationDetail;
  signatory?: ISignatoryDetail;
  contacts?: IContactDetail[];
  suggestedChanges?: ISuggestChangesPreview;
};

const contentBlockTextEnum = {
  beneficiary: {
    heading: 'Beneficiary Information',
    text: 'These are the details of the company that will receive the material.',
  },
  depositor: {
    heading: 'Depositor Information',
    text: 'These are the details of the source code owner.',
  },
};

const SuggestChangesPreview: React.FunctionComponent<Props> = ({
  role,
  organization,
  signatory,
  contacts,
  isOwnOrganization = false,
  suggestedChanges,
}) => {
  return (
    <div className={styles.container}>
      <InfoContentBlock
        heading={contentBlockTextEnum[role].heading}
        headingLabel={isOwnOrganization ? '(your organization)' : ''}
        text={contentBlockTextEnum[role].text}
      />
      <OrganizationDetailBlock
        className={styles.block}
        type={role}
        {...organization}
        suggestionList={
          suggestedChanges?.organizationItems && (
            <SuggestChangesList
              status={suggestedChanges.status}
              currentCompanyRole={suggestedChanges.currentCompanyRole}
              creator={suggestedChanges.creator}
              receiver={suggestedChanges.receiver}
              items={suggestedChanges.organizationItems}
            />
          )
        }
      />
      {signatory && (
        <SignatoryDetailBlock
          className={styles.block}
          type={role}
          {...signatory}
          suggestionList={
            suggestedChanges?.signatoryItems && (
              <SuggestChangesList
                status={suggestedChanges.status}
                currentCompanyRole={suggestedChanges.currentCompanyRole}
                creator={suggestedChanges.creator}
                receiver={suggestedChanges.receiver}
                items={suggestedChanges.signatoryItems}
              />
            )
          }
        />
      )}

      <div className={styles.block}>
        {contacts?.map((contact) => (
          <ContactDetailBlock
            className={styles['contact-item']}
            key={contact.id}
            type={role}
            {...contact}
            suggestionList={
              suggestedChanges && (
                <SuggestChangesList
                  status={suggestedChanges.status}
                  currentCompanyRole={suggestedChanges.currentCompanyRole}
                  creator={suggestedChanges.creator}
                  receiver={suggestedChanges.receiver}
                  items={suggestedChanges.byContactId(contact.id)}
                />
              )
            }
          />
        ))}

        {suggestedChanges?.newContacts?.map((contact) => (
          <ContactDetailBlock
            className={styles['contact-item']}
            key={contact.id}
            type={role}
            {...contact}
            suggestionList={
              <SuggestChangesList
                status={suggestedChanges.status}
                currentCompanyRole={suggestedChanges.currentCompanyRole}
                creator={suggestedChanges.creator}
                receiver={suggestedChanges.receiver}
                items={[
                  {
                    label: 'Suggested',
                    value: 'new contact',
                  },
                ]}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export default SuggestChangesPreview;
