import {
  IOrganizationForm,
  ISignatoryForm,
  IAddContactForm,
  EditableDetailsByRole,
  IContactDetail,
  IOrganizationDetail,
  ISignatoryDetail,
  IAddExistingContactForm,
  ToggleAddNewContactForm,
} from 'src/components/Escrow/Contacts';
import { useContactsSuggestChanges } from 'src/context/suggest-changes';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';

type Props = {
  role: EscrowPartyTypeEnum;
};

const EditableMainPartyContainer: React.FunctionComponent<Props> = ({ role }) => {
  const { view, existingUsers, updateOrganization, updateSignatory, addNewContact, updateContact, removeContact } =
    useContactsSuggestChanges();

  const organization = view?.[role]?.organization as IOrganizationDetail;
  const signatory = view?.[role]?.signatory as ISignatoryDetail;
  const contacts: IContactDetail[] = view?.[role]?.contacts || [];
  const existingUserOptions =
    existingUsers.map(({ id, name }) => ({
      value: id || '',
      label: name || '',
    })) || [];

  const onSubmitSignatory = ({ email, name }: ISignatoryForm) => {
    updateSignatory(role, {
      email,
      name,
    });
  };
  const onSubmitContact = (id: string, data: IAddContactForm & { isDynamic?: boolean }) =>
    updateContact(role, {
      id,
      email: data.email,
      name: data.name,
      role: data.role,
      phone: data.phone,
      isDynamic: data.isDynamic,
    });
  const onSubmitNewContact = ({ email, name, role: newRole, phone }: IAddContactForm) => {
    const existingContact = existingUsers.find((user) => user.email === email);

    if (existingContact) {
      return addNewContact(role, {
        id: existingContact.id,
        email,
        name: existingContact.name,
        role: newRole,
        phone: existingContact.phone || '',
        isDynamic: true,
      });
    }

    addNewContact(role, {
      id: `new-static-${String(Date.now())}`,
      email,
      name,
      role: newRole,
      phone,
    });
  };
  const onSubmitOrganization = (data: IOrganizationForm) =>
    updateOrganization(role, {
      name: data.name,
      website: data.website,
      registrationNumber: data.registrationNumber,
      address: {
        street: data.street,
        streetNumber: data.streetNumber,
        city: data.city,
        postalCode: data.zip,
        region: data.state,
        country: data.country,
      },
    });
  const onSubmitExistingContact = ({ id, role: newRole }: IAddExistingContactForm) => {
    const contact = existingUsers.find((user) => user.id === id);

    if (!contact) return;
    const { email, name, phone } = contact;

    addNewContact(role, {
      id,
      email,
      name: name || '',
      role: newRole,
      phone: phone || '',
      isDynamic: true,
    });
  };
  const onRemoveContact = (contactId: string) => removeContact(role, contactId);

  return (
    <div className={styles.container}>
      <EditableDetailsByRole
        role={role}
        isOwnOrganization
        organization={organization}
        signatory={signatory}
        contacts={contacts}
        onSubmitSignatory={onSubmitSignatory}
        onSubmitContact={onSubmitContact}
        onSubmitOrganization={onSubmitOrganization}
        onRemoveContact={onRemoveContact}
      />

      <ToggleAddNewContactForm
        type={role}
        onSubmitContact={onSubmitNewContact}
        withExistingSelect={true}
        existingContacts={existingUserOptions}
        onSubmitExistingContact={onSubmitExistingContact}
      />
    </div>
  );
};

export default EditableMainPartyContainer;
