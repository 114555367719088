import { useState } from 'react';

import { QueryOrderEnum } from 'src/graphql/schema/index.ts';

function useSorting() {
  const [order, setOrder] = useState<QueryOrderEnum>(QueryOrderEnum.Desc);

  const orderQuery = () => {
    if (order === QueryOrderEnum.Asc) {
      setOrder(QueryOrderEnum.Desc);
    } else {
      setOrder(QueryOrderEnum.Asc);
    }
  };

  return [order, orderQuery] as const;
}

export default useSorting;
