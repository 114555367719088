import { useNavigate, useParams } from 'react-router-dom';

import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { useCustodianProfile } from 'src/context/custodian';
import { CustodianRoutesEnum } from 'src/pages/Custodian';
import { CustodianProfileStatusEnum } from 'src/graphql/schema';

const CustodianNavigationContainer = () => {
  const navigate = useNavigate();
  const { id, '*': activePath } = useParams<{
    id: string;
    '*': CustodianRoutesEnum;
  }>();
  const {
    custodianProfile: { status, custodianAssets },
    ui: {
      assets: { isEditMode },
    },
  } = useCustodianProfile();

  if (isEditMode) return null;

  const routes = [CustodianRoutesEnum.Assets];

  if (custodianAssets.length) routes.push(CustodianRoutesEnum.Operations);
  if (status !== CustodianProfileStatusEnum.Draft) routes.push(CustodianRoutesEnum.Integrations);
  if (status === CustodianProfileStatusEnum.Active) routes.push(CustodianRoutesEnum.Certificate);

  const activeRouteIndex = routes.findIndex((route) => route === activePath);

  const goToPreviousRoute = () => {
    if (activeRouteIndex > 0) {
      navigate(`/custodian-profiles/${id}/${routes[activeRouteIndex - 1]}`);
    }
  };

  const goToNextRoute = () => {
    if (activeRouteIndex < routes.length - 1) {
      navigate(`/custodian-profiles/${id}/${routes[activeRouteIndex + 1]}`);
    }
  };

  if (activePath && !Object.values(CustodianRoutesEnum).includes(activePath)) return null;

  return (
    <JourneyNavigation
      isPrevHidden={!activeRouteIndex}
      isNextHidden={routes.length !== 1 && activeRouteIndex === routes.length - 1}
      onPrev={goToPreviousRoute}
      onNext={goToNextRoute}
      isNextDisabled={routes.length === 1}
    />
  );
};

export default CustodianNavigationContainer;
