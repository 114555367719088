import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  INITIAL = 'initial',
  INVITE = 'invite',
  EDIT = 'edit',
}

type IActionReducer =
  | IAction<ActionEnum.INITIAL, object>
  | IAction<ActionEnum.INVITE, object>
  | IAction<ActionEnum.EDIT, { memberId: string }>;

interface IState {
  memberId: string;
  modalType: 'invite' | 'edit';
  isOpen: boolean;
}

const initialState: IState = {
  memberId: '',
  modalType: 'invite',
  isOpen: false,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.INVITE:
      return {
        ...state,
        modalType: 'invite',
        isOpen: true,
      };
    case ActionEnum.EDIT:
      return {
        ...state,
        modalType: 'edit',
        isOpen: true,
        memberId: action.payload.memberId,
      };
    case ActionEnum.INITIAL:
      return {
        memberId: '',
        modalType: 'invite',
        isOpen: false,
      };
    default:
      return state;
  }
};

export const useAccountMembersSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const editMember = (memberId: string) => {
    dispatch({
      type: ActionEnum.EDIT,
      payload: { memberId },
    });
  };

  const inviteMember = () => dispatch({ type: ActionEnum.INVITE, payload: {} });

  const setInitial = () => dispatch({ type: ActionEnum.INITIAL, payload: {} });

  return { state, inviteMember, editMember, setInitial };
};
