import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

export enum StepEnum {
  INITIAL = 'initial',
  SEND_CODE = 'send_code',
  RESET = 'reset',
  CONFIRMATION = 'confirmation',
  SET_LOADING = 'set_loading',
}

type IActionReducer =
  | IAction<StepEnum.INITIAL>
  | IAction<StepEnum.SEND_CODE, { email: string }>
  | IAction<StepEnum.RESET>
  | IAction<StepEnum.CONFIRMATION>
  | IAction<StepEnum.SET_LOADING, boolean>;

interface IState {
  loading: boolean;
  activeStep: StepEnum;
  email: string;
}

const initialState: IState = {
  loading: false,
  activeStep: StepEnum.INITIAL,
  email: '',
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case StepEnum.SEND_CODE:
      return {
        ...state,
        email: action.payload.email,
        activeStep: StepEnum.RESET,
      };
    case StepEnum.RESET:
      return {
        ...state,
        activeStep: StepEnum.CONFIRMATION,
      };
    case StepEnum.CONFIRMATION:
      return {
        ...state,
        email: '',
        activeStep: StepEnum.INITIAL,
      };
    case StepEnum.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export const useForgotPasswordSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sendCode = (email: string) => {
    dispatch({
      type: StepEnum.SEND_CODE,
      payload: {
        email,
      },
    });
  };

  const setLoading = (loading: boolean) => dispatch({ type: StepEnum.SET_LOADING, payload: loading });

  const reset = () =>
    dispatch({
      type: StepEnum.RESET,
      payload: {},
    });

  return { state, sendCode, setLoading, reset };
};
