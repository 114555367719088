import classNames from 'classnames';

import styles from './ProfileCard.module.scss';

type Props = {
  title?: string;
  text?: string;
  className?: string;
};

const ProfileCard = ({ title, text, children, className }: React.PropsWithChildren<Props>) => {
  const cardClasses = classNames(styles.card, className);

  return (
    <div className={cardClasses}>
      {title && <h6 className={styles.title}>{title}</h6>}
      {text && <p className={styles.text}>{text}</p>}
      {children}
    </div>
  );
};

export default ProfileCard;
