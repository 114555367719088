import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from 'src/components/Loader/Loader';
import { HomeContent, HomeEmptyContent } from 'src/components/Home';
import { ActionsProvider } from 'src/context/home';
import { useCompanyQuery } from 'src/graphql/schema';

const HomeContainer: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: company, loading } = useCompanyQuery();

  const invitationStatusPending = !loading && company?.company?.invitationStatus === 'pending';

  useEffect(() => {
    if (company && invitationStatusPending && !loading) {
      navigate('/invite');
    }
  }, [company, invitationStatusPending, loading, navigate]);

  if (loading) return <Loader />;

  const shouldRenderContent = company && !invitationStatusPending;
  const onCreateNewEscrow = () => navigate('/escrows/new');

  if (!shouldRenderContent) return <HomeEmptyContent onCreate={onCreateNewEscrow} />;

  return (
    <ActionsProvider>
      <HomeContent />
    </ActionsProvider>
  );
};

export default HomeContainer;
