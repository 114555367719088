import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconEdit = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 11"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m9.415.275 1.32 1.325a.926.926 0 0 1 .27.662c0 .25-.096.486-.272.663l-6.52 6.537L1.572 6.81 8.092.273a.934.934 0 0 1 1.323.002ZM.092 10.908a.315.315 0 0 1-.08-.308L.85 7.67l2.487 2.482-2.936.836a.32.32 0 0 1-.309-.08Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconEdit);
export default Memo;
