import { SaasStatusIndicator, SaasStatusIndicatorEnum } from 'src/components/Saas/StatusIndicator';
import { useSaasEscrow } from 'src/context/saas';
import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

const SaasStatusIndicatorContainer = () => {
  const { environment } = useSaasEscrow();

  let currentStatus = SaasStatusIndicatorEnum.ENVIRONMENT;

  if (environment.status === SaasEnvironmentStatusEnum.Pending) currentStatus = SaasStatusIndicatorEnum.ACCESS_SETUP;

  if (environment.status === SaasEnvironmentStatusEnum.Active) currentStatus = SaasStatusIndicatorEnum.ACTIVE;

  if (environment.saasEnvironmentTermination) currentStatus = SaasStatusIndicatorEnum.TERMINATED_REQUESTED;

  if (environment.status === SaasEnvironmentStatusEnum.Terminated) currentStatus = SaasStatusIndicatorEnum.TERMINATED;

  return <SaasStatusIndicator currentStatus={currentStatus} />;
};

export default SaasStatusIndicatorContainer;
