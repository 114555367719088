import { useAddDeposit } from 'src/context/deposit';
import {
  useAssetsDepositSourceQuery,
  useGetAssetsCountQuery,
  AssetTypeEnum,
  useOauthReposConnQuery,
} from 'src/graphql/schema';

// eslint-disable-next-line react/display-name
export const withInitialOAuthData = (Component: React.ElementType) => () => {
  const {
    ui: { selectedEscrowId, integration },
  } = useAddDeposit();
  const { data: dataRepos, loading: loadingRepos } = useOauthReposConnQuery({
    variables: {
      integrationId: integration?.id ?? '',
    },
    skip: !integration?.id,
  });
  const { data: assetsCountData, loading: assetsCountLoading } = useGetAssetsCountQuery({
    fetchPolicy: 'network-only',
    variables: { escrowId: selectedEscrowId },
    skip: !selectedEscrowId,
  });
  const { data: assetsSourceData, loading: assetsSourceLoading } = useAssetsDepositSourceQuery({
    fetchPolicy: 'network-only',
    variables: { escrowId: selectedEscrowId, perPage: assetsCountData?.assets?.nodesCount },
    skip: assetsCountLoading || !selectedEscrowId,
  });
  const isLoading = loadingRepos || assetsCountLoading || assetsSourceLoading;
  // Filter out the repositories that are already added to the escrow
  const assetsSource: string[] = [];
  for (let i = 0; i < (assetsSourceData?.assetsSource?.nodes.length ?? 0); i++) {
    if (assetsSourceData?.assetsSource?.nodes[i].type !== AssetTypeEnum.OauthRepo) break;
    assetsSource.push(assetsSourceData?.assetsSource?.nodes[i].depositSource ?? '');
  }
  const repositories =
    (!isLoading &&
      dataRepos?.oauthReposConn.repositories
        .filter(({ fullName }) => !assetsSource.includes(fullName ?? ''))
        .map((repo) => ({ oauthRepositoryId: repo.id || '', oauthRepositoryFullName: repo.fullName ?? '' }))) ||
    [];

  return <Component repositories={repositories} isLoading={isLoading} />;
};
