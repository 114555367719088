import { Button } from 'antd';

import {
  EscrowsDocument,
  useRejectEscrowInvitationMutation,
  useAcceptEscrowInvitationMutation,
  useEscrowsCountQuery,
} from 'src/graphql/schema';
import { message } from 'src/components/Misc';

type Props = {
  escrowInvitationId: string;
};

const EscrowInvitationActionsContainer = ({ escrowInvitationId }: Props) => {
  const { refetch: refetchCount } = useEscrowsCountQuery();
  const [acceptEscrowInvitation, { loading: acceptLoading }] = useAcceptEscrowInvitationMutation({
    refetchQueries: [EscrowsDocument],
  });
  const [rejectEscrowInvitation, { loading: rejectLoading }] = useRejectEscrowInvitationMutation({
    refetchQueries: [EscrowsDocument],
  });

  const acceptInvitation = async () => {
    try {
      const { data } = await acceptEscrowInvitation({
        variables: {
          escrowInvitationId,
        },
      });
      if (data?.acceptEscrowInvitation?.success) {
        refetchCount();
        message.success('Invitation accepted!');
      } else {
        throw Error(data?.acceptEscrowInvitation?.errors?.[0]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    }
  };

  const rejectInvitation = async () => {
    try {
      const { data } = await rejectEscrowInvitation({
        variables: {
          escrowInvitationId,
        },
      });
      if (data?.rejectEscrowInvitation?.success) {
        refetchCount();
        message.success('Invitation rejected!');
      } else {
        throw Error(data?.rejectEscrowInvitation?.errors?.[0]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    }
  };

  return (
    <div className="d-flex align-items-center">
      <Button size="small" type="default" className="mr-2" onClick={rejectInvitation} loading={rejectLoading}>
        Reject
      </Button>
      <Button size="small" type="primary" onClick={acceptInvitation} loading={acceptLoading}>
        Accept
      </Button>
    </div>
  );
};

export default EscrowInvitationActionsContainer;
