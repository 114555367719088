import { Fragment } from 'react';
import { Button } from 'antd';
import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import styles from './MigrationConnect.module.scss';

type Props = {
  items: {
    id: string;
    title: string;
  }[];
  onClick: () => void;
};

const MigrationConnectFailure = ({ items, onClick }: Props) => {
  const itemsCount = items.length;

  return (
    <Fragment>
      <h3 className={styles.title}>
        {itemsCount > 1 ? `${itemsCount} migrations failed` : 'Migration failed'}
        &nbsp;
        <CloseCircleFilled style={{ color: '#EE5E5E' }} />
      </h3>
      <p className={styles.text}>These assets cannot be migrated at this time:</p>

      <div className="mb-4">
        {items.map((item) => (
          <p className="text-left mb-2" key={item.id}>
            <ExclamationCircleFilled style={{ color: '#FFC53D' }} />
            &nbsp;
            {item.title}
          </p>
        ))}

        <p>Please attempt to migrate each of these assets individually.</p>
      </div>

      <div className={styles['btn-confirm']}>
        <Button type="primary" onClick={onClick}>
          Confirm
        </Button>
      </div>
    </Fragment>
  );
};

export default MigrationConnectFailure;
