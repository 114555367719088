import { useMemo } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import { AssetTypeEnum } from 'src/graphql/schema';
import { PROVIDER_KEY_TO_NAME, ASSETS_LIMIT } from 'src/shared/constants';
import { message } from 'src/components/Misc';

import DepositAssetsTableAction from './DepositAssetsTableAction';
import styles from './DepositAssetsTable.module.scss';

export type ITableRow = {
  action: {
    id: string;
    type?: string;
  };
  type: string;
  provider: string;
  source: string;
  latestDeposit: Date;
  size: string;
  status: string;
};

type Props = (
  | {
      isSuggestedChanges: true;
      onRemoveAsset: (id: string) => void;
      onUndoRemovedAsset: (id: string) => void;
      deletedIdAssets: string[];
    }
  | {
      isSuggestedChanges?: false;
      deletedIdAssets?: undefined;
      onRemoveAsset?: undefined;
      onUndoRemovedAsset?: undefined;
      onDestroyAsset?: (id: string) => Promise<void>;
    }
) & {
  assetsData: ITableRow[];
  pagination: {
    current: number | undefined;
    total: number;
    onChange: (page: number | undefined) => Promise<void>;
  };
  isLoading?: boolean;
};

const DepositAssetsTable: React.FunctionComponent<Props> = (props) => {
  const { assetsData, pagination, isLoading = false, isSuggestedChanges } = props;

  const columns = useMemo(
    () => [
      {
        title: 'TYPE',
        dataIndex: 'type',
        render: (text: string) => {
          let type = '';
          if (text === AssetTypeEnum.CustomRepo) {
            type = 'Custom';
          } else if (text === AssetTypeEnum.OauthRepo) {
            type = 'OAuth';
          } else {
            type = 'Secure Upload';
          }
          return <span>{type}</span>;
        },
      },
      {
        title: 'PROVIDER',
        dataIndex: 'provider',
        render: (text) => (
          <span>
            {text
              ? PROVIDER_KEY_TO_NAME[text as keyof typeof PROVIDER_KEY_TO_NAME] || capitalize(text)
              : 'Secure Upload'}
          </span>
        ),
      },
      {
        title: 'DEPOSIT SOURCE',
        dataIndex: 'source',
      },
      {
        title: 'LAST DEPOSIT',
        dataIndex: 'latestDeposit',
        render: (text: string) => <span>{text ? dayjs(text).format('DD/M/YYYY, HH:mm') : 'No deposit yet'}</span>,
      },
      {
        title: 'LAST UPDATE CHECK',
        dataIndex: 'lastUpdateCheck',
        render: ({ lastUpdateCheck, type }: { lastUpdateCheck: string | undefined; type: string }) => {
          if (type === 'secure_upload') return <span>-</span>;

          if (lastUpdateCheck) {
            return <span>{dayjs(lastUpdateCheck).format('DD/M/YYYY, HH:mm')}</span>;
          } else return <span>No deposit yet</span>;
        },
      },
      {
        title: 'DEPOSIT STATUS',
        dataIndex: 'status',
        render: (text: string) => (
          <span
            className={classNames(styles['deposit-status'], {
              [styles.taken]: text === 'taken',
              [styles.awaiting]: text === 'awaiting',
              [styles.configuring]: text === 'configuring',
            })}
          >
            {text}
          </span>
        ),
      },
      {
        title: 'SIZE',
        dataIndex: 'size',
      },
      {
        title: '',
        dataIndex: 'action',
        render: ({ id, type }: { id: string; type?: string }, { status }: { status: string }) => {
          const handleRemoveAsset = () => {
            if (props.deletedIdAssets && props.deletedIdAssets.length + 1 < pagination.total) {
              props.onRemoveAsset && props.onRemoveAsset(id);
            } else {
              message.error('You cannot remove all assets. At least one asset must be left.');
            }
          };

          return (
            <DepositAssetsTableAction
              id={id}
              {...(isSuggestedChanges
                ? {
                    isSuggestedChanges: true,
                    onRemoveAsset: handleRemoveAsset,
                    onUndoRemovedAsset: props.onUndoRemovedAsset,
                    isRequestedToRemove: props.deletedIdAssets.includes(id),
                  }
                : {
                    isSuggestedChanges: false,
                    type,
                    onDestroyAsset: status !== 'configuring' ? props.onDestroyAsset : undefined,
                  })}
            />
          );
        },
      },
    ],
    [isSuggestedChanges, props.deletedIdAssets, props.onRemoveAsset, props.onUndoRemovedAsset],
  );

  return (
    <div className="ck-deposit-assets__table">
      <Table
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        onChange={(e) => pagination.onChange(e.current)}
        columns={columns}
        dataSource={assetsData}
        pagination={{
          current: pagination.current,
          pageSize: ASSETS_LIMIT,
          total: pagination.total,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        loading={isLoading}
      />
    </div>
  );
};

export default DepositAssetsTable;
