import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { IContactDetail } from '..';
import styles from '../EscrowContacts.module.scss';

import ContactDetailBlock from './ContactDetailBlock';

type Props = {
  type: 'beneficiary' | 'depositor';
  contacts?: IContactDetail[];
  className?: string;
};

const ContactListDetailBlock: React.FunctionComponent<Props> = ({ type, contacts = [], className }) => {
  if (isEmpty(contacts)) return null;

  return (
    <div className={classNames(className)}>
      {contacts.map((contact) => {
        return <ContactDetailBlock className={styles['contact-item']} key={contact.id} type={type} {...contact} />;
      })}
    </div>
  );
};

export default ContactListDetailBlock;
