import { message } from 'src/components/Misc';
import { UserFeedbackForm, IUserFeedbackForm } from 'src/components/Profile/Forms';
import { ProfileCard } from 'src/components/Profile/Card';
import { useCreateFeedbackMutation } from 'src/graphql/schema';

const UserFeedbackContainer = () => {
  const [createFeedback, { loading }] = useCreateFeedbackMutation();

  const onSubmit = async ({ feedback }: IUserFeedbackForm) => {
    try {
      const { data } = await createFeedback({
        variables: {
          message: feedback,
        },
      });

      if (data?.createFeedback?.success) {
        message.success('Feedback sent!');
      } else {
        throw new Error(data?.createFeedback?.errors?.[0]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    }
  };

  return (
    <ProfileCard title="Send us feedback" text="We always love to know what’s on your mind!" className="container--535">
      <UserFeedbackForm onSubmit={onSubmit} isLoading={loading} />
    </ProfileCard>
  );
};

export default UserFeedbackContainer;
