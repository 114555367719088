import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconRelabel = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path fill="currentColor" d="M20 22h1.882v1.882H20z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M30.85 3.05h-13.7v3.413l-2.665-2.665-9.687 9.687 2.665 2.665H4.15v13.7h15.84l4.287-4.052q.17-.161.316-.337l.025.023 6.232-6.595zm-4.976 18.63 3.276-3.467V8.7h-9.763l6.298 6.298zM18.85 7h10.3V4.75h-10.3zm5.155 8.722.123 4.339-4.139-3.911h-9.25l6.847-6.847zM16.385 8.1 9.1 15.384l-1.899-1.899 7.283-7.283zM10.7 28.15h8.613l3.796-3.587a2.15 2.15 0 0 0 0-3.126l-3.796-3.587H10.7zm-1.7 0H5.85v-10.3H9z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconRelabel);
export default Memo;
