import { message } from 'src/components/Misc';
import { CancelPlanForm } from 'src/components/Billing/Cancel/Plan';
import { useCancelationPlan } from 'src/context/billing';
import { useCreateFeedbackMutation } from 'src/graphql/schema';

import type { ICancelPlanForm } from 'src/components/Billing/Cancel/Plan';

const CancelationFeedbackContainer = () => {
  const {
    ui: { setStep, setInitialData },
    activateFeedbackSubmitted,
    exclusiveOfferApplied,
  } = useCancelationPlan();
  const [createFeedback, { loading }] = useCreateFeedbackMutation();

  const onSubmitFeedback = async ({ reasons, other }: ICancelPlanForm) => {
    let messageText = reasons.map((reason) => `- ${reason};\n`).join('');

    if (other) messageText += `- Comments: ${other}`;

    try {
      await createFeedback({
        variables: {
          message: messageText,
        },
      });
      setStep('review-changes');
      if (!exclusiveOfferApplied) activateFeedbackSubmitted();
    } catch {
      message.error('Something went wrong');
    }
  };

  return <CancelPlanForm onSubmit={onSubmitFeedback} onCancel={setInitialData} isLoading={loading} />;
};

export default CancelationFeedbackContainer;
