import { Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { TextBlock } from 'src/components/Misc';

import styles from './DepositAssetsHeader.module.scss';

type Props = {
  hideAddBtn?: boolean;
  showRequestChangeHelper?: boolean;
  toggleModal?: () => void;
  isEmpty?: boolean;
  isRequired?: boolean;
};

const DepositAssetsHeader: React.FunctionComponent<Props> = ({
  hideAddBtn = false,
  toggleModal,
  showRequestChangeHelper = false,
  isEmpty = false,
  isRequired = false,
}) => {
  return (
    <div className={styles.block}>
      <div className={styles.row}>
        <TextBlock
          title="Deposit Assets"
          text={!isEmpty ? 'These are the assets currently deposited for this escrow.' : ''}
          segment={isRequired && <i className={styles.segment}>Required</i>}
        />
        {showRequestChangeHelper && (
          <p className={styles['secondary-text']}>
            <i className={styles['icon-exclamation']}>
              <ExclamationCircleFilled />
            </i>
            You can request to remove a deposited asset using the Request Changes feature.
          </p>
        )}
      </div>
      {hideAddBtn && !isEmpty && (
        <Button type="default" className={styles['btn-add']} onClick={toggleModal}>
          + Add New Assets
        </Button>
      )}
    </div>
  );
};

export default DepositAssetsHeader;
