import { forwardRef, useImperativeHandle, useRef, ElementRef, useId } from 'react';
import { Form, Select, Row, Col } from 'antd';

import { optionsRole } from './escrow-contacts.enum';
import styles from './EscrowContactsForm.module.scss';

export interface IAddExistingContactForm {
  id: string;
  role: string;
}

type Props = {
  onSubmit: (data: IAddExistingContactForm) => void;
  options?: { label: string; value: string }[];
};

type Ref = { onSubmit: (() => void) | null };

const AddExistingContactForm = forwardRef<Ref, Props>(({ onSubmit, options }, ref) => {
  const formRef = useRef<ElementRef<typeof Form<IAddExistingContactForm>>>(null);
  const formId = useId();

  useImperativeHandle(ref, () => ({ onSubmit: formRef.current && formRef.current.submit }), []);

  return (
    <Form layout="vertical" className="ck-escrow-contacts__form" onFinish={onSubmit} ref={formRef}>
      <Row className={styles['row-md']}>
        <Col span={12} className={styles['col-md']}>
          <Form.Item name="id" rules={[{ required: true }]} htmlFor={`id-${formId}`}>
            <Select placeholder="Select contact" options={options} id={`id-${formId}`} />
          </Form.Item>
        </Col>

        <Col span={12} className={styles['col-md']}>
          <Form.Item name="role" rules={[{ required: true }]} htmlFor={`role-${formId}`}>
            <Select placeholder="Select role" options={optionsRole} id={`role-${formId}`} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

AddExistingContactForm.displayName = 'AddExistingContactForm';

export default AddExistingContactForm;
