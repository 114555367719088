import { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import { message } from 'src/components/Misc';
import {
  useAcceptEscrowInvitationMutation,
  useRejectEscrowInvitationMutation,
  EscrowInvitationsDocument,
  IEscrowInvitation,
  IAcceptEscrowInvitationMutation,
  IRejectEscrowInvitationMutation,
} from 'src/graphql/schema/index.ts';

import RejectModal from './RejectModal';

export default function Invitation({
  item,
  passSuccessInvitation,
}: {
  item: IEscrowInvitation;
  passSuccessInvitation: (item: IEscrowInvitation) => void;
}) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const [acceptEscrowInvitation] = useAcceptEscrowInvitationMutation();
  const [rejectEscrowInvitation] = useRejectEscrowInvitationMutation({
    refetchQueries: [EscrowInvitationsDocument],
  });

  const toggleModal = () => {
    setVisible(!visible);
  };

  const handleError = (error: unknown) => {
    console.error(error);
    message.error('Something went wrong');
  };

  const handleSuccess = (
    data:
      | IAcceptEscrowInvitationMutation['acceptEscrowInvitation']
      | IRejectEscrowInvitationMutation['rejectEscrowInvitation'],
    successMessage: string,
    successCallback: (data: IEscrowInvitation) => void,
  ) => {
    if (data?.success) {
      message.success(successMessage);
      successCallback(data?.escrowInvitation as IEscrowInvitation);
    } else {
      message.error(data?.errors?.[0]);
    }
  };

  const acceptInvitation = async () => {
    try {
      const { data } = await acceptEscrowInvitation({
        variables: {
          escrowInvitationId: item.id,
        },
      });
      handleSuccess(data?.acceptEscrowInvitation, 'Invitation accepted!', passSuccessInvitation);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const rejectInvitation = async () => {
    try {
      const { data } = await rejectEscrowInvitation({
        variables: {
          escrowInvitationId: item.id,
        },
      });
      handleSuccess(data?.rejectEscrowInvitation, 'Invitation rejected!', toggleModal);
    } catch (error) {
      handleError(error);
    }
  };

  const viewEscrow = () => {
    navigate('/');
  };

  return (
    <div className="invitations-screen__item">
      <div>
        <p className="invitations-screen__item-title mb-0">{item.companyName}</p>
      </div>
      {item.status === 'pending' && (
        <div className="d-flex">
          <Button className="btn--gray mr-2" onClick={toggleModal}>
            Reject
          </Button>
          <Button type="primary" onClick={acceptInvitation}>
            Accept
          </Button>
        </div>
      )}

      {item.status === 'accepted' && (
        <div className="d-flex">
          <Button type="link" onClick={viewEscrow}>
            <span className="text-underline">View escrow</span> <RightOutlined />
          </Button>
        </div>
      )}

      <RejectModal visible={visible} showModal={toggleModal} rejectInvitation={rejectInvitation} />
    </div>
  );
}
