import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconAgreementOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M31.301 8.186 25.115 2H8.757v3.82H5V34h22.544v-3.82h3.757zm-5.636-2.979L28.157 7.7h-2.492zm0 26.914H6.88V7.7h1.878V30.18h16.908zm-15.029-3.82V3.88h13.15v5.698h5.637v18.724z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.043 12.15h1.913c.167 0 .336 0 .48.011.16.012.36.042.567.14.28.135.518.354.67.634.116.211.15.419.165.58.012.143.012.308.012.462v.673h2.107c.166 0 .335 0 .48.011.159.012.36.042.566.14.282.135.519.355.67.634.116.211.15.419.165.58.012.143.012.308.012.462v7.373h-13.7v-5.5c0-.153 0-.317.012-.46.014-.16.049-.368.164-.58.153-.281.391-.5.672-.634.206-.098.406-.128.566-.14.144-.011.313-.011.479-.011h2.107v-2.55c0-.153 0-.317.012-.46.014-.16.049-.368.164-.58.153-.281.391-.5.672-.634.206-.098.406-.128.566-.14.144-.011.313-.011.479-.011m-1.893 6.075h-2.3v3.925h2.3zm1.7 3.925h2.3v-8.3h-2.3v8.3m4 0h2.3v-5.8h-2.3z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconAgreementOperation);
export default Memo;
