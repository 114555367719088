import { Layout } from 'src/components/Layout';

import HeaderContainer from './Header/HeaderContainer';
import SidebarContainer from './Sidebar/SidebarContainer';

const LayoutContainer: React.FunctionComponent = () => {
  return <Layout sidebar={SidebarContainer} header={HeaderContainer} />;
};

export default LayoutContainer;
