import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Select, Input, InputNumber, Button, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { TextBlock } from 'src/components/Misc';

import { roleOptions, protectionPeriodOptions } from '../saas-providers.enum';

import styles from './SaasProviderForms.module.scss';

export type ISaasEditProviderForm = {
  role: string;
  description: string;
  email: string;
  cost: number;
  continuity: string;
};

type Props = {
  onSubmit: (values: ISaasEditProviderForm) => void;
  onCancel: () => void;
  isLoading?: boolean;
  initialValues?: ISaasEditProviderForm;
};

type Ref = { onResetFields: () => void };

const SaasEditProviderForm = forwardRef<Ref, Props>(({ onSubmit, onCancel, isLoading = false, initialValues }, ref) => {
  const [disabled, setDisabled] = useState(true);

  const [form] = Form.useForm<ISaasEditProviderForm>();

  useImperativeHandle(
    ref,
    () => ({
      onResetFields: () => {
        form.resetFields();
        setDisabled(true);
      },
    }),
    [],
  );

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  const onFieldsChange = ([key]: [string], value: string) => {
    const currentValue = typeof value === 'string' ? value.trim() : value;

    if (
      initialValues &&
      Boolean(currentValue) &&
      key in initialValues &&
      initialValues[key as keyof ISaasEditProviderForm] !== value
    ) {
      return setDisabled(false);
    }

    return setDisabled(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      requiredMark={false}
      onFieldsChange={([field]) => onFieldsChange(field.name, field.value)}
    >
      <Form.Item name="role" label="Provider Role" rules={[{ required: true }]}>
        <Select placeholder="Select provider role" options={roleOptions} />
      </Form.Item>
      <Form.Item
        name="description"
        label={
          <Fragment>
            Environment Role Description
            <Tooltip
              placement="right"
              overlayClassName="ck-tooltip--light"
              title="Please add more details about this service, such as its role in your environment and the current pricing plan you're subscribed to."
            >
              <span className={styles['tooltip-btn']}>
                <QuestionCircleFilled />
              </span>
            </Tooltip>
          </Fragment>
        }
        rules={[{ required: true }]}
      >
        <Input.TextArea placeholder="Placeholder text" rows={6} />
      </Form.Item>
      <Form.Item
        name="email"
        label={
          <Fragment>
            Provider Contact Email
            <Tooltip
              overlayClassName="ck-tooltip--light"
              placement="right"
              title="The contact details of the representative your team gets in touch with."
            >
              <span className={styles['tooltip-btn']}>
                <QuestionCircleFilled />
              </span>
            </Tooltip>
          </Fragment>
        }
        rules={[{ required: true }]}
      >
        <Input placeholder="example@email.com" type="email" />
      </Form.Item>

      <div className={styles.label}>
        Approx. Monthly Costs <span>(Average over the last 12 months)</span>
      </div>
      <Form.Item className="ck-cost__input" name="cost" rules={[{ required: true }]}>
        <InputNumber addonBefore="USD" type="number" controls={false} size="middle" />
      </Form.Item>

      <TextBlock
        title="Continuity Protection Period"
        as="h4"
        titleColor="dark-blue"
        text="In the event of a disruption, how long would you like us to keep this Provider's account active on your behalf?"
        className="mb-3"
      />
      <Form.Item name="continuity" className="mb-5" rules={[{ required: true }]}>
        <Select placeholder="Select protection period" options={protectionPeriodOptions} />
      </Form.Item>
      <div className="d-flex justify-content-end">
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ml-2" type="primary" htmlType="submit" loading={isLoading} disabled={disabled}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
});

SaasEditProviderForm.displayName = 'SaasEditProviderForm';

export default SaasEditProviderForm;
