import { cloneDeep } from '@apollo/client/utilities';
import { gql } from '@apollo/client';
import { PartialDeep } from 'type-fest';

import { CustodianProfileDocument, CustodianProfilesDocument } from 'src/graphql/schema';

import { apolloClient as client, apolloCache as cache } from '../apollo-client';

import type {
  ICustodianProfileQuery,
  ICustodianProfilesQuery,
  ICustodianOperation,
  ICustodianProfile,
} from 'src/graphql/schema';

const CustodianOperationFragment = gql`
  fragment CustodianOperationFragment on CustodianProfile {
    custodianOperations {
      id
      custodianServiceEvents
      custodianServiceEventsCustom
      dataRetention
      endOfRetentionPeriod
      endOfRetentionPeriodCustom
      operationType
      operationTypeCustom
      regulatoryRequirements
      regulatoryRequirementsCustom
    }
  }
`;

export const removeCustodianOperationCache = (profileId: string, operationId: string) => {
  const cacheData = cloneDeep<ICustodianProfileQuery | null>(
    client.readQuery({
      query: CustodianProfileDocument,
      variables: {
        id: profileId,
      },
    }),
  );

  if (!cacheData) return;

  const custodianOperations =
    cacheData.custodianProfile?.custodianOperations.filter(({ id }) => id !== operationId) ?? [];

  cache.writeQuery({
    query: CustodianProfileDocument,
    data: {
      custodianProfile: {
        ...cacheData.custodianProfile,
        custodianOperations,
        policy: {
          ...(cacheData?.custodianProfile?.policy ?? {}),
          submitForReview: Boolean(custodianOperations.length),
        },
      },
    },
  });
};

export const updateCustodianOperationCache = (operationId: string, data?: PartialDeep<ICustodianOperation>) => {
  if (!data) return;

  client.writeFragment({
    id: `CustodianOperation:${operationId}`,
    fragment: CustodianOperationFragment,
    data,
  });
};

export const addCustodianOperationCache = (profileId: string, data: ICustodianOperation) => {
  const cacheData = cloneDeep<ICustodianProfileQuery | null>(
    client.readQuery({
      query: CustodianProfileDocument,
      variables: {
        id: profileId,
      },
    }),
  );

  if (!cacheData) return;

  const custodianOperations = [...(cacheData.custodianProfile?.custodianOperations ?? []), data];

  client.writeQuery({
    query: CustodianProfileDocument,
    data: {
      custodianProfile: {
        ...cacheData.custodianProfile,
        custodianOperations,
        policy: {
          ...(cacheData?.custodianProfile?.policy ?? {}),
          submitForReview: true,
        },
      },
    },
  });
};

export const addCustodianProfileCache = (data: ICustodianProfile) => {
  const cacheData = cloneDeep<ICustodianProfilesQuery | null>(
    client.readQuery({
      query: CustodianProfilesDocument,
    }),
  );

  if (!cacheData) return;

  const nodes = [...cacheData.custodianProfiles.nodes, data];

  client.writeQuery({
    query: CustodianProfilesDocument,
    data: {
      custodianProfiles: {
        ...cacheData.custodianProfiles,
        nodes,
        nodesCount: cacheData.custodianProfiles.nodesCount + 1,
      },
    },
  });
};

export const removeCustodianProfileCache = (profileId: string) => {
  const cacheData = cloneDeep<ICustodianProfilesQuery | null>(
    client.readQuery({
      query: CustodianProfilesDocument,
      variables: {
        id: profileId,
      },
    }),
  );

  const profile = cacheData?.custodianProfiles.nodes.find(({ id }) => id === profileId);

  if (!profile || !cacheData) return;

  client.writeQuery({
    query: CustodianProfilesDocument,
    data: {
      custodianProfiles: {
        ...cacheData.custodianProfiles,
        nodesCount: cacheData.custodianProfiles.nodesCount - 1,
      },
    },
  });

  cache.modify({
    id: cache.identify(profile),
    fields(_fieldValue, { DELETE }) {
      return DELETE;
    },
  });
};
