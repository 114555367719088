import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Skeleton } from 'antd';

import { useAuth } from 'src/context/auth/use-auth';
import styles from 'src/components/Profile/Profile.module.scss';

import EnableTOTPContainer from './EnableTOTPContainer';
import DisableTOTPContainer from './DisableTOTPContainer';

const ProfileTOTPContainer = () => {
  const [isTOTPEnabled, setIsTOTPEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { shouldShowTOTPType } = useAuth();

  useEffect(() => {
    const fetchTOTPType = async () => {
      try {
        const isEnabled = await shouldShowTOTPType();

        setIsTOTPEnabled(isEnabled);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTOTPType();
  }, []);

  const statusClasses = classNames(styles['two-fa__status'], {
    [styles.enabled]: isTOTPEnabled,
    [styles.disabled]: !isTOTPEnabled,
  });

  if (isLoading) return <Skeleton active paragraph={{ rows: 1 }} />;

  return (
    <div className={styles['two-fa']}>
      <h6 className={styles['two-fa__heading']}>Multi-factor authentication</h6>

      <div className={styles['two-fa__row']}>
        <div className={statusClasses}>{isTOTPEnabled ? 'Enabled' : 'Disabled'}</div>

        {isTOTPEnabled ? (
          <DisableTOTPContainer setTOTPEnabled={setIsTOTPEnabled} />
        ) : (
          <EnableTOTPContainer setTOTPEnabled={setIsTOTPEnabled} />
        )}
      </div>
    </div>
  );
};

export default ProfileTOTPContainer;
