import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'src/components/Misc';
import { SetPasswordForm, ISetPasswordForm } from 'src/components/Authentication';
import { useAuth } from 'src/context/auth/use-auth';

const SetPasswordContainer: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { setNewPassword } = useAuth();
  const navigate = useNavigate();

  const onFinish = async ({ password }: ISetPasswordForm) => {
    setIsLoading(true);

    try {
      await setNewPassword(password);

      navigate('/');
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="invite-login-screen">
      <h2>Set your password</h2>

      <SetPasswordForm onFinish={onFinish} isLoading={isLoading} />
    </div>
  );
};

export default SetPasswordContainer;
