import { Button, Table, Tooltip } from 'antd';
import { CheckOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';

import { IAssetsQuery, AssetsOrderByEnum, AssetTypeEnum, IEscrow } from 'src/graphql/schema/index';
import { DepositAssetsActions } from 'src/components/Deposit';
import { formatBytes } from 'src/utils/formatBytes';
import { DEPOSITS_PER_PAGE, PROVIDER_KEY_TO_NAME } from 'src/shared/constants';

export type IAssetNodes = NonNullable<IAssetsQuery['assets']>['nodes'];

type Props = {
  assets: IAssetNodes;
  currentPage: number;
  getCurrentPage: (value: number) => void;
  nodesCount: number;
  order: string;
  filter: AssetsOrderByEnum | null;
  loading: boolean;
  onOrderChange: () => Promise<void>;
  onFilterChange: (value: AssetsOrderByEnum | null) => Promise<void>;
  onSearchChange: IDebouncedFunc<(value: string) => Promise<void>>;
};

const ActiveDeposits: React.FunctionComponent<Props> = ({
  assets,
  getCurrentPage,
  nodesCount,
  order,
  filter,
  currentPage,
  loading,
  onOrderChange,
  onFilterChange,
  onSearchChange,
}) => {
  const navigate = useNavigate();

  const renderClassName = (text: string) => {
    if (text === 'Disconnected' || text === 'awaiting deposit') return '-unpaid';
    if (text === 'draft') return '-draft';
    if (text === 'admin_review') return '-review';
    if (text === 'awaiting_sync' || text === 'no configuration') return '-no-config';
    if (text === 'Grace Period' || text === 'Pending') return '-grace';
    return '';
  };

  const columns = [
    {
      title: 'Escrow',
      dataIndex: 'escrow',
      render: (text: IEscrow) => (
        <span>
          {text.depositor?.companyName}
          {text.beneficiary?.companyName && text.depositor?.companyName ? ' - ' : ''} {text.beneficiary?.companyName}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      render: (text: string) => (
        <span>
          {text ? PROVIDER_KEY_TO_NAME[text as keyof typeof PROVIDER_KEY_TO_NAME] || capitalize(text) : 'Secure Upload'}
        </span>
      ),
    },
    {
      title: 'Deposit Source',
      dataIndex: 'source',
    },
    {
      title: 'Last Deposit',
      dataIndex: 'latestDeposit',
      render: (text: string) => <span>{text ? dayjs(text).format('MMM DD, YYYY, HH:mm') : 'No deposit yet'}</span>,
    },
    {
      title: 'Last Update Check',
      dataIndex: 'lastUpdateCheck',
      render: ({ lastUpdateCheck, type }: { lastUpdateCheck: string | undefined; type: string }) => {
        if (type === 'secure_upload') return <span>-</span>;

        if (lastUpdateCheck) {
          return <span>{dayjs(lastUpdateCheck).format('MMM DD, YYYY, HH:mm')}</span>;
        } else return <span>No deposit yet</span>;
      },
    },
    {
      title: 'Deposit Status',
      dataIndex: 'status',
      render: (text: string) =>
        text && <span className={`${renderClassName(text)} active-homepage__content-status`}>{text} </span>,
    },
    {
      title: 'Sync Status',
      dataIndex: 'sync',
      render: (
        {
          status,
          error,
        }: {
          status: string;
          error?: string;
        },
        other: Record<string, unknown>,
      ) => {
        if ((other?.lastUpdateCheck as { type: string })?.type === 'secure_upload') return <span>-</span>;

        return (
          <div className="d-flex align-items-center">
            <span className={`${renderClassName(status)} active-homepage__content-status`}>
              {status.replace('_', ' ')}{' '}
            </span>

            {error && (
              <Tooltip title={error}>
                <span className="active-homepage__tooltip-btn">
                  <ExclamationCircleTwoTone twoToneColor="#EE5E5E" />
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: string) => (
        <Button type="text" className="table-link" onClick={() => navigate(`/escrows/${text}/basics`)}>
          View escrow
        </Button>
      ),
    },
  ];

  const data = assets.map((item) => {
    let type = '';

    if (item.type === AssetTypeEnum.CustomRepo) {
      type = 'Custom';
    } else if (item.type === AssetTypeEnum.OauthRepo) {
      type = 'OAuth';
    } else {
      type = 'Secure Upload';
    }

    return {
      key: item.id,
      escrow: item.escrow,
      type,
      provider: item.integration?.provider || '',
      source: item.depositSource,
      latestDeposit: item.latestDeposit,
      lastUpdateCheck: { lastUpdateCheck: item.lastUpdateCheck, type: item.type },
      status: item.depositStatus.replace(/_/g, ' '),
      sync: {
        status: item.syncStatus,
        error: item.error,
      },
      size: item.size ? formatBytes(item.size) : '-',
      action: item.escrow.id,
    };
  });

  const depositsOrderByItems = Object.values(AssetsOrderByEnum).map((value: AssetsOrderByEnum, key: number) => {
    return {
      label: (
        <div className="text-capitalize" onClick={() => onFilterChange(value)} key={key}>
          {value.replace(/_/g, ' ')} {filter === value && <CheckOutlined />}
        </div>
      ),
      key: value,
    };
  });

  // Add label header
  depositsOrderByItems.unshift({
    label: <div className="filter-title mb-0 p-0">Order by:</div>,
    key: 'filter' as AssetsOrderByEnum,
  });

  return (
    <div className="active-deposits">
      <DepositAssetsActions
        onFilterChange={onFilterChange}
        onSearchChange={onSearchChange}
        onOrderChange={onOrderChange}
        filter={filter}
        order={order}
      />

      <Table
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        className="active-deposits__table"
        columns={columns}
        dataSource={data}
        onChange={(e) => {
          getCurrentPage(e.current as number);
        }}
        pagination={{
          pageSize: DEPOSITS_PER_PAGE,
          total: nodesCount,
          hideOnSinglePage: true,
          current: currentPage,
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
};

export default ActiveDeposits;
