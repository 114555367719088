import { Typography, Button } from 'antd';

import { ImageEmptyBox } from 'src/components/Svg/Images';

const { Title } = Typography;

type Props = {
  onCreate: () => void;
};

const HomeEmptyContent: React.FunctionComponent<Props> = ({ onCreate }) => {
  return (
    <div className="empty-home">
      <div className="empty-home__container">
        <Title className="empty-home__title">Welcome to Codekeeper!</Title>

        <ImageEmptyBox className="empty-home__image" />

        <Title className="empty-home__subtitle" level={2}>
          Get Started With Codekeeper
        </Title>

        <p className="empty-home__text">Your account is ready for use. Configure your agreement in just a few steps.</p>

        <Button className="ant-btn-default--primary" onClick={onCreate}>
          Create new escrow
        </Button>
      </div>
    </div>
  );
};

export default HomeEmptyContent;
