import { Fragment } from 'react';

import { InfoPreviewByRole, IContactDetail, IOrganizationDetail } from 'src/components/Escrow/Contacts';
import { EscrowPartyTypeEnum, ISignature } from 'src/graphql/schema';

export interface IPartyPreview {
  isOwnOrganization?: boolean;
  organization: IOrganizationDetail;
  signatory?: ISignature;
  contacts?: IContactDetail[];
}

type Props = {
  parties: { [key in EscrowPartyTypeEnum]: IPartyPreview };
};

const EscrowPartyPreviewContainer: React.FunctionComponent<Props> = ({ parties }) => {
  return (
    <Fragment>
      {Object.entries(parties).map(([role, { organization, signatory, contacts, isOwnOrganization }]) => (
        <InfoPreviewByRole
          key={role}
          role={role as EscrowPartyTypeEnum}
          isOwnOrganization={isOwnOrganization}
          organization={organization}
          signatory={signatory}
          contacts={contacts}
        />
      ))}
    </Fragment>
  );
};

export default EscrowPartyPreviewContainer;
