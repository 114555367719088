import { ComponentProps, ComponentType, lazy, Suspense, useMemo } from 'react';

import { Loader } from 'src/components/Loader';

type Props<C extends ComponentType<unknown>> = {
  loader: () => Promise<{
    default: C;
  }>;
} & ComponentProps<C>;

const LazyLoad = <C extends ComponentType<TODO>>({ loader, ...props }: Props<C>) => {
  const LazyComponent = useMemo(() => lazy(() => loader().catch(() => window.location.reload())), []);

  return (
    <Suspense fallback={<Loader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default LazyLoad;
