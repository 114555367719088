import { Button } from 'antd';
import { CloudTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

import { SaasProviderStatusEnum, SaasEnvironmentStatusEnum } from 'src/graphql/schema';

import ProviderStatusLabel from '../StatusLabel/ProviderStatusLabel';
import { providerListDict } from '../List/providerList.enum';
import { protectionPeriodDict, roleDict, providerTextHelper } from '../saas-providers.enum';

import styles from './ProviderView.module.scss';

export type IProviderView = {
  id: string;
  role: string;
  name: string;
  status: SaasProviderStatusEnum;
  description: string;
  email: string;
  cost: number;
  continuityPeriod: string;
  completedAt: Date;
};

type Props = { data: IProviderView; environmentStatus: SaasEnvironmentStatusEnum; onEdit: (id: string) => void };

const ProviderView = ({ environmentStatus, data, onEdit }: Props) => {
  const Logo = providerListDict[data.name]?.icon;
  const { id, name, status, description, role, email, cost, continuityPeriod, completedAt } = data;
  const detailItems = [
    {
      label: 'Provider Role',
      text: roleDict[role as keyof typeof roleDict] || '',
    },
    {
      label: 'Environment Role Description',
      text: description,
    },
    {
      label: 'Provider contact email',
      text: email,
    },
    {
      label: 'Approx. Monthly Costs (Average over the last 12 months):',
      text: cost.toLocaleString() + ' USD',
    },
  ];

  const completedDate = dayjs(completedAt).format('DD/MM/YYYY');
  const helperText =
    data.status === SaasProviderStatusEnum.SetupCompleted
      ? providerTextHelper[SaasProviderStatusEnum.SetupCompleted].replace(/{date}/, completedDate)
      : providerTextHelper[data.status];

  return (
    <div>
      <div className={styles.header}>
        <div className={styles['logo-wrapper']}>
          {Logo ? (
            <Logo className={styles.logo} />
          ) : (
            <CloudTwoTone className={styles['icon-placeholder']} twoToneColor="#00a0b6" />
          )}
        </div>
        <div className={styles['header-info']}>
          <h2 className={styles.title}>{name}</h2>
          <ProviderStatusLabel status={status} />
        </div>
      </div>

      <div className={styles['description-row']}>
        <p className={styles.description}>{helperText}</p>
      </div>
      <h3 className={styles.heading}>Platform details</h3>

      <ul className={`ck-custom-list ${styles['item-info__list']}`}>
        {detailItems.map(({ label, text }, index) => (
          <li key={index} className={styles['item-info__row']}>
            <span className={styles['item-info__label']}>{label}</span>
            <p className={styles['item-info__text']}>{text}</p>
          </li>
        ))}
      </ul>

      <div className={styles.continuity}>
        <h3 className={styles.heading}>Continuity Protection Period</h3>
        <strong>{protectionPeriodDict[continuityPeriod as keyof typeof protectionPeriodDict] || ''}</strong>
      </div>

      {environmentStatus !== SaasEnvironmentStatusEnum.Pending && (
        <div className="d-flex justify-content-end">
          <Button ghost type="primary" onClick={() => onEdit(id)}>
            Edit details
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProviderView;
