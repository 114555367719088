import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoAzure = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 256 256"
    role="img"
    className={props.className}
    {...props}
  >
    <defs>
      <linearGradient
        id="logo-azure_svg__a"
        x1={-960.606}
        x2={-1032.511}
        y1={283.397}
        y2={70.972}
        gradientTransform="matrix(1 0 0 -1 1075 318)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#114a8b" />
        <stop offset={1} stopColor="#0669bc" />
      </linearGradient>
      <linearGradient
        id="logo-azure_svg__b"
        x1={-938.144}
        x2={-954.778}
        y1={184.402}
        y2={178.778}
        gradientTransform="matrix(1 0 0 -1 1075 318)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0.3} />
        <stop offset={0.071} stopOpacity={0.2} />
        <stop offset={0.321} stopOpacity={0.1} />
        <stop offset={0.623} stopOpacity={0.05} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="logo-azure_svg__c"
        x1={-947.292}
        x2={-868.363}
        y1={289.594}
        y2={79.308}
        gradientTransform="matrix(1 0 0 -1 1075 318)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3ccbf4" />
        <stop offset={1} stopColor="#2892df" />
      </linearGradient>
    </defs>
    <path
      fill="url(#logo-azure_svg__a)"
      d="M89.158 18.266h69.238L86.523 231.224a11.04 11.04 0 0 1-10.461 7.51H22.179a11.023 11.023 0 0 1-10.445-14.548l66.963-198.41a11.04 11.04 0 0 1 10.461-7.51"
    />
    <path
      fill="#0078d4"
      d="M189.77 161.104H79.976a5.083 5.083 0 0 0-3.468 8.8l70.552 65.847a11.1 11.1 0 0 0 7.567 2.983h62.167z"
    />
    <path
      fill="url(#logo-azure_svg__b)"
      d="M89.158 18.266a10.95 10.95 0 0 0-10.483 7.654L11.817 224.006a11.01 11.01 0 0 0 10.393 14.728h55.274a11.81 11.81 0 0 0 9.069-7.714l13.33-39.29 47.625 44.418a11.27 11.27 0 0 0 7.089 2.586h61.937l-27.166-77.63-79.19.018 48.47-142.856z"
    />
    <path
      fill="url(#logo-azure_svg__c)"
      d="M177.592 25.764a11.02 11.02 0 0 0-10.444-7.498H89.984a11.02 11.02 0 0 1 10.445 7.498l66.967 198.421a11.024 11.024 0 0 1-10.445 14.549h77.164a11.024 11.024 0 0 0 10.444-14.549z"
    />
  </svg>
);
const Memo = memo(SvgLogoAzure);
export default Memo;
