import { App } from 'antd';

import type { MessageInstance } from 'antd/lib/message/interface';
import type { NotificationInstance } from 'antd/lib/notification/interface';

let message: MessageInstance;
let notification: NotificationInstance;

export default () => {
  const fn = App.useApp();
  message = fn.message;
  notification = fn.notification;

  return null;
};

export { message, notification };
