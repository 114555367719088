import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  SELECT_ASSET = 'select-asset',
  CHANGE_ASSET_STATUS = 'change-asset-status',
  REMOVE_ASSETS = 'remove-assets',
  SET_INITIAL_ASSETS = 'set-initial-assets',
  SET_EDIT_MODE = 'set-edit-mode',
  SET_INITIAL_DATA = 'set-initial-data',
}

type IActionReducer =
  | IAction<ActionEnum.SELECT_ASSET, { integrationId: string; assets: ISelectedAsset[]; isUpdateEnabled?: boolean }>
  | IAction<ActionEnum.CHANGE_ASSET_STATUS, { integrationId: string; assetId: string; status: string }>
  | IAction<ActionEnum.REMOVE_ASSETS>
  | IAction<ActionEnum.SET_INITIAL_ASSETS, ICustodianState['selectedAssets']>
  | IAction<ActionEnum.SET_EDIT_MODE, boolean>
  | IAction<ActionEnum.SET_INITIAL_DATA>;
type ISelectedAsset = {
  id: string;
  name: string;
  status: string;
};

export type ICustodianState = {
  selectedAssets: {
    [integrationId: string]: ISelectedAsset[];
  } | null;
  isEditMode: boolean;
  isUpdateEnabled: boolean;
};

const initialState: ICustodianState = {
  selectedAssets: null,
  isEditMode: false,
  isUpdateEnabled: false,
};

const reducer = (state: ICustodianState, action: IActionReducer): ICustodianState => {
  switch (action.type) {
    case ActionEnum.SELECT_ASSET: {
      return {
        ...state,
        selectedAssets: {
          ...state.selectedAssets,
          [action.payload.integrationId]: action.payload.assets,
        },
        isUpdateEnabled: action.payload.isUpdateEnabled || false,
      };
    }
    case ActionEnum.CHANGE_ASSET_STATUS: {
      const { integrationId, assetId, status } = action.payload;
      const selectedAssets = structuredClone(state.selectedAssets) || {};
      const assets = structuredClone(selectedAssets[integrationId]) || [];
      const assetIndex = assets.findIndex((asset) => asset.id === assetId);
      assets[assetIndex].status = status;

      return {
        ...state,
        selectedAssets: {
          ...state.selectedAssets,
          [integrationId]: assets,
        },
        isUpdateEnabled: true,
      };
    }
    case ActionEnum.REMOVE_ASSETS:
      return {
        ...state,
        selectedAssets: null,
      };
    case ActionEnum.SET_INITIAL_ASSETS:
      return {
        ...state,
        selectedAssets: action.payload,
      };
    case ActionEnum.SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload,
      };
    case ActionEnum.SET_INITIAL_DATA:
      return initialState;
    default:
      return state;
  }
};

export const useCustodianAssetsSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectAsset = (integrationId: string, assets: ISelectedAsset[], isUpdateEnabled?: boolean) =>
    dispatch({
      type: ActionEnum.SELECT_ASSET,
      payload: { integrationId, assets, isUpdateEnabled },
    });

  const changeAssetStatus = (integrationId: string, assetId: string, status: string) =>
    dispatch({
      type: ActionEnum.CHANGE_ASSET_STATUS,
      payload: { integrationId, assetId, status },
    });

  const removeAssets = () => dispatch({ type: ActionEnum.REMOVE_ASSETS, payload: {} });

  const setInitialAssets = (assets: ICustodianState['selectedAssets']) =>
    dispatch({
      type: ActionEnum.SET_INITIAL_ASSETS,
      payload: assets,
    });

  const setEditMode = (isEditMode: boolean) =>
    dispatch({
      type: ActionEnum.SET_EDIT_MODE,
      payload: isEditMode,
    });

  const setInitialData = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });

  return {
    ...state,
    selectAsset,
    changeAssetStatus,
    removeAssets,
    setInitialAssets,
    setEditMode,
    setInitialData,
  };
};
