import { useSuspenseQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { GeneratedAgreementContent, GeneratedAgreementSidebar } from 'src/components/Escrow/Agreement';
import {
  AgreementFormEnum,
  EscrowPartyTypeEnum,
  EscrowAgreementTermsDocument,
  IEscrowAgreementTermsQuery,
} from 'src/graphql/schema';
import { TextBlock } from 'src/components/Misc';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { useEscrow } from 'src/context/escrow';
import styles from 'src/components/Escrow/Agreement/Generated/GeneratedAgreement.module.scss';
import { withEscrowGuard, withSuspense } from 'src/hoc';

const EscrowModalMain = JourneyModalLayout.Main;

const getTimezoneShort = (timeZone: string) => {
  return (
    new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      timeZoneName: 'short',
    })
      .formatToParts(new Date())
      .find((part) => part.type == 'timeZoneName') as { value: string }
  ).value;
};

const EscrowGeneratedAgreementContainer = () => {
  const { escrow } = useEscrow();
  const { data: dataAgreement } = useSuspenseQuery<IEscrowAgreementTermsQuery>(EscrowAgreementTermsDocument, {
    variables: { escrowId: escrow.id },
    skip: !escrow.id,
  });

  const tz = getTimezoneShort(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const timestamp = dayjs().format(`DD MMM YYYY HH:mm [${tz}]`);
  const fileName =
    [escrow.depositor?.companyName, escrow.beneficiary?.companyName].filter(Boolean).join(' - ') +
    ` agreement - ${timestamp}`;

  return (
    <EscrowModalMain className={styles.container}>
      <div className={styles['container-wrapper']}>
        <TextBlock
          className={styles['text-block']}
          title="Generated Escrow Agreement"
          text="The terms of this escrow agreement have been created based on the selected configurations. Please review the document."
        />

        <div className={styles.section}>
          <GeneratedAgreementContent
            fileName={fileName}
            terms={dataAgreement?.escrowTerms.agreement?.terms ?? ''}
            termsSignedDownloadPdfUrl={dataAgreement?.escrowTerms.agreement?.termsSignedDownloadPdfUrl ?? ''}
          />

          <GeneratedAgreementSidebar
            currentCompanyRole={escrow.currentCompanyRole as EscrowPartyTypeEnum}
            approvedBy={{
              beneficiary: Boolean(escrow.agreement?.approvedByBeneficiary),
              codekeeper: Boolean(escrow.agreement?.approvedByCodekeeper),
              depositor: Boolean(escrow.agreement?.approvedByDepositor),
            }}
            download={{
              docxUrl: dataAgreement?.escrowTerms.agreement?.termsDownloadDocxUrl ?? '',
              pdfUrl: dataAgreement?.escrowTerms.agreement?.termsDownloadPdfUrl ?? '',
              signedPdfUrl: dataAgreement?.escrowTerms.agreement?.termsSignedDownloadPdfUrl ?? '',
            }}
            updatedAt={escrow.agreement?.updatedAt}
            fileName={fileName}
            isTripartite={escrow.agreement?.agreementForm === AgreementFormEnum.Tripartite}
          />
        </div>
      </div>
    </EscrowModalMain>
  );
};

export default withEscrowGuard(withSuspense(EscrowGeneratedAgreementContainer));
