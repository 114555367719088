import { AcceptedOffer } from 'src/components/Billing/Cancel/Plan';
import { useCancelationPlan } from 'src/context/billing';
import { EXCLUSIVE_OFFER } from 'src/shared/constants';
import { apolloCache as cache } from 'src/graphql';
import { ExclusiveOfferPlansDocument } from 'src/graphql/schema';

import type { ICancelPlanItem } from 'src/components/Billing/Cancel/Plan';
import type { IExclusiveOfferPlansQuery } from 'src/graphql/schema';

const AcceptedOfferContainer = ({ onBack }: { onBack: () => void }) => {
  const { exclusiveOfferApplied } = useCancelationPlan();
  const data = cache.readQuery<IExclusiveOfferPlansQuery>({ query: ExclusiveOfferPlansDocument });

  const plans =
    (data?.exclusiveOfferPlans?.some(({ discounted }) => discounted) &&
      (data?.exclusiveOfferPlans
        ?.filter(({ status, discounted }) => status === 'active' && discounted)
        .map(({ id, name, periodUnit, currencyCode, price, discountedPrice, discounted }) => ({
          id,
          name,
          period: periodUnit,
          currency: currencyCode,
          price,
          discountedPrice,
          discounted,
          showActiveLabel: true,
        })) as ICancelPlanItem[])) ||
    [];

  const acceptedOfferData: {
    [key: string]: {
      heading: string;
      description: string;
      plans?: ICancelPlanItem[];
    };
  } = {
    exclusive: {
      heading: `All set! Here is your current plan at ${EXCLUSIVE_OFFER}% off`,
      description: `Thank you for choosing to continue your relationship with us!\nEnjoy your current plan at ${EXCLUSIVE_OFFER}% off.`,
      plans,
    },
    exclusiveNoPlan: {
      heading: `Get your current plan for ${EXCLUSIVE_OFFER}% off`,
      description: `Thank you for choosing to continue your relationship with us! Your\n discount request will be reviewed and applied based on the terms of\n your account. We'll be in touch!`,
    },
    other: {
      heading: `Get Software Backup for ${EXCLUSIVE_OFFER}% off`,
      description:
        'Thank you for accepting our discount offer and choosing to continue your relationship with us. We will get in touch with you on your change of plan.',
    },
  };

  let activeData = plans.length ? acceptedOfferData.exclusive : acceptedOfferData.exclusiveNoPlan;
  if (exclusiveOfferApplied) activeData = acceptedOfferData.other;

  return <AcceptedOffer {...activeData} onBack={onBack} />;
};

export default AcceptedOfferContainer;
