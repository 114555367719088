import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSummary = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 17a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2h-9a1 1 0 0 1-1-1m-1.36-2.768a1 1 0 0 1 .128 1.408l-2.5 3a1 1 0 0 1-1.323.192l-1.5-1a1 1 0 0 1 1.11-1.664l.752.502 1.925-2.31a1 1 0 0 1 1.408-.128M10 12a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2h-9a1 1 0 0 1-1-1M8.64 9.232a1 1 0 0 1 .128 1.408l-2.5 3a1 1 0 0 1-1.323.192l-1.5-1a1 1 0 0 1 1.11-1.664l.752.502 1.925-2.31a1 1 0 0 1 1.408-.128M10 7a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2h-9a1 1 0 0 1-1-1M8.64 4.232a1 1 0 0 1 .128 1.408l-2.5 3a1 1 0 0 1-1.323.192l-1.5-1a1 1 0 0 1 1.11-1.664l.752.502 1.925-2.31a1 1 0 0 1 1.408-.128"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSummary);
export default Memo;
