import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconDevelopment = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M20 27.57c2.03 0 3.68-1.65 3.68-3.68 0-2.03-1.65-3.68-3.68-3.68a3.685 3.685 0 0 0-3.68 3.68c0 2.03 1.65 3.68 3.68 3.68Zm0-5.017c.737 0 1.337.6 1.337 1.337s-.6 1.337-1.337 1.337-1.336-.6-1.336-1.337.6-1.337 1.336-1.337Z"
      />
      <path
        fill="currentColor"
        d="M40 0H0v35.383h14.824V40L20 37.36 25.176 40v-4.617H40V0Zm-2.344 9.393H28.29l5.197-7.05h4.17v7.05Zm-15.162 0 5.197-7.05h2.883l-5.197 7.05h-2.883Zm2.285-7.05-5.196 7.05H2.343v-7.05H24.78ZM14.041 23.89a5.92 5.92 0 0 1 1.745-4.214A5.92 5.92 0 0 1 20 17.931a5.92 5.92 0 0 1 4.214 1.745 5.92 5.92 0 0 1 1.745 4.214 5.92 5.92 0 0 1-1.745 4.214A5.92 5.92 0 0 1 20 29.849a5.92 5.92 0 0 1-4.214-1.745 5.92 5.92 0 0 1-1.745-4.214Zm-1.626 3.388H7.354v-7.59h5.481a8.253 8.253 0 0 0-1.138 4.202c0 1.187.248 2.336.718 3.388ZM20 34.729l-2.832 1.445v-4.475a8.308 8.308 0 0 0 2.832.494c.981 0 1.936-.17 2.832-.494v4.475L20 34.729Zm5.176-1.69v-2.656a8.421 8.421 0 0 0 .831-.762h8.726v-2.343h-7.148c.331-.74.552-1.528.653-2.344h6.495V22.59h-6.531a8.216 8.216 0 0 0-.737-2.344h7.268v-2.343h-8.981A8.246 8.246 0 0 0 20 15.587a8.24 8.24 0 0 0-5.11 1.758H5.01v12.277h8.983a8.527 8.527 0 0 0 .831.76v2.657H2.344V11.737h35.312v21.302h-12.48Z"
      />
      <path
        fill="currentColor"
        d="M4.853 4.675h2.344V7.02H4.853V4.675ZM9.722 4.675h2.344V7.02H9.722V4.675ZM14.589 4.675h2.344V7.02h-2.344V4.675Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconDevelopment);
export default Memo;
