import { EditFilled } from '@ant-design/icons';

import styles from './SuggestChangesList.module.scss';

export interface ISuggestChangesItem {
  label: string;
  value: string;
}

type Props = ISuggestChangesItem;

const SuggestChangesItem: React.FunctionComponent<Props> = ({ label, value }) => {
  return (
    <li>
      <EditFilled className={styles['image-icon']} />
      <div>
        {label}:<strong> {value}</strong>
      </div>
    </li>
  );
};

export default SuggestChangesItem;
