import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSecurityOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.65 10.85V7.49C12.65 4.85 15.222 3 18 3s5.35 1.85 5.35 4.49v3.36H30v21H6v-21zm1.7-3.36c0-1.381 1.457-2.79 3.65-2.79s3.65 1.409 3.65 2.79v3.36h-7.3zM7.7 12.55h20.6v17.6H7.7z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M17 17.85h1.882v9.412H17zM22.506 26.885l3.247-4.33-3.247-4.328L21 19.356l2.4 3.2-2.4 3.2zM14.753 25.756l-2.4-3.2 2.4-3.2-1.506-1.13L10 22.557l3.247 4.33z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSecurityOperation);
export default Memo;
