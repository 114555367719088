import type { IExclusiveOfferPlansQuery } from 'src/graphql/schema';
import type { ICancelPlanItem } from 'src/components/Billing/Cancel/Plan';

export const mapExclusiveOfferPlans = (data: IExclusiveOfferPlansQuery) => {
  return (
    (data.exclusiveOfferPlans
      ?.filter(({ status }) => status === 'active')
      .map(({ id, name, periodUnit, currencyCode, price, discountedPrice, discounted }) => ({
        id,
        name,
        period: periodUnit,
        currency: currencyCode,
        price,
        discountedPrice,
        discounted,
      })) as ICancelPlanItem[]) ?? []
  );
};
