import classNames from 'classnames';
import { Skeleton } from 'antd';

import type { EscrowStatusFilterEnum } from 'src/graphql/schema';

import styles from './HomeCarousel.module.scss';

type Props = {
  id: EscrowStatusFilterEnum;
  name: string;
  count: number;
  isNew?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element> | React.ComponentType;
  onClick: (id: EscrowStatusFilterEnum) => void;
};

const HomeCarouselItem = ({
  id,
  name,
  count,
  icon,
  isNew = false,
  isActive = false,
  isLoading = false,
  onClick,
}: Props) => {
  const Icon = icon;
  const itemClasses = classNames(styles.item, {
    [styles.active]: isActive,
  });

  const handleClick = () => onClick(id);

  return (
    <div className={itemClasses} onClick={handleClick}>
      <div className={styles['item-text']}>
        {Icon && <Icon className={styles['item-icon']} />} {name}
      </div>

      {isLoading ? (
        <div className={styles['count-skeleton']}>
          <Skeleton.Button size="small" block />
        </div>
      ) : (
        <div className={styles['item-count']}>{count}</div>
      )}

      {isNew && <strong className={styles['new-label']}>new</strong>}
    </div>
  );
};

export default HomeCarouselItem;
