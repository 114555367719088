import { IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

export enum EscrowStatusIndicatorEnum {
  CONFIG = 'config',
  REVIEW_SIGNING = 'review-signing',
  DEPOSIT_ASSETS = 'deposit-assets',
  ACTIVE = 'active',
  CANCELLATION_INITIATED = 'cancellation-initiated',
  CANCELED = 'canceled',
  TERMINATED_REQUESTED = 'terminated-requested',
}

export const statusIndicatorItems: IJourneyStatusIndicatorItem[] = [
  {
    id: EscrowStatusIndicatorEnum.CONFIG,
    label: 'Escrow Configuration',
  },
  {
    id: EscrowStatusIndicatorEnum.REVIEW_SIGNING,
    label: 'Review & Signing',
  },
  {
    id: EscrowStatusIndicatorEnum.DEPOSIT_ASSETS,
    label: 'Deposit Assets',
  },
  {
    id: EscrowStatusIndicatorEnum.ACTIVE,
    label: 'Active Escrow',
  },
  {
    id: EscrowStatusIndicatorEnum.CANCELLATION_INITIATED,
    label: 'Cancellation Initiated',
    isHidden: true,
  },
  {
    id: EscrowStatusIndicatorEnum.CANCELED,
    label: 'Escrow Cancelled',
    isHidden: true,
  },
  {
    id: EscrowStatusIndicatorEnum.TERMINATED_REQUESTED,
    label: 'Terminated Initiated',
    isHidden: true,
  },
];
