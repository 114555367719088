import { JourneyMenu } from 'src/components/Journey/Menu';

import CustodianMenuItem, { ICustodianMenuItem } from './CustodianMenuItem';

type Props = {
  basePath: string;
  profileId: string;
  isLoading?: boolean;
  items: ICustodianMenuItem[];
};

const CustodianMenu = ({ items, profileId, ...rest }: Props) => {
  return (
    <JourneyMenu>
      {items.map((item) => (
        <CustodianMenuItem key={item.path} slug={profileId} {...item} {...rest} />
      ))}
    </JourneyMenu>
  );
};

export default CustodianMenu;
