import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgIconBeneficiary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 56 56"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#417BD2"
      fillRule="evenodd"
      d="M14 2C7.373 2 2 7.373 2 14v28c0 6.627 5.373 12 12 12h28c.282 0 .56-.01.837-.029l11.134-11.133c.02-.277.029-.556.029-.838V14c0-6.627-5.373-12-12-12H14Z"
      clipRule="evenodd"
    />
    <path fill="#164995" d="M42.695 44.58c0-1.04.844-1.884 1.884-1.884H54L42.696 54v-9.42Z" />
    <path
      fill="#fff"
      d="M18.155 27.894c.596 0 1.171.25 1.578.687l2.112 2.267a2.16 2.16 0 0 1 2.059-1.517h3.32c.464 0 .912-.169 1.261-.476a5.04 5.04 0 0 1 6.592-.045 2.154 2.154 0 0 1 1.762-.916h2.97c.397 0 .718.322.718.719v8.623a.719.719 0 0 1-.718.719h-2.97a2.15 2.15 0 0 1-1.62-.737l-.426.12a16.53 16.53 0 0 1-4.473.617h-6.416a5.054 5.054 0 0 1-4.024-2.012l-3.45-4.6a2.158 2.158 0 0 1 1.726-3.448ZM36.12 35.8a.72.72 0 0 0 .719.718h2.251v-7.186H36.84a.72.72 0 0 0-.72.719v5.749ZM17.58 30.48l3.45 4.6a3.61 3.61 0 0 0 2.874 1.437h6.416c1.38 0 2.755-.189 4.084-.563l.28-.079-.001-.076v-5.424l-.501-.44a3.601 3.601 0 0 0-4.747 0 3.345 3.345 0 0 1-2.212.834h-3.32a.72.72 0 0 0 0 1.437h5.108a.719.719 0 0 1 0 1.437h-5.589c-.596 0-1.17-.25-1.577-.687L18.68 29.56a.72.72 0 0 0-1.102.92Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M30.049 18.08a.76.76 0 0 0-1.018.34l-3.037 6.072a.76.76 0 0 0 1.358.679l3.036-6.072a.759.759 0 0 0-.34-1.019Zm-5.873.143a.759.759 0 0 0-1.074 0l-3.036 3.036a.759.759 0 0 0 0 1.073l3.036 3.036a.759.759 0 1 0 1.074-1.073l-2.5-2.5 2.5-2.499a.759.759 0 0 0 0-1.073Zm8.033 0a.759.759 0 0 1 1.073 0l3.036 3.036a.759.759 0 0 1 0 1.073l-3.036 3.036a.759.759 0 1 1-1.073-1.073l2.5-2.5-2.5-2.5a.759.759 0 0 1 0-1.073Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgIconBeneficiary);
export default Memo;
