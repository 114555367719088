import { Fragment, useState } from 'react';
import { Button } from 'antd';

import { EscrowPartyTypeEnum } from 'src/graphql/schema';

import ConfirmationMessageBlock from '../InfoBlocks/ConfirmationMessageBlock';
import ConfirmationMessageForm, { IConfirmationMessageForm } from '../Forms/ConfirmationMessageForm';

import styles from './EscrowContactsEditable.module.scss';

type Props = {
  type: EscrowPartyTypeEnum;
  value: boolean;
  onSubmit: (data: IConfirmationMessageForm) => Promise<void>;
};

const EditableConfirmationMessage: React.FunctionComponent<Props> = ({ type, value, onSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);

  const toggleEdit = () => setIsEdit((prevIsEdit) => !prevIsEdit);

  return (
    <Fragment>
      {isEdit ? (
        <ConfirmationMessageForm
          label={`Would you like to notify the ${type} when this escrow is active?`}
          onCancel={toggleEdit}
          onSubmit={onSubmit}
          value={value}
        />
      ) : (
        <Fragment>
          <ConfirmationMessageBlock role={type} value={value} />
          <div className={styles['edit-row']}>
            <Button ghost className={styles['edit-btn']} onClick={toggleEdit}>
              Edit details
            </Button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditableConfirmationMessage;
