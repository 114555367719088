import { Link } from 'react-router-dom';

import { LabelText } from 'src/components/Label';

import { custodianLabelStatusMap } from '../../model';
import { CustodianIcon } from '../../Icon';

import type { ICustodianProfileStatus } from '../../model';

import CustodianItemDropdown from './CustodianItemDropdown';
import styles from './CustodianItem.module.scss';

export type ICustodianItem = {
  id: string;
  name: string;
  status: ICustodianProfileStatus;
  assetsCount: number;
  operationsCount: number;
  providersCount: number;
  canRemove?: boolean;
};

type Props = {
  data: ICustodianItem;
  dropdownItemClick: (value: string) => void;
};

const CustodianItem = ({ data, dropdownItemClick }: Props) => {
  const { id, assetsCount, status, operationsCount, providersCount, name, canRemove } = data;
  const statusType = custodianLabelStatusMap[status];

  return (
    <div className={styles.item}>
      <Link to={`/custodian-profiles/${id}`}>
        <div className={styles['item-wrapper']}>
          <div className={styles['item-action']}>
            <CustodianItemDropdown canRemove={canRemove} onClick={dropdownItemClick} />
          </div>
          <div className={styles['item-row']}>
            <CustodianIcon className={styles.figure} />
            <div className={styles.content}>
              <div className={styles.name}>{name}</div>
              <LabelText {...statusType} />
            </div>
          </div>

          <div className={styles['info-row']}>
            <span className={styles['info-label']}>Assets:</span>{' '}
            <strong className={styles['info-value']}>{assetsCount || '-'}</strong>
          </div>
          <div className={styles['info-row']}>
            <span className={styles['info-label']}>Operations:</span>{' '}
            <strong className={styles['info-value']}>{operationsCount || '-'}</strong>
          </div>
          <div className={styles['info-row']}>
            <span className={styles['info-label']}>Providers:</span>{' '}
            <strong className={styles['info-value']}>{providersCount || '-'}</strong>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CustodianItem;
