import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoRackspace = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="img" className={props.className} {...props}>
    <path d="M8.926.014c3.556-.216 6.377 2.093 6.586 5.668.235 4.068-3.005 8.237-7.241 9.316-1.466.37-2.838.328-4.03-.048-.086-1.605.226-3.278.914-4.447 1.121-1.905 2.819-1.742 3.79.362.366.791.577 1.746.638 2.729a10 10 0 0 0 .983-.602c-1.05-2.548-1.52-5.566 2.244-10.866-1.094-.876-2.776-1.301-4.877-1.031q.464-.544.993-1.08" />
    <path
      fill="#b80017"
      d="M8.06 4.33c-.305.64-.182 1.166.272 1.176C8.784 5.513 9.4 5 9.704 4.358c.305-.64.184-1.167-.27-1.177-.454-.007-1.07.506-1.374 1.148m-.308-1.817C4.57 6.412 3.22 11.476 3.41 15.993 1.532 15.24.156 13.43.012 11.135v-.027c-.237-4.071 3.01-8.243 7.253-9.318 1.648-.415 3.204-.304 4.503.226a11 11 0 0 1-1.171 2.317c-.939 1.395-2.288 2.274-3.012 1.966-.723-.31-.548-1.694.393-3.09.216-.323.456-.618.705-.876a9 9 0 0 0-.931.18"
    />
  </svg>
);
const Memo = memo(SvgLogoRackspace);
export default Memo;
