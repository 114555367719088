import { Table, Button } from 'antd';

import Delete from 'src/assets/images/icons/delete.svg';
import Edit from 'src/assets/images/icons/edit.svg';
import { UserRoleEnum } from 'src/graphql/schema';

export type DestructuredUser = {
  name: string;
  role: UserRoleEnum;
  id: string;
  email: string;
  phone: string;
};

type Props = {
  setCurrentPage: (page: number) => void;
  currentPage: number;
  isOwner: boolean;
  loggedInUserId: string;
  members: DestructuredUser[];
  onEdit: (userId: string) => void;
  onDelete: (userId: string) => void;
};

const TeamMembersTable: React.FunctionComponent<Props> = ({
  setCurrentPage,
  currentPage,
  members,
  isOwner,
  loggedInUserId,
  onEdit,
  onDelete,
}) => {
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
    },
    {
      title: 'PHONE NUMBER',
      dataIndex: 'phone',
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
    },
    {
      title: 'Action',
      render: (user: DestructuredUser) => {
        const handleDelete = () => onDelete(user.id);
        const handleEdit = () => onEdit(user.id);
        return (
          <div className="d-flex align-items-center">
            {isOwner && user.role !== UserRoleEnum.Owner && (
              <>
                <Button onClick={handleEdit} style={{ height: 'auto' }} className="mb-0 mr-2 p-0 border-0">
                  <img src={Edit} alt="" />
                </Button>
                {/* Can't delete your self */}
                {loggedInUserId !== user.id && (
                  <Button ghost onClick={handleDelete} style={{ height: 'auto' }} className="mb-0 p-0 border-0">
                    <img src={Delete} alt="" />
                  </Button>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
      onChange={(e) => {
        setCurrentPage(e.current as number);
      }}
      className="mt-12"
      pagination={{
        current: currentPage,
        pageSize: 10,
        total: members.length,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
      columns={columns}
      dataSource={members}
      rowKey="id"
    />
  );
};

export default TeamMembersTable;
