import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconUpload = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M1.867 5.335a.8.8 0 0 1 .8-.8h10.666a.8.8 0 0 1 .8.8v7.333c0 .81-.656 1.467-1.466 1.467H3.333c-.81 0-1.466-.656-1.466-1.466V5.335Zm1.6.8v6.4h9.066v-6.4H3.467Z"
        clipRule="evenodd"
      />
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M8 6.535a.8.8 0 0 1 .8.8v4a.8.8 0 0 1-1.6 0v-4a.8.8 0 0 1 .8-.8Z"
        clipRule="evenodd"
      />
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M5.334 9.556a.8.8 0 0 1 1.11-.222L8 10.37l1.556-1.037a.8.8 0 0 1 .888 1.33l-2 1.334a.8.8 0 0 1-.888 0l-2-1.333a.8.8 0 0 1-.222-1.11ZM3.304 2.606a1.467 1.467 0 0 1 1.273-.739h6.846c.526 0 1.012.282 1.273.74l1.332 2.33a.8.8 0 0 1-.695 1.197H2.667a.8.8 0 0 1-.695-1.197l1.332-2.33Zm1.35.861-.609 1.067h7.91l-.61-1.067h-6.69Zm6.653-.067.116-.066-.116.066Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconUpload);
export default Memo;
