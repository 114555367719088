import { SaasProviderRoleEnum, SaasProviderProtectionPeriodEnum, SaasProviderStatusEnum } from 'src/graphql/schema';

export const roleOptions = [
  { value: SaasProviderRoleEnum.Hosting, label: 'Hosting provider' },
  { value: SaasProviderRoleEnum.ThirdParty, label: '3rd party service' },
];

export const roleDict = roleOptions.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});

export const protectionPeriodOptions = [
  {
    value: SaasProviderProtectionPeriodEnum.M_3,
    label: '3 months',
  },
  {
    value: SaasProviderProtectionPeriodEnum.M_6,
    label: '6 months',
  },
  {
    value: SaasProviderProtectionPeriodEnum.Y_1,
    label: '1 year',
  },
  {
    value: SaasProviderProtectionPeriodEnum.Y_2,
    label: '2 years',
  },
  {
    value: SaasProviderProtectionPeriodEnum.Y_3,
    label: '3 years',
  },
];
export const protectionPeriodDict = protectionPeriodOptions.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {},
);

export const providerTextHelper = {
  [SaasProviderStatusEnum.Pending]:
    'We will send detailed instructions on how to create a Codekeeper user for this provider via email.',
  [SaasProviderStatusEnum.SetupCompleted]: 'We successfully accessed your provider on {date}',
};
