import { useState, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { TeamMembers, TeamMembersTable } from 'src/components/Account';
import { useAccountMembersSlice } from 'src/slices';
import { IProfileQuery, UserRoleEnum } from 'src/graphql/schema';
import { DestructuredUser } from 'src/components/Account/TeamMembers/TeamMembersTable';

import DeleteMemberModalContainer from './DeleteMemberModalContainer';
import AddMemberModalContainer from './AddMemberModalContainer';

type Props = {
  users: DestructuredUser[];
  profile: IProfileQuery['profile'];
};

const TeamMembersContainer = ({ users, profile }: Props) => {
  const [removeUserId, setRemoveUserId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const {
    state: { isOpen, memberId, modalType },
    setInitial,
    inviteMember,
    editMember,
  } = useAccountMembersSlice();

  const ownerUser = users.find((user) => user.role === UserRoleEnum.Owner);
  const isOwner = profile?.id === ownerUser?.id;
  const isAdmin = users.some((user) => user.id === profile.id && user.role === UserRoleEnum.Administrator);

  const memberData = useMemo(() => {
    if (isEmpty(memberId && users)) return null;

    return users.find(({ id }) => id === memberId) ?? null;
  }, [memberId, users]);

  const onEdit = (memberId: string) => editMember(memberId);
  const onDelete = (memberId: string) => setRemoveUserId(memberId);

  return (
    <TeamMembers isOwner={isOwner || isAdmin} membersCount={users.length} onAdd={inviteMember}>
      <TeamMembersTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        members={users}
        loggedInUserId={profile.id || ''}
        isOwner={isOwner || isAdmin}
        onEdit={onEdit}
        onDelete={onDelete}
      />

      <AddMemberModalContainer
        isOpen={isOpen}
        onCloseModal={setInitial}
        editUserId={memberId}
        member={memberData}
        type={modalType}
      />

      <DeleteMemberModalContainer userId={removeUserId} setUserId={setRemoveUserId} />
    </TeamMembers>
  );
};

export default TeamMembersContainer;
