import { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Result, Spin, Button } from 'antd';

import useIntegrationAuth from '../hooks/use-integration-auth';

import type { OauthIntegrationProviderEnum } from 'src/graphql/schema';

export default function IntegrationAuth() {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const { provider } = useParams();
  const [searchParams] = useSearchParams();

  const { finishAuth } = useIntegrationAuth(provider as OauthIntegrationProviderEnum);

  const onLoad = async () => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (!code || !state) {
      setError('Invalid redirect url.');
      setLoading(false);
    } else {
      try {
        await finishAuth(code, state);
        setSuccess(true);
      } catch (e) {
        if (e instanceof Error) setError(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="integrations -small">
      <div className="integrations__header d-flex align-items-center justify-content-between">
        <p className="integrations__header-title">Integrations</p>
      </div>

      <div className="active-integrations">
        <div className="h-100 d-flex flex-column justify-content-center">
          {loading ? (
            <Result icon={<Spin size="large"></Spin>} title="Connecting..." status="info" />
          ) : success ? (
            <Result
              status="success"
              title="Successfully connected!"
              extra={[
                // eslint-disable-next-line react/jsx-key
                <div className="mx-auto">
                  <p className="integration-modal-second__subtitle">Next Steps</p>

                  <p>1. You can now use this integration to deposit your assets into escrow.</p>
                  <p>
                    2. Go to <strong>‘Escrows’</strong>
                  </p>
                  <p>3. Select the Escrow you would like to deposit to.</p>
                  <p>
                    4. Click the <strong>‘Assets’</strong> tab.
                  </p>
                  <p>5. Click ‘Add Asset’ and select the repository from the dropdown selector.</p>
                  <p>
                    Repeat this for all the repositories you would like to deposit into the escrow(s) on your account.
                  </p>

                  <Link to="/escrows">
                    <Button type="primary">Go to Escrows</Button>
                  </Link>
                </div>,
              ]}
            />
          ) : (
            <Result
              status="error"
              title="Connection failed!"
              subTitle={`Something went wrong with connecting your integration. ${error}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}
