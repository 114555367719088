import { Select } from 'antd';

import { actionOptions } from '../';

import styles from './SaasEscrowsActions.module.scss';

type Props = {
  onSelect: (value: string) => void;
};

const SaasEscrowsActions = ({ onSelect }: Props) => {
  return (
    <div className={styles.row}>
      <h1>Escrows</h1>
      <div className={styles.select}>
        <Select
          defaultValue="all"
          options={actionOptions}
          onSelect={onSelect}
          style={{
            display: 'block',
          }}
        />
      </div>
    </div>
  );
};

export default SaasEscrowsActions;
