import { Row, Col, Skeleton, Space } from 'antd';
import { Fragment } from 'react';

import IntegrationItemRadio, { IIntegrationItemRadio } from './IntegrationItemRadio';
import styles from './IntegrationRadio.module.scss';

type Props = {
  items: IIntegrationItemRadio[];
  selectedItemId?: string;
  onClick: (id: string) => void;
  heading?: string;
};

const IntegrationRadioGroupSkeleton = () => (
  <Row gutter={[16, 16]}>
    {Array.from({ length: 4 }).map((_, index) => (
      <Col key={index} span={6}>
        <Space direction="vertical" className={styles.item}>
          <Skeleton.Avatar shape="circle" active size="large" />
          <Skeleton
            active
            paragraph={{
              rows: 2,
            }}
          />
        </Space>
      </Col>
    ))}
  </Row>
);

const IntegrationRadioGroup = ({ items, selectedItemId, onClick, heading }: Props) => {
  if (!items.length) return null;

  return (
    <Fragment>
      {heading && <h6 className={styles.heading}>{heading}</h6>}

      <Row gutter={[16, 16]}>
        {items.map((item) => (
          <Col key={item.id} span={6} className={styles['list-item']}>
            <IntegrationItemRadio {...item} onClick={onClick} selected={item.id === selectedItemId} />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

IntegrationRadioGroup.Skeleton = IntegrationRadioGroupSkeleton;

export default IntegrationRadioGroup;
