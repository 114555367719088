import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { CustomRadioWithIconGroup } from 'src/components/Form';
import { IconBeneficiary, IconDepositor } from 'src/components/Svg/Images';
import { IconBipartite, IconTripartite } from 'src/components/Svg/Icons';
import { TextBlock } from 'src/components/Misc';

import styles from './EscrowRole.module.scss';

type Props = {
  role: {
    activeValue?: string;
    onChange: (role: string) => void;
  };
  agreement: {
    activeValue?: string;
    onChange: (agreement: string) => void;
  };
};

const EscrowRoleEdit: React.FunctionComponent<Props> = ({ role, agreement }) => {
  return (
    <div className={styles.main}>
      <div className={styles.block}>
        <TextBlock
          className={styles['info-block']}
          title="Escrow Role"
          text="Select the role you have for the process of establishing this escrow. You can only select one option."
          segment={!role.activeValue && <i className={styles.segment}>Required</i>}
        />
        <div className={styles.list}>
          <CustomRadioWithIconGroup
            selectedValue={role.activeValue}
            col={2}
            onChange={role.onChange}
            options={[
              {
                heading: 'Depositor',
                text: 'The party that owns the material that will be deposited into escrow.',
                value: 'depositor',
                iconType: 'image',
                icon: IconDepositor,
                size: 52,
              },
              {
                heading: 'Beneficiary',
                text: 'The party that will receive the material if the release clauses are triggered.',
                value: 'beneficiary',
                iconType: 'image',
                icon: IconBeneficiary,
                size: 52,
              },
            ]}
          />
        </div>
        <p className={styles['secondary-text']}>
          <ExclamationCircleFilled className={styles.icon} />
          Once your agreement form has been selected, it cannot be changed for the configuration of this escrow.
        </p>
      </div>

      <div className={styles.block}>
        <TextBlock
          className={styles['info-block']}
          title="Agreement Form"
          text="Select the type of agreement for this escrow."
          segment={!agreement.activeValue && <i className={styles.segment}>Required</i>}
        />
        <div className={styles.list}>
          <CustomRadioWithIconGroup
            selectedValue={agreement.activeValue}
            col={2}
            onChange={agreement.onChange}
            options={[
              {
                heading: 'Bipartite form',
                text: 'Depositor and Codekeeper are signatories on the escrow agreement.',
                value: 'bipartite',
                iconType: 'regular',
                icon: IconBipartite,
                disabled: role.activeValue === 'beneficiary',
              },
              {
                heading: 'Tripartite form',
                text: 'Beneficiary will also be a signatory on the escrow agreement.',
                value: 'tripartite',
                iconType: 'regular',
                icon: IconTripartite,
              },
            ]}
          />
        </div>
        <p className={styles['secondary-text']}>
          <ExclamationCircleFilled className={styles.icon} />
          Once your agreement form has been selected, it cannot be changed for the configuration of this escrow.
        </p>
      </div>
    </div>
  );
};

export default EscrowRoleEdit;
