import { Suspense } from 'react';

import { EscrowPartyTypeEnum, EscrowStatusEnum } from 'src/graphql/schema';
import { ContactsSuggestChangesProvider } from 'src/context/suggest-changes';
import {
  getPartyOrganization,
  getPartyContacts,
  getPartySignatory,
  getExistingUsers,
} from 'src/utils/destructs/escrow.destructs';
import Loader from 'src/components/Loader/Loader';
import { useEscrow } from 'src/context/escrow';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';

import TripartiteSuggestChangesContainer from '../SuggestChanges/TripartiteSuggestChangesContainer';
import EscrowPartyPreviewContainer, { IPartyPreview } from '../Main/EscrowPartyPreviewContainer';
import ContactsSuggestChangesActions from '../SuggestChanges/ContactsSuggestChangesActions';

import EscrowTripartiteEditableContainer from './EscrowTripartiteEditableContainer';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowTripartiteContainer: React.FunctionComponent = () => {
  const {
    escrow,
    ui: { isSuggestChangesMode },
  } = useEscrow();
  const mainPartyRole = escrow.currentCompanyRole as EscrowPartyTypeEnum;
  const secondPartyRole =
    mainPartyRole === EscrowPartyTypeEnum.Beneficiary ? EscrowPartyTypeEnum.Depositor : EscrowPartyTypeEnum.Beneficiary;
  const parties = {
    [mainPartyRole]: {
      isOwnOrganization: true,
      organization: getPartyOrganization(escrow, mainPartyRole),
      signatory: getPartySignatory(escrow, mainPartyRole),
      contacts: getPartyContacts(escrow, mainPartyRole),
    },
    [secondPartyRole]: {
      organization: getPartyOrganization(escrow, secondPartyRole),
      signatory: getPartySignatory(escrow, secondPartyRole),
      contacts: getPartyContacts(escrow, secondPartyRole),
    },
  } as { [key in EscrowPartyTypeEnum]: IPartyPreview };

  // Suggest Changes Mode
  if (
    !escrow.escrowTermination &&
    (escrow.status === EscrowStatusEnum.Active ||
      escrow.status === EscrowStatusEnum.Signed ||
      (escrow.status === EscrowStatusEnum.Onboarding && escrow.depositor && escrow.beneficiary))
  ) {
    const mainPartyExistingUsers = getExistingUsers(escrow, mainPartyRole);
    const secondaryPartyExistingUsers = getExistingUsers(escrow, secondPartyRole);

    return (
      <Suspense fallback={<Loader />}>
        <ContactsSuggestChangesProvider
          isSuggestChangesMode={isSuggestChangesMode}
          escrowId={escrow.id}
          mainPartyRole={mainPartyRole}
          parties={parties}
          existingUsers={{
            [mainPartyRole]: mainPartyExistingUsers,
            [secondPartyRole]: secondaryPartyExistingUsers,
          }}
        >
          <EscrowModalMain>
            <TripartiteSuggestChangesContainer />
          </EscrowModalMain>
          <ContactsSuggestChangesActions />
        </ContactsSuggestChangesProvider>
      </Suspense>
    );
  }

  // New/Edit Mode
  if ([EscrowStatusEnum.Onboarding, EscrowStatusEnum.Draft, EscrowStatusEnum.AdminReview].includes(escrow.status))
    return (
      <EscrowModalMain>
        <EscrowTripartiteEditableContainer />
      </EscrowModalMain>
    );

  // Preview Mode
  return (
    <EscrowModalMain>
      <EscrowPartyPreviewContainer parties={parties} />
    </EscrowModalMain>
  );
};

export default EscrowTripartiteContainer;
