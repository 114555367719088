import { Fragment, useState } from 'react';

import { ProfileCard } from 'src/components/Profile/Card';
import { ProfileInfoRow } from 'src/components/Profile/InfoRow';
import { useProfile } from 'src/context/profile';

import ProfileTOTPContainer from '../TOTP/ProfileTOTPContainer';

import UserInfoFormContainer from './UserInfoFormContainer';
import UserChangePasswordContainer from './UserChangePasswordContainer';
import UserChangeEmailContainer from './UserChangeEmailContainer';

const UserInfoContainer = () => {
  const [ui, setUi] = useState({
    isPasswordModalOpen: false,
    isEmailModalOpen: false,
  });
  const { profile } = useProfile();

  const showEmailModal = () => setUi((prevUi) => ({ ...prevUi, isEmailModalOpen: true }));
  const showPasswordModal = () => setUi((prevUi) => ({ ...prevUi, isPasswordModalOpen: true }));
  const setPasswordModalOpen = (isOpen: boolean) => setUi((prevUi) => ({ ...prevUi, isPasswordModalOpen: isOpen }));
  const setEmailModalOpen = (isOpen: boolean) => setUi((prevUi) => ({ ...prevUi, isEmailModalOpen: isOpen }));

  return (
    <Fragment>
      <ProfileCard title="User profile">
        <div className="mb-4">
          <UserInfoFormContainer name={profile.name || ''} phone={profile.phone || ''} />
        </div>

        <ProfileInfoRow label="Email" value={profile.email || ''} onClick={showEmailModal} />
        <ProfileInfoRow label="Password" value={'•'.repeat(15)} onClick={showPasswordModal} />

        {/* TOTP Container */}
        <ProfileTOTPContainer />
      </ProfileCard>

      {/* Change Modals */}
      <UserChangePasswordContainer isOpen={ui.isPasswordModalOpen} setIsOpen={setPasswordModalOpen} />
      <UserChangeEmailContainer
        email={profile.email || ''}
        isOpen={ui.isEmailModalOpen}
        setIsOpen={setEmailModalOpen}
      />
    </Fragment>
  );
};

export default UserInfoContainer;
