import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFileEdit = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.333 2.666h10.084c.743 0 1.452.31 1.956.855l5.916 6.39c.457.492.71 1.139.71 1.81v14.945a2.667 2.667 0 0 1-2.666 2.667h-8a1.333 1.333 0 0 1 0-2.667h8V11.722l-5.916-6.39H9.333v9.334a1.333 1.333 0 1 1-2.667 0V5.333a2.667 2.667 0 0 1 2.667-2.667"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.39 13.725c.521-.521 1.365-.521 1.886 0l3.334 3.333c.52.52.52 1.365 0 1.886l-10 10c-.25.25-.59.39-.943.39H5.333A1.333 1.333 0 0 1 4 28.001v-3.334c0-.353.14-.692.39-.943zM6.667 25.22v1.447h1.447l8.667-8.666-1.448-1.448z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.057 17.725c.52-.521 1.364-.521 1.885 0l2.667 2.666a1.333 1.333 0 1 1-1.886 1.886l-2.667-2.667a1.333 1.333 0 0 1 0-1.886M18.667 2.666c.737 0 1.334.597 1.334 1.333v6.667h6.666a1.333 1.333 0 1 1 0 2.667h-6.666a2.667 2.667 0 0 1-2.667-2.667V3.999c0-.736.597-1.333 1.333-1.333"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFileEdit);
export default Memo;
