import { CustodianStatusIndicator, CustodianStatusIndicatorEnum } from 'src/components/Custodian/StatusIndicator';
import { useCustodianProfile } from 'src/context/custodian';
import { CustodianProfileStatusEnum } from 'src/graphql/schema';

const CustodianStatusIndicatorContainer = () => {
  const {
    custodianProfile: { status },
    isTerminationRequested,
  } = useCustodianProfile();

  let currentStatus = CustodianStatusIndicatorEnum.CONFIG;

  switch (status) {
    case CustodianProfileStatusEnum.AccessSetup:
      currentStatus = CustodianStatusIndicatorEnum.ACCESS_SETUP;
      break;
    case CustodianProfileStatusEnum.Active:
      currentStatus = CustodianStatusIndicatorEnum.LIFECYCLE;
      break;
    case CustodianProfileStatusEnum.Terminated:
      currentStatus = CustodianStatusIndicatorEnum.TERMINATED;
      break;
    default:
      currentStatus = CustodianStatusIndicatorEnum.CONFIG;
  }

  if (isTerminationRequested) currentStatus = CustodianStatusIndicatorEnum.TERMINATION_PENDING;

  return <CustodianStatusIndicator currentStatus={currentStatus} />;
};

export default CustodianStatusIndicatorContainer;
