import { CompanyDocument, useRemoveUserMutation } from 'src/graphql/schema';
import { ModalProceed, message } from 'src/components/Misc';

type Props = {
  userId: string;
  setUserId: (userId: string) => void;
};

const DeleteMemberModalContainer: React.FunctionComponent<Props> = ({ userId, setUserId }) => {
  const [removeMember, { loading }] = useRemoveUserMutation({
    refetchQueries: [CompanyDocument],
  });

  const onDeleteMember = async () => {
    try {
      const { data } = await removeMember({
        variables: {
          userId,
        },
      });

      if (data?.removeUser?.success) {
        message.success('Team member deleted!');
        setUserId('');
      } else {
        message.error(data?.removeUser?.errors?.[0] || 'Something went wrong');
      }
    } catch (e: unknown) {
      e instanceof Error && message.error(e.message || 'Something went wrong');
    }
  };

  const closeModal = () => setUserId('');

  return (
    <ModalProceed
      isOpen={Boolean(userId)}
      title="Delete Team Member"
      text="Are you sure you want to delete this member?"
      proceedText="Delete"
      proceedButtonProps={{ danger: true, type: 'default' }}
      onProceed={onDeleteMember}
      onCancel={closeModal}
      isLoading={loading}
    />
  );
};

export default DeleteMemberModalContainer;
