import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoSendbird = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" role="img" className={props.className} {...props}>
    <path
      fill="#825EEB"
      d="M24 5.206c11.046 0 20 8.955 20 20 0 10.849-8.637 19.68-19.41 19.992l-.59.008c-10.849-.01-19.68-8.648-19.992-19.42L4 25.195h9.796c-5.268-.106-9.538-4.28-9.785-9.511L4 15.206v-10z"
    />
  </svg>
);
const Memo = memo(SvgLogoSendbird);
export default Memo;
