import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconTransaction = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path fill="currentColor" d="M6.35 15.561h4.399v2.344h-4.4V15.56Z" />
      <path
        fill="currentColor"
        d="M40 4.83A4.835 4.835 0 0 0 35.17 0h-3.665v2.344c.91 0 1.65.74 1.65 1.65 0 .91-.74 1.65-1.65 1.65H6.953v5.23H0v21.929a4.835 4.835 0 0 0 4.83 4.83h3.713v-2.344c-.91 0-1.65-.74-1.65-1.65 0-.91.74-1.65 1.65-1.65h6.292V40l5.176-2.64L25.187 40v-8.012h7.908V26.76H40V4.83ZM24.225 27.87a5.92 5.92 0 0 1-4.214 1.745 5.92 5.92 0 0 1-4.214-1.745 5.92 5.92 0 0 1-1.745-4.214 5.92 5.92 0 0 1 1.745-4.214 5.92 5.92 0 0 1 4.214-1.745 5.92 5.92 0 0 1 4.214 1.745 5.92 5.92 0 0 1 1.745 4.214 5.92 5.92 0 0 1-1.745 4.214Zm-1.382 8.304-2.832-1.445-2.832 1.445v-4.186h5.664v4.186Zm7.908-6.53h-4.99a8.249 8.249 0 0 0 2.552-5.988 8.249 8.249 0 0 0-2.431-5.87 8.249 8.249 0 0 0-5.871-2.433 8.249 8.249 0 0 0-5.871 2.432 8.3 8.3 0 0 0-1.705 2.463H6.35v2.344h5.425a8.436 8.436 0 0 0 .03 2.344H6.35v2.344h6.186a8.314 8.314 0 0 0 1.724 2.365H8.543a3.999 3.999 0 0 0-3.637 5.644H4.83a2.489 2.489 0 0 1-2.486-2.486V13.217H30.75v16.428Zm6.905-5.228h-4.561V10.873H9.297V7.989h22.208a3.999 3.999 0 0 0 3.994-3.994 3.97 3.97 0 0 0-.358-1.65h.029a2.489 2.489 0 0 1 2.486 2.486v19.586Z"
      />
      <path
        fill="currentColor"
        d="M20.01 19.976a3.684 3.684 0 0 0-3.68 3.68c0 2.03 1.651 3.68 3.68 3.68 2.03 0 3.68-1.65 3.68-3.68 0-2.029-1.65-3.68-3.68-3.68Zm0 5.017a1.338 1.338 0 0 1 .001-2.673c.736 0 1.336.6 1.336 1.337s-.6 1.336-1.337 1.336Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconTransaction);
export default Memo;
