import { DisruptionNotificationEnum, DisruptionNotificationGracePeriodEnum } from 'src/graphql/schema';

export function setGracePeriodOption(
  disruptedValue?: DisruptionNotificationEnum,
  gracePeriodValue?: DisruptionNotificationGracePeriodEnum,
) {
  if (disruptedValue === DisruptionNotificationEnum.ImmediateNotification) {
    if (gracePeriodValue) return gracePeriodValue;
    else return 'none';
  } else return undefined;
}
