import { Fragment, useState } from 'react';
import { Pagination, Empty } from 'antd';

import { useActivitiesQuery, IActivity } from 'src/graphql/schema';
import { ProfileActivityList } from 'src/components/Activity';
import { ProfileCard } from 'src/components/Profile/Card';
import Loader from 'src/components/Loader/Loader';

const ProfileActivityContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useActivitiesQuery({
    variables: {
      page: currentPage,
      perPage: 10,
    },
  });

  if (loading) return <Loader />;

  return (
    <Fragment>
      {data?.activities.nodes.length ? (
        <ProfileCard className="container--1200">
          <Pagination
            className="d-flex justify-content-end"
            defaultCurrent={currentPage}
            total={data?.activities.nodesCount || 0}
            defaultPageSize={10}
            onChange={(page) => setCurrentPage(page)}
            showSizeChanger={false}
            hideOnSinglePage
          />
          <ProfileActivityList items={data?.activities.nodes as IActivity[]} />
        </ProfileCard>
      ) : (
        <Empty description="No activity yet" />
      )}
    </Fragment>
  );
};

export default ProfileActivityContainer;
