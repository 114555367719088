import { fetchAuthSession } from '@aws-amplify/auth';

import env from 'env';

const fetchCognitoToken = async () => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};

  return accessToken;
};

const fetchLocalToken = () => {
  const userBody = localStorage.getItem('user');

  if (userBody) {
    const user = JSON.parse(userBody);
    return user.token;
  } else {
    return null;
  }
};

export default env.VITE_COGNITO_AUTH ? fetchCognitoToken : fetchLocalToken;
