import { message, Modal } from 'antd';

import { UserChangePasswordForm, IUserChangePasswordForm } from 'src/components/Profile/Forms';
import { useAuth } from 'src/context/auth/use-auth';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const UserChangePasswordContainer = ({ isOpen, setIsOpen }: Props) => {
  const { changePassword } = useAuth();

  const onSubmit = async ({ password, newPassword }: IUserChangePasswordForm) => {
    try {
      await changePassword(password, newPassword);
      setIsOpen(false);
      message.success('Password changed!');
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.name === 'NotAuthorizedException') message.error('Invalid password');
        else message.error(e.message);
      }
    }
  };

  const onCancel = () => setIsOpen(false);

  return (
    <Modal open={isOpen} title="Change password" footer={null} onCancel={onCancel}>
      <UserChangePasswordForm onSubmit={onSubmit} onCancel={onCancel} />
    </Modal>
  );
};

export default UserChangePasswordContainer;
