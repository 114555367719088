import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import classNames from 'classnames';

import CustomCheckboxWithIcon, { ICustomCheckboxWithIcon } from './CustomCheckboxWithIcon';

type Props = {
  options: ICustomCheckboxWithIcon[];
  onChange?: (checkedValue: CheckboxValueType[]) => void;
  col?: keyof typeof colToSpanEnum;
  className?: string;
  classes?: {
    row?: string;
    column?: string;
    item?: {
      icon?: string;
    };
  };
  selectedValues?: string[];
  layout?: 'vertical' | 'horizontal';
};

const colToSpanEnum = {
  1: 24,
  2: 12,
  3: 8,
  4: 6,
  5: 5,
  6: 4,
  8: 3,
  12: 2,
} as const;

const CustomCheckboxWithIconGroup: React.FunctionComponent<Props> = ({
  className,
  classes,
  options,
  col = 1,
  onChange,
  selectedValues = [],
  layout,
}) => {
  return (
    <Checkbox.Group
      className={classNames('ck-custom-checkbox-group', className)}
      onChange={onChange}
      value={selectedValues}
    >
      <Row gutter={[16, 16]} className={classNames('ck-custom-checkbox-group__row', classes?.row)}>
        {options.map((option) => (
          <Col key={option.value} span={colToSpanEnum[col]} className={classes?.column}>
            <CustomCheckboxWithIcon
              selected={selectedValues.includes(option.value)}
              layout={layout}
              classes={{ ...classes?.item }}
              {...option}
            />
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export default CustomCheckboxWithIconGroup;
