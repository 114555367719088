import { useReducer } from 'react';

import { IOrganizationDetail, ISignatoryDetail, IContactDetail } from 'src/components/Escrow/Contacts';
import {
  EscrowPartyTypeEnum,
  ContactTypeEnum,
  UserRoleEnum,
  ICreateContactInputAttributes,
  IEditContactChangeInputAttributes,
} from 'src/graphql/schema';
import { IAction, IRequireAtLeastOne } from 'src/utils/ts-utilities';

export enum ActionEnum {
  SET_INITIAL_DATA = 'set_initial_data',
  UPDATE_ORGANIZATION = 'update_organization',
  UPDATE_CONTACT = 'update_contact',
  UPDATE_SIGNATORY = 'update_signatory',
  ADD_NEW_CONTACT = 'add_new_contact',
  REMOVE_CONTACT = 'remove_contact',
}

const userRoleEnumType = {
  [ContactTypeEnum.Administrative]: UserRoleEnum.Administrator,
  [ContactTypeEnum.Legal]: UserRoleEnum.Legal,
  [ContactTypeEnum.Technical]: UserRoleEnum.Technical,
};

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA, { data: IViewState; existingUsers: IContactDetail[] }>
  | IAction<ActionEnum.UPDATE_ORGANIZATION, { role: EscrowPartyTypeEnum; organization: IOrganizationDetail }>
  | IAction<
      ActionEnum.UPDATE_CONTACT,
      { role: EscrowPartyTypeEnum; contact: IContactDetail & { isDynamic?: boolean }; isBipartite?: boolean }
    >
  | IAction<ActionEnum.UPDATE_SIGNATORY, { role: EscrowPartyTypeEnum; signatory: ISignatoryDetail }>
  | IAction<ActionEnum.ADD_NEW_CONTACT, { role: EscrowPartyTypeEnum; contact: IContactDetail; isBipartite?: boolean }>
  | IAction<ActionEnum.REMOVE_CONTACT, { role: EscrowPartyTypeEnum; contactId: string }>;

export type IViewState = IRequireAtLeastOne<
  Record<
    string,
    {
      organization?: IOrganizationDetail;
      signatory?: ISignatoryDetail;
      contacts?: Array<IContactDetail & { isDynamic?: boolean }>;
    }
  >
>;

export type ISuggestedChangesState = IRequireAtLeastOne<
  Record<
    string,
    {
      deletedContacts?: { id: string }[];
      newContacts?: ICreateContactInputAttributes[];
      editedContacts?: IEditContactChangeInputAttributes[];
    }
  >
>;
export type IEntitiesState = {
  existingUsers: IContactDetail[];
  addedExistingUsers: IContactDetail[];
};

export type IState = {
  view: IViewState | null;
  suggestedChangesForReview: ISuggestedChangesState | null;
  entities: IEntitiesState;
};

const initialState: IState = {
  view: null,
  suggestedChangesForReview: null,
  entities: {
    existingUsers: [],
    addedExistingUsers: [],
  },
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_INITIAL_DATA:
      return {
        ...state,
        view: action.payload.data,
        suggestedChangesForReview: null,
        entities: {
          ...state.entities,
          addedExistingUsers: [],
          existingUsers: action.payload.existingUsers,
        },
      };
    case ActionEnum.ADD_NEW_CONTACT: {
      const partyRole = action.payload.role;
      const isBipartite = action.payload.isBipartite;
      const newContact = action.payload.contact;
      const partyViewData = state.view?.[partyRole];
      const partyChangesData = state.suggestedChangesForReview?.[partyRole];
      const partyViewContacts = partyViewData?.contacts || [];
      const newContacts = partyChangesData?.newContacts?.slice() || [];
      let existingUsers = state.entities.existingUsers.slice();
      const addedExistingUsers = state.entities.addedExistingUsers.slice();

      if (newContact.id.includes('new-static-')) {
        if (partyRole === EscrowPartyTypeEnum.Beneficiary && isBipartite) {
          newContacts.push({
            userId: newContact.id,
            name: newContact.name,
            email: newContact.email,
            phone: newContact.phone,
            contactType: newContact.role as ContactTypeEnum,
          });
        } else {
          newContacts.push({
            userId: newContact.id,
            user: {
              name: newContact.name,
              email: newContact.email,
              phone: newContact.phone,
              role: userRoleEnumType[newContact.role as keyof typeof userRoleEnumType],
            },
            contactType: newContact.role as ContactTypeEnum,
          });
        }
      } else {
        newContacts.push({
          userId: newContact.id,
          contactType: newContact.role as ContactTypeEnum,
        });
        const existingUser = existingUsers.find(({ id }) => id === newContact.id);
        if (existingUser) addedExistingUsers.push(existingUser);
        existingUsers = existingUsers.filter(({ id }) => id !== newContact.id);
      }

      return {
        ...state,
        view: {
          ...state.view,
          [partyRole]: {
            ...partyViewData,
            contacts: [...partyViewContacts, { ...newContact }],
          },
        },
        suggestedChangesForReview: {
          ...state.suggestedChangesForReview,
          [partyRole]: {
            ...partyChangesData,
            newContacts,
          },
        },
        entities: {
          ...state.entities,
          existingUsers,
          addedExistingUsers,
        },
      };
    }
    case ActionEnum.UPDATE_ORGANIZATION: {
      const partyRole = action.payload.role;
      const partyViewData = state.view?.[partyRole];

      return {
        ...state,
        view: {
          ...state.view,
          [partyRole]: { ...partyViewData, organization: action.payload.organization },
        },
      };
    }
    case ActionEnum.UPDATE_CONTACT: {
      const partyRole = action.payload.role;
      const isBipartite = action.payload.isBipartite;
      const partyViewData = state.view?.[partyRole];
      const partyChangesData = state.suggestedChangesForReview?.[partyRole];
      const contacts = partyViewData?.contacts?.slice() || [];
      const updatedContact = action.payload.contact;
      const contactUpdatedIndex = contacts.findIndex(({ id }) => id === updatedContact.id);
      contacts[contactUpdatedIndex] = {
        ...contacts[contactUpdatedIndex],
        ...updatedContact,
      };
      const editedSuggestedContacts = partyChangesData?.editedContacts?.slice() || [];
      const editedNewSuggestedContacts = partyChangesData?.newContacts?.slice() || [];
      const contactExistsInEditSuggestedChanges = editedSuggestedContacts.find(({ id }) => id === updatedContact.id);
      const contactExistsInNewSuggestedChanges = editedNewSuggestedContacts.find(
        ({ userId }) => userId === updatedContact.id,
      );

      // Updating suggestedChangesForReview data
      if (contactExistsInNewSuggestedChanges) {
        const newContactUpdatedIndex = editedNewSuggestedContacts.findIndex(
          ({ userId }) => userId === updatedContact.id,
        );

        if (updatedContact.id.includes('new-static-')) {
          if (partyRole === EscrowPartyTypeEnum.Beneficiary && isBipartite) {
            editedNewSuggestedContacts[newContactUpdatedIndex] = {
              userId: updatedContact.id,
              name: updatedContact.name,
              email: updatedContact.email,
              phone: updatedContact.phone,
              contactType: updatedContact.role as ContactTypeEnum,
            };
          } else {
            editedNewSuggestedContacts[newContactUpdatedIndex] = {
              userId: updatedContact.id,
              user: {
                email: updatedContact.email,
                name: updatedContact.name,
                phone: updatedContact.phone,
                role: userRoleEnumType[updatedContact.role as keyof typeof userRoleEnumType],
              },
              contactType: updatedContact.role as ContactTypeEnum,
            };
          }
        } else {
          editedNewSuggestedContacts[newContactUpdatedIndex] = {
            userId: updatedContact.id,
            contactType: updatedContact.role as ContactTypeEnum,
          };
        }
      } else if (contactExistsInEditSuggestedChanges) {
        const editedSuggestedContactIndex = editedSuggestedContacts.findIndex(({ id }) => id === updatedContact.id);
        if (updatedContact.isDynamic) {
          editedSuggestedContacts[editedSuggestedContactIndex] = {
            id: updatedContact.id,
            contactType: updatedContact.role as ContactTypeEnum,
          };
        } else {
          editedSuggestedContacts[editedSuggestedContactIndex] = {
            ...editedSuggestedContacts[editedSuggestedContactIndex],
            ...updatedContact,
          };
        }
      } else {
        if (updatedContact.isDynamic)
          editedSuggestedContacts.push({
            id: updatedContact.id,
            contactType: updatedContact.role as ContactTypeEnum,
          });
        else editedSuggestedContacts.push(updatedContact);
      }

      return {
        ...state,
        view: {
          ...state.view,
          [partyRole]: {
            ...partyViewData,
            contacts,
          },
        },
        suggestedChangesForReview: {
          ...state.suggestedChangesForReview,
          [partyRole]: {
            ...partyChangesData,
            editedContacts: editedSuggestedContacts,
            newContacts: editedNewSuggestedContacts,
          },
        },
      };
    }
    case ActionEnum.REMOVE_CONTACT: {
      const partyRole = action.payload.role;
      const contactId = action.payload.contactId;
      const partyViewData = state.view?.[partyRole];
      const contacts = partyViewData?.contacts?.filter(({ id }) => id !== contactId);
      const partyChangesData = state.suggestedChangesForReview?.[partyRole];
      let removedEditedSuggestedContacts = partyChangesData?.editedContacts?.slice() || [];
      let removedNewSuggestedContacts = partyChangesData?.newContacts?.slice() || [];
      const contactExistsInNewSuggestedChanges = removedNewSuggestedContacts.find(({ userId }) => userId === contactId);
      const contactExistsInEditedSuggestedChanges = removedEditedSuggestedContacts.find(({ id }) => id === contactId);
      const deletedContacts = partyChangesData?.deletedContacts?.slice() || [];
      let addedExistingUsers = state.entities.addedExistingUsers.slice();
      const existingUsers = state.entities.existingUsers.slice();

      if (contactExistsInNewSuggestedChanges) {
        removedNewSuggestedContacts = removedNewSuggestedContacts.filter(({ userId }) => userId !== contactId);
        const existingUser = addedExistingUsers.find(({ id }) => id === contactId);
        if (existingUser) existingUsers.push(existingUser);
        addedExistingUsers = addedExistingUsers.filter(({ id }) => id !== contactId);
      } else if (contactExistsInEditedSuggestedChanges) {
        removedEditedSuggestedContacts = removedEditedSuggestedContacts.filter(({ id }) => id !== contactId);
        deletedContacts.push({ id: contactId });
      } else {
        deletedContacts.push({ id: contactId });
      }

      return {
        ...state,
        view: {
          ...state.view,
          [partyRole]: {
            ...partyViewData,
            contacts,
          },
        },
        suggestedChangesForReview: {
          ...state.suggestedChangesForReview,
          [partyRole]: {
            ...partyChangesData,
            deletedContacts,
            newContacts: [...removedNewSuggestedContacts],
            editedContacts: [...removedEditedSuggestedContacts],
          },
        },
        entities: {
          ...state.entities,
          addedExistingUsers,
          existingUsers,
        },
      };
    }
    case ActionEnum.UPDATE_SIGNATORY: {
      const partyRole = action.payload.role;
      const partyViewData = state.view?.[partyRole];

      return {
        ...state,
        view: {
          ...state.view,
          [partyRole]: { ...partyViewData, signatory: action.payload.signatory },
        },
      };
    }
    default:
      return state;
  }
};

export const useContactsSuggestChangesSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setInitialData = (data: IViewState, existingUsers: IContactDetail[]): void => {
    dispatch({
      type: ActionEnum.SET_INITIAL_DATA,
      payload: {
        data,
        existingUsers,
      },
    });
  };

  const addNewContact = (
    role: EscrowPartyTypeEnum,
    contact: IContactDetail & { isDynamic?: boolean },
    isBipartite?: boolean,
  ) => {
    dispatch({
      type: ActionEnum.ADD_NEW_CONTACT,
      payload: {
        role,
        contact,
        isBipartite,
      },
    });
  };

  const updateContact = (
    role: EscrowPartyTypeEnum,
    contact: IContactDetail & { isDynamic?: boolean },
    isBipartite?: boolean,
  ) =>
    dispatch({
      type: ActionEnum.UPDATE_CONTACT,
      payload: {
        role,
        contact,
        isBipartite,
      },
    });

  const removeContact = (role: EscrowPartyTypeEnum, contactId: string) =>
    dispatch({
      type: ActionEnum.REMOVE_CONTACT,
      payload: {
        role,
        contactId,
      },
    });

  const updateOrganization = (role: EscrowPartyTypeEnum, organization: IOrganizationDetail) =>
    dispatch({
      type: ActionEnum.UPDATE_ORGANIZATION,
      payload: {
        role,
        organization,
      },
    });

  const updateSignatory = (role: EscrowPartyTypeEnum, signatory: ISignatoryDetail) =>
    dispatch({
      type: ActionEnum.UPDATE_SIGNATORY,
      payload: {
        role,
        signatory,
      },
    });

  return {
    view: state.view,
    existingUsers: state.entities.existingUsers,
    suggestedChangesForReview: state.suggestedChangesForReview,
    setInitialData,
    addNewContact,
    updateContact,
    removeContact,
    updateOrganization,
    updateSignatory,
  };
};
