import { Fragment } from 'react';
import { LockOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { SaasProviderStatusEnum } from 'src/graphql/schema';

import styles from './ProviderStatusLabel.module.scss';

type Props = {
  status: SaasProviderStatusEnum;
  className?: string;
  disabled?: boolean;
};

const statusTextEnum = {
  [SaasProviderStatusEnum.Pending]: 'Pending',
  [SaasProviderStatusEnum.SetupCompleted]: (
    <Fragment>
      <LockOutlined /> Setup completed
    </Fragment>
  ),
};

const ProviderStatusLabel = ({ status, disabled = false, className }: Props) => {
  const statusClasses = classNames(styles.status, className, {
    [styles.disabled]: disabled,
    [styles[status]]: !disabled,
  });

  return <strong className={statusClasses}>{statusTextEnum[status]}</strong>;
};

export default ProviderStatusLabel;
