import { Button } from 'antd';

import Chat from 'src/assets/images/icons/chat.svg';
import Mail from 'src/assets/images/icons/envelope.svg';
import Clock from 'src/assets/images/icons/clock.svg';
import { Main } from 'src/components/Layout';
import './support.scss';

export default function SupportContent() {
  return (
    <Main title="Support" className="supp">
      <div className="supp__container">
        <p className="supp__title">Your account comes with a full concierge service. Let us know how we can help!</p>
        <div className="d-flex justify-content-center">
          <div className="supp__info">
            <img src={Mail} alt="mail" />
            <p className="supp__info-label">Contact us</p>
            <p className="supp__info-text">Looking for something specific? Do you have any suggestions?</p>
            <Button
              className="ant-btn-default--primary"
              onClick={() => window.open('https://codekeeper.co/contact.html', '_blank')}
            >
              Get in touch
            </Button>
          </div>
          <div className="supp__info">
            <img src={Chat} alt="chat" />
            <p className="supp__info-label">Sales Inquiries</p>
            <p className="supp__info-text">
              Please use our website chat feature or send us an email by clicking the button below.
            </p>
            <Button
              onClick={() => window.open('https://codekeeper.co/contact.html', '_blank')}
              className="ant-btn-default--primary"
            >
              Get in touch
            </Button>
          </div>
          <div className="supp__info">
            <img src={Clock} alt="clock" />
            <p className="supp__info-label">Release Request</p>
            <p className="supp__info-text">
              If you can not log in to your account please send us an email by clicking the button below (Monitored
              24/7/365).
            </p>
            <Button
              onClick={() => window.open('https://codekeeper.co/contact.html', '_blank')}
              className="ant-btn-default--primary"
            >
              Get in touch
            </Button>
          </div>
        </div>
      </div>
    </Main>
  );
}
