import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconCertificate = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.317 1.735a2.03 2.03 0 0 0-2.634 0l-.814.694a.53.53 0 0 1-.302.125l-1.066.085A2.03 2.03 0 0 0 2.64 4.5l-.085 1.066a.53.53 0 0 1-.125.302l-.694.814a2.03 2.03 0 0 0 0 2.634l.694.814a.53.53 0 0 1 .125.302l.085 1.066A2.03 2.03 0 0 0 4.5 13.36l1.066.085a.53.53 0 0 1 .302.125l.814.694a2.03 2.03 0 0 0 2.634 0l.814-.694a.53.53 0 0 1 .302-.125l1.066-.085A2.03 2.03 0 0 0 13.36 11.5l.085-1.066a.53.53 0 0 1 .125-.302l.694-.814a2.03 2.03 0 0 0 0-2.634l-.694-.814a.53.53 0 0 1-.125-.302l-.085-1.066A2.03 2.03 0 0 0 11.5 2.64l-1.066-.085a.53.53 0 0 1-.302-.125zM7.656 2.877a.53.53 0 0 1 .688 0l.814.693c.325.277.73.445 1.155.479l1.066.085a.53.53 0 0 1 .487.487l.085 1.066c.034.425.201.83.479 1.155l.693.814a.53.53 0 0 1 0 .688l-.693.814a2.03 2.03 0 0 0-.479 1.155l-.085 1.066a.53.53 0 0 1-.486.487l-1.067.085a2.03 2.03 0 0 0-1.155.479l-.814.693a.53.53 0 0 1-.688 0l-.814-.693a2.03 2.03 0 0 0-1.155-.479l-1.066-.085a.53.53 0 0 1-.487-.486l-.085-1.067a2.03 2.03 0 0 0-.479-1.155l-.693-.814a.53.53 0 0 1 0-.688l.693-.814a2.03 2.03 0 0 0 .479-1.155l.085-1.066a.53.53 0 0 1 .487-.487l1.066-.085a2.03 2.03 0 0 0 1.155-.479zm2.874 4.32a.75.75 0 1 0-1.06-1.06L7.333 8.272 6.53 7.47a.75.75 0 0 0-1.06 1.06l1.333 1.334a.75.75 0 0 0 1.06 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconCertificate);
export default Memo;
