import { LazyLoad } from 'src/components/Misc';

const BackupPlatformContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Platform/BackupPlatformContainer')} />
);
const BackupSettingsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Settings/BackupSettingsContainer')} />
);
const BackupAssetsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Assets/BackupAssetsContainer')} />
);
const BackupSummaryContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Summary/BackupSummaryContainer')} />
);
const BackupMembersContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Members/BackupMembersContainer')} />
);
const BackupReportsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Reports/BackupReportsContainer')} />
);
const BackupReportContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Report/BackupReportContainer')} />
);
const BackupReportEmptyContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Report/ReportEmptyContainer')} />
);
import type { RouteObject } from 'react-router-dom';

export enum BackupRoutesEnum {
  Platform = 'platform',
  Members = 'members',
  Settings = 'settings',
  Assets = 'assets',
  Summary = 'summary',
  Reports = 'reports',
}

export const backupRouteElements: RouteObject[] = [
  {
    path: BackupRoutesEnum.Platform,
    element: <BackupPlatformContainer />,
  },
  {
    path: BackupRoutesEnum.Members,
    element: <BackupMembersContainer />,
  },
  {
    path: BackupRoutesEnum.Settings,
    element: <BackupSettingsContainer />,
  },
  {
    path: BackupRoutesEnum.Assets,
    element: <BackupAssetsContainer />,
  },
  {
    path: BackupRoutesEnum.Summary,
    element: <BackupSummaryContainer />,
  },
  {
    path: BackupRoutesEnum.Reports,
    element: <BackupReportsContainer />,
  },
  {
    path: `${BackupRoutesEnum.Reports}/:reportId`,
    element: <BackupReportContainer />,
  },
  {
    path: `${BackupRoutesEnum.Reports}/empty`,
    element: <BackupReportEmptyContainer />,
  },
];
