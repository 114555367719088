import { Modal, Button } from 'antd';
import classNames from 'classnames';

import { useCancelationPlan } from 'src/context/billing';
import { TextBlock } from 'src/components/Misc';

import {
  CancelationFeedbackContainer,
  ReviewChangesContainer,
  ExclusiveOfferContainer,
  AcceptedOfferContainer,
  PreCancelationContainer,
  OfferAppliedContainer,
  OtherOfferContainer,
} from './Steps';

const cancelationStepMap: {
  [key: string]: {
    modalWidth?: number;
    textBlock?: { title: string; text: string };
    component: React.FunctionComponent<{ onBack: () => void }>;
  };
} = {
  feedback: {
    textBlock: {
      title: 'Sorry to hear you need to cancel...',
      text: 'Before you go, can you please share your reason for canceling your subscription? Your feedback will help us to improve our service.',
    },
    component: CancelationFeedbackContainer,
  },
  'review-changes': {
    textBlock: {
      title: 'Review your account changes',
      text: "Canceling means you'll move over to a free account, which affects how your account works. After 30 days, you'll lose the benefits of your Disaster Recovery Solutions.",
    },
    component: ReviewChangesContainer,
  },
  'exclusive-offer': {
    modalWidth: 760,
    component: ExclusiveOfferContainer,
  },
  'offer-confirmation': {
    component: AcceptedOfferContainer,
  },
  'pre-cancelation': {
    modalWidth: 760,
    textBlock: {
      title: 'You are about to cancel your plan',
      text: 'Before you can proceed, please make sure the account cancelation requirements below are met.',
    },
    component: PreCancelationContainer,
  },
  'cancelation-finished': {
    modalWidth: 470,
    textBlock: {
      title: 'Your cancelation has been scheduled',
      text: 'The changes to your account have been saved and will be executed based on the terms of your account.\n\nYou still have access to all the features of your current plan until then. You can resubscribe or switch to another plan at any time.',
    },
    component: ({ onBack }) => (
      <div className="text-center">
        <Button type="primary" onClick={onBack}>
          Back to Billing Page
        </Button>
      </div>
    ),
  },
  'offer-applied': {
    component: OfferAppliedContainer,
    modalWidth: 440,
  },
  'other-offer': {
    modalWidth: 760,
    component: OtherOfferContainer,
  },
};

type Props = {
  refetch: () => void;
};

const BillingCancelationPlanContainer = ({ refetch }: Props) => {
  const {
    ui: { step, setInitialData },
  } = useCancelationPlan();

  const activeStepData = step && cancelationStepMap[step as (typeof step)[number]];
  const textBlockClasses = classNames('mb-5', { 'text-center': step === 'cancelation-finished' });
  const ActiveComponent = activeStepData?.component;

  const onBack = async () => {
    setInitialData();
    setTimeout(() => refetch(), 50);
  };

  return (
    <Modal
      open={Boolean(step)}
      onCancel={setInitialData}
      footer={null}
      className="ck-cancel-plan__modal"
      width={activeStepData?.modalWidth ?? 550}
    >
      {activeStepData?.textBlock && (
        <TextBlock
          title={activeStepData.textBlock.title}
          text={activeStepData.textBlock.text}
          className={textBlockClasses}
        />
      )}

      {ActiveComponent && <ActiveComponent onBack={onBack} />}
    </Modal>
  );
};

export default BillingCancelationPlanContainer;
