import { Fragment, useState } from 'react';

import { TextBlock } from 'src/components/Misc';
import { CustomCheckboxWrapper } from 'src/components/Form';
import env from 'env';

import ThirdPartyModal from '../../Modal/ThirdParty/ThirdPartyModal';
import styles from '../../Escrow.module.scss';

type Props = {
  isExternal?: boolean;
  onChange: (value: Record<string, boolean>) => void;
};

const ExternalThirdPartySelect = ({ isExternal = false, onChange }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => setIsModalOpen(false);
  const onOpen = () => setIsModalOpen(true);
  const handleSubmit = async () => await onChange({ thirdPartyAgreement: !isExternal });

  return (
    <Fragment>
      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="External Third Party Escrow Agreement"
          titleColor="dark-blue"
          as="h4"
          text="Select this option to disable the escrow agreement terms provided by the platform, and to use an external document as the escrow agreement."
        />
        <div className={`${styles['external-block']} ${styles['mb--40']}`}>
          <CustomCheckboxWrapper value={isExternal} selected={isExternal} onChange={onOpen}>
            <div className={styles['external-block-title']}>Use external third party escrow agreement</div>
            <p>
              Email the escrow agreement in <strong>.docx</strong> format that you would like to use to{' '}
              <a href={`mailto: ${env.CODEKEEPER_SERVICE_EMAIL}`}>{env.CODEKEEPER_SERVICE_EMAIL}</a>
            </p>
          </CustomCheckboxWrapper>
        </div>
      </div>

      <ThirdPartyModal isExternal={isExternal} isOpen={isModalOpen} onClose={onClose} onSubmit={handleSubmit} />
    </Fragment>
  );
};

export default ExternalThirdPartySelect;
