import { Button, ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { message } from 'src/components/Misc';
import { ICustodianProfile, useCreateCustodianProfileMutation } from 'src/graphql/schema';
import { addCustodianProfileCache } from 'src/graphql/client/cache';

type Props = {
  btnText?: string;
  size?: ButtonProps['size'];
};

const CreateCustodianProfileContainer = ({ btnText = '+ New Profile', size }: Props) => {
  const [createCustodianProfile, { loading }] = useCreateCustodianProfileMutation();
  const navigate = useNavigate();

  const onCreate = async () => {
    try {
      const { data } = await createCustodianProfile();

      if (data?.createCustodianProfile?.success) {
        addCustodianProfileCache(data.createCustodianProfile.custodianProfile as ICustodianProfile);
        navigate(`/custodian-profiles/${data.createCustodianProfile.custodianProfile?.id}`);
      }
    } catch (error) {
      message.error('Failed to create custodian profile');
    }
  };

  return (
    <Button size={size} type="primary" onClick={onCreate} loading={loading}>
      {btnText}
    </Button>
  );
};

export default CreateCustodianProfileContainer;
