import { Button, Result } from 'antd';
import { useNavigate, useRouteError } from 'react-router-dom';
import { BugTwoTone } from '@ant-design/icons';

import styles from './ErrorBoundary.module.scss';

const ErrorBoundary = () => {
  const error = useRouteError() as Error;
  const navigate = useNavigate();

  const handleGoHome = () => navigate('/');

  return (
    <div className={styles.block}>
      <Result
        icon={<BugTwoTone twoToneColor="#EE5E5E" />}
        title="There are some problems with your action."
        subTitle={error?.message}
        extra={
          <Button type="primary" onClick={handleGoHome} size="large" ghost>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default ErrorBoundary;
