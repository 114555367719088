import { Spin } from 'antd';
import classNames from 'classnames';

import type { SpinSize } from 'antd/es/spin';

export default function Loader({ size = 'default', className }: { size?: SpinSize; className?: string }) {
  const loaderClasses = classNames('d-flex justify-content-center align-items-center h-100', className);

  return (
    <div className={loaderClasses} role="alert">
      <Spin size={size} />
    </div>
  );
}
