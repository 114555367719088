import { Button } from 'antd';

import { message } from 'src/components/Misc';
import { useCustodianProfile } from 'src/context/custodian';
import { useUpdateCustodianAssetsMutation, CustodianAssetOperationalStatusEnum } from 'src/graphql/schema';

type Props = {
  submitText?: string;
  successCallback?: () => void;
};

const UpdateCustodianAssetsContainer = ({ submitText = 'Save & Next', successCallback }: Props) => {
  const {
    custodianProfile,
    refetch,
    ui: {
      assets: { selectedAssets, isUpdateEnabled },
    },
  } = useCustodianProfile();
  const [updateCustodianAssets, { loading }] = useUpdateCustodianAssetsMutation();

  const onSave = async () => {
    const isEmpty = !selectedAssets || Object.values(selectedAssets).every((assets) => !assets.length);

    if (isEmpty) return message.warning('One asset must be selected');

    const updateAllAssets = Object.entries(selectedAssets).map(([integrationId, assets]) => {
      const assetsInput = assets.map(({ id: oauthRepositoryId, status: operationalStatus }) => ({
        oauthRepositoryId,
        operationalStatus: operationalStatus as CustodianAssetOperationalStatusEnum,
      }));

      return updateCustodianAssets({
        variables: {
          custodianProfileId: custodianProfile.id,
          integrationId,
          assetsInput,
        },
      });
    });

    try {
      await Promise.all(updateAllAssets);
      await refetch();
      successCallback?.();
    } catch (error) {
      message.error('Failed to update custodian assets');
    }
  };

  return (
    <Button type="primary" disabled={!isUpdateEnabled} onClick={onSave} loading={loading}>
      {submitText}
    </Button>
  );
};

export default UpdateCustodianAssetsContainer;
