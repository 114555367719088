import {
  gracePeriodDictionary,
  disruptedNotificationsDictionary,
} from 'src/components/Escrow/Notifications/escrowNotifications.enum';

import type { INotificationsChangesPayload } from '../model/types';

export const getDisruptedChanges = (payload: INotificationsChangesPayload) => {
  const disruptedSuggestedChangesItems: {
    [key in 'beneficiary' | 'depositor']: {
      label: string;
      value: string;
    }[];
  } = {
    beneficiary: [],
    depositor: [],
  };

  if (payload?.['beneficiary_disruption_notification']) {
    disruptedSuggestedChangesItems.beneficiary.push({
      label: 'Beneficiary Disrupted Connection Notifications to',
      value: disruptedNotificationsDictionary[payload['beneficiary_disruption_notification']],
    });
  }

  if (payload?.['beneficiary_disruption_notification_grace_period']) {
    disruptedSuggestedChangesItems.beneficiary.push({
      label: 'Disrupted Connection Notification grace period to',
      value: gracePeriodDictionary[payload['beneficiary_disruption_notification_grace_period']],
    });
  }

  if (payload?.['depositor_disruption_notification']) {
    disruptedSuggestedChangesItems.depositor.push({
      label: 'Depositor Disrupted Connection Notifications to',
      value: disruptedNotificationsDictionary[payload['depositor_disruption_notification']],
    });
  }

  if (payload?.['depositor_disruption_notification_grace_period']) {
    disruptedSuggestedChangesItems.depositor.push({
      label: 'Disrupted Connection Notification grace period to',
      value: gracePeriodDictionary[payload['depositor_disruption_notification_grace_period']],
    });
  }

  return disruptedSuggestedChangesItems;
};
