import styles from './BackupSummaryCard.module.scss';

type Props = {
  subHeading?: string;
  heading: string;
  action?: React.ReactNode;
};

const BackupSummaryCard = ({ subHeading, heading, action }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles['main-col']}>
          {subHeading && <div className={styles.subheading}>{subHeading}</div>}
          <div className={styles.heading}>{heading}</div>
        </div>

        {action && <div className={styles['action-col']}>{action}</div>}
      </div>
    </div>
  );
};

export default BackupSummaryCard;
