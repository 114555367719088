import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconDiscount = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M15.216 2.347a1.875 1.875 0 0 0-2.432 0L11.02 3.85a3.68 3.68 0 0 1-2.09.866L6.62 4.9a1.875 1.875 0 0 0-1.718 1.72H4.9l-.184 2.31a3.67 3.67 0 0 1-.865 2.09l-1.503 1.763a1.876 1.876 0 0 0 0 2.433l1.502 1.763c.501.588.805 1.32.867 2.09l.185 2.311A1.874 1.874 0 0 0 6.62 23.1v-.002l2.31.185a3.67 3.67 0 0 1 2.09.866l1.764 1.503c.7.597 1.732.597 2.432 0l1.763-1.504a3.68 3.68 0 0 1 2.091-.866l2.31-.184.143 1.794-2.31.185a1.87 1.87 0 0 0-1.066.441l-1.763 1.503a3.676 3.676 0 0 1-4.767 0L9.853 25.52a1.88 1.88 0 0 0-1.066-.443l-2.31-.185v.002a3.674 3.674 0 0 1-3.37-3.37l-.184-2.311a1.88 1.88 0 0 0-.443-1.066L.978 16.383a3.676 3.676 0 0 1 0-4.767L2.48 9.853c.255-.3.409-.672.44-1.067l.185-2.31h.002a3.674 3.674 0 0 1 3.368-3.37l2.31-.184a1.88 1.88 0 0 0 1.067-.442L11.617.977a3.676 3.676 0 0 1 4.767 0l1.764 1.503c.299.255.671.41 1.065.44l2.31.185a3.675 3.675 0 0 1 3.37 3.371l.184 2.31c.032.392.187.765.443 1.066l1.502 1.764a3.674 3.674 0 0 1 0 4.765l-1.501 1.765c-.257.301-.412.674-.443 1.067l-.185 2.31-1.794-.144.184-2.31a3.67 3.67 0 0 1 .867-2.09l1.502-1.764c.597-.7.597-1.73 0-2.431L24.15 11.02a3.68 3.68 0 0 1-.867-2.091l-.184-2.31a1.875 1.875 0 0 0-1.72-1.72l-2.309-.184a3.67 3.67 0 0 1-2.09-.865z"
      />
      <path
        fill="currentColor"
        d="M8.624 8.624a2.683 2.683 0 1 1 3.795 3.794 2.683 2.683 0 0 1-3.795-3.794M8.478 18.508l10.029-10.03 1.273 1.273-10.03 10.03zM15.58 15.58a2.683 2.683 0 1 1 3.795 3.796 2.683 2.683 0 0 1-3.794-3.795M21.38 23.1a1.874 1.874 0 0 0 1.72-1.72l1.794.143a3.674 3.674 0 0 1-3.37 3.37z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconDiscount);
export default Memo;
