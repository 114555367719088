import Amazon7 from 'src/assets/images/integrations/amazon7.svg';

import Step from './Step';

export default function BeanstalkInfoContent() {
  return (
    <>
      <Step icon={Amazon7} stepNumber={1}>
        Please enter your Beanstalk App credentials.
      </Step>
    </>
  );
}
