import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useEscrow } from 'src/context/escrow';
import { useSuggestedChanges } from 'src/hooks/use-client-suggested-changes';
import {
  useCreateSuggestedChangeEscrowMutation,
  useClientChangesRequestedQuery,
  SuggestedChangeChangeTypeEnum,
  SuggestedChangeStatusEnum,
  EscrowStatusEnum,
  EscrowPartyTypeEnum,
  ISuggestedChangesQuery,
  IClientChangesRequestedQuery,
} from 'src/graphql/schema';
import { writeNewSuggestChanges, suggestChangesVar } from 'src/graphql/client/cache';

import { createRequiredContext } from '../createRequiredContext';

const [useDepositsSuggestChanges, DepositsSuggestChangesProvider] = createRequiredContext<{
  deletedIdAssets: string[];
  suggestedChanges: IClientChangesRequestedQuery['clientChangesRequested']['parties'];
  isLoading: boolean;
  sendSuggestedChanges: () => Promise<boolean>;
  onRemoveAsset: (id: string) => void;
  onUndoRemovedAsset: (id: string) => void;
}>();

const DepositsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [deletedIdAssets, setDeletedIdAssets] = useState<string[]>([]);

  const { escrowId } = useParams();
  const { escrow } = useEscrow();

  const [createSuggestedChanges] = useCreateSuggestedChangeEscrowMutation();
  const { data: changesRequestedData } = useClientChangesRequestedQuery();

  const onCompletedSuggestedChanges = useCallback((suggestedChanges: ISuggestedChangesQuery['suggestedChanges']) => {
    const availableChanges = suggestedChanges.nodes.filter(
      ({ partyStatus }) =>
        partyStatus === SuggestedChangeStatusEnum.Pending || partyStatus === SuggestedChangeStatusEnum.Accepted,
    );

    if (availableChanges.length) {
      writeNewSuggestChanges(suggestChangesVar)('deposits', {
        id: availableChanges[0].id,
        creator: availableChanges[0].creatorType,
        status: availableChanges[0].partyStatus as SuggestedChangeStatusEnum,
        payload: availableChanges[0].payload,
        receiver: availableChanges[0].receiverType as EscrowPartyTypeEnum,
      });
    }
  }, []);

  const { loading } = useSuggestedChanges({
    escrowId: String(escrowId),
    changeType: SuggestedChangeChangeTypeEnum.DepositSuggestedChange,
    skip: escrow.status !== EscrowStatusEnum.Active,
    onCompleted: onCompletedSuggestedChanges,
  });

  const sendSuggestedChanges = useCallback(async () => {
    try {
      const { data } = await createSuggestedChanges({
        variables: {
          escrowId: String(escrowId),
          suggestedChangeParams: {
            depositSuggestedChange: {
              deletedAssets: deletedIdAssets.map((id) => ({ id })),
            },
          },
        },
      });

      if (data?.createSuggestedChangeEscrow?.errors?.length) {
        throw Error(data?.createSuggestedChangeEscrow?.errors[0]);
      } else {
        writeNewSuggestChanges(suggestChangesVar)('deposits', {
          id: data?.createSuggestedChangeEscrow?.suggestedChange?.id,
          creator: data?.createSuggestedChangeEscrow?.suggestedChange?.creatorType,
          status: data?.createSuggestedChangeEscrow?.suggestedChange?.partyStatus as SuggestedChangeStatusEnum,
          payload: data?.createSuggestedChangeEscrow?.suggestedChange?.payload,
          receiver: data?.createSuggestedChangeEscrow?.suggestedChange?.receiverType as EscrowPartyTypeEnum,
        });
        setDeletedIdAssets([]);
        return true;
      }
    } catch (e: unknown) {
      throw Error(String(e));
    }
  }, [deletedIdAssets]);

  const onRemoveAsset = useCallback((id: string) => {
    setDeletedIdAssets((ids) => [...ids, id]);
  }, []);

  const onUndoRemovedAsset = useCallback((id: string) => {
    setDeletedIdAssets((ids) => ids.filter((assetId) => assetId !== id));
  }, []);

  const providerValue = {
    deletedIdAssets,
    suggestedChanges: changesRequestedData?.clientChangesRequested.deposits,
    isLoading: loading,
    sendSuggestedChanges,
    onRemoveAsset,
    onUndoRemovedAsset,
  };

  return <DepositsSuggestChangesProvider value={providerValue}>{children}</DepositsSuggestChangesProvider>;
};

export { useDepositsSuggestChanges, DepositsContextProvider };
