import { useIsUpdateableBillingQuery, useAwaitingLegacyReposCountQuery } from 'src/graphql/schema';
import { Sidebar } from 'src/components/Layout';

const SidebarContainer: React.FunctionComponent = () => {
  const { data: legacyReposCount, loading: legacyReposLoading } = useAwaitingLegacyReposCountQuery();
  const { data: updateableBillingData, loading: companyLoading } = useIsUpdateableBillingQuery();

  const nodesCount = legacyReposCount?.legacyRepos.count ?? 0;
  const withBilling = Boolean(updateableBillingData?.company.updateableBilling);

  return (
    <Sidebar migrationsCount={nodesCount} withBilling={withBilling} isLoading={legacyReposLoading || companyLoading} />
  );
};

export default SidebarContainer;
