import { CheckCircleFilled } from '@ant-design/icons';
import { capitalize, get, pick, isEmpty } from 'lodash';

import { TextBlock, ModalConfirmation } from 'src/components/Misc';
import { CardWithIconList } from 'src/components/Card';
import {
  escrowLegalContextDictionary,
  escrowDepositTypeDictionary,
  escrowReleaseDictionary,
  escrowJurisdictionDictionary,
  escrowLiabilityDictionary,
} from 'src/components/Escrow/Agreement';
import { useEscrow } from 'src/context/escrow';
import { useAgreementSuggestChanges } from 'src/context/suggest-changes';
import { SuggestChangesList } from 'src/components/SuggestChanges';
import styles from 'src/components/Escrow/Escrow.module.scss';

const conditionKeys = ['release_insolvency', 'release_maintenance', 'release_bankruptcy', 'release_custom'] as const;

const setValuesToString = (values: string[]) => {
  let lastValue = '';
  const newValues = values.slice();

  if (values.length > 1) lastValue = ' and ' + newValues.splice(-1, 1);

  return newValues.join(', ') + lastValue;
};

const ViewAgreementChangesContainer = () => {
  const { escrow, ui, setConfirmModalOpen } = useEscrow();
  const { suggestedChanges } = useAgreementSuggestChanges();

  const agreement = escrow.agreement;
  const legalContentItems =
    agreement?.legalContext.map((item) => escrowLegalContextDictionary[item]).filter(Boolean) || [];
  const depositItems = agreement?.depositType.map((item) => escrowDepositTypeDictionary[item]).filter(Boolean) || [];
  const releaseConditions: { [key: string]: boolean } = {
    releaseBankruptcy: agreement?.releaseBankruptcy || false,
    releaseInsolvency: agreement?.releaseInsolvency || false,
    releaseMaintenance: agreement?.releaseMaintenance || false,
    releaseCustom: agreement?.releaseCustom || false,
  };
  const releaseConditionsItems = Object.keys(releaseConditions)
    .filter((key) => Boolean(releaseConditions[key]))
    .map((key) => escrowReleaseDictionary[key]);
  const jurisdictionOption =
    agreement?.jurisdiction &&
    escrowJurisdictionDictionary[agreement.jurisdiction as keyof typeof escrowJurisdictionDictionary];
  const liabilityOption =
    agreement?.liability && escrowLiabilityDictionary[agreement.liability as keyof typeof escrowLiabilityDictionary];

  // Suggest Changes Helpers
  const legalContextChanges = get(suggestedChanges, 'payload.legal_context', []).map(
    (change: string) => capitalize(change) + ' agreement',
  );
  const depositTypesChanges = get(suggestedChanges, 'payload.deposit_type', []).map((change: string) =>
    change in escrowDepositTypeDictionary ? escrowDepositTypeDictionary[change].heading : capitalize(change),
  );
  const jurisdictionChange = get(suggestedChanges, 'payload.jurisdiction', '');
  const liabilityChange = get(suggestedChanges, 'payload.liability', '');
  const currentReleaseConditions = (() => {
    const values: string[] = [];

    if (agreement?.releaseBankruptcy) values.push('bankruptcy');
    if (agreement?.releaseCustom) values.push('custom');
    if (agreement?.releaseInsolvency) values.push('insolvency');
    if (agreement?.releaseMaintenance) values.push('maintenance');

    return values;
  })();

  const releaseConditionsChangesPayload: Partial<Record<(typeof conditionKeys)[number], string>> = pick(
    get(suggestedChanges, 'payload', {}),
    [...conditionKeys],
  );
  const newCurrentConditions = currentReleaseConditions
    .map((item) => `release_${item}`)
    .filter((item) => {
      if (item in releaseConditionsChangesPayload) {
        if (releaseConditionsChangesPayload[item as keyof typeof releaseConditionsChangesPayload]) return true;
      } else return true;
    });
  const releaseConditionsChanges =
    (!isEmpty(releaseConditionsChangesPayload) &&
      Object.keys(releaseConditionsChangesPayload).filter(
        (change) =>
          conditionKeys.includes(change as (typeof conditionKeys)[number]) &&
          Boolean(releaseConditionsChangesPayload[change as keyof typeof releaseConditionsChangesPayload]),
      )) ||
    [];
  const releaseChanges =
    !isEmpty(releaseConditionsChangesPayload) &&
    [...newCurrentConditions, ...releaseConditionsChanges]
      .map((change) => capitalize(change.replace('_', ' ')))
      .filter(Boolean);
  const otherContextChanges = get(suggestedChanges, 'payload.legal_context_other', '');
  const otherDepositChanges = get(suggestedChanges, 'payload.deposit_type_other', '');
  const customConditionsChanges = get(suggestedChanges, 'payload.release_custom_conditions', '');

  return (
    <div className={styles.block}>
      <TextBlock
        className={styles['mb--30']}
        title="Escrow Agreement Details"
        text="These settings will configure the escrow agreement, which you can review and edit in the next steps."
      />

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Legal Context"
          titleColor="dark-blue"
          as="h4"
          text="The legal context of the agreement that discusses this escrow arrangement."
        />
        <div className={styles['container--990']}>
          {suggestedChanges && !isEmpty(legalContextChanges) && (
            <SuggestChangesList
              status={suggestedChanges.status as 'accepted' | 'pending'}
              currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
              creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
              receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
              items={[
                {
                  label: 'Legal Context',
                  value: setValuesToString(legalContextChanges),
                },
              ]}
            />
          )}
          <CardWithIconList
            className={`${styles['card-list__row']} ${styles['mb--40']}`}
            col={5}
            items={legalContentItems}
          />

          {(agreement?.legalContextOther || (suggestedChanges && otherContextChanges)) && (
            <div className={`${styles['other-text__block']} ${styles['mb--40']}`}>
              <strong>Other agreement type:</strong>
              {suggestedChanges && otherContextChanges && (
                <SuggestChangesList
                  status={suggestedChanges.status as 'accepted' | 'pending'}
                  currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
                  creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
                  receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
                  items={[
                    {
                      label: 'Other agreement type',
                      value: otherContextChanges,
                    },
                  ]}
                />
              )}
              {agreement?.legalContextOther && <p>{agreement.legalContextOther}</p>}
            </div>
          )}
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Deposit Type"
          titleColor="dark-blue"
          as="h4"
          text="The materials that will be deposited into escrow."
        />

        <div className={styles['container--990']}>
          {suggestedChanges && !isEmpty(depositTypesChanges) && (
            <SuggestChangesList
              status={suggestedChanges.status as 'accepted' | 'pending'}
              currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
              creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
              receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
              items={[
                {
                  label: 'Deposit Type',
                  value: setValuesToString(depositTypesChanges),
                },
              ]}
            />
          )}
          <CardWithIconList
            className={`${styles['card-list__row']} ${styles['mb--40']}`}
            col={5}
            items={depositItems}
          />

          {(agreement?.depositTypeOther || (suggestedChanges && otherDepositChanges)) && (
            <div className={`${styles['other-text__block']} ${styles['mb--40']}`}>
              <strong>Other deposit type:</strong>
              {suggestedChanges && otherDepositChanges && (
                <SuggestChangesList
                  status={suggestedChanges.status as 'accepted' | 'pending'}
                  currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
                  creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
                  receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
                  items={[
                    {
                      label: 'Other deposit type',
                      value: otherDepositChanges,
                    },
                  ]}
                />
              )}

              {agreement?.depositTypeOther && <p>{agreement.depositTypeOther}</p>}
            </div>
          )}
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Release Conditions"
          titleColor="dark-blue"
          as="h4"
          text="The selected conditions under which the release should take place."
        />
        <div className={styles['container--990']}>
          {suggestedChanges && releaseChanges && (
            <SuggestChangesList
              status={suggestedChanges.status as 'accepted' | 'pending'}
              currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
              creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
              receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
              items={[
                {
                  label: 'Release Conditions',
                  value: setValuesToString(releaseChanges),
                },
              ]}
            />
          )}
          <CardWithIconList
            className={`${styles['card-list__row']} ${styles['mb--40']}`}
            col={5}
            items={releaseConditionsItems}
          />

          {(agreement?.releaseCustomConditions || (suggestedChanges && customConditionsChanges)) && (
            <div className={`${styles['other-text__block']} ${styles['mb--40']}`}>
              <strong>Custom Conditions:</strong>
              {suggestedChanges && customConditionsChanges && (
                <SuggestChangesList
                  status={suggestedChanges.status as 'accepted' | 'pending'}
                  currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
                  creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
                  receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
                  items={[
                    {
                      label: 'Custom Conditions',
                      value: customConditionsChanges,
                    },
                  ]}
                />
              )}
              <p>{agreement?.releaseCustomConditions}</p>
            </div>
          )}
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Jurisdiction"
          titleColor="dark-blue"
          as="h4"
          text="The legal jurisdiction that will be applicable to the escrow agreement."
        />
        {suggestedChanges && jurisdictionChange && (
          <SuggestChangesList
            className={styles['container--990']}
            status={suggestedChanges.status as 'accepted' | 'pending'}
            currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
            creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
            receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
            items={[
              {
                label: 'Jurisdiction',
                value: escrowJurisdictionDictionary[jurisdictionChange],
              },
            ]}
          />
        )}
        <div className={`${styles['selected-option']} ${styles['mb--40']}`}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{jurisdictionOption}</strong>
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Escrow Agreement Liability SLA"
          titleColor="dark-blue"
          as="h4"
          text="The selected liability and SLA applied to this escrow agreement."
        />
        {suggestedChanges && liabilityChange && (
          <SuggestChangesList
            className={styles['container--990']}
            status={suggestedChanges.status as 'accepted' | 'pending'}
            currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
            creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
            receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
            items={[
              {
                label: 'Liability',
                value: escrowLiabilityDictionary[liabilityChange],
              },
            ]}
          />
        )}
        <div className={`${styles['selected-option']} ${styles['mb--40']}`}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{liabilityOption}</strong>
        </div>
      </div>

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="New updates has been sent for review!"
        text="Codekeeper will now review these changes and apply them where acceptable. The updated escrow will be sent for your review."
        onClose={() => setConfirmModalOpen(false)}
      />
    </div>
  );
};

export default ViewAgreementChangesContainer;
