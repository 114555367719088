import React from 'react';
import classNames from 'classnames';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import styles from './JourneyStatusIndicator.module.scss';

export const statusIndicatorEnum = ['active', 'completed', 'canceled', 'pending-cancellation'] as const;

export interface IJourneyStatusIndicatorItem {
  id: string;
  label: string;
  isHidden?: boolean;
  status?: (typeof statusIndicatorEnum)[number];
}

const JourneyStatusIndicatorItem: React.FunctionComponent<IJourneyStatusIndicatorItem> = ({
  label,
  isHidden = false,
  status,
}) => {
  const isCompleted = status === 'completed';
  const isCanceled = status === 'canceled' || status === 'pending-cancellation';

  const itemClasses = classNames(styles.item, {
    [styles.active]: status === 'active',
    [styles.completed]: isCompleted,
    [styles.canceled]: isCanceled,
  });
  const iconCancelClasses = classNames(styles['icon-cancel'], {
    [styles.yellow]: status === 'pending-cancellation',
    [styles.red]: status === 'canceled',
  });

  if (isHidden) return null;

  return (
    <li className={itemClasses}>
      <span className={styles.dot}>
        {isCompleted && <CheckCircleFilled className={styles['icon-check']} />}
        {isCanceled && <CloseCircleFilled className={iconCancelClasses} />}
        {!isCompleted && !isCanceled && <span className={styles['dot-inner']} />}
      </span>
      <strong className="ck-text--ellipsis">{label}</strong>
    </li>
  );
};

export default JourneyStatusIndicatorItem;
