import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSearch = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M2.8 6.666a3.867 3.867 0 1 1 7.733 0 3.867 3.867 0 0 1-7.733 0Zm3.867-5.467a5.467 5.467 0 1 0 3.258 9.857l3.51 3.509a.8.8 0 0 0 1.13-1.131l-3.508-3.51A5.467 5.467 0 0 0 6.667 1.2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSearch);
export default Memo;
