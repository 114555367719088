import BackupResultItemCard from './BackupResultItemCard';
import BackupResultItemContent, { IBackupResultItemData } from './BackupResultItemContent';

type Props = {
  data: IBackupResultItemData;
  className?: string;
};

const BackupResultItem = ({ data, className }: Props) => {
  return (
    <BackupResultItemCard className={className}>
      <BackupResultItemContent data={data} />
    </BackupResultItemCard>
  );
};

export default BackupResultItem;
