import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import type { CollapseProps } from 'antd/lib/collapse/Collapse';

import CancelCollapseHeader, { Props as ICancelCollapseHeader } from './CancelCollapseHeader';
import CancelCollapseBody, { Props as ICancelCollapseBody } from './CancelCollapseBody';
import styles from './CancelCollapse.module.scss';

type Props = {
  header: ICancelCollapseHeader;
  body: ICancelCollapseBody;
  className?: string;
};

const ExpandIcon = ({ isActive }: { isActive?: boolean }) => (
  <DownOutlined
    className={classNames(styles['icon-arrow'], {
      [styles.active]: isActive,
    })}
  />
);

const CancelCollapse = ({ header, body, className }: Props) => {
  const items: CollapseProps['items'] = [
    {
      label: <CancelCollapseHeader {...header} />,
      children: <CancelCollapseBody {...body} />,
    },
  ];

  const collapseClasses = classNames(styles.container, className);

  return (
    <Collapse
      ghost
      collapsible="icon"
      items={items}
      expandIconPosition="end"
      expandIcon={ExpandIcon}
      rootClassName="ck-cancel-collapse"
      className={collapseClasses}
    />
  );
};

export default CancelCollapse;
