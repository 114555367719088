import { CustodianProfileStatusEnum } from './';

export const custodianLabelStatusMap = {
  [CustodianProfileStatusEnum.Draft]: {
    text: 'Draft',
    bgColor: 'orange',
  },
  [CustodianProfileStatusEnum.AccessSetup]: {
    text: 'Access Setup',
    bgColor: 'orange',
  },
  [CustodianProfileStatusEnum.Active]: {
    text: 'Active',
    bgColor: 'green',
  },
  [CustodianProfileStatusEnum.Terminated]: {
    text: 'Terminated',
    bgColor: 'red',
  },
  [CustodianProfileStatusEnum.TerminationRequested]: {
    text: 'Termination Requested',
    bgColor: 'red',
  },
} as const;
