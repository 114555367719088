import { Button, Form, Input, Select } from 'antd';

import { IStaticIntegration, regions } from 'src/shared/entities';

import styles from './styles.module.scss';

const { Option } = Select;

export type ICustomIntegrationValues = {
  accountName: string;
  accountUrl?: string;
  username: string;
  password: string;
  region?: string;
};

type Props = {
  integration: IStaticIntegration;
  initialValues?: Partial<ICustomIntegrationValues>;
  submitButtonText: string;
  loading: boolean;
  onFinish: (values: ICustomIntegrationValues) => Promise<void>;
};

export default function CredentialsForm({ integration, initialValues, loading, submitButtonText, onFinish }: Props) {
  return (
    <Form layout="vertical" className={styles.CredentialsForm} onFinish={onFinish} initialValues={initialValues}>
      <Form.Item
        label="Name"
        name="accountName"
        rules={[
          {
            required: true,
            message: `Add the name`,
          },
        ]}
        className={styles.customFormItem}
      >
        <Input />
      </Form.Item>

      {integration.provider === 'aws' && (
        <Form.Item
          label="Region"
          name="region"
          rules={[
            {
              required: true,
              message: `Add the Region`,
            },
          ]}
          className={styles.customFormItem}
        >
          <Select placeholder="Select a region" className={styles.customSelect}>
            {regions.map((item) => (
              <Option key={item.region} value={item.region}>
                {item.region}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label={integration.keyLabel}
        name="username"
        rules={[
          {
            required: true,
            message: `Add the ${integration.keyLabel}`,
          },
        ]}
        className={styles.customFormItem}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={integration.passwordLabel}
        name="password"
        rules={[
          {
            required: true,
            message: `Add the ${integration.passwordLabel}`,
          },
        ]}
        className={styles.customFormItem}
      >
        <Input.Password />
      </Form.Item>

      {integration.accountUrlRequired && (
        <Form.Item
          rules={[
            {
              required: true,
              message: `Add the ${integration.name} URL`,
            },
          ]}
          label={`${integration.name} URL`}
          name="accountUrl"
          className={styles.customFormItem}
        >
          <Input />
        </Form.Item>
      )}

      <Button type="primary" htmlType="submit" loading={loading} className={styles.updateBtn}>
        {submitButtonText}
      </Button>
    </Form>
  );
}
