import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageHiddenDeposits = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 70 80"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#F5F5F7"
      fillOpacity={0.8}
      d="M34.765 80c19.2 0 34.765-2.925 34.765-6.533 0-3.608-15.565-6.533-34.765-6.533C15.565 66.934 0 69.859 0 73.467 0 77.075 15.565 80 34.765 80Z"
    />
    <path
      fill="#AEB8C2"
      fillRule="evenodd"
      d="M62.576 54.274 50.308 39.089c-.589-.715-1.449-1.148-2.355-1.148H21.575c-.905 0-1.765.433-2.354 1.148L6.953 54.274v7.933h55.624v-7.933Z"
      clipRule="evenodd"
    />
    <path
      fill="#F5F5F7"
      fillRule="evenodd"
      d="M17.347 18.343h34.835c.544 0 1.065.217 1.45.604s.6.912.6 1.459v48.138c0 .547-.215 1.072-.6 1.459a2.045 2.045 0 0 1-1.45.604H17.347a2.045 2.045 0 0 1-1.45-.604 2.069 2.069 0 0 1-.601-1.459V20.406c0-.547.216-1.072.6-1.459a2.045 2.045 0 0 1 1.451-.604Z"
      clipRule="evenodd"
    />
    <path
      fill="#DCE0E6"
      fillRule="evenodd"
      d="M21.885 23.476h25.76c.272 0 .533.108.725.302.193.193.3.456.3.729v12.87c0 .274-.107.536-.3.73a1.022 1.022 0 0 1-.725.302h-25.76c-.272 0-.533-.109-.725-.302a1.034 1.034 0 0 1-.3-.73v-12.87c0-.274.107-.536.3-.73.192-.193.453-.301.725-.301Zm.134 20.532h25.492c.307 0 .603.123.82.342a1.17 1.17 0 0 1 0 1.65 1.157 1.157 0 0 1-.82.341H22.019c-.307 0-.603-.123-.82-.342a1.17 1.17 0 0 1 .82-1.99Zm0 6.066h25.492c.308 0 .603.123.82.342a1.17 1.17 0 0 1 0 1.65 1.157 1.157 0 0 1-.82.342H22.019c-.308 0-.603-.123-.82-.342a1.17 1.17 0 0 1 0-1.65c.217-.219.512-.342.82-.342ZM62.464 72.51c-.398 1.584-1.794 2.764-3.454 2.764H10.52c-1.66 0-3.056-1.18-3.453-2.764a3.79 3.79 0 0 1-.114-.923V54.275h13.495c1.491 0 2.693 1.262 2.693 2.795v.02c0 1.533 1.215 2.77 2.706 2.77h17.837c1.49 0 2.706-1.248 2.706-2.781v-.006c0-1.533 1.2-2.799 2.692-2.799h13.495v17.312c0 .319-.04.627-.113.923Z"
      clipRule="evenodd"
    />
    <path
      fill="#FAAD14"
      fillRule="evenodd"
      d="m45.214 28.656-4.854 2.281a.596.596 0 0 1-.392.043.676.676 0 0 1-.35-.216.91.91 0 0 1-.202-.409 1.035 1.035 0 0 1 .008-.476l1.377-5.343a15.188 15.188 0 0 1-2.92-8.959C37.88 6.974 45.07 0 53.94 0c8.868 0 16.06 6.974 16.06 15.577s-7.19 15.577-16.06 15.577c-3.218 0-6.214-.917-8.727-2.498Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M47.584 9.263a.72.72 0 0 1 1.032 0l11.68 11.91a.755.755 0 0 1 0 1.054.72.72 0 0 1-1.032 0l-2.057-2.098c-.974.484-2.08.825-3.267.825-2.27 0-4.26-1.248-5.628-2.404a15.938 15.938 0 0 1-2.09-2.146 8.785 8.785 0 0 1-.153-.197l-.01-.013-.002-.003-.001-.002.584-.446-.585-.445.001-.001.002-.002.004-.007.016-.021a7.593 7.593 0 0 1 .277-.35c.188-.227.462-.544.809-.903a14.655 14.655 0 0 1 2.2-1.883l-1.78-1.815a.755.755 0 0 1 0-1.053Zm-1.529 6.035a.756.756 0 0 0 0 .89l.585-.445-.585-.445Zm8.83 2.463 1.218 1.242c-.693.287-1.42.462-2.163.462-1.761 0-3.422-.985-4.696-2.062a14.46 14.46 0 0 1-1.66-1.66c.159-.188.368-.423.62-.684a12.91 12.91 0 0 1 2.215-1.852l1.545 1.575a2.263 2.263 0 0 0-.214.963c0 1.234.98 2.233 2.19 2.233.338 0 .658-.078.944-.218Zm-1.447-1.476-.027-.027.026.027Zm.502-4.264c-.214 0-.426.015-.637.042a.733.733 0 0 1-.817-.644.742.742 0 0 1 .632-.833c.269-.035.543-.054.822-.054 2.27 0 4.26 1.248 5.628 2.405a15.959 15.959 0 0 1 2.09 2.145 8.895 8.895 0 0 1 .154.198l.009.012.003.004v.001s.001.001-.584.446l.585.446-.001.001-.002.004-.008.01-.028.037a13.408 13.408 0 0 1-.485.593c-.33.38-.808.894-1.404 1.429a.72.72 0 0 1-1.03-.068.755.755 0 0 1 .066-1.05 14.632 14.632 0 0 0 1.364-1.402 14.47 14.47 0 0 0-1.66-1.66c-1.275-1.077-2.935-2.062-4.697-2.062Zm7.885 4.168a.757.757 0 0 0 0-.891l-.585.445.585.446Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageHiddenDeposits);
export default Memo;
