import { useState } from 'react';
import { Modal } from 'antd';

import './ThirdPartyModal.module.scss';

type Props = {
  isOpen: boolean;
  isExternal: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const ThirdPartyModal = ({ isOpen, isExternal, onClose, onSubmit }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await onSubmit();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      className="ck-third-party__modal"
      title={isExternal ? 'Use Codekeeper agreement' : 'External escrow agreement'}
      okText="Proceed"
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <p>
        {isExternal
          ? 'Choose to continue with our template agreement that can be tailored to your needs.'
          : 'Are you sure you want to use an external agreement for this escrow, instead of our template agreement that can be tailored to your needs?'}
      </p>
    </Modal>
  );
};

export default ThirdPartyModal;
