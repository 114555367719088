import { Form, Input, Button, message, Select, Col, Row } from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';

import { countries } from 'src/shared/entities';
import { useUpdateCompanyDetailsMutation, useCompanyQuery } from 'src/graphql/schema/index.ts';
import { CountrySelectorType } from 'src/components/Escrow/Contacts';

import { StepsEnum } from '../../InviteSteps.enum';

const countriesOptions: CountrySelectorType[] = countries.map((country) => ({
  label: country.name,
  value: country.name,
}));

const InvitationStrings = {
  beneficiary: 'Beneficiary invitation',
  depositor: 'Depositor invitation',
};

type FormValues = {
  name: string;
  street: string;
  number: string;
  city: string;
  zip: string;
  state: string;
  country: string;
};

export default function CompanyForm({ setStep }: { setStep: (step: StepsEnum) => void }) {
  const [updateCompany] = useUpdateCompanyDetailsMutation();
  const [isInvitation, setIsInvitation] = useState(false);
  const { data: company } = useCompanyQuery();

  const companyData = get(company, 'company', null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        name: companyData.companyName || '',
        street: companyData.street || '',
        number: companyData.streetNumber || '',
        city: companyData.city || '',
        zip: companyData.postalCode || '',
        state: companyData.region || '',
        country: companyData.country || '',
      });
      if (
        companyData.companyName === InvitationStrings.beneficiary ||
        companyData.companyName === InvitationStrings.depositor
      ) {
        setIsInvitation(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === InvitationStrings.beneficiary || value === InvitationStrings.depositor) {
      setIsInvitation(true);
    } else {
      setIsInvitation(false);
    }
  };

  const onFinish = (values: FormValues) => {
    updateCompany({
      variables: {
        company: {
          companyName: values.name,
          street: values.street,
          streetNumber: values.number,
          city: values.city,
          postalCode: values.zip,
          region: values.state,
          country: values.country,
        },
      },
    })
      .then((data) => {
        if (data?.data?.updateCompanyDetails?.success) {
          setStep('invite');
        } else {
          message.error(data?.data?.updateCompanyDetails?.errors?.[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };
  return (
    <>
      <h2 className="invite-screen__title mb-5">Tell us about your organization</h2>
      <Form
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        form={form}
        initialValues={{ invitation_status: 'completed' }}
      >
        <Form.Item
          label="Organization name"
          name="name"
          rules={[{ required: true, message: 'Please add the organization name!' }]}
        >
          <Input style={isInvitation ? { color: '#bfbfbf' } : {}} onChange={onChange} />
        </Form.Item>
        <div className="d-flex">
          <Form.Item
            name="street"
            label="Legal Address"
            className="w-100"
            rules={[{ required: true, message: 'Please add the street name!' }]}
          >
            <Input placeholder="Street Address" />
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item name="number" className="w-100">
            <Input placeholder="Number/Suite/Unit/Floor" />
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item name="city" className="w-100 mr-3" rules={[{ required: true, message: 'Please add the city!' }]}>
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item name="state" className="w-100">
            <Input placeholder="State/Province/Region" />
          </Form.Item>
        </div>

        <Row>
          <Col span={12} style={{ paddingRight: 8 }}>
            <Form.Item
              name="zip"
              className="w-100 mr-3"
              rules={[{ required: true, message: 'Please add the postal zip!' }]}
            >
              <Input placeholder="Zip/Postal code" className="mr-3 mb-4" />
            </Form.Item>
          </Col>
          <Col span={12} style={{ paddingLeft: 8 }}>
            <Form.Item
              name="country"
              className="w-100"
              rules={[{ required: true, message: 'Please select a country!' }]}
            >
              <Select
                placeholder="Country"
                className="invite-screen__select mb-4"
                options={countriesOptions}
                showSearch
                filterOption={(input, option) =>
                  ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Invitation status" name="invitation_status" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" className="invite-screen__btn -company" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
