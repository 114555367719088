import { Fragment, useState } from 'react';
import { Button } from 'antd';

import { ModalProceed, message } from 'src/components/Misc';
import { useAcceptEscrowTerminationMutation } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';

const ApproveTerminationActionContainer = () => {
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);

  const { escrow, refetch } = useEscrow();
  const [acceptEscrowTermination, { loading }] = useAcceptEscrowTerminationMutation();

  const onRequestTermination = async () => {
    try {
      const { data } = await acceptEscrowTermination({
        variables: { escrowTerminationId: escrow?.escrowTermination?.id || '' },
      });
      if (data?.acceptEscrowTermination?.success) {
        await refetch();
        setIsProceedModalOpen(false);
      } else {
        throw new Error(data?.acceptEscrowTermination?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message || 'Something went wrong');
      }
    }
  };

  return (
    <Fragment>
      <Button ghost danger onClick={() => setIsProceedModalOpen(true)}>
        Approve Termination
      </Button>

      <ModalProceed
        title="Are you sure you want to accept the escrow termination request?"
        text="If you approve, the agreed upon notice period will be triggered and all data will be removed."
        isOpen={isProceedModalOpen}
        proceedText="Approve Termination"
        onCancel={() => setIsProceedModalOpen(false)}
        onProceed={onRequestTermination}
        isLoading={loading}
        proceedButtonProps={{ danger: true, type: 'default' }}
      />
    </Fragment>
  );
};

export default ApproveTerminationActionContainer;
