import { Fragment, forwardRef, useImperativeHandle } from 'react';
import { Form, Select, Input, InputNumber, Button, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { TextBlock } from 'src/components/Misc';

import { roleOptions, protectionPeriodOptions } from '../saas-providers.enum';

import styles from './SaasProviderForms.module.scss';

export type ISaasAddProviderForm = {
  name?: string;
  role: string;
  description: string;
  email: string;
  cost: number;
  continuity: string;
};

type Props = {
  onSubmit: (values: ISaasAddProviderForm) => void;
  onCancel: () => void;
  isLoading?: boolean;
  withRequiredName?: boolean;
};

type Ref = { onResetFields: () => void };

const SaasAddProviderForm = forwardRef<Ref, Props>(
  ({ onSubmit, onCancel, isLoading = false, withRequiredName = false }, ref) => {
    const [form] = Form.useForm<ISaasAddProviderForm>();

    useImperativeHandle(ref, () => ({ onResetFields: form.resetFields }), []);

    return (
      <Form form={form} layout="vertical" onFinish={onSubmit} requiredMark={false}>
        {/* Field "name" is required for unknown provider */}
        {withRequiredName && (
          <Form.Item name="name" label="Provider Name" rules={[{ required: true }]}>
            <Input placeholder="Enter provider name" />
          </Form.Item>
        )}

        <Form.Item name="role" label="Provider Role" rules={[{ required: true }]}>
          <Select placeholder="Select provider role" options={roleOptions} />
        </Form.Item>
        <Form.Item
          name="description"
          label={
            <Fragment>
              Environment Role Description
              <Tooltip
                placement="right"
                overlayClassName="ck-tooltip--light"
                title="Please add more details about this service, such as its role in your environment and the current pricing plan you're subscribed to."
              >
                <span className={styles['tooltip-btn']}>
                  <QuestionCircleFilled />
                </span>
              </Tooltip>
            </Fragment>
          }
          rules={[{ required: true }]}
        >
          <Input.TextArea placeholder="Placeholder text" rows={6} />
        </Form.Item>
        <Form.Item
          name="email"
          label={
            <Fragment>
              Provider Contact Email
              <Tooltip
                overlayClassName="ck-tooltip--light"
                placement="right"
                title="The contact details of the representative your team gets in touch with."
              >
                <span className={styles['tooltip-btn']}>
                  <QuestionCircleFilled />
                </span>
              </Tooltip>
            </Fragment>
          }
          rules={[{ required: true }]}
        >
          <Input placeholder="example@email.com" type="email" />
        </Form.Item>

        <div className={styles.label}>
          Approx. Monthly Costs <span>(Average over the last 12 months)</span>
        </div>
        <Form.Item className="ck-cost__input" name="cost" rules={[{ required: true }]}>
          <InputNumber addonBefore="USD" type="number" controls={false} size="middle" />
        </Form.Item>

        <TextBlock
          title="Continuity Protection Period"
          as="h4"
          titleColor="dark-blue"
          text="In the event of a disruption, how long would you like us to keep this Provider's account active on your behalf?"
          className="mb-3"
        />
        <Form.Item name="continuity" className="mb-5" rules={[{ required: true }]}>
          <Select placeholder="Select protection period" options={protectionPeriodOptions} />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="ml-2" type="primary" htmlType="submit" loading={isLoading}>
            Add Provider
          </Button>
        </div>
      </Form>
    );
  },
);

SaasAddProviderForm.displayName = 'SaasAddProviderForm';

export default SaasAddProviderForm;
