import { CloudTwoTone } from '@ant-design/icons';
import classNames from 'classnames';

import ProviderStatusLabel from '../StatusLabel/ProviderStatusLabel';
import { providerListDict } from '../List/providerList.enum';

import type { SaasProviderStatusEnum } from 'src/graphql/schema';

import ProviderCardDropdown from './ProviderCardDropdown';
import styles from './SaasProviderCard.module.scss';

export type IProviderCardItem = {
  id: string;
  name: string;
  status: SaasProviderStatusEnum;
  description: string;
  disabled?: boolean;
};

type Props = {
  canUpdate?: boolean;
  provider: IProviderCardItem;
  dropdownItemClick: (value: string) => void;
};

const ProviderCardItem = ({ canUpdate, provider, dropdownItemClick }: Props) => {
  const Icon = providerListDict[provider.name]?.icon;
  const cardClasses = classNames(styles.card, {
    [styles.disabled]: provider.disabled,
  });

  return (
    <div className={cardClasses}>
      <div className={styles['card-action']}>
        <ProviderCardDropdown canUpdate={canUpdate} onClick={!provider.disabled ? dropdownItemClick : undefined} />
      </div>
      <figure className={styles['card-figure']}>
        {Icon ? (
          <Icon className={styles['card-icon']} />
        ) : (
          <CloudTwoTone className={styles['icon-placeholder']} twoToneColor="#00a0b6" />
        )}
        <figcaption className={styles['card-title']}>{provider.name}</figcaption>
      </figure>
      <div className={styles['card-status__row']}>
        <ProviderStatusLabel status={provider.status} disabled={provider.disabled} />
      </div>
      {!provider.disabled && <p className={styles['card-description']}>{provider.description}</p>}
    </div>
  );
};

export default ProviderCardItem;
