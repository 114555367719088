import { IEscrowsQuery } from 'src/graphql/schema';
import { IEscrowTableRow } from 'src/components/Escrows';
import { OtherEscrowStatusEnum } from 'src/components/Escrow/escrow.enum';

export const getEscrows = (escrows: IEscrowsQuery['escrows']['nodes']): IEscrowTableRow[] => {
  return escrows.map((item) => ({
    depositor: item.depositor && item.depositor.companyName ? item.depositor.companyName : '',
    beneficiary: item.beneficiary && item.beneficiary.companyName ? item.beneficiary.companyName : '',
    status: item.depositStatus.replace(/_/g, ' '),
    sync:
      item.status && item.escrowTermination?.status !== 'accepted' && item.escrowTermination?.status !== 'pending'
        ? item.status
        : OtherEscrowStatusEnum.TerminationRequested,
    action: {
      id: item.id,
      invitationId:
        item.escrowInvitation?.status === 'pending' && item.escrowInvitation.acceptable
          ? item.escrowInvitation.id
          : undefined,
    },
  }));
};
