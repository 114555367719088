import classNames from 'classnames';

import formatPrice from 'src/utils/currency-code-to-symbol';

import styles from './BillingTotal.module.scss';

type Props = {
  total: number;
  currency: string;
  period: string;
  billingPeriod: number;
  className?: string;
};

export default function BillingTotal({ total, currency, period, billingPeriod, className }: Props) {
  const sectionClasses = classNames(styles.section, className);

  return (
    <div className={sectionClasses}>
      <p className={styles.total}>Total:</p>
      <div className={styles.info}>
        <p className={styles.amount}>{formatPrice(currency, total, true)}</p>
        <p className={styles.period}>
          Billed {billingPeriod == 1 ? `${period}ly` : `every ${billingPeriod} ${period}s`}
        </p>
      </div>
    </div>
  );
}
