import { message } from 'src/components/Misc';
import { useUpdateProfileInfoMutation, ProfileDocument } from 'src/graphql/schema';
import { UserInfoForm, IUserInfoForm } from 'src/components/Profile/Forms';

type Props = {
  name: string;
  phone?: string;
};

const UserInfoFormContainer = ({ name, phone }: Props) => {
  const [updateProfile, { loading }] = useUpdateProfileInfoMutation({
    refetchQueries: [ProfileDocument],
  });

  const onSubmit = async (values: IUserInfoForm) => {
    try {
      const { data } = await updateProfile({
        variables: {
          name: values.name,
          phone: values.phone,
        },
      });

      if (data?.updateProfileInfo?.success) {
        message.success('Profile updated!');
      } else {
        throw new Error(data?.updateProfileInfo?.errors?.[0]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        message.error(e.message || 'Something went wrong');
      }
    }
  };

  return (
    <UserInfoForm
      onSubmit={onSubmit}
      initialValues={{
        name,
        phone,
      }}
      isLoading={loading}
    />
  );
};

export default UserInfoFormContainer;
