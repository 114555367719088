import { IActivity } from 'src/graphql/schema';

import ProfileActivityItem from './ProfileActivityItem';
import styles from './ProfileActivity.module.scss';

type Props = {
  items: IActivity[];
};

const ProfileActivityList = ({ items }: Props) => {
  return (
    <div className={styles.list}>
      {items.map((item) => (
        <ProfileActivityItem key={item.id} {...item} />
      ))}
    </div>
  );
};

export default ProfileActivityList;
