import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFileTextF = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M10.667 0h-9.34C.593 0 0 .6 0 1.333l.007 9.334C.007 11.4.6 12 1.333 12H8l4-4V1.333C12 .6 11.4 0 10.667 0m-8 3.333h6.666v1.334H2.667zm3.333 4H2.667V6H6zM7.333 11V7.333H11z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFileTextF);
export default Memo;
