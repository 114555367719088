import { Fragment } from 'react';

import { EscrowPartyTypeEnum } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';

import EscrowMainPartyEditableContainer from '../Main/EscrowMainPartyEditableContainer';

import EscrowNewSecondaryPartyContainer from './EscrowNewSecondaryPartyContainer';

const EscrowBipartiteEditableContainer: React.FunctionComponent = () => {
  const { escrow } = useEscrow();
  const currentEscrowRole = escrow?.currentCompanyRole || '';
  const secondPartyRole =
    currentEscrowRole === EscrowPartyTypeEnum.Beneficiary
      ? EscrowPartyTypeEnum.Depositor
      : EscrowPartyTypeEnum.Beneficiary;

  if (!currentEscrowRole) return null;

  return (
    <Fragment>
      <EscrowMainPartyEditableContainer role={currentEscrowRole} />

      <EscrowNewSecondaryPartyContainer role={secondPartyRole} />
    </Fragment>
  );
};

export default EscrowBipartiteEditableContainer;
