import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import env from 'env';
import { ClientError } from 'src/utils/errors';

import App from './App';

const tagManagerArgs = {
  gtmId: 'UA-100639203-4',
};

if (env.PROD) {
  TagManager.initialize(tagManagerArgs);
}

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      mutationBreadcrumbLimit: 1000,
      mutationLimit: 2500,
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    const error = hint.originalException;

    if (error instanceof ClientError) return null;

    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
