import { IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

export enum SaasStatusIndicatorEnum {
  ENVIRONMENT = 'environment',
  ACCESS_SETUP = 'access-setup',
  ACTIVE = 'active',
  TERMINATED_REQUESTED = 'terminated-requested',
  TERMINATED = 'terminated',
}

export const statusIndicatorItems: Array<IJourneyStatusIndicatorItem & { id: SaasStatusIndicatorEnum }> = [
  {
    id: SaasStatusIndicatorEnum.ENVIRONMENT,
    label: 'Environment',
    status: 'active',
  },
  {
    id: SaasStatusIndicatorEnum.ACCESS_SETUP,
    label: 'Access Set up',
  },
  {
    id: SaasStatusIndicatorEnum.ACTIVE,
    label: 'SaaS Escrow Active',
  },
  {
    id: SaasStatusIndicatorEnum.TERMINATED_REQUESTED,
    label: 'Termination Pending',
    isHidden: true,
  },
  {
    id: SaasStatusIndicatorEnum.TERMINATED,
    label: 'Escrow Terminated',
    isHidden: true,
  },
];
