import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconBin = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 1.2c-.81 0-1.466.656-1.466 1.466v.533H2.667a.8.8 0 1 0 0 1.6H3.2v8.534c0 .81.657 1.466 1.467 1.466h6.667c.81 0 1.466-.656 1.466-1.466V4.799h.534a.8.8 0 0 0 0-1.6h-1.867v-.533c0-.81-.657-1.467-1.466-1.467H6Zm4.668 3.6H4.8v8.4h6.4V4.8h-.532Zm-.8-1.6v-.4H6.133v.4h3.733Zm-3.2 2.665a.8.8 0 0 1 .8.8v4.667a.8.8 0 0 1-1.6 0V6.665a.8.8 0 0 1 .8-.8Zm2.666 0a.8.8 0 0 1 .8.8v4.667a.8.8 0 0 1-1.6 0V6.665a.8.8 0 0 1 .8-.8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconBin);
export default Memo;
