import { Fragment, useEffect } from 'react';

import { message, ModalProceed, ModalConfirmation } from 'src/components/Misc';
import { EscrowPartyTypeEnum, EscrowStatusEnum } from 'src/graphql/schema';
import { useContactsSuggestChanges } from 'src/context/suggest-changes';
import { shouldEnableSendForReview } from 'src/utils/validation/suggest-changes.validation';
import { getPartyOrganization, getPartySignatory } from 'src/utils/destructs/escrow.destructs';
import { useEscrow } from 'src/context/escrow';
import { getApiError } from 'src/utils/errors';

import { IPartyPreview } from '../Main/EscrowPartyPreviewContainer';
import EscrowMainPartyEditableContainer from '../Main/EscrowMainPartyEditableContainer';

import EditableMainPartyContainer from './EditableMainPartyContainer';
import SuggestChangesPreviewContainer from './SuggestChangesPreviewContainer';

const TripartiteSuggestChangesContainer: React.FunctionComponent = () => {
  const { escrow, ui, setEnableSendForReviewBtn, setSendModalOpen, setCustomUi } = useEscrow();
  const { view, suggestedChangesForReview, createSuggestedChanges } = useContactsSuggestChanges();

  const currentCompanyRole = escrow.currentCompanyRole as EscrowPartyTypeEnum;
  const secondPartyRole =
    currentCompanyRole === EscrowPartyTypeEnum.Beneficiary
      ? EscrowPartyTypeEnum.Depositor
      : EscrowPartyTypeEnum.Beneficiary;

  // Enable/Disable 'Send changes for Review' button
  useEffect(() => {
    const isEnabled =
      shouldEnableSendForReview(
        {
          organization: view?.[currentCompanyRole].organization,
          signatory: view?.[currentCompanyRole].signatory,
        },
        {
          organization: getPartyOrganization(escrow, currentCompanyRole),
          signatory: getPartySignatory(escrow, currentCompanyRole),
        },
      ) ||
      Boolean(suggestedChangesForReview?.[currentCompanyRole]?.editedContacts?.length) ||
      Boolean(suggestedChangesForReview?.[currentCompanyRole]?.deletedContacts?.length) ||
      Boolean(suggestedChangesForReview?.[currentCompanyRole]?.newContacts?.length);

    if (isEnabled) setEnableSendForReviewBtn(true);
    else setEnableSendForReviewBtn(false);
  }, [view, suggestedChangesForReview]);

  const onSubmitChanges = async () => {
    try {
      await createSuggestedChanges(currentCompanyRole);
      setCustomUi({ isSendModalOpen: false, isConfirmModalOpen: true });
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const isCreator = escrow.currentCompanyRole === escrow.startedBy;

  return (
    <Fragment>
      {(escrow.status === EscrowStatusEnum.Active ||
        escrow.status === EscrowStatusEnum.Signed ||
        (escrow.status === EscrowStatusEnum.Onboarding &&
          isCreator &&
          escrow.escrowInvitation?.status === 'accepted')) && (
        <Fragment>
          {ui.isSuggestChangesMode ? (
            <EditableMainPartyContainer role={currentCompanyRole} />
          ) : (
            <SuggestChangesPreviewContainer
              parties={view as { [key in EscrowPartyTypeEnum]: IPartyPreview }}
              currentCompanyRole={currentCompanyRole}
            />
          )}
        </Fragment>
      )}

      {escrow.status === EscrowStatusEnum.Onboarding &&
        !isCreator &&
        escrow.escrowInvitation?.status === 'accepted' && (
          <Fragment>
            <EscrowMainPartyEditableContainer role={currentCompanyRole} />
            <SuggestChangesPreviewContainer
              parties={
                { [secondPartyRole]: view?.[secondPartyRole] } as { [key in EscrowPartyTypeEnum]: IPartyPreview }
              }
              currentCompanyRole={currentCompanyRole}
            />
          </Fragment>
        )}

      <ModalProceed
        isOpen={ui.isSendModalOpen}
        title="Send change requests for review?"
        text="The counterparty will get notified, and may choose to accept or reject these changes."
        proceedText="Send"
        onProceed={onSubmitChanges}
        onCancel={() => setSendModalOpen(false)}
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Your requests have been sent for review!"
        text="The counterparty will now review your change requests. We will notify you of the result as soon as possible!"
        onClose={() =>
          setCustomUi({
            isConfirmModalOpen: false,
            isSuggestChangesMode: false,
            isSendForReviewBtnDisabled: true,
          })
        }
      />
    </Fragment>
  );
};

export default TripartiteSuggestChangesContainer;
