import { IconFile, IconProvider, IconSettingO, IconCertificate } from 'src/components/Svg/Icons';
import { CustodianRoutesEnum } from 'src/pages/Custodian/CustodianSlug';

import type { ICustodianMenuItem } from './CustodianMenuItem';

export const custodianMenuItems: ICustodianMenuItem[] = [
  {
    path: CustodianRoutesEnum.Assets,
    label: 'Assets',
    icon: IconFile,
    isRequired: true,
  },
  {
    path: CustodianRoutesEnum.Operations,
    label: 'Operations',
    icon: IconSettingO,
    isDisabled: true,
  },
  {
    path: CustodianRoutesEnum.Integrations,
    label: 'Integrations',
    icon: IconProvider,
    isDisabled: true,
  },
  {
    path: CustodianRoutesEnum.Certificate,
    label: 'Certificate',
    icon: IconCertificate,
    isDisabled: true,
  },
];
