import { Button } from 'antd';
import classNames from 'classnames';

import { ImageCoupon } from 'src/components/Svg/Images';

import styles from './BillingCoupon.module.scss';

type Props = {
  coupon?: string;
  onAddCoupon?: () => void;
  className?: string;
  justify?: 'between' | 'center';
  withBorder?: boolean;
};

const Coupon = ({ coupon, onAddCoupon, justify = 'between', withBorder = true, className }: Props) => {
  const blockClasses = classNames('d-flex', `justify-content-${justify}`, styles.block, className, {
    [styles['with-border']]: withBorder,
  });

  return (
    <div className={blockClasses}>
      <div className="d-flex align-items-center">
        <ImageCoupon aria-label="icon-coupon" className={styles['icon-coupon']} />
        <span className={styles.label}>Coupon:</span>
      </div>
      {coupon ? (
        <div className={styles.code}>{coupon}</div>
      ) : (
        <Button type="link" onClick={onAddCoupon}>
          + Add Coupon Code
        </Button>
      )}
    </div>
  );
};

export default Coupon;
