import { useEffect } from 'react';
import { get } from 'lodash';

import { DropdownProfileMenu } from 'src/components/Layout';
import { useAuth } from 'src/context/auth/use-auth';
import useImpersonate from 'src/hooks/use-impersonate';
import { useProfileLazyQuery } from 'src/graphql/schema';

const DropdownProfileMenuContiner: React.FunctionComponent = () => {
  const [getData, { data, loading }] = useProfileLazyQuery();

  useEffect(() => {
    getData();
  }, [getData]);

  const { signOut } = useAuth();
  const { isImpersonating, stopImpersonating } = useImpersonate();

  const profile = get(data, 'profile');

  return (
    profile && (
      <DropdownProfileMenu
        user={{
          name: profile.name! || profile.email!,
          image: profile.gravatarUrl!,
        }}
        signOut={signOut}
        isImpersonating={isImpersonating}
        stopImpersonating={stopImpersonating}
        isLoading={loading}
      />
    )
  );
};

export default DropdownProfileMenuContiner;
