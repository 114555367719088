import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconGroup = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M12.784 8.594A.783.783 0 0 0 12 7.813H3.92a.783.783 0 0 0-.785.78c0 .432.351.782.784.782H12c.433 0 .784-.35.784-.781ZM3.918 10.938a.783.783 0 0 0-.784.78c0 .432.351.782.784.782h4.908c.434 0 .785-.35.785-.781a.783.783 0 0 0-.785-.781H3.918Z"
      />
      <path
        fill="currentColor"
        d="M5.762 18.438H3.138c-.865 0-1.569-.701-1.569-1.563V3.125c0-.862.704-1.563 1.57-1.563h9.645c.865 0 1.57.701 1.57 1.563V7.93c0 .431.35.78.784.78.433 0 .785-.349.785-.78V3.125A3.135 3.135 0 0 0 12.784 0H3.138A3.135 3.135 0 0 0 0 3.125v13.75A3.135 3.135 0 0 0 3.138 20h2.624c.434 0 .785-.35.785-.781a.783.783 0 0 0-.785-.782Z"
      />
      <path
        fill="currentColor"
        d="M17.31 11.31a2.363 2.363 0 0 0-3.328 0l-4.308 4.28a.781.781 0 0 0-.196.325l-.938 3.076a.779.779 0 0 0 .75 1.008c.07 0 .14-.01.21-.028l3.167-.874a.785.785 0 0 0 .345-.2l4.298-4.272a2.34 2.34 0 0 0 0-3.315Zm-5.261 6.336-1.593.44.466-1.529 2.906-2.887 1.11 1.105-2.889 2.871Zm4.152-4.126-.152.15-1.11-1.104.152-.15a.788.788 0 0 1 1.11 0 .78.78 0 0 1 0 1.104ZM12 4.688H3.917a.783.783 0 0 0-.784.78c0 .432.351.782.784.782H12c.433 0 .784-.35.784-.781A.783.783 0 0 0 12 4.687Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconGroup);
export default Memo;
