import { IEscrow } from 'src/graphql/schema';

import { URL_PATTERN } from './validation/validation-patterns';

/**
 * Toggle sorting
 * @param {String} sorting
 * @param {Function} changeSorting
 * @returns {String}
 */
export function changeSort(sorting: string, changeSorting: (value: string) => void) {
  if (sorting === 'asc') {
    changeSorting('desc');
  } else {
    changeSorting('asc');
  }
}

const setAction = (eventType: string) => {
  const approved = eventType === 'approved_by_beneficiary' || eventType === 'approved_by_depositor';
  const activated = eventType === 'activated_by_beneficiary' || eventType === 'activated_by_depositor';
  const changes = eventType === 'changes_suggested_by_depositor' || eventType === 'changes_suggested_by_beneficiary';

  if (approved) return 'approved';
  else if (activated) return 'activated';
  else if (changes) return 'suggested changes for';
  else return eventType;
};
/**
 * Get event
 * @param {Object} activity
 * @returns {String}
 */
export function getEvent(activity: {
  klass: string;
  eventType: string;
  payload: { depositor: { companyName: string }; beneficiary: { companyName: string } };
}) {
  const { klass, eventType, payload } = activity;
  const depositorCompanyName = payload?.depositor?.companyName;
  const beneficiaryCompanyName = payload?.beneficiary?.companyName;
  const termsChanged = eventType === 'terms_changed';

  const subjectName = depositorCompanyName || beneficiaryCompanyName || 'Codekeeper';

  if (klass === 'escrow_event') {
    return `${subjectName} has ${setAction(eventType)} the escrow`;
  } else if (klass === 'agreement_event') {
    return `${subjectName} has ${termsChanged ? 'changed' : eventType} the ${
      eventType === 'updated' ? 'agreement' : 'agreement terms'
    }`;
  } else {
    return `${subjectName} has ${eventType} the cancelation request`;
  }
}

export function renderClassName(text: string) {
  return `-${text.replace(' ', '-').toLowerCase()}`;
}

// Takes escrow and adds actionRequired value
export const setRequiredEscrowAction = (item: IEscrow) => {
  let actionRequired = '';

  if (item.status === 'onboarding') {
    // bipartite agreements are NEVER approved by the beneficiary
    if (item.agreement?.agreementForm === 'bipartite') {
      if (!item.agreement.approvedByDepositor || !item.agreement.approvedByCodekeeper) {
        actionRequired = 'Review concept agreement';
      } else if (item.agreement.approved) {
        actionRequired = 'Pending signature (e-signature request sent)';
      }
    } else {
      if (
        !item.agreement?.approvedByBeneficiary ||
        !item.agreement?.approvedByDepositor ||
        !item.agreement?.approvedByCodekeeper
      ) {
        actionRequired = 'Review concept agreement';
      } else if (item.agreement.approved) {
        actionRequired = 'Pending signature (e-signature request sent)';
      }
    }
  }

  if (item.status === 'draft') {
    actionRequired = 'Complete escrow configuration';
  } else {
    if (item.escrowInvitation?.acceptable && item.status === 'onboarding') {
      actionRequired = 'Accept or reject escrow invitation';
    }
    if (item.status === 'signed') {
      if (item.depositStatus === 'taken') {
        actionRequired = 'Activate Escrow';
      }
      if (item.depositStatus === 'no_configuration' || item.depositStatus === 'awaiting') {
        if (item.currentCompanyRole === 'depositor') {
          actionRequired = 'Configure assets';
        } else {
          actionRequired = 'Assets to be configured';
        }
      }
    }
    // Priority wise, always show reject/accept action first, so we keep them at the bottom
    if (item.changesRequested) {
      actionRequired = 'Requests to be reviewed';
    }
    if (item.escrowTermination && item.status !== 'pending_termination') {
      actionRequired = 'Termination request to be reviewed';
    }
  }

  return actionRequired;
};

/**
 * Checks if a string is a valid URL.
 *
 * @param str - The string to be checked.
 * @returns `true` if the string is a valid URL, `false` otherwise.
 */
export function isUrl(str: string): boolean {
  //eslint-disable-next-line
  return URL_PATTERN.test(str);
}
