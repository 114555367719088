import { useEffect } from 'react';

import { useCancelationPlanSlice } from 'src/slices';
import { useLocalStorage } from 'src/hooks';

import { createRequiredContext } from '../createRequiredContext';

const [useCancelationPlan, CancelationPlanProvider] = createRequiredContext<{
  ui: ReturnType<typeof useCancelationPlanSlice>;
  exclusiveOfferApplied: boolean;
  feedbackSubmitted: boolean;
  cancellationRequested: boolean;
  activateFeedbackSubmitted: () => void;
}>();

const CancelationPlanContextProvider = ({
  exclusiveOfferApplied,
  cancellationRequested = false,
  children,
}: {
  exclusiveOfferApplied: boolean;
  cancellationRequested?: boolean;
  children: React.ReactNode;
}) => {
  const ui = useCancelationPlanSlice();
  const [feedbackSubmitted, setFeedbackSubmitted] = useLocalStorage('cancelationFeedbackSubmitted', false);

  const activateFeedbackSubmitted = () => setFeedbackSubmitted(true);

  useEffect(() => {
    if (exclusiveOfferApplied) setFeedbackSubmitted(false);
  }, [exclusiveOfferApplied]);

  const providerValue = {
    ui,
    exclusiveOfferApplied,
    cancellationRequested,
    feedbackSubmitted,
    activateFeedbackSubmitted,
  };

  return <CancelationPlanProvider value={providerValue}>{children}</CancelationPlanProvider>;
};

export { useCancelationPlan, CancelationPlanContextProvider };
