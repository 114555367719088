import { Button } from 'antd';
import { CheckCircleFilled, SyncOutlined } from '@ant-design/icons';

import styles from './CancelCollapse.module.scss';

export type Props = {
  heading: string;
  text: string;
  action?: {
    text: string;
    onClick: () => void;
  };
  isLoading?: boolean;
};

const CancelAccordionHeader = ({ heading, text, action, isLoading }: Props) => {
  return (
    <div className={`d-flex justify-content-between align-items-center ${styles.header}`}>
      <div>
        <div className={styles.heading}>{heading}</div>
        <p className={styles.text}>{text}</p>
      </div>
      {isLoading ? (
        <SyncOutlined spin className={styles['icon-status']} data-testid="loader" />
      ) : action ? (
        <div className={styles.action}>
          <Button ghost type="primary" onClick={action.onClick}>
            {action.text}
          </Button>
        </div>
      ) : (
        <CheckCircleFilled className={styles['icon-status']} data-testid="completed" />
      )}
    </div>
  );
};

export default CancelAccordionHeader;
