import { JourneyMenu, IJourneyMenuItem } from 'src/components/Journey/Menu';

import styles from './BackupMenu.module.scss';

export type IBackupMenuItem = {
  isRequired?: boolean;
} & IJourneyMenuItem;

const BackupMenuItem = ({ isRequired = false, ...rest }: IBackupMenuItem) => {
  return <JourneyMenu.Item {...rest}>{isRequired && <span className={styles['required-dot']} />}</JourneyMenu.Item>;
};

export default BackupMenuItem;
