export default {
  VITE_GRAPHIQL_ENABLED: import.meta.env.VITE_GRAPHIQL_ENABLED,
  PROD: import.meta.env.PROD,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_BACKEND_URL: import.meta.env.VITE_BACKEND_URL,
  VITE_COGNITO_REGION: import.meta.env.VITE_COGNITO_REGION,
  VITE_COGNITO_USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  VITE_COGNITO_USER_POOL_WEB_CLIENT_ID: import.meta.env.VITE_COGNITO_USER_POOL_WEB_CLIENT_ID,
  VITE_COGNITO_AUTH: import.meta.env.VITE_COGNITO_AUTH,
  CODEKEEPER_SERVICE_EMAIL: 'service@codekeeper.co',
};
