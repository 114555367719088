import React, { Fragment } from 'react';
import { Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client/errors';

import { JourneyLayoutMain } from './';

import { JourneyFooter, JourneyHeader } from '../';

import styles from './JourneyLayout.module.scss';

export type Props = {
  header: React.ReactNode;
  menu: React.ReactNode;
  statusIndicator: React.ReactNode;
  error?: { goTo?: string } & ApolloError;
};

const JourneyLayout = ({ header, children, error, statusIndicator, menu }: React.PropsWithChildren<Props>) => {
  const navigate = useNavigate();

  const onClose = () => error?.goTo && navigate(error.goTo);

  if (error)
    return (
      <div className={styles['error-alert']}>
        <Alert message="Error" description={error.message} type="error" showIcon closable onClose={onClose} />
      </div>
    );

  return (
    <Fragment>
      {header}
      <div className={styles.layout}>
        {statusIndicator}
        {menu}

        <div className={styles.main}>
          <div className={styles['main-wrapper']}>{children}</div>
        </div>
      </div>
    </Fragment>
  );
};

JourneyLayout.Header = JourneyHeader;
JourneyLayout.Main = JourneyLayoutMain;
JourneyLayout.Footer = JourneyFooter;

export default JourneyLayout;
