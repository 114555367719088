import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  SET_INITIAL_DATA = 'set_initial_data',
  UPDATE_CONTEXT = 'update_context',
  UPDATE_OTHER_CONTEXT = 'update_other_context',
  UPDATE_DEPOSIT = 'update_deposit',
  UPDATE_OTHER_DEPOSIT = 'update_other_deposit',
  UPDATE_RELEASE_CONDITIONS = 'update_release_conditions',
  UPDATE_CONDITION = 'update_condition',
  UPDATE_JURISDICTION = 'update_jurisdiction',
  UPDATE_LIABILITY = 'update_liability',
  RESET_STORE = 'reset_store',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA, IDataState>
  | IAction<ActionEnum.UPDATE_CONTEXT, { values: string[]; hasChanges: boolean }>
  | IAction<ActionEnum.UPDATE_OTHER_CONTEXT, { value: string; hasChanges: boolean }>
  | IAction<ActionEnum.UPDATE_DEPOSIT, { values: string[]; hasChanges: boolean }>
  | IAction<ActionEnum.UPDATE_OTHER_DEPOSIT, { value: string; hasChanges: boolean }>
  | IAction<
      ActionEnum.UPDATE_RELEASE_CONDITIONS,
      { data: Partial<IDataState['releaseConditions']>; hasChanges: boolean }
    >
  | IAction<ActionEnum.UPDATE_CONDITION, { value: string; hasChanges: boolean }>
  | IAction<ActionEnum.UPDATE_JURISDICTION, string>
  | IAction<ActionEnum.UPDATE_LIABILITY, string>
  | IAction<ActionEnum.RESET_STORE>;
interface IDataState {
  context: string[];
  otherContext: string;
  deposit: string[];
  otherDeposit: string;
  condition: string;
  jurisdiction: string;
  liability: string;
  releaseConditions: {
    releaseBankruptcy: boolean;
    releaseCustom: boolean;
    releaseInsolvency: boolean;
    releaseMaintenance: boolean;
  };
}
interface IState {
  data: IDataState;
  validation: Record<
    string,
    Partial<{
      isRequired?: boolean;
      hasChanges: boolean;
    }>
  >;
}

const initialState: IState = {
  data: {
    context: [],
    otherContext: '',
    deposit: [],
    otherDeposit: '',
    condition: '',
    jurisdiction: '',
    liability: '',
    releaseConditions: {
      releaseBankruptcy: false,
      releaseCustom: false,
      releaseInsolvency: false,
      releaseMaintenance: false,
    },
  },
  validation: {
    context: {
      isRequired: false,
      hasChanges: false,
    },
    otherContext: {
      isRequired: false,
      hasChanges: false,
    },
    deposit: {
      isRequired: false,
      hasChanges: false,
    },
    otherDeposit: {
      isRequired: false,
      hasChanges: false,
    },
    releaseConditions: {
      isRequired: false,
    },
    releaseBankruptcy: {
      hasChanges: false,
    },
    releaseCustom: {
      hasChanges: false,
    },
    releaseInsolvency: {
      hasChanges: false,
    },
    releaseMaintenance: {
      hasChanges: false,
    },
    condition: {
      isRequired: false,
      hasChanges: false,
    },
  },
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_INITIAL_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case ActionEnum.UPDATE_CONTEXT: {
      return {
        ...state,
        data: {
          ...state.data,
          context: action.payload.values,
        },
        validation: {
          ...state.validation,
          context: {
            isRequired: !action.payload.values.length ? true : false,
            hasChanges: action.payload.hasChanges,
          },
          otherContext: {
            ...state.validation.otherContext,
            isRequired: action.payload.values.includes('other') && !state.data.otherContext ? true : false,
          },
        },
      };
    }
    case ActionEnum.UPDATE_OTHER_CONTEXT:
      return {
        ...state,
        data: {
          ...state.data,
          otherContext: action.payload.value.trim(),
        },
        validation: {
          ...state.validation,
          otherContext: {
            isRequired: state.data.context.includes('other') && !action.payload.value.trim() ? true : false,
            hasChanges: action.payload.hasChanges,
          },
        },
      };
    case ActionEnum.UPDATE_DEPOSIT:
      return {
        ...state,
        data: {
          ...state.data,
          deposit: action.payload.values,
        },
        validation: {
          ...state.validation,
          deposit: {
            isRequired: !action.payload.values.length ? true : false,
            hasChanges: action.payload.hasChanges,
          },
          otherDeposit: {
            ...state.validation.otherDeposit,
            isRequired: action.payload.values.includes('other') && !state.data.otherDeposit ? true : false,
          },
        },
      };
    case ActionEnum.UPDATE_OTHER_DEPOSIT:
      return {
        ...state,
        data: {
          ...state.data,
          otherDeposit: action.payload.value.trim(),
        },
        validation: {
          ...state.validation,
          otherDeposit: {
            isRequired: state.data.deposit.includes('other') && !action.payload.value.trim() ? true : false,
            hasChanges: action.payload.hasChanges,
          },
        },
      };
    case ActionEnum.UPDATE_RELEASE_CONDITIONS: {
      const newReleaseConditions = {
        ...state.data.releaseConditions,
        ...action.payload.data,
      };

      const conditionChanged = Object.keys(action.payload.data).reduce(
        (acc, key) => ({ ...acc, [key]: { hasChanges: action.payload.hasChanges } }),
        {},
      );

      return {
        ...state,
        data: {
          ...state.data,
          releaseConditions: {
            ...state.data.releaseConditions,
            ...action.payload.data,
          },
        },
        validation: {
          ...state.validation,
          ...conditionChanged,
          releaseConditions: {
            isRequired: Object.values(newReleaseConditions).every((condition) => condition === false) ? true : false,
          },
          condition: {
            ...state.validation.condition,
            isRequired: newReleaseConditions.releaseCustom && !state.data.condition ? true : false,
          },
        },
      };
    }
    case ActionEnum.UPDATE_CONDITION:
      return {
        ...state,
        data: {
          ...state.data,
          condition: action.payload.value.trim(),
        },
        validation: {
          ...state.validation,
          condition: {
            isRequired: state.data.releaseConditions.releaseCustom && action.payload.value.trim() ? false : true,
            hasChanges: action.payload.hasChanges,
          },
        },
      };
    case ActionEnum.UPDATE_JURISDICTION:
      return {
        ...state,
        data: {
          ...state.data,
          jurisdiction: action.payload,
        },
      };
    case ActionEnum.UPDATE_LIABILITY:
      return {
        ...state,
        data: {
          ...state.data,
          liability: action.payload,
        },
      };
    case ActionEnum.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export const useAgreementSuggestChangesSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setInitialData = (payload: IDataState) => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload });

  const updateContext = (payload: { values: string[]; hasChanges: boolean }) =>
    dispatch({ type: ActionEnum.UPDATE_CONTEXT, payload });

  const updateOtherContext = (payload: { value: string; hasChanges: boolean }) =>
    dispatch({
      type: ActionEnum.UPDATE_OTHER_CONTEXT,
      payload,
    });

  const updateDeposit = (payload: { values: string[]; hasChanges: boolean }) =>
    dispatch({ type: ActionEnum.UPDATE_DEPOSIT, payload });

  const updateOtherDeposit = (payload: { value: string; hasChanges: boolean }) =>
    dispatch({
      type: ActionEnum.UPDATE_OTHER_DEPOSIT,
      payload,
    });

  const updateReleaseConditions = (payload: {
    data: Partial<IDataState['releaseConditions']>;
    hasChanges: boolean;
  }) => {
    dispatch({ type: ActionEnum.UPDATE_RELEASE_CONDITIONS, payload });
  };

  const updateCondition = (payload: { value: string; hasChanges: boolean }) =>
    dispatch({ type: ActionEnum.UPDATE_CONDITION, payload });

  const updateJurisdiction = (payload: string) => dispatch({ type: ActionEnum.UPDATE_JURISDICTION, payload });

  const updateLiability = (payload: string) => dispatch({ type: ActionEnum.UPDATE_LIABILITY, payload });

  return {
    state,
    setInitialData,
    updateContext,
    updateOtherContext,
    updateDeposit,
    updateOtherDeposit,
    updateReleaseConditions,
    updateCondition,
    updateJurisdiction,
    updateLiability,
  };
};
