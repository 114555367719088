import { redirect } from 'react-router-dom';

import { CustodianPage, CustodianSlugLayout, custodianRouteElements, CustodianRoutesEnum } from '../pages/Custodian';

import type { RouteObject } from 'react-router-dom';

export const custodianRoute: RouteObject = {
  path: 'custodian-profiles',
  element: <CustodianPage />,
};

export const custodianSingleRoutes: RouteObject = {
  path: 'custodian-profiles',
  element: <CustodianSlugLayout />,
  children: [
    {
      loader: ({ params }) => {
        const { id = null, '*': path } = params as { id: string | null; '*': CustodianRoutesEnum };

        if (id && !path) return redirect(`/custodian-profiles/${id}/${CustodianRoutesEnum.Assets}`);

        if (!Object.values(CustodianRoutesEnum).includes(path)) {
          return redirect('/404');
        }

        return null;
      },
      path: ':id/*',
      children: custodianRouteElements,
    },
  ],
};
