import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconArchive = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M14.8 9.177h6.855V10.7H14.8zM21.655 12.224H14.8v1.523h6.855zM21.655 27H14.8v1.523h6.855z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.7 17.15H8.475l-2.85 5.7H8.15v12h19.7v-12h2.525l-2.85-5.7H24.65V2.1h-9.337L11.7 5.712zm11.425 0h-9.901V7.754h4.13v-4.13h5.771zM15.83 3.736V6.23h-2.494zM9.85 22.85v10.3h16.3v-10.3zm-.325-4-1.15 2.3h19.25l-1.15-2.3z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconArchive);
export default Memo;
