import { InvoiceStatusEnum } from 'src/graphql/schema';

export const labelStatusMap = {
  [InvoiceStatusEnum.Paid]: {
    text: 'Paid',
    bgColor: 'green',
  },
  [InvoiceStatusEnum.NotPaid]: {
    text: 'Not Paid',
    bgColor: 'red',
  },
  [InvoiceStatusEnum.PaymentDue]: {
    text: 'Payment Due',
    bgColor: 'orange',
  },
  [InvoiceStatusEnum.Posted]: {
    text: 'Posted',
    bgColor: 'light-blue',
  },
  [InvoiceStatusEnum.Voided]: {
    text: 'Voided',
    bgColor: 'gray',
  },
  [InvoiceStatusEnum.Pending]: {
    text: 'Pending',
    bgColor: 'purple',
  },
} as const;
