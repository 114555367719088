import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconInformation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 45"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m32.546 2 7.748 7.748v27.546h-7.059V42H5V6.706h4.706V2h22.84zm3.731 7.059-3.042-3.042v3.042h3.042zm-28.924 0v30.588h23.53v-2.353H9.704V9.059H7.352zm16.471 8.235h-2.353v2.353h2.353v-2.353zm2.353 0V22h-7.059v-4.706h-2.353v17.57h16.471v-14.63l-3.884-2.94h-3.174zm9.412 1.768V34.94h2.353V11.41h-7.059V4.354H12.06V34.94h2.353v-20H30.14l5.449 4.12zm-4.704 10H19.12v2.354h11.765v-2.353zm-11.765-4.71h11.765v2.353H19.12v-2.353zM16.766 6.704h-2.353v2.353h2.353V6.704zm2.354 0h2.353v2.353H19.12V6.704zm7.058 0h-2.353v2.353h2.353V6.704z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconInformation);
export default Memo;
