import { intersectionBy } from 'lodash';

import { IEscrow, EscrowPartyTypeEnum, DepositStatusEnum } from 'src/graphql/schema';
import { IContactDetail } from 'src/components/Escrow/Contacts';
import { ITableRow as IAssetsTableRow } from 'src/components/Deposit';
import { IAssetNodes } from 'src/containers/Escrow';
import { formatBytes } from 'src/utils/formatBytes';

export const getPartyOrganization = (escrow: IEscrow, role: string) => {
  const organization = escrow[role as keyof IEscrow];

  return {
    name: organization?.companyName || '',
    address: {
      street: organization?.street || '',
      streetNumber: organization?.streetNumber || '',
      city: organization?.city || '',
      postalCode: organization?.postalCode || '',
      region: organization?.region || '',
      country: organization?.country || '',
    },
    registrationNumber: organization?.companyRegistrationNumber || '',
    website: organization?.companyWebsite || '',
  };
};

export const getPartySignatory = (escrow: IEscrow, role: string) => {
  const signature = escrow.signatures.find((signature) => signature.role === role);

  if (!signature) return;

  return {
    name: signature?.name || '',
    email: signature?.email || '',
  };
};

export const getPartyContacts = (escrow: IEscrow, role: EscrowPartyTypeEnum): IContactDetail[] => {
  const contactsData = escrow?.[role]?.contacts || [];

  if (!contactsData.length) return [];

  return contactsData.map(({ id, contactType, user, email, phone, name }) => ({
    id: id || '',
    email: user?.email || email || '',
    name: user?.name || name || '',
    phone: user?.phone || phone || '',
    role: contactType,
    isDynamic: true,
  }));
};

export const getExistingUsers = (escrow: IEscrow, role: EscrowPartyTypeEnum): IContactDetail[] => {
  const usersData = escrow?.[role]?.company?.users || [];
  const contactsData = escrow?.[role]?.contacts || [];

  if (!usersData.length) return [];

  const contactsList = contactsData.map(({ contactType, user }) => ({
    id: user?.id,
    email: user?.email,
    name: user?.name,
    phone: user?.phone,
    role: contactType,
  }));
  const usersList = usersData.map(({ id, role, email, phone, name }) => ({
    id,
    email,
    name: name || '',
    phone: phone || '',
    role,
  }));
  const intersection = intersectionBy(...[usersList, contactsList], 'id').map(({ id }) => id);
  const existingUsers: IContactDetail[] = [];

  usersList.forEach((contact) => {
    const isDuplicate = intersection.includes(contact.id);
    if (!isDuplicate) existingUsers.push(contact);
  });

  return existingUsers;
};

const depositStatusMap = {
  [DepositStatusEnum.NoConfiguration]: 'configuring',
};

export const getDepositsAssets = (assetsNodes: IAssetNodes): IAssetsTableRow[] => {
  if (!assetsNodes.length) return [];

  return assetsNodes.map((item) => ({
    key: item.id,
    type: item.type,
    provider: item.integration?.provider || '',
    source: item.depositSource,
    latestDeposit: item.latestDeposit,
    lastUpdateCheck: {
      lastUpdateCheck: item.lastUpdateCheck,
      type: item.type,
    },
    status:
      item.depositStatus in depositStatusMap
        ? depositStatusMap[item.depositStatus as keyof typeof depositStatusMap]
        : item.depositStatus,
    size: item.size ? formatBytes(item.size) : '-',
    action: {
      id: item.id,
      type: item.removalStatus,
    },
  })) as IAssetsTableRow[];
};
