import React from 'react';
import { Skeleton } from 'antd';

import JourneyMenuItem from './JourneyMenuItem';
import styles from './JourneyMenu.module.scss';

const JourneyMenuSkeleton = ({ items }: { items: number }) => {
  return (
    <nav className={styles.nav}>
      {Array.from({ length: items }).map((_, index) => (
        <Skeleton.Button key={index} className={styles['item-skeleton']} active size="large" block />
      ))}
    </nav>
  );
};

const JourneyMenu = ({ children }: React.PropsWithChildren) => {
  return (
    <nav className={styles.nav}>
      <ul className="ck-custom-list">{children}</ul>
    </nav>
  );
};

JourneyMenu.Item = JourneyMenuItem;
JourneyMenu.Skeleton = JourneyMenuSkeleton;

export default JourneyMenu;
