import classNames from 'classnames';

import styles from './Main.module.scss';

type Props = {
  title: string;
  headerActions?: React.ReactNode;
  headerChildren?: React.ReactNode;
  className?: string;
  mainContentClassName?: string;
  noPaddingOnContent?: boolean;
};

const Main = ({
  title,
  headerActions,
  headerChildren,
  children,
  noPaddingOnContent = false,
  mainContentClassName,
  className,
}: React.PropsWithChildren<Props>) => {
  const mainClasses = classNames(styles.main, className);
  const mainContentClasses = classNames(styles['main-content'], mainContentClassName, {
    [styles['no--padding']]: noPaddingOnContent,
  });

  return (
    <main className={mainClasses}>
      <div className={styles['main-header']}>
        <div className={styles['main-header__row']}>
          <h2 className={styles['main-title']}>{title}</h2>

          {headerActions && <div className={styles['main-header__actions']}>{headerActions}</div>}
        </div>

        {headerChildren && headerChildren}
      </div>

      <div className={mainContentClasses}>{children}</div>
    </main>
  );
};

export default Main;
