import classNames from 'classnames';

import styles from './InfoDetailBlock.module.scss';

type Props = {
  title: string;
  text: string;
  secondaryText?: string;
  items: { label: string; value: string }[];
  className?: string;
  suggestionList?: React.ReactNode;
};

const InfoDetailBlock: React.FunctionComponent<Props> = ({
  title,
  text,
  secondaryText,
  items = [],
  className,
  suggestionList,
}) => {
  const blockClassNames = classNames(styles.container, className);

  return (
    <div className={blockClassNames}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{text}</p>
      {secondaryText && <p className={styles['secondary-text']} dangerouslySetInnerHTML={{ __html: secondaryText }} />}

      {suggestionList && suggestionList}

      <ul className={`ck-custom-list ${styles.list}`}>
        {items.map(({ label, value }) => (
          <li key={label}>
            <span>{label}</span>
            <strong>{value}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InfoDetailBlock;
