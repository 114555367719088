import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconHealthOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.244 5.15h2.313c.753 0 1.37 0 1.873.042.52.044.993.137 1.434.368a3.7 3.7 0 0 1 1.592 1.632c.221.445.311.921.353 1.45.04.514.04 1.145.04 1.919v2.491q.21-.18.397-.33c.38-.298.755-.536 1.194-.671a3.6 3.6 0 0 1 2.12 0c.438.136.815.373 1.194.672.367.288.785.674 1.295 1.144l3.241 2.99.065.06c.483.445.832.767 1.089 1.163.225.348.39.73.492 1.133.114.458.114.937.114 1.605v7.332h2.8v1.7H3.15v-1.7h2.8V10.566c0-.776 0-1.408.04-1.922.043-.53.133-1.007.354-1.452a3.7 3.7 0 0 1 1.59-1.632c.442-.23.914-.324 1.436-.368.503-.042 1.12-.042 1.874-.042m-3.594 23h5.3v-7.342c0-.662 0-1.14.114-1.595.1-.403.267-.786.492-1.133.255-.394.605-.717 1.084-1.158l.07-.064 2.44-2.251v-4.011c0-.818 0-1.381-.035-1.818-.035-.428-.097-.661-.181-.83a2 2 0 0 0-.858-.881c-.158-.083-.376-.146-.788-.18-.423-.036-.97-.037-1.768-.037h-2.24c-.798 0-1.345 0-1.768.036-.413.035-.632.098-.79.18a2 2 0 0 0-.856.881c-.084.17-.147.403-.18.832-.035.438-.036 1.003-.036 1.821zm11.065-12.675-2.853 2.632c-.578.533-.755.706-.88.898q-.185.287-.27.62c-.056.227-.062.481-.062 1.28v7.245h13.7v-7.246c0-.796-.007-1.051-.064-1.279a2 2 0 0 0-.269-.62c-.124-.192-.301-.365-.88-.898l-3.22-2.97c-.538-.497-.907-.837-1.214-1.078-.3-.236-.487-.335-.646-.384a1.9 1.9 0 0 0-1.114 0c-.16.049-.347.148-.645.383-.307.242-.675.58-1.213 1.077l-.235.216v.124z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.5 9h1.875v1.875H9.5zM13.266 9h1.874v1.875h-1.875zM9.5 12.766h1.875v1.875H9.5zM13.266 12.766h1.874v1.875h-1.875zM22.706 18h-1.883v2.824H18v1.882h2.823v2.823h1.883v-2.823h2.823v-1.882h-2.823z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconHealthOperation);
export default Memo;
