import { cloneDeep } from '@apollo/client/utilities';

import { BackupMenu } from 'src/components/Backup/Menu';
import { backupMenuItems } from 'src/components/Backup/Menu/backupMenu.enum';
import { useBackup } from 'src/context/backup';
import { BackupStatusEnum } from 'src/graphql/schema';
import { BackupRoutesEnum } from 'src/pages/Backup';

import type { IBackupMenuItem } from 'src/components/Backup/Menu';

const applyEditModes = (isEditAccessMode: boolean, isEditSettingsMode: boolean, menuItems: IBackupMenuItem[]) => {
  if (isEditAccessMode) {
    return menuItems.map((item) => ({
      ...item,
      isDisabled: item.path !== BackupRoutesEnum.Members,
    }));
  }

  if (isEditSettingsMode) {
    return menuItems.map((item) => ({
      ...item,
      isDisabled: item.path !== BackupRoutesEnum.Settings,
    }));
  }

  return menuItems;
};

const BackupMenuContainer = () => {
  const {
    backup,
    ui: { isEditAccessMode, isEditSettingsMode },
  } = useBackup();

  let menuItems = cloneDeep(backupMenuItems);
  if (!backup.policy.enableReports && !backup.reportsEnabled)
    menuItems = menuItems.filter(({ path }) => path !== BackupRoutesEnum.Reports);

  switch (backup.status) {
    case BackupStatusEnum.Draft:
      menuItems = menuItems.map((item, index) => {
        return index < 4
          ? { ...item, isDisabled: false, isRequired: index === 3 && !backup?.backupAssets.length }
          : item;
      });
      break;
    case BackupStatusEnum.Active:
    case BackupStatusEnum.Stopped:
      menuItems = menuItems.map((item) => ({
        ...item,
        isDisabled: false,
        isRequired: false,
      }));
      break;
    case BackupStatusEnum.Removed:
      menuItems = menuItems.map((item) => ({
        ...item,
        isDisabled: item.path === BackupRoutesEnum.Reports,
        isRequired: false,
      }));
      break;
  }

  menuItems = applyEditModes(isEditAccessMode, isEditSettingsMode, menuItems);

  return <BackupMenu basePath="backups" items={menuItems} backupId={backup.id} />;
};

export default BackupMenuContainer;
