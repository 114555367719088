import {
  IOrganizationForm,
  IAddContactForm,
  IContactDetail,
  IOrganizationDetail,
  ToggleAddNewContactForm,
  EditableOrganizationDetail,
  EditableContactList,
  InfoContentBlock,
} from 'src/components/Escrow/Contacts';
import { useContactsSuggestChanges } from 'src/context/suggest-changes';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';

type Props = {
  role: EscrowPartyTypeEnum;
};

const contentBlockTextEnum = {
  beneficiary: {
    heading: 'Beneficiary Information',
    text: 'These are the details of the company that will receive the material.',
  },
  depositor: {
    heading: 'Depositor Information',
    text: 'These are the details of the source code owner.',
  },
};

const EditableSecondaryPartyContainer: React.FunctionComponent<Props> = ({ role }) => {
  const { view, updateOrganization, addNewContact, updateContact, removeContact } = useContactsSuggestChanges();
  const organization = view?.[role]?.organization as IOrganizationDetail;
  const contacts: IContactDetail[] = view?.[role]?.contacts || [];

  const onSubmitContact = (id: string, data: IAddContactForm & { isDynamic?: boolean }) => {
    updateContact(
      role,
      {
        id,
        email: data.email,
        name: data.name,
        role: data.role,
        phone: data.phone,
        isDynamic: data.isDynamic,
      },
      true,
    );
  };

  const onSubmitNewContact = ({ email, name, role: newRole, phone }: IAddContactForm) => {
    addNewContact(
      role,
      {
        id: `new-static-${String(Date.now())}`,
        email,
        name,
        role: newRole,
        phone,
      },
      true,
    );
  };

  const onSubmitOrganization = (data: IOrganizationForm) => {
    updateOrganization(role, {
      name: data.name,
      website: data.website,
      registrationNumber: data.registrationNumber,
      address: {
        street: data.street,
        streetNumber: data.streetNumber,
        city: data.city,
        postalCode: data.zip,
        region: data.state,
        country: data.country,
      },
    });
  };

  const onRemoveContact = (contactId: string) => removeContact(role, contactId);

  return (
    <div className={styles.container}>
      <InfoContentBlock heading={contentBlockTextEnum[role].heading} text={contentBlockTextEnum[role].text} />
      <div className={styles.block}>
        <EditableOrganizationDetail type={role} data={organization} onSubmit={onSubmitOrganization} />
      </div>
      <EditableContactList
        className={styles.block}
        type={role}
        contacts={contacts}
        onSubmitContact={onSubmitContact}
        onRemoveContact={onRemoveContact}
      />
      <ToggleAddNewContactForm type={role} onSubmitContact={onSubmitNewContact} />
    </div>
  );
};

export default EditableSecondaryPartyContainer;
