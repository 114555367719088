import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoAzureDevops = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    className={props.className}
    {...props}
  >
    <linearGradient id="logo-azure-devops_svg__a" x1={12} x2={12} y1={20.944} y2={3.034} gradientUnits="userSpaceOnUse">
      <stop offset={0} stopColor="#0078d4" />
      <stop offset={0.16} stopColor="#1380da" />
      <stop offset={0.53} stopColor="#3c91e5" />
      <stop offset={0.82} stopColor="#559cec" />
      <stop offset={1} stopColor="#5ea0ef" />
    </linearGradient>
    <path
      fill="url(#logo-azure-devops_svg__a)"
      d="M21 6.37v10.945L16.5 21l-6.975-2.54v2.518L5.576 15.82l11.509.9V6.864zm-3.836.551L10.706 3v2.573L4.778 7.315 3 9.595v5.18L5.543 15.9v-6.64z"
    />
  </svg>
);
const Memo = memo(SvgLogoAzureDevops);
export default Memo;
