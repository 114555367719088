import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFolderCheck = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 4h8.488l2.296 2.616H29v20.598H3V4Zm10.076 4.297-.041-.046h-8.39V22.31h22.71V8.25H13.13l-.054.047Zm14.278 15.648H4.646v1.634h22.708v-1.634ZM10.74 5.635l.86.98H4.646v-.98h6.094Zm3.834 14.093 6.868-7.085-1.185-1.134-5.683 5.862-2.83-2.92-1.186 1.134 4.016 4.143Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFolderCheck);
export default Memo;
