import { Button, Modal } from 'antd';

import styles from '../MiscModal.module.scss';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  text: string;
  okText?: string;
};

const ModalConfirmation = ({ isOpen, onClose, title, text, okText = 'OK' }: Props) => {
  return (
    <Modal
      centered
      className="ck-modal__confirm"
      title={title}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      closeIcon={null}
    >
      <p className={styles['confirm-text']} dangerouslySetInnerHTML={{ __html: text }} />

      <div className="d-flex justify-content-center">
        <Button type="primary" onClick={onClose}>
          {okText}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
