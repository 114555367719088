import type { IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

export enum CustodianStatusIndicatorEnum {
  CONFIG = 'config',
  ACCESS_SETUP = 'access-setup',
  LIFECYCLE = 'lifecycle',
  TERMINATION_PENDING = 'termination-pending',
  TERMINATED = 'terminated',
}

export const statusIndicatorItems: IJourneyStatusIndicatorItem[] = [
  {
    id: CustodianStatusIndicatorEnum.CONFIG,
    label: 'Configuration',
  },
  {
    id: CustodianStatusIndicatorEnum.ACCESS_SETUP,
    label: 'Access Setup',
  },
  {
    id: CustodianStatusIndicatorEnum.LIFECYCLE,
    label: 'Full Lifecycle',
  },
  {
    id: CustodianStatusIndicatorEnum.TERMINATION_PENDING,
    label: 'Termination Pending',
    isHidden: true,
  },
  {
    id: CustodianStatusIndicatorEnum.TERMINATED,
    label: 'Profile Terminated',
    isHidden: true,
  },
];
