import styles from './AuthLayout.module.scss';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles['auth-layout']}>
      <div className={styles['layout-content']}>{children}</div>
      <div className={styles['layout-footer']}>
        <p className={styles['layout-copy']}>
          © Codekeeper, Inc.
          <br />
          <a href="https://legal.codekeeper.co/page?page=TermsConditions" target="_blank" rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          apply.
        </p>

        <a href="https://www.codekeeper.co" target="_blank" rel="noreferrer" className={styles['layout-site']}>
          www.codekeeper.co
        </a>
      </div>
    </div>
  );
};
export default AuthLayout;
