import GraphiQL from 'graphiql';

import fetchTokenStrategy from 'src/utils/fetch-token-strategy';
import env from 'env';

export default function Graphiql() {
  return (
    <GraphiQL
      fetcher={async (graphQLParams) => {
        const data = await fetch(`${env.VITE_BACKEND_URL}/graphql`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await fetchTokenStrategy()}`,
          },
          body: JSON.stringify(graphQLParams),
          credentials: 'same-origin',
        });
        return data.json().catch(() => data.text());
      }}
    />
  );
}
