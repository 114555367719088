import { cloneDeep } from '@apollo/client/utilities';

import { LegacyReposDocument, AwaitingLegacyReposCountDocument } from 'src/graphql/schema';

import { apolloClient as client } from '../apollo-client';

import type { ILegacyReposQuery, ILegacyReposQueryVariables, IAwaitingLegacyReposCountQuery } from 'src/graphql/schema';

export const removeLegacyReposCache = (legacyId: string | string[], variables: ILegacyReposQueryVariables) => {
  const cacheLegacyData = cloneDeep<ILegacyReposQuery | null>(
    client.readQuery({
      query: LegacyReposDocument,
      variables,
    }),
  );

  if (!cacheLegacyData) return;

  const nodesCount = cacheLegacyData.legacyRepos.nodesCount - (Array.isArray(legacyId) ? legacyId.length : 1);

  client.writeQuery({
    query: LegacyReposDocument,
    variables,
    data: {
      ...cacheLegacyData,
      legacyRepos: {
        ...cacheLegacyData.legacyRepos,
        nodesCount,
        nodes: cacheLegacyData.legacyRepos.nodes.filter((repo) =>
          Array.isArray(legacyId) ? !legacyId.includes(repo.id) : repo.id !== legacyId,
        ),
      },
    },
  });

  // Update awaiting legacy repos count
  const cacheAwaitingData = cloneDeep<IAwaitingLegacyReposCountQuery | null>(
    client.readQuery({
      query: AwaitingLegacyReposCountDocument,
    }),
  );

  if (!cacheAwaitingData) return;

  client.writeQuery({
    query: AwaitingLegacyReposCountDocument,
    data: {
      legacyRepos: {
        ...cacheAwaitingData?.legacyRepos,
        count: nodesCount,
      },
    },
  });
};
