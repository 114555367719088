import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconAgreement = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 45"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.06 2h21.627c3.7 0 6.709 3.012 6.709 6.715V42H12.06V28.19l-3.928 2.004V21.19a8.461 8.461 0 0 1-.697-.623 8.261 8.261 0 0 1-2.435-5.88c0-2.22.865-4.31 2.435-5.88a8.253 8.253 0 0 1 4.625-2.342V2zm24.403 5.824c.542 0 1.065.108 1.546.314a4.371 4.371 0 0 0-3.924-3.772c.22.495.342 1.041.342 1.616v1.842h2.036zM9.095 10.466a5.93 5.93 0 0 0-1.748 4.22c0 1.595.62 3.094 1.748 4.22a5.93 5.93 0 0 0 4.22 1.749 5.93 5.93 0 0 0 4.22-1.748c1.128-1.127 1.749-2.626 1.749-4.22s-.621-3.093-1.748-4.22c-1.128-1.128-2.627-1.749-4.22-1.749s-3.094.621-4.22 1.748zm4.22 12.536a8.32 8.32 0 0 1-2.836-.494v3.855l2.836-1.447 2.837 1.447v-3.855a8.32 8.32 0 0 1-2.837.494zm1.092 5.106v11.545h23.64V11.85a1.684 1.684 0 0 0-.474-1.22 1.546 1.546 0 0 0-1.11-.459H32.08V5.982c0-.837-.628-1.529-1.437-1.635H14.407v2.095a8.253 8.253 0 0 1 4.788 2.365 8.262 8.262 0 0 1 2.436 5.88c0 1.705-.51 3.331-1.458 4.705h13.8v2.348H18.498v2.347h15.475v2.347H18.498v3.761l-4.092-2.087zm19.566-13.412h-8.36v2.347h8.36v-2.347zm-20.66 3.676A3.69 3.69 0 0 0 17 14.686 3.69 3.69 0 0 0 13.314 11c-2.033 0-3.686 1.654-3.686 3.686s1.653 3.686 3.686 3.686zm0-5.025a1.34 1.34 0 0 1 0 2.677 1.34 1.34 0 0 1-1.338-1.338c0-.738.6-1.339 1.339-1.339zm14.215 18.108 1.281 1.282 3.408-3.408 1.66 1.66-5.068 5.067-2.94-2.94 1.659-1.66z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconAgreement);
export default Memo;
