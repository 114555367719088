import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

type Props = {
  order: string;
  onSearchChange: (value: string) => void;
  onOrderChange: () => void;
};

const EscrowsActionBar: React.FunctionComponent<Props> = ({ order, onSearchChange, onOrderChange }) => {
  return (
    <div className="d-flex align-items-center justify-content-between active-deposits__toolbar">
      <Input
        placeholder="Search escrows"
        className="active-homepage__content-search"
        prefix={<SearchOutlined className="site-form-item-icon" />}
        allowClear
        onChange={(e) => onSearchChange(e.target.value)}
      />

      <div className="d-flex active-deposits__btns">
        <Button className="ml-2 d-flex align-items-center" onClick={onOrderChange}>
          {order === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
        </Button>
      </div>
    </div>
  );
};

export default EscrowsActionBar;
