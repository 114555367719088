import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSigned = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 3.125h7.89c1.164 0 2.11.946 2.11 2.11v8.828a.937.937 0 1 1-1.875 0V5.233A.235.235 0 0 0 17.891 5H1.875v11.016c0 .129.105.234.234.234h9.141a.937.937 0 1 1 0 1.875H2.11A2.112 2.112 0 0 1 0 16.015V2.11C0 .946.946 0 2.11 0h5.78C9.055 0 10 .946 10 2.11v1.015Zm-2.11-1.25H2.11a.235.235 0 0 0-.235.234v1.016h6.25V2.109a.235.235 0 0 0-.234-.234Zm7.108 13.438a.937.937 0 1 1 1.875 0v.937h.938a.937.937 0 1 1 0 1.875h-.938v.938a.937.937 0 1 1-1.875 0v-.938h-.937a.937.937 0 1 1 0-1.875h.937v-.938Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSigned);
export default Memo;
