import { Form, Row, Col, Input } from 'antd';
import { debounce } from 'lodash';
import { useCallback } from 'react';

import { TextBlock } from 'src/components/Misc';
import { CustomCheckboxWithIcon } from 'src/components/Form';

import { escrowReleaseOptions } from '../escrowAgreement.enum';
import sharedStyles from '../../Escrow.module.scss';

import styles from './EscrowAgreementEdit.module.scss';

type Props = {
  onChangeConditions: (value: Record<string, boolean>) => void;
  onChangeCustomCondition: (value: Record<string, string>) => void;
  releaseConditions: {
    releaseBankruptcy: boolean;
    releaseInsolvency: boolean;
    releaseMaintenance: boolean;
    releaseCustom: boolean;
  };
  releaseCustomConditions?: string;
};

const ReleaseConditionsSelect: React.FunctionComponent<Props> = ({
  onChangeConditions,
  onChangeCustomCondition,
  releaseConditions,
  releaseCustomConditions,
}) => {
  const isRequired = Object.values(releaseConditions).every((value) => value === false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomConditionsChange = useCallback(
    debounce((value: Record<string, string>) => onChangeCustomCondition(value), 500),
    [onChangeCustomCondition],
  );

  return (
    <div className={`${sharedStyles['bb--dashed']} ${sharedStyles['mb--40']}`}>
      <TextBlock
        className={sharedStyles['mb--20']}
        title="Release Conditions"
        titleColor="dark-blue"
        as="h4"
        text="Select the specific conditions that you and your counterparty agreed on. The material will be released when these events are triggered."
        segment={isRequired && <i className={sharedStyles.segment}>Required</i>}
      />
      <div className={sharedStyles['container--990']}>
        <Form
          onValuesChange={onChangeConditions}
          initialValues={{
            ...releaseConditions,
          }}
        >
          <div className={`ck-custom-checkbox-group ${sharedStyles['mb--40']}`}>
            <Row className={`ck-custom-radio-group__row ${sharedStyles['card-list__row']}`} gutter={[16, 16]}>
              {escrowReleaseOptions.map((option) => {
                const isSelected = option?.name && releaseConditions[option.name as keyof typeof releaseConditions];

                return (
                  <Col span={5} key={option.name}>
                    <Form.Item name={option.name} noStyle>
                      <CustomCheckboxWithIcon
                        selected={isSelected ? true : false}
                        valuePropName="checked"
                        {...option}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Form>

        {releaseConditions?.releaseCustom && (
          <div className={sharedStyles['mb--40']}>
            <Col span={19}>
              <Form
                className="ck-agreement__form-other"
                onValuesChange={handleCustomConditionsChange}
                initialValues={{
                  releaseCustomConditions,
                }}
                layout="vertical"
              >
                <Form.Item
                  className="ck-agreement__form-item"
                  name="releaseCustomConditions"
                  label="Please describe your release condition"
                  rules={[
                    {
                      required: true,
                      message: 'Please describe your release condition!',
                    },
                  ]}
                >
                  <Input.TextArea className={styles.texatarea} placeholder="Your release condition" rows={8} />
                </Form.Item>
              </Form>
            </Col>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReleaseConditionsSelect;
