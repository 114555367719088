import { JourneyLayout } from 'src/components/Journey';

import HeaderContainer from './CustodianHeaderContainer';
import StatusIndicatorContainer from './CustodianStatusIndicatorContainer';
import MenuContainer from './CustodianMenuContainer';
import AlertBannerContainer from './CustodianAlertBannerContainer';
import CustodianFooterContainer from './CustodianFooterContainer';
import CustodianNavigationContainer from './CustodianNavigationContainer';

const CustodianLayoutContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <JourneyLayout header={<HeaderContainer />} statusIndicator={<StatusIndicatorContainer />} menu={<MenuContainer />}>
      <AlertBannerContainer />
      {children}
      <CustodianFooterContainer />
      <CustodianNavigationContainer />
    </JourneyLayout>
  );
};

export default CustodianLayoutContainer;
