import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { DepositsContextProvider } from 'src/context/suggest-changes';
import { withEscrowGuard } from 'src/hoc/withEscrowGuard';

import DepositsAssetsContainer from './DepositsAssetsContainer';
import DepositsSuggestChangesActions from './SuggestChanges/DepositsSuggestChangesActions';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowDepositsContainer = () => {
  return (
    <DepositsContextProvider>
      <EscrowModalMain withPadding>
        <DepositsAssetsContainer />
      </EscrowModalMain>
      <DepositsSuggestChangesActions />
    </DepositsContextProvider>
  );
};

export default withEscrowGuard(EscrowDepositsContainer);
