import env from 'env';

export const benefitList = [
  {
    id: 'compliance-alignment',
    title: 'Compliance Alignment',
    text: 'Without proper solutions in place, you may face legal and regulatory challenges.',
  },
  {
    id: 'recovery-readiness',
    title: 'Recovery readiness',
    text: 'Your plan means that your critical software assets are secure and easily recoverable.',
  },
  {
    id: 'risk-mitigation',
    title: 'Risk mitigation',
    text: 'Without protection against unauthorized access and data breaches, you risk information loss and vendor dependency.',
  },
  {
    id: 'access-to-agreements',
    title: 'Access to agreements',
    text: 'Canceling your account means terminating your active escrows and backups before the cancelation is initiated.',
  },
];

export const solutionsList = [
  {
    id: 'another',
    title: 'Need another solution?',
    text: 'We offer Disaster Recovery Solutions fit for different levels of protection that your business needs. <a href="https://codekeeper.co/solutions.html" target="_blank">Explore other solutions</a>',
  },
  {
    id: 'alternative',
    title: 'Found alternative provider?',
    text: `Let us know and we can offer you a lower rate. Send us a message with your invoice or quote at <a href="mailto: ${env.CODEKEEPER_SERVICE_EMAIL}">${env.CODEKEEPER_SERVICE_EMAIL}</a>`,
  },
];
