import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'src/context/auth/use-auth';

const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { inProgress, isSignedIn } = useAuth();
  const location = useLocation();

  if (inProgress) {
    return <></>;
  }

  if (!isSignedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
