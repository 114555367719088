import { AlertActionMessage } from 'src/components/Alert';
import { useSaasEscrow } from 'src/context/saas';
import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

import type { IAlertActionMessageProps } from 'src/components/Alert';

const SaasAlertBannerContainer = () => {
  const { environment } = useSaasEscrow();

  let alertData: IAlertActionMessageProps | null = null;

  if (environment.status === SaasEnvironmentStatusEnum.Terminated) {
    alertData = {
      bgColor: 'red',
      text: 'You are viewing a terminated SaaS escrow.',
      color: 'red',
    };
  }

  if (environment.saasEnvironmentTermination) {
    alertData = {
      bgColor: 'red',
      text: "This SaaS escrow is pending termination. Please revoke Codekeeper's access to your platforms.",
      color: 'red',
    };
  }

  return alertData ? <AlertActionMessage {...alertData} /> : null;
};

export default SaasAlertBannerContainer;
