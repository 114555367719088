import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageCustodian = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 28"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#0B47A2"
      d="M.387 16.283V2.717A2.07 2.07 0 0 1 2.45.643h19.097a2.07 2.07 0 0 1 2.065 2.074v13.566a7.79 7.79 0 0 1-3.648 6.604L13.09 27.19a2.06 2.06 0 0 1-2.183 0l-6.873-4.304a7.79 7.79 0 0 1-3.648-6.604"
    />
    <path
      fill="#013685"
      fillRule="evenodd"
      d="M12 27.504V.643h9.548a2.07 2.07 0 0 1 2.065 2.074v13.566a7.79 7.79 0 0 1-3.648 6.604l-6.873 4.304c-.335.209-.713.313-1.092.313"
      clipRule="evenodd"
    />
    <path
      fill="#0B47A2"
      fillRule="evenodd"
      d="M2.452 1.028c-.927 0-1.678.75-1.678 1.676v13.493a7.35 7.35 0 0 0 3.466 6.24l6.873 4.28a1.68 1.68 0 0 0 1.774 0l6.873-4.28a7.35 7.35 0 0 0 3.466-6.24V2.704c0-.926-.751-1.676-1.678-1.676zM0 2.704A2.45 2.45 0 0 1 2.452.254h19.096A2.45 2.45 0 0 1 24 2.704v13.493a8.13 8.13 0 0 1-3.83 6.897l-6.874 4.28c-.793.494-1.799.494-2.592 0l-6.874-4.28A8.13 8.13 0 0 1 0 16.197z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.58 12.649c0-2.712 1.937-4.963 4.479-5.398l1.087 1.131-1.416 1.473a3.06 3.06 0 0 0-1.778 2.794c0 1.491 1.048 2.733 2.435 3.004l-.707.735a.53.53 0 0 0 0 .732l.955.993c-2.82-.17-5.055-2.551-5.055-5.464m6.406 5.373c2.477-.489 4.346-2.709 4.346-5.373 0-2.855-2.147-5.2-4.887-5.452l.788.82a.53.53 0 0 1 0 .731l-.84.874c1.452.215 2.568 1.488 2.568 3.027a3.06 3.06 0 0 1-2.03 2.894l-1.164 1.211zm-1.03-12.215c-3.711 0-6.72 3.063-6.72 6.842s3.009 6.842 6.72 6.842c3.712 0 6.72-3.063 6.72-6.842s-3.008-6.842-6.72-6.842m-1.66 6.842c0-.934.744-1.69 1.66-1.69.918 0 1.66.756 1.66 1.69s-.742 1.69-1.66 1.69c-.916 0-1.66-.757-1.66-1.69"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageCustodian);
export default Memo;
