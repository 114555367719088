import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSecureUpload = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M32 5.647h-3.765V1.882H13.541L12.914 0H2.824v19.292A7.04 7.04 0 0 0 0 24.941 7.067 7.067 0 0 0 7.059 32h11.294a7.03 7.03 0 0 0 4.793-1.882H32zm-5.647-1.882v1.882H14.796l-.627-1.882zM11.557 1.882l1.882 5.647h16.679v1.883H4.706v-7.53zm6.796 28.236H7.059a5.18 5.18 0 0 1-5.177-5.177 5.16 5.16 0 0 1 3.206-4.788l.645-.266c-.056-.612-.086-.783-.086-1.064a3.77 3.77 0 0 1 3.765-3.764c1.078 0 2.107.465 2.823 1.275l.55.623.686-.468a2.827 2.827 0 0 1 4.412 2.324l-.01.9.879.067c2.679.203 4.777 2.47 4.777 5.161a5.18 5.18 0 0 1-5.176 5.177m6.241-1.883a7.061 7.061 0 0 0-4.9-10.225 4.714 4.714 0 0 0-6.598-3.464 5.644 5.644 0 0 0-8.39 1.16v-4.412h25.412v16.941z"
      />
      <path
        fill="currentColor"
        d="m7.806 21.923 1.33 1.33 1.218-1.216v6.198h1.882v-6.198l1.217 1.217 1.33-1.331-3.488-3.49z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSecureUpload);
export default Memo;
