import { Divider } from 'antd';
import { useSuspenseQuery } from '@apollo/client';

import { Main } from 'src/components/Layout';
import { IntegrationsDocument, IIntegrationsQuery } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { USER_INTEGRATIONS_LIMIT } from 'src/shared/constants';

import ActiveIntegrations from './components/ActiveIntegrations';
import StaticIntegrations from './components/StaticIntegrations';

const IntegrationsContent = () => {
  const { data } = useSuspenseQuery<IIntegrationsQuery>(IntegrationsDocument, {
    variables: {
      page: 1,
      perPage: USER_INTEGRATIONS_LIMIT,
    },
  });

  return (
    <Main title="Integrations" className="integrations -small">
      {/* Active integrations */}
      <p className="active-integrations__title">Active integrations</p>
      <ActiveIntegrations integrations={data.integrations.nodes} />
      <Divider />

      {/* Static integrations */}
      <p className="active-integrations__title">Select the integration you would like to activate</p>
      <StaticIntegrations />

      <p className="active-integrations__text">
        Don&apos;t see the platform you prefer or need a custom integration? Contact our{' '}
        <a href="https://codekeeper.co/contact.html" target="_blank" rel="noopener noreferrer">
          Customer Support
        </a>
      </p>
    </Main>
  );
};

export default withSuspense(IntegrationsContent);
