import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconAddFile = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 45"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M38.877 9.733 31.144 2H10.697v4.775H6.001V42h28.18v-4.775h4.696V9.733zm-7.045-3.724 3.114 3.114h-3.114V6.01zm0 33.643H8.349V9.124h2.348v28.102h21.135v2.427zm-18.787-4.775V4.349h16.438v7.123h7.045v23.405H13.045zM24.71 13.743c-5.18 0-9.393 4.214-9.393 9.393s4.214 9.394 9.393 9.394c5.18 0 9.393-4.214 9.393-9.394s-4.214-9.393-9.393-9.393zm0 16.438a7.053 7.053 0 0 1-7.045-7.045 7.053 7.053 0 0 1 7.045-7.045 7.053 7.053 0 0 1 7.045 7.045 7.053 7.053 0 0 1-7.045 7.045zm-1.176-11.782h2.349v3.522h3.522v2.349h-3.522v3.522h-2.349V24.27h-3.522V21.92h3.522V18.4z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconAddFile);
export default Memo;
