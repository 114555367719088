import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageEmptyBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 184 152"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#F5F5F7"
      fillOpacity={0.8}
      d="M91.797 151.228c37.443 0 67.797-5.672 67.797-12.668 0-6.996-30.354-12.668-67.797-12.668S24 131.564 24 138.56c0 6.996 30.354 12.668 67.797 12.668Z"
    />
    <path
      fill="#AEB8C2"
      fillRule="evenodd"
      d="m146.034 101.344-23.925-29.445c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L37.56 101.344v15.383h108.475v-15.383h-.001Z"
      clipRule="evenodd"
    />
    <path
      fill="#F5F5F7"
      fillRule="evenodd"
      d="M57.83 31.67h67.933a4.002 4.002 0 0 1 4 4v93.344a3.999 3.999 0 0 1-4 4H57.83a3.997 3.997 0 0 1-4-4V35.67a4 4 0 0 1 4-4Z"
      clipRule="evenodd"
    />
    <path
      fill="#DCE0E6"
      fillRule="evenodd"
      d="M66.678 41.623h50.237a1.998 1.998 0 0 1 2 2V68.58a2 2 0 0 1-2 2H66.678a2 2 0 0 1-2-2V43.623a2 2 0 0 1 2-2Zm.262 39.814h49.713a2.264 2.264 0 0 1 2.262 2.262 2.262 2.262 0 0 1-2.262 2.262H66.94a2.262 2.262 0 1 1 0-4.524Zm0 11.763h49.713a2.262 2.262 0 1 1 0 4.525H66.94a2.262 2.262 0 0 1 0-4.525Zm78.873 43.502c-.775 3.071-3.497 5.36-6.735 5.36H44.515c-3.238 0-5.96-2.29-6.734-5.36a7.305 7.305 0 0 1-.222-1.79v-33.567h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393v-.012c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569a7.33 7.33 0 0 1-.221 1.789ZM149.121 33.292l-6.83 2.65a1.003 1.003 0 0 1-1.045-.201.995.995 0 0 1-.272-1.029l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M170.304 21.365c1.574 0 2.849-1.26 2.849-2.815s-1.275-2.815-2.849-2.815c-1.573 0-2.849 1.26-2.849 2.815s1.276 2.815 2.849 2.815Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M155.348 21.013h-5.698l2.898-4.926 2.8 4.926Zm3.561-4.926h4.985v4.926h-4.985v-4.926Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageEmptyBox);
export default Memo;
