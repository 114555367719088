import { ModalProceed } from 'src/components/Misc';
import env from 'env';

type Props = {
  isOpen: boolean;
  type: 'accept' | 'reject';
  onCancel: () => void;
  onProceed: () => void;
  isLoading?: boolean;
};

const SuggestChangesActionModal = ({ isOpen, onCancel, type, onProceed, isLoading }: Props) => {
  const actionContent = {
    accept: {
      title: 'Accept requested changes?',
      text: `These changes may have an effect on the escrow agreement terms. If you're unsure about these changes, reach out to us at <a href="mailto: ${env.CODEKEEPER_SERVICE_EMAIL}">${env.CODEKEEPER_SERVICE_EMAIL}</a>`,
      btnText: 'Accept & Send for Review',
    },
    reject: {
      title: 'Reject requested changes?',
      text: 'The escrow will not be updated and the counterparty will get notified that their requests have been rejected.',
      btnText: 'Reject',
    },
  };

  return (
    <ModalProceed
      isOpen={isOpen}
      onCancel={onCancel}
      title={actionContent[type].title}
      text={actionContent[type].text}
      proceedText={actionContent[type].btnText}
      isLoading={isLoading}
      onProceed={onProceed}
    />
  );
};

export default SuggestChangesActionModal;
