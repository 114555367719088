import { useNavigate } from 'react-router-dom';

import { JourneyModalHeader } from 'src/components/Journey/ModalLayout';
import { labelStatusTypeEnum as statusTypeEnum, OtherEscrowStatusEnum } from 'src/components/Escrow/escrow.enum';
import { useEscrow } from 'src/context/escrow';
import { useClientChangesRequestedQuery, EscrowStatusEnum } from 'src/graphql/schema';

const EscrowModalHeaderContainer = () => {
  const { escrow } = useEscrow();
  const { data: changesRequestedData } = useClientChangesRequestedQuery();
  const navigate = useNavigate();

  const onClose = () => navigate('/escrows');

  let status: EscrowStatusEnum | OtherEscrowStatusEnum = escrow?.status;

  if (escrow?.pendingActivation) status = OtherEscrowStatusEnum.ActivationProcess;

  if (changesRequestedData?.clientChangesRequested.isRequested) status = OtherEscrowStatusEnum.ChangesRequested;

  if (escrow?.status === EscrowStatusEnum.Onboarding && escrow?.agreement?.approved)
    status = OtherEscrowStatusEnum.AwaitingSignature;

  if (
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow?.status) &&
    escrow.escrowTermination
  )
    status = OtherEscrowStatusEnum.TerminationRequested;

  const statusLabel: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
    status && status in statusTypeEnum ? statusTypeEnum[status as keyof typeof statusTypeEnum] : undefined;

  return (
    <JourneyModalHeader
      title={[escrow?.depositor?.companyName, escrow?.beneficiary?.companyName].filter(Boolean).join(' - ')}
      onClose={onClose}
      statusLabel={statusLabel}
    />
  );
};

export default EscrowModalHeaderContainer;
