import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImage404 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 373 270"
    role="img"
    className={props.className}
    {...props}
  >
    <filter id="a" width={329} height={270} x={10} y={0} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={15} />
      <feGaussianBlur stdDeviation={22.5} />
      <feColorMatrix values="0 0 0 0 0.719566 0 0 0 0 0.748166 0 0 0 0 0.795833 0 0 0 0.25 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
    </filter>
    <filter
      id="b"
      width={138.538}
      height={138.538}
      x={234}
      y={105}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dx={9} dy={20} />
      <feGaussianBlur stdDeviation={20} />
      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.351917 0 0 0 0 0.429167 0 0 0 0.25 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
    </filter>
    <filter
      id="c"
      width={128.357}
      height={128.357}
      x={0}
      y={43}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dx={9} dy={20} />
      <feGaussianBlur stdDeviation={20} />
      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.351917 0 0 0 0 0.429167 0 0 0 0.25 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
    </filter>
    <g filter="url(#a)">
      <rect width={239} height={180} x={55} y={30} fill="#fff" rx={30} />
    </g>
    <rect width={30} height={17} x={310} y={111} fill="#ff7c74" rx={8.5} transform="rotate(180 310 111)" />
    <rect width={30} height={17} x={38} y={137} fill="#ffc634" rx={8.5} transform="rotate(180 38 137)" />
    <rect width={24} height={17} x={144} y={219} fill="#ffc634" rx={8.5} transform="rotate(180 144 219)" />
    <g filter="url(#b)">
      <rect width={58.538} height={58.538} x={265} y={125} fill="#ffc634" rx={20} />
    </g>
    <path
      fill="#fcfdfd"
      fillRule="evenodd"
      d="M294.5 139a2.5 2.5 0 0 0-2.5 2.5v15a2.5 2.5 0 1 0 5 0v-15a2.5 2.5 0 0 0-2.5-2.5m0 23a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7"
      clipRule="evenodd"
    />
    <g filter="url(#c)">
      <rect width={48.357} height={48.357} x={31} y={63} fill="#ff7c74" rx={17} />
    </g>
    <path
      fill="#fdfefe"
      d="M55 99c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12m4.42-15.005a1 1 0 1 0-1.415-1.414L55 85.586l-3.005-3.005a.997.997 0 0 0-1.414 0 1 1 0 0 0 0 1.414L53.586 87l-3.005 3.005a1 1 0 1 0 1.414 1.414L55 88.414l3.005 3.005a1 1 0 1 0 1.414-1.414L56.414 87z"
    />
    <path
      fill="#ff7c74"
      fillRule="evenodd"
      d="M143 90a6 6 0 0 0-6 6v18h-12V96a6 6 0 1 0-12 0v24a6 6 0 0 0 6 6h18v18a6 6 0 1 0 12 0V96a6 6 0 0 0-6-6m88.001 0a6 6 0 0 0-6 6v18h-12V96a6 6 0 1 0-12 0v24a6 6 0 0 0 6 6h18v18a6 6 0 1 0 12 0V96a6 6 0 0 0-6-6M163 90h24a6 6 0 0 1 6 6v48a6 6 0 0 1-6 6h-24a6 6 0 0 1-6-6V96a6 6 0 0 1 6-6m6 48h12v-36h-12z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImage404);
export default Memo;
