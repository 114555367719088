import { Button } from 'antd';

import { JourneyFooter as EscrowModalFooter } from 'src/components/Journey';
import { useEscrow } from 'src/context/escrow';
import { useContactsSuggestChanges } from 'src/context/suggest-changes';
import { SuggestedChangeStatusEnum } from 'src/graphql/schema';
import RequestTerminationActionContainer from 'src/containers/Escrow/Layout/Actions/RequestTerminationActionContainer';

import ActionSuggestChangesContainer from '../../SuggestChanges/ActionSuggestChangesContainer';

const ContactsSuggestChangesActions = () => {
  const { escrow, ui, setSuggestChangesMode, setSendModalOpen } = useEscrow();
  const { isLoading, suggestedChanges } = useContactsSuggestChanges();

  const handleDiscardChanges = () => setSuggestChangesMode(false);

  if (isLoading || escrow.escrowTermination) return null;

  if (!ui.isSuggestChangesMode && suggestedChanges) {
    const otherPartyHasToAct =
      suggestedChanges?.creator !== escrow.currentCompanyRole &&
      suggestedChanges?.status === SuggestedChangeStatusEnum.Pending;
    if (otherPartyHasToAct)
      return (
        <EscrowModalFooter justify="space-between">
          <EscrowModalFooter.Item>
            <RequestTerminationActionContainer />
          </EscrowModalFooter.Item>

          <EscrowModalFooter.Group>
            <EscrowModalFooter.Item>
              <ActionSuggestChangesContainer type="reject" suggestedChangeId={suggestedChanges.id || ''} />
            </EscrowModalFooter.Item>
            <EscrowModalFooter.Item>
              <ActionSuggestChangesContainer type="accept" suggestedChangeId={suggestedChanges.id || ''} />
            </EscrowModalFooter.Item>
          </EscrowModalFooter.Group>
        </EscrowModalFooter>
      );
    return null;
  }

  if (!ui.isSuggestChangesMode) return null;

  return (
    <EscrowModalFooter justify="end">
      <EscrowModalFooter.Item>
        <Button onClick={handleDiscardChanges}>Discard Changes</Button>
      </EscrowModalFooter.Item>
      <EscrowModalFooter.Item>
        <Button type="primary" disabled={ui.isSendForReviewBtnDisabled} onClick={() => setSendModalOpen(true)}>
          Send Suggestions
        </Button>
      </EscrowModalFooter.Item>
    </EscrowModalFooter>
  );
};

export default ContactsSuggestChangesActions;
