import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageBackups = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 28"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#D9FCFF"
      d="M.387 16.03V2.462A2.07 2.07 0 0 1 2.45.39h19.097a2.07 2.07 0 0 1 2.065 2.074V16.03a7.79 7.79 0 0 1-3.648 6.604l-6.874 4.304a2.06 2.06 0 0 1-2.183 0l-6.873-4.304a7.79 7.79 0 0 1-3.648-6.604"
    />
    <path
      fill="#BDF2F7"
      fillRule="evenodd"
      d="M12 27.25V.39h9.548a2.07 2.07 0 0 1 2.065 2.074V16.03a7.79 7.79 0 0 1-3.648 6.604l-6.873 4.304c-.335.209-.713.313-1.092.313"
      clipRule="evenodd"
    />
    <path
      fill="#BDF2F7"
      fillRule="evenodd"
      d="M2.452.774c-.927 0-1.678.75-1.678 1.677v13.492a7.35 7.35 0 0 0 3.466 6.24l6.873 4.28a1.68 1.68 0 0 0 1.774 0l6.873-4.28a7.35 7.35 0 0 0 3.466-6.24V2.451c0-.926-.751-1.677-1.678-1.677zM0 2.45A2.45 2.45 0 0 1 2.452 0h19.096A2.45 2.45 0 0 1 24 2.45v13.493a8.13 8.13 0 0 1-3.83 6.897l-6.874 4.28c-.793.494-1.799.494-2.592 0L3.83 22.84A8.13 8.13 0 0 1 0 15.943z"
      clipRule="evenodd"
    />
    <path
      fill="#009CB7"
      fillRule="evenodd"
      d="M16.983 5.446a.82.82 0 0 0-.818.822v1.351a6.38 6.38 0 0 0-6.09-1.145 6.4 6.4 0 0 0-3.222 2.4 6.465 6.465 0 0 0 .16 7.653 6.4 6.4 0 0 0 3.32 2.26 6.38 6.38 0 0 0 4.006-.163 6.4 6.4 0 0 0 3.126-2.523.824.824 0 0 0-.24-1.137.815.815 0 0 0-1.131.242 4.78 4.78 0 0 1-2.329 1.88 4.75 4.75 0 0 1-2.985.122 4.78 4.78 0 0 1-2.473-1.684 4.815 4.815 0 0 1-.119-5.702 4.78 4.78 0 0 1 2.401-1.787 4.75 4.75 0 0 1 4.622.925h-1.724a.82.82 0 0 0-.818.821.82.82 0 0 0 .818.822h3.496a.82.82 0 0 0 .817-.822V6.268a.82.82 0 0 0-.817-.822"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageBackups);
export default Memo;
