import { useEffect } from 'react';
import { Button, Form, Input } from 'antd';

export type IUserInfoForm = {
  name: string;
  phone: string;
};

type Props = {
  onSubmit: (values: IUserInfoForm) => Promise<void>;
  initialValues?: Partial<IUserInfoForm>;
  isLoading: boolean;
};

const UserInfoForm = ({ initialValues, onSubmit, isLoading = false }: Props) => {
  const [form] = Form.useForm<IUserInfoForm>();

  useEffect(() => {
    form.setFieldsValue(initialValues as IUserInfoForm);
  }, [initialValues]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={{ ...initialValues }}>
      <Form.Item name="name" label="Full name" rules={[{ required: true, message: 'Please add your name!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="phone" label="Phone Number">
        <Input />
      </Form.Item>
      <div className="text-right">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default UserInfoForm;
