import { ImageEmptyBox } from 'src/components/Svg/Images';

import styles from './CustodianEmpty.module.scss';

type Props = {
  action: React.ReactNode;
};

const CustodianEmpty = ({ action }: Props) => {
  return (
    <div className={styles.block}>
      <ImageEmptyBox className={styles.icon} />
      <div className={styles.heading}>Create your first Custodian profile</div>
      <p className={styles.text}>
        Ensure accountability and compliance. Click the button below to begin setup of your first Custodian Profile.
      </p>
      {action}
    </div>
  );
};

export default CustodianEmpty;
