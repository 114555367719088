import { Fragment, useEffect, useState, useId } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { capitalize } from 'lodash';

import { IInviteDetail } from '../';

import styles from './EscrowContactsForm.module.scss';

export interface IPartyInvitationForm {
  name: string;
  email: string;
  phone?: string;
}

export type Props = {
  type: 'beneficiary' | 'depositor';
  onSubmit: (data: IPartyInvitationForm) => Promise<void>;
  onCancel: () => void;
  initialValues?: IInviteDetail;
};

const PartyInvitationForm: React.FunctionComponent<Props> = ({ type, onSubmit, onCancel, initialValues }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<IPartyInvitationForm>();
  const formId = useId();

  useEffect(() => {
    if (initialValues) {
      const { name, email, phone } = initialValues;

      form.setFieldsValue({
        name,
        email,
        phone: phone || '',
      });
    }
  }, [form, initialValues]);

  const handleSubmit = async (data: IPartyInvitationForm) => {
    setIsLoading(true);

    try {
      await onSubmit(data);
      onCancel();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {capitalize(type)} Contact Details<sub>Required</sub>
      </h3>
      <p className={styles.text}>
        The representative who will monitor the entire escrow configuration process. Some roles may have restrictions.
      </p>
      <Form
        form={form}
        layout="vertical"
        className="ck-escrow-contacts__form"
        onFinish={handleSubmit}
        aria-label="party-invitation-form"
      >
        <Row className={styles['row-md']}>
          <Col span={12} className={styles['col-md']}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]} htmlFor={`name-${formId}`}>
              <Input id={`name-${formId}`} />
            </Form.Item>
          </Col>

          <Col span={12} className={styles['col-md']}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: 'email' }]}
              htmlFor={`email-${formId}`}
            >
              <Input id={`email-${formId}`} />
            </Form.Item>
          </Col>

          <Col span={12} className={styles['col-md']}>
            <Form.Item
              name="phone"
              label={
                <Fragment>
                  Phone Number<sub>optional</sub>
                </Fragment>
              }
              rules={[{ required: false }]}
              htmlFor={`phone-${formId}`}
            >
              <Input id={`phone-${formId}`} />
            </Form.Item>
          </Col>
        </Row>

        <div className={styles['action-list']}>
          <div className={styles['action-list-right']}>
            <div className={styles['action-item']}>
              <Button type="default" onClick={onCancel}>
                Discard
              </Button>
            </div>
            <div className={styles['action-item']}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save details
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PartyInvitationForm;
