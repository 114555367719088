import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFilter = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M6.223 4.002c0-.479.39-.867.87-.867a.869.869 0 0 1 .87.887.869.869 0 0 1-.87.846.869.869 0 0 1-.87-.866Zm3.214.799a2.477 2.477 0 0 1-2.344 1.667 2.472 2.472 0 0 1-2.477-2.466 2.472 2.472 0 0 1 2.477-2.467c1.087 0 2.01.697 2.344 1.666h4.685c.444 0 .803.358.803.8 0 .442-.36.8-.803.8H9.437Zm1.673 2.334c-.48 0-.87.388-.87.867 0 .478.39.866.87.866s.87-.388.87-.866a.869.869 0 0 0-.87-.867Zm-2.477.867a2.472 2.472 0 0 1 2.477-2.467c1.086 0 2.01.697 2.344 1.666h.669c.443 0 .803.358.803.8 0 .442-.36.8-.803.8h-.669a2.477 2.477 0 0 1-2.344 1.668 2.472 2.472 0 0 1-2.477-2.467Zm-4.419 4c0-.479.39-.867.87-.867.481 0 .871.388.871.867 0 .478-.39.866-.87.866a.868.868 0 0 1-.87-.866ZM2.74 12.8h-.668a.802.802 0 0 1-.804-.8c0-.442.36-.8.804-.8h.67a2.477 2.477 0 0 1 2.343-1.666 2.472 2.472 0 0 1 2.477 2.467 2.472 2.472 0 0 1-2.477 2.466 2.477 2.477 0 0 1-2.345-1.667Zm5.893-.8c0-.442.36-.8.803-.8h4.687c.443 0 .803.358.803.8 0 .442-.36.8-.803.8H9.436a.802.802 0 0 1-.803-.8ZM2.072 7.2a.802.802 0 0 0-.804.8c0 .442.36.8.804.8h4.686a.802.802 0 0 0 .803-.8c0-.442-.36-.8-.803-.8H2.072ZM1.268 4c0-.442.36-.8.804-.8h.67c.443 0 .803.358.803.8 0 .442-.36.8-.804.8h-.67a.802.802 0 0 1-.803-.8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFilter);
export default Memo;
