import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoIbm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 512 205"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#1f70c1"
      d="M0 0v14.012h99.66V0zm113.78 0v14.012H255.8S241.293 0 222.08 0zm169.88 0v14.012h85.92L364.48 0zm147.52 0-5.1 14.012h85.14V0zM0 27.25v14.012h99.66V27.25zm113.78.02v13.992h158.5s-1.851-10.784-5.08-13.992zm169.88 0v13.992H379l-4.72-13.992zm137.32 0-4.72 13.992h94.96V27.27zM28.64 54.5V68.53H71.8V54.5zm113.78 0V68.53h43.16V54.5zm85.14 0V68.53h43.16s2.74-7.41 2.74-14.031zm84.74 0V68.53h76.52l-5.1-14.031zm99.28 0-5.12 14.031h76.9V54.5zM28.64 81.77V95.78H71.8V81.77zm113.78 0V95.78h110.24s9.222-7.19 12.16-14.012zm169.88 0V95.78h43.16v-7.8l2.74 7.8h79.06l2.94-7.8v7.8h43.16V81.77h-81.02l-4.3 11.869-4.32-11.869zM28.64 109.018v14.012H71.8v-14.012zm113.78 0v14.012h122.4c-2.939-6.8-12.16-14.012-12.16-14.012zm169.88 0v14.012h43.16v-14.012zm51 0 5.22 14.012h59.28l4.96-14.012zm76.9 0v14.012h43.16v-14.012zm-411.56 27.25v14.012H71.8v-14.012zm113.78 0v14.012h43.16v-14.012zm85.14 0v14.012h45.9c0-6.613-2.74-14.012-2.74-14.012zm84.74 0v14.012h43.16v-14.012zm60.8 0 5.06 14.012h39.74l5.1-14.012zm67.1 0v14.012h43.16v-14.012zM.78 163.519v14.031h99.66v-14.032zm113 0v14.031H267.2c3.229-3.213 5.08-14.032 5.08-14.032zm170.66 0v14.031h71.02v-14.032zm98.48 0 5.2 14.031h20.22l4.88-14.032zm57.28 0v14.031H512v-14.032zM.78 190.788V204.8h99.66v-14.012zm113 0v13.991h108.3c19.213 0 33.72-13.992 33.72-13.992zm170.66 0V204.8h71.02v-14.012zm108.34 0 5 13.971.86.02 5.08-13.992zm47.42 0V204.8H512v-14.012z"
    />
  </svg>
);
const Memo = memo(SvgLogoIbm);
export default Memo;
