import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconChangesRequested = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.8 0H3.729A1.924 1.924 0 0 0 1.8 1.92v7.07c0 .502.403.909.9.909s.9-.407.9-.91V1.92c0-.02.007-.04.029-.062a.14.14 0 0 1 .1-.039H9.9v4.394a1.96 1.96 0 0 0 1.95 1.97h4.35v9.899c0 .02-.007.04-.028.062a.14.14 0 0 1-.1.039H9.9c-.497 0-.9.407-.9.909s.403.909.9.909h6.172A1.924 1.924 0 0 0 18 18.08V6.76c0-.492-.189-.96-.522-1.314L12.914.606l-.651.627.651-.627A1.933 1.933 0 0 0 11.508 0H10.8Zm.9 1.954v4.258a.15.15 0 0 0 .15.152h4.007L11.7 1.954ZM8.961 8.45a.894.894 0 0 0-1.272 0l-7.425 7.5a.914.914 0 0 0-.264.643v2.5c0 .502.403.909.9.909h2.475a.895.895 0 0 0 .636-.267l7.425-7.5a.915.915 0 0 0 0-1.285l-2.475-2.5ZM1.8 18.184v-1.215l4.163-4.204 1.202 1.214-4.163 4.205H1.8Zm6.638-5.49-1.203-1.215 1.09-1.1 1.202 1.214-1.09 1.1Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconChangesRequested);
export default Memo;
