import React from 'react';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import CardWithIcon, { ICardWithIcon } from './CardWithIcon';
import styles from './CardWithIcon.module.scss';

type Props = {
  items: ICardWithIcon[];
  col?: keyof typeof colToSpanEnum;
  className?: string;
  layout?: 'vertical' | 'horizontal';
};

const colToSpanEnum = {
  1: 24,
  2: 12,
  3: 8,
  4: 6,
  5: 5,
  6: 4,
  8: 3,
  12: 2,
} as const;

const CardWithIconList: React.FunctionComponent<Props> = ({ className, items, col = 1, layout }) => {
  return (
    <Row gutter={[16, 16]} className={classNames('ck-card--with-icon__row', className)}>
      {items.map((option, index) => (
        <Col key={index} span={colToSpanEnum[col]}>
          <CardWithIcon {...option} className={styles['card-item']} layout={layout} />
        </Col>
      ))}
    </Row>
  );
};

export default CardWithIconList;
