import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Breadcrumb as AntdBreadcrumb } from 'antd';

import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

export type IBreadcrumbItem = ItemType;

type Props = {
  items: IBreadcrumbItem[];
  className?: string;
};

const itemRender = (currentRoute: IBreadcrumbItem, _: unknown, items: IBreadcrumbItem[], paths: string[]) => {
  const isLast = currentRoute?.path === items[items.length - 1]?.path;

  return isLast ? <span>{currentRoute.title}</span> : <Link to={`/${paths.join('/')}`}>{currentRoute.title}</Link>;
};

const Breadcrumb = ({ items, className }: Props) => {
  const breadcrumbClasses = classNames(className);

  return <AntdBreadcrumb itemRender={itemRender} items={items} className={breadcrumbClasses} />;
};

export default Breadcrumb;
