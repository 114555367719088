const k = 1024;
const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) return '0';

  const n = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / Math.pow(k, n)).toFixed(decimals)} ${sizes[n]}`;
};
