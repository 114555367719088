import { ApolloError } from '@apollo/client/errors';
import { useSuspenseQuery } from '@apollo/client';
import { startTransition, useCallback } from 'react';

import { SaasEnvironmentDocument, SaasProviderRoleEnum } from 'src/graphql/schema';
import { useSaasEscrowSlice } from 'src/slices';

import { createRequiredContext } from '../createRequiredContext';

import type {
  ISaasEnvironment,
  ISaasProvider,
  ISaasEnvironmentQuery,
  ISaasEnvironmentQueryVariables,
} from 'src/graphql/schema';

const [useSaasEscrow, SaasEscrowProvider] = createRequiredContext<
  {
    environment: ISaasEnvironment;
    hostingProviders: Partial<ISaasProvider>[];
    thirdPartyProviders: Partial<ISaasProvider>[];
    error?: ApolloError;
    ui: ReturnType<typeof useSaasEscrowSlice>['state']['ui'];
    refetch: (variables?: ISaasEnvironmentQueryVariables) => void;
  } & Omit<ReturnType<typeof useSaasEscrowSlice>, 'state'>
>();

type Props = {
  environmentId: string;
};

const SaasEscrowContextProvider = ({ environmentId, children }: React.PropsWithChildren<Props>) => {
  const { data, error, refetch } = useSuspenseQuery<ISaasEnvironmentQuery>(SaasEnvironmentDocument, {
    variables: {
      id: environmentId,
    },
    skip: !environmentId,
  });

  const handleRefetch = useCallback(
    (variables?: ISaasEnvironmentQueryVariables) =>
      startTransition(() => {
        refetch(variables);
      }),
    [],
  );

  const hostingProviders =
    data?.saasEnvironment?.saasProviders.filter(({ role }) => role === SaasProviderRoleEnum.Hosting) ?? [];
  const thirdPartyProviders =
    data?.saasEnvironment?.saasProviders.filter(({ role }) => role === SaasProviderRoleEnum.ThirdParty) ?? [];

  const { state, ...rest } = useSaasEscrowSlice();

  const providerValue = {
    environment: data?.saasEnvironment as ISaasEnvironment,
    hostingProviders,
    thirdPartyProviders,
    ui: state.ui,
    error,
    refetch: handleRefetch,
    ...rest,
  };

  return <SaasEscrowProvider value={providerValue}>{children}</SaasEscrowProvider>;
};

export { useSaasEscrow, SaasEscrowContextProvider };
