import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFolderCode = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.666 8.167v14h18.667v-14zM3.5 5.834h19.833a2.333 2.333 0 0 1 2.333 2.333v14a2.333 2.333 0 0 1-2.333 2.334H4.666a2.333 2.333 0 0 1-2.333-2.334V7.001A1.167 1.167 0 0 1 3.5 5.834"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.509 12.008a1.167 1.167 0 0 1 1.65 0l2.333 2.333a1.167 1.167 0 0 1 0 1.65l-2.333 2.333a1.167 1.167 0 0 1-1.65-1.65l1.508-1.508-1.508-1.508a1.167 1.167 0 0 1 0-1.65M12.492 12.008a1.167 1.167 0 0 1 0 1.65l-1.508 1.508 1.508 1.508a1.167 1.167 0 1 1-1.65 1.65l-2.333-2.333a1.167 1.167 0 0 1 0-1.65l2.333-2.333a1.167 1.167 0 0 1 1.65 0M2.333 5.833A2.333 2.333 0 0 1 4.666 3.5h6.517c.619 0 1.212.246 1.65.683l1.992 1.992a1.167 1.167 0 0 1-1.65 1.65l-1.992-1.992H4.666V7a1.167 1.167 0 1 1-2.333 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFolderCode);
export default Memo;
