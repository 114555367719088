import PropTypes from 'prop-types';
import { Button } from 'antd';

import { IOauthIntegration, OauthIntegrationProviderEnum } from 'src/graphql/schema';

import useIntegrationAuth from '../../hooks/use-integration-auth';

type Props = {
  integration: Partial<IOauthIntegration>;
  integrationName: string;
};

export default function Common({ integration, integrationName }: Props) {
  const { initAuth } = useIntegrationAuth(integration.provider as OauthIntegrationProviderEnum);

  return (
    <Button
      type="primary"
      className="d-flex align-items-center integration-modal-second__btn"
      onClick={() => initAuth()}
    >
      {`Connect ${integrationName} App`}
    </Button>
  );
}

Common.propTypes = {
  integration: PropTypes.object.isRequired,
  integrationName: PropTypes.string.isRequired,
};
