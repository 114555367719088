import { message } from 'src/components/Misc';
import {
  EditablePartyInvitationDetail,
  IPartyInvitationForm,
  InfoContentBlock,
  InvitationDetailBlock,
} from 'src/components/Escrow/Contacts';
import {
  EscrowPartyTypeEnum,
  EscrowDocument,
  useInviteOwnerBeneficiaryMutation,
  useInviteOwnerDepositorMutation,
  useUpdateEscrowInvitationMutation,
  IInviteOwnerDepositorMutationVariables,
  IEscrowInvitation,
} from 'src/graphql/schema';
import { getApiError, ClientError } from 'src/utils/errors';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';

type Props = {
  role: 'beneficiary' | 'depositor';
  escrowId: string;
  escrowInvitation?: IEscrowInvitation;
  escrowStatus: string;
};

const contentBlockTextEnum = {
  beneficiary: {
    heading: 'Beneficiary Information',
    text: 'These are the details of the company that will receive the material.',
  },
  depositor: {
    heading: 'Depositor Information',
    text: 'These are the details of the source code owner.',
  },
};

const SecondPartyInvitationContainer: React.FunctionComponent<Props> = ({
  role,
  escrowId,
  escrowInvitation,
  escrowStatus,
}) => {
  const [inviteBeneficiary] = useInviteOwnerBeneficiaryMutation({
    refetchQueries: [EscrowDocument],
  });
  const [inviteDepositor] = useInviteOwnerDepositorMutation({
    refetchQueries: [EscrowDocument],
  });
  const [updateInvitation] = useUpdateEscrowInvitationMutation({
    refetchQueries: [EscrowDocument],
  });

  const onSubmitInvitation = async ({ email, name, phone }: IPartyInvitationForm) => {
    const inviteByRole = role === EscrowPartyTypeEnum.Depositor ? inviteDepositor : inviteBeneficiary;
    const dataInviteType = role === EscrowPartyTypeEnum.Depositor ? 'inviteOwnerDepositor' : 'inviteOwnerBeneficiary';
    const variables: IInviteOwnerDepositorMutationVariables['administrativeInputUser'] = {
      name,
      email,
      phone,
    };

    try {
      const { data } = await inviteByRole({
        variables: {
          escrowId: escrowId,
          administrativeInputUser: variables,
        },
      });

      // @ts-expect-error /fix Property 'errors' does not exist on type '"Mutation"'.
      if (data?.[dataInviteType as keyof typeof data]?.errors?.length) {
        // @ts-expect-error /fix Property 'errors' does not exist on type '"Mutation"'.
        throw new ClientError(data?.[dataInviteType as keyof typeof data]?.errors[0]);
      } else {
        message.success('Saved!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const onUpdateInvitation = async ({ email, name, phone }: IPartyInvitationForm) => {
    try {
      const { data } = await updateInvitation({
        variables: {
          escrowInvitationId: escrowInvitation!.id,
          administrativeInputUser: {
            email,
            name,
            phone,
          },
        },
      });

      if (data?.updateEscrowInvitation?.errors?.length) {
        throw new ClientError(data?.updateEscrowInvitation?.errors[0]);
      } else {
        message.success('Saved!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const escrowInvitationData = {
    email: escrowInvitation?.user?.email || '',
    name: escrowInvitation?.user?.name || '',
    phone: escrowInvitation?.user?.phone || '',
  };

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <InfoContentBlock heading={contentBlockTextEnum[role].heading} text={contentBlockTextEnum[role].text} />

        {escrowStatus === 'onboarding' || escrowStatus === 'admin_review' ? (
          <InvitationDetailBlock type={role} {...escrowInvitationData} />
        ) : (
          <EditablePartyInvitationDetail
            type={role}
            onSubmit={escrowInvitation ? onUpdateInvitation : onSubmitInvitation}
            data={escrowInvitation && escrowInvitationData}
          />
        )}
      </div>
    </div>
  );
};

export default SecondPartyInvitationContainer;
