import { ModalProceed, message } from 'src/components/Misc';
import { useRemoveCustodianProfileMutation } from 'src/graphql/schema';
import { removeCustodianProfileCache } from 'src/graphql/client/cache';

type Props = {
  isOpen: boolean;
  profileId: string;
  closeModal: () => void;
};

const CustodianRemoveContainer = ({ isOpen, profileId, closeModal }: Props) => {
  const [removeCustodianProfile, { loading }] = useRemoveCustodianProfileMutation();

  const onProceed = async () => {
    try {
      await removeCustodianProfile({
        variables: {
          id: profileId,
        },
      });

      closeModal();
      message.success('Profile deleted successfully');
      removeCustodianProfileCache(profileId);
    } catch (error) {
      message.error('Failed to delete profile');
    }
  };

  return (
    <ModalProceed
      isOpen={isOpen}
      title="Delete profile"
      text="Are you sure you want to delete this draft? All the configured data in this profile will be deleted."
      onProceed={onProceed}
      onCancel={closeModal}
      proceedText="Delete"
      proceedButtonProps={{
        danger: true,
        ghost: true,
      }}
      isLoading={loading}
    />
  );
};

export default CustodianRemoveContainer;
