import { Fragment } from 'react';

import {
  IOrganizationDetail,
  IContactDetail,
  IOrganizationForm,
  ISignatoryDetail,
  ISignatoryForm,
  IAddContactForm,
} from '../';
import InfoContentBlock from '../InfoBlocks/InfoContentBlock';
import styles from '../EscrowContacts.module.scss';

import EditableOrganizationDetail from './EditableOrganizationDetail';
import EditableSignatoryDetail from './EditableSignatoryDetail';
import EditableContactList from './EditableContactList';

type Props = {
  role: 'beneficiary' | 'depositor';
  organization: IOrganizationDetail;
  isOwnOrganization?: boolean;
  signatory?: ISignatoryDetail;
  contacts: Array<IContactDetail & { isDynamic?: boolean }>;
  onSubmitOrganization: (data: IOrganizationForm) => void;
  onSubmitSignatory: (data: ISignatoryForm) => void;
  onSubmitContact: (id: string, data: IAddContactForm) => void;
  onRemoveContact: (id: string) => void;
};

const contentBlockTextEnum = {
  beneficiary: {
    heading: 'Beneficiary Information',
    text: 'These are the details of the company that will receive the material.',
  },
  depositor: {
    heading: 'Depositor Information',
    text: 'These are the details of the source code owner.',
  },
};

const EditableDetailsByRole: React.FunctionComponent<Props> = ({
  role,
  organization,
  isOwnOrganization = false,
  signatory,
  contacts,
  onSubmitOrganization,
  onSubmitSignatory,
  onSubmitContact,
  onRemoveContact,
}) => {
  return (
    <Fragment>
      <InfoContentBlock
        heading={contentBlockTextEnum[role].heading}
        headingLabel={isOwnOrganization ? '(your organization)' : ''}
        text={contentBlockTextEnum[role].text}
      />
      <div className={styles.block}>
        <EditableOrganizationDetail type={role} data={organization} onSubmit={onSubmitOrganization} />
      </div>
      <div className={styles.block}>
        <EditableSignatoryDetail type={role} data={signatory} onSubmit={onSubmitSignatory} />
      </div>
      <EditableContactList
        className={styles.block}
        type={role}
        contacts={contacts}
        onSubmitContact={onSubmitContact}
        onRemoveContact={onRemoveContact}
      />
    </Fragment>
  );
};

export default EditableDetailsByRole;
