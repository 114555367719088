import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconMaintenance = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M40 23.35v-6.7l-5.3-.883a15.183 15.183 0 0 0-1.313-3.167l3.124-4.374-4.737-4.737L27.4 6.613A15.17 15.17 0 0 0 24.233 5.3L23.35 0h-6.7l-.883 5.3A15.178 15.178 0 0 0 12.6 6.613L8.226 3.49 3.49 8.226 6.613 12.6a15.165 15.165 0 0 0-1.496 3.87H0V40h23.53v-5.117a15.167 15.167 0 0 0 3.87-1.496l4.374 3.124 4.737-4.737-3.124-4.374a15.17 15.17 0 0 0 1.313-3.167l5.3-.883ZM21.177 37.647H2.352V18.824h18.823v18.823Zm-8.62-21.176A8.233 8.233 0 0 1 20 11.765c4.54 0 8.235 3.694 8.235 8.235a8.233 8.233 0 0 1-4.706 7.443V16.471H12.557Zm20.88 15.048-1.918 1.918-4.013-2.866c-.419.231-1.748 1.253-3.977 1.883v-2.468A10.584 10.584 0 0 0 30.59 20c0-5.838-4.75-10.588-10.589-10.588-4.524 0-8.506 2.86-9.986 7.059H7.546c.631-2.232 1.66-3.572 1.883-3.977L6.563 8.48 8.48 6.563l4.013 2.866.667-.416c2.277-1.421 4.21-1.659 4.672-1.798l.81-4.862h2.713l.81 4.862.766.177c2.603.603 4.155 1.811 4.574 2.037l4.013-2.866 1.918 1.918-2.866 4.013.416.667c1.421 2.277 1.659 4.21 1.798 4.672l4.862.81v2.713l-4.862.81c-.134.447-.374 2.391-1.798 4.674l-.416.666 2.866 4.013Z"
      />
      <path
        fill="currentColor"
        d="M9.412 21.177H7.06v2.353H4.707v2.353h2.352v2.353h2.353v-2.353h9.412V23.53H9.412v-2.353ZM16.471 28.235h-2.353v2.353H4.707v2.353h9.411v2.353h2.353v-2.353h2.353v-2.353h-2.353v-2.353Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconMaintenance);
export default Memo;
