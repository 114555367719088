import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconGdpr = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="m14.284 3.503-1.158-.846h1.432L15 1.286l.442 1.371h1.432l-1.158.846.442 1.379L15 4.029l-1.158.853zm1.432 23.84.442 1.371L15 27.87l-1.158.845.442-1.371-1.158-.846h1.432L15 25.118l.442 1.38h1.432zM3.16 15.914l-1.158.854.442-1.372-1.158-.823h1.432l.442-1.371.442 1.371h1.44l-1.166.854.442 1.371-1.158-.853zM9.796 5.095l.442 1.372-1.158-.838-1.158.845.442-1.371-1.158-.846h1.432l.442-1.371.442 1.371h1.432zm-5.463 3.52.442-1.371.45 1.371h1.394l-1.158.854.442 1.371-1.166-.853-1.166.853.442-1.371-1.158-.854zM2.87 20.531h1.463l.442-1.371.45 1.371h1.394l-1.158.854.442 1.371-1.166-.846-1.166.846.442-1.371-1.158-.854zm5.494 5.212-1.158-.838h1.432l.442-1.372.442 1.372h1.432l-1.158.838.442 1.371-1.158-.845-1.158.845zm19.192-10.316.442 1.371-1.158-.853-1.173.853.441-1.371-1.165-.854h1.485l.442-1.371.442 1.371h1.402zM20.204 5.095l-1.158-.838h1.432l.442-1.371.442 1.371h1.432l-1.158.838.442 1.372-1.158-.838-1.158.845.442-1.371zm4.571 3.513.45-1.372.442 1.372h1.432l-1.128.86.442 1.372-1.158-.853-1.165.853.441-1.371-1.15-.854zm.892 11.923h1.432l-1.128.854.442 1.371-1.158-.846-1.165.846.441-1.371-1.15-.854h1.432l.45-1.371.442 1.371zm-2.873 4.374-1.158.838.442 1.371-1.158-.845-1.158.845.442-1.371-1.158-.838h1.432l.442-1.372.442 1.372z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconGdpr);
export default Memo;
