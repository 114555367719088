import { ProfileContextProvider } from 'src/context/profile';
import { ProfileLayout } from 'src/components/Profile/Layout';

const ProfileLayoutContainer = () => {
  return (
    <ProfileContextProvider>
      <ProfileLayout />
    </ProfileContextProvider>
  );
};

export default ProfileLayoutContainer;
