import { Main } from 'src/components/Layout';
import { CustodianListContainer, CreateCustodianProfileContainer } from 'src/containers/Custodian';

const CustodianPage = () => {
  return (
    <Main title="My Custodian" headerActions={<CreateCustodianProfileContainer />}>
      <CustodianListContainer />
    </Main>
  );
};

export default CustodianPage;
