import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Fragment } from 'react';

import PlanImg from 'src/assets/images/billing/plan.svg';
import PlanImg2 from 'src/assets/images/billing/plan2.svg';

import styles from './BillingExploreDetails.module.scss';

const BillingExploreDetails = () => {
  return (
    <Fragment>
      <div className={styles.explore}>
        <img className={styles.image} src={PlanImg} alt="plan" />
        <p className={styles.title}>From developers to enterprises we’ve got you covered!</p>
        <p className={styles.text}>Learn more about our other plans with the best prices in the market!</p>
        <Button type="primary" href="https://codekeeper.co/pricing.html" target="_blank">
          Explore other plans
        </Button>
      </div>

      <div className={`${styles.explore} ${styles.reverse}`}>
        <img className={styles.image} src={PlanImg2} alt="plan" />
        <p className={styles.title}>Custom plan</p>
        <p className={styles.text}>Need something different for your unique enterprise needs?</p>
        <div className={styles.info}>
          <p>
            <CheckOutlined style={{ color: '#30DFB5' }} className="mr-2" />
            Unlimited Escrow Agreements
          </p>
          <p>
            <CheckOutlined style={{ color: '#30DFB5' }} className="mr-2" />
            Fully customized features that you can fit to your enterprise
          </p>
        </div>
        <Button type="primary" ghost href="https://codekeeper.co/contact.html" target="_blank">
          Contact Us
        </Button>
      </div>
    </Fragment>
  );
};

export default BillingExploreDetails;
