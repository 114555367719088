import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

const steps = [
  'feedback',
  'review-changes',
  'exclusive-offer',
  'offer-confirmation',
  'pre-cancelation',
  'cancelation-finished',
  'offer-applied',
  'other-offer',
] as const;

enum ActionEnum {
  SET_STEP = 'set-step',
  SET_INITIAL_DATA = 'set-initial-data',
  GET_BACK = 'get-back',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA>
  | IAction<
      ActionEnum.SET_STEP,
      {
        step: (typeof steps)[number];
      }
    >
  | IAction<ActionEnum.GET_BACK>;

type IState = {
  step: (typeof steps)[number] | null;
  history: (typeof steps)[number][];
};

const initialState: IState = {
  step: null,
  history: [],
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_STEP:
      return {
        ...state,
        step: action.payload.step,
        history: [...state.history, action.payload.step],
      };
    case ActionEnum.GET_BACK: {
      const history = [...state.history];
      history.pop();

      return {
        ...state,
        step: history[history.length - 1],
        history,
      };
    }
    case ActionEnum.SET_INITIAL_DATA:
      return initialState;
    default:
      return state;
  }
};

export const useCancelationPlanSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setStep = (step: (typeof steps)[number]) => dispatch({ type: ActionEnum.SET_STEP, payload: { step } });

  const setInitialData = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });

  const getBack = () => dispatch({ type: ActionEnum.GET_BACK, payload: {} });

  return {
    ...state,
    setStep,
    setInitialData,
    getBack,
  };
};
