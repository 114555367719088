import { useProfileQuery, IProfile } from 'src/graphql/schema';

import { createRequiredContext } from '../createRequiredContext';

const [useProfile, ProfileProvider] = createRequiredContext<{
  profile: IProfile;
  isLoading: boolean;
  refetch: () => void;
}>();

const ProfileContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, loading, refetch } = useProfileQuery();

  const providerValue = {
    profile: data?.profile as IProfile,
    isLoading: loading,
    refetch,
  };

  return <ProfileProvider value={providerValue}>{children}</ProfileProvider>;
};

export { useProfile, ProfileContextProvider };
