import { useState, Fragment, useEffect } from 'react';
import { Select } from 'antd';
import { useSuspenseQuery } from '@apollo/client';

import { SaasEnvironmentsDocument, ISaasEnvironmentsQuery } from 'src/graphql/schema';
import { SaasEscrowContextProvider } from 'src/context/saas';
import { EmptySaas } from 'src/components/Saas/Empty';
import { withSuspense } from 'src/hoc';
import { ProviderCardList } from 'src/components/Saas/Provider';

import CreateNewEnvironmentContainer from '../Environments/CreateNewEnvironmentContainer';

import SaasEnvironmentsListContainer from './SaasEnvironmentsListContainer';

const SaasEscrowContextWithSuspense = withSuspense(
  ({ environmentId }: { environmentId: string }) => (
    <SaasEscrowContextProvider environmentId={environmentId}>
      <SaasEnvironmentsListContainer />
    </SaasEscrowContextProvider>
  ),
  () => (
    <Fragment>
      <ProviderCardList.Skeleton col={4} items={2} />
      <ProviderCardList.Skeleton col={4} items={3} />
    </Fragment>
  ),
);

const SaasEnvironmentsContainer = () => {
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState('');

  const { data } = useSuspenseQuery<ISaasEnvironmentsQuery>(SaasEnvironmentsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      perPage: 100,
    },
  });

  useEffect(() => {
    const onCompleted = ({ saasEnvironments }: ISaasEnvironmentsQuery) => {
      if (saasEnvironments.nodesCount) {
        setSelectedEnvironmentId(saasEnvironments.nodes[0].id);
      }
    };

    if (data) onCompleted(data);
  }, [data]);

  const environmentsOptions = data?.saasEnvironments?.nodes?.map((environment) => ({
    label: environment.name,
    value: environment.id,
  }));

  if (!data?.saasEnvironments.nodesCount)
    return (
      <div className="ck-saas__empty d-flex align-items-center justify-content-center">
        <EmptySaas
          type="environment"
          action={<CreateNewEnvironmentContainer titleButton="Create new escrow" size="large" />}
        />
      </div>
    );

  return (
    <div className="ck-saas__environments">
      <h1>Application Environments</h1>

      <Select
        className="ck-saas__environments-select"
        value={selectedEnvironmentId}
        onChange={setSelectedEnvironmentId}
        placeholder="Select environment..."
        options={environmentsOptions}
      />

      {selectedEnvironmentId && <SaasEscrowContextWithSuspense environmentId={selectedEnvironmentId} />}
    </div>
  );
};

export default withSuspense(SaasEnvironmentsContainer);
