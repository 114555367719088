export const flattenObject = (object: Record<string, unknown>) => {
  const result: Record<string, unknown> = {};

  for (const i in object) {
    if (typeof object[i] === 'object' && !Array.isArray(object[i])) {
      const temp = flattenObject(object[i] as Record<string, unknown>);

      for (const j in temp) {
        result[i + '.' + j] = temp[j];
      }
    } else {
      result[i] = object[i];
    }
  }
  return result;
};
