import { Outlet, Link } from 'react-router-dom';
import { Button } from 'antd';

import { EscrowsContainer } from 'src/containers/Escrows';
import { Main } from 'src/components/Layout';

export default function Escrows() {
  return (
    <Main
      title="Escrows"
      headerActions={
        <Link to="/escrows/new">
          <Button type="primary">+ New Escrow</Button>
        </Link>
      }
      noPaddingOnContent={true}
      className="escrows escrow-content"
    >
      <EscrowsContainer />

      <Outlet />
    </Main>
  );
}
