import React, { Fragment } from 'react';
import { Alert, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './MigrationAlert.module.scss';

type Props = {
  nodesCount: number;
  className?: string;
  action?: React.ReactNode;
};

const MigrationAlert: React.FunctionComponent<Props> = ({ nodesCount, action, className }) => {
  const navigate = useNavigate();
  const alertClasses = classNames('ck-migration__alert', className);
  const goToMigrations = () => navigate('/migrations');

  return (
    <Alert
      className={alertClasses}
      message={
        <Fragment>
          <span className={styles.text}>
            You have <strong>{nodesCount}</strong> assets waiting to be migrated to connection via OAuth. Please migrate
            them at your convenience.
          </span>
          {action || (
            <Button className="primary--orange" type="primary" onClick={goToMigrations}>
              Go to Migrations
            </Button>
          )}
        </Fragment>
      }
      type="warning"
    />
  );
};

export default MigrationAlert;
