import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

import type { CustodianOperationTypeEnum } from 'src/graphql/schema';

enum ActionEnum {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  LIST = 'list',
  REMOVE = 'remove',
  SET_INITIAL = 'set_initial',
}

type IActionReducer =
  | IAction<
      ActionEnum.ADD,
      {
        type: CustodianOperationTypeEnum;
      }
    >
  | IAction<
      ActionEnum.VIEW,
      {
        id: string;
      }
    >
  | IAction<
      ActionEnum.EDIT,
      {
        id: string;
      }
    >
  | IAction<
      ActionEnum.REMOVE,
      {
        id: string;
      }
    >
  | IAction<ActionEnum.LIST>
  | IAction<ActionEnum.SET_INITIAL>;

type IState = {
  modalType: 'add' | 'edit' | 'view' | 'list' | 'remove' | null;
  type?: CustodianOperationTypeEnum;
  id?: string;
};

const initialState: IState = {
  modalType: null,
  type: undefined,
  id: undefined,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.ADD: {
      return {
        modalType: 'add',
        type: action.payload.type,
      };
    }
    case ActionEnum.VIEW: {
      return {
        modalType: 'view',
        id: action.payload.id,
      };
    }
    case ActionEnum.EDIT: {
      return {
        modalType: 'edit',
        id: action.payload.id,
      };
    }
    case ActionEnum.LIST: {
      return {
        modalType: 'list',
      };
    }
    case ActionEnum.REMOVE: {
      return {
        modalType: 'remove',
        id: action.payload.id,
      };
    }
    case ActionEnum.SET_INITIAL: {
      return initialState;
    }
    default:
      return state;
  }
};

export const useCustodianOperationsSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addOperation = (type: CustodianOperationTypeEnum) => dispatch({ type: ActionEnum.ADD, payload: { type } });

  const setInitial = () => dispatch({ type: ActionEnum.SET_INITIAL, payload: {} });

  const viewOperation = (id: string) => dispatch({ type: ActionEnum.VIEW, payload: { id } });

  const editOperation = (id: string) => dispatch({ type: ActionEnum.EDIT, payload: { id } });

  const viewOperationList = () => dispatch({ type: ActionEnum.LIST, payload: {} });

  const removeOperation = (id: string) => dispatch({ type: ActionEnum.REMOVE, payload: { id } });

  return {
    ...state,
    addOperation,
    viewOperation,
    editOperation,
    viewOperationList,
    removeOperation,
    setInitial,
  };
};
