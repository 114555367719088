import { Modal } from 'antd';
import { capitalize } from 'lodash';

import { renderLogo } from 'src/utils/renderLogo';
import { IOauthIntegration } from 'src/graphql/schema';

import type { IStaticIntegration } from 'src/shared/entities';

import AzureForm from './AzureForm';
import Common from './Common';

type Props = {
  toggleVisibility: () => void;
  visible: boolean;
  integration: IStaticIntegration;
};

export default function OauthModal({ toggleVisibility, visible, integration }: Props) {
  const integrationName =
    integration.name || capitalize(integration.provider === 'gcp' ? 'Google Cloud' : integration.provider);

  const Icon = renderLogo(integration.provider);

  return (
    <Modal
      title={null}
      open={visible}
      onOk={toggleVisibility}
      onCancel={toggleVisibility}
      centered
      className="integration-modal-second"
      key={`${integration.provider}-modal`}
      footer={null}
    >
      <div className="w-100">
        <div className="d-flex align-items-center text-center justify-content-center flex-column h-100 integration-modal__col">
          <p className="integration-modal-second__subtitle">{`${integrationName}`}</p>

          <Icon className="integration-modal-second__icon" />

          <p className="integration-modal-second__text">
            Use the OAuth method to authorize Codekeeper to sync to your list of {integration.name} repositories, and
            take deposits of the ones you want to add into escrow. This will also monitor the connection status.
          </p>

          {integration.provider === 'azure' ? (
            <AzureForm integration={integration as Partial<IOauthIntegration>} />
          ) : (
            <Common integration={integration as Partial<IOauthIntegration>} integrationName={integrationName} />
          )}
        </div>
      </div>
    </Modal>
  );
}
