import { Fragment } from 'react';

import { useSaasEscrow } from 'src/context/saas';

import ProviderViewContainer from './ProviderViewContainer';
import EditProviderContainer from './EditProviderContainer';
import RemoveProviderContainer from './RemoveProviderContainer';

type ChildProps = {
  dropdownItemClick: (type: string, id: string) => void;
};

const ActionProviderContainer = ({ children }: { children: React.FunctionComponent<ChildProps> }) => {
  const { viewProvider, editProvider, removeProvider } = useSaasEscrow();

  const dropdownItemClick = (type: string, id: string) => {
    switch (type) {
      case 'view':
        viewProvider(id);
        break;
      case 'edit':
        editProvider(id);
        break;
      case 'remove':
        removeProvider(id);
        break;
      default:
        throw new Error('Invalid type');
    }
  };

  return (
    <Fragment>
      {children({
        dropdownItemClick,
      })}

      <ProviderViewContainer />
      <EditProviderContainer />
      <RemoveProviderContainer />
    </Fragment>
  );
};

export default ActionProviderContainer;
