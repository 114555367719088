import { Button } from 'antd';

import { MigrationAlert } from 'src/components/Migration';
import { useMigrations } from 'src/context/migrations';

const MigrationsPageAlert = () => {
  const {
    legacy: { count },
    setIsInfoModalVisible,
  } = useMigrations();

  const showInfoModal = () => setIsInfoModalVisible(true);

  return (
    <MigrationAlert
      nodesCount={count}
      action={
        <Button ghost type="primary" onClick={showInfoModal}>
          Learn more
        </Button>
      }
    />
  );
};

export default MigrationsPageAlert;
