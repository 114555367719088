import React, { PropsWithChildren } from 'react';
import { Checkbox } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import styles from './CustomCheckboxWithIcon.module.scss';

export interface ICustomCheckboxWrapper {
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (value: string | boolean) => void;
  value: string | boolean;
  name?: string;
  valuePropName?: 'checked';
}

const CustomCheckboxWrapper: React.FunctionComponent<PropsWithChildren<ICustomCheckboxWrapper>> = ({
  selected = false,
  disabled = false,
  value,
  name,
  className,
  onChange,
  children,
  valuePropName,
}) => {
  const checkboxClasses = classNames('ck-custom-checkbox--with-icon', className, {
    [styles.selected]: selected,
    disabled,
  });
  const handleChange = (e: CheckboxChangeEvent) => {
    if (!onChange) return;
    if (valuePropName === 'checked') return onChange(e.target.checked);
    onChange(e.target.value);
  };

  return (
    <Checkbox
      name={name}
      className={checkboxClasses}
      value={value}
      checked={selected}
      disabled={disabled}
      onChange={handleChange}
    >
      <div className={styles.checkbox}>{selected ? <CheckCircleFilled /> : <span className={styles.circle} />}</div>
      {children}
    </Checkbox>
  );
};

export default CustomCheckboxWrapper;
