import { Skeleton } from 'antd';
import { useReadQuery } from '@apollo/client';

import { BillingCompanyForm } from 'src/components/Billing';
import { CompanyDocument, useUpdateBillingAddressMutation } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { message } from 'src/components/Misc';

import type { ICompanyQuery, IBillingQuery } from 'src/graphql/schema';
import type { IBillingCompanyForm } from 'src/components/Billing';
import type { QueryRef } from '@apollo/client';

type Props = {
  queryRef: QueryRef<ICompanyQuery>;
};

const BillingInformationContainer = ({ queryRef }: Props) => {
  const { data } = useReadQuery<IBillingQuery>(queryRef);
  const [updateBillingAddress, { loading }] = useUpdateBillingAddressMutation({
    refetchQueries: [{ query: CompanyDocument }],
  });

  const onSubmit = async (values: IBillingCompanyForm) => {
    try {
      const { data } = await updateBillingAddress({
        variables: {
          billingAddress: {
            firstName: values.firstName,
            lastName: values.lastName,
            companyName: values.company,
            addressLine1: values.address1,
            addressLine2: values.address2,
            addressLine3: values.address3,
            city: values.city,
            zip: values.zip,
            state: values.state,
            country: values.country,
            email: values.email,
            companyRegistrationNumber: values.registrationNumber || '',
          },
        },
      });

      if (!data) return null;
      const { updateBillingAddress: updateBillingAddressData } = data;

      if (updateBillingAddressData?.success) {
        message.success('Billing information updated!');
      } else {
        if (!updateBillingAddressData?.errors) return;

        message.error(updateBillingAddressData?.errors[0]);
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  return <BillingCompanyForm data={data} onSubmit={onSubmit} isLoading={loading} />;
};

const InformationSkeleton = () => (
  <Skeleton
    active
    paragraph={{
      rows: 7,
    }}
  />
);

export default withSuspense(BillingInformationContainer, InformationSkeleton);
