import {
  LogoAws,
  LogoGoogleCloud,
  LogoAlibaba,
  LogoAzure,
  LogoIbm,
  LogoOracle,
  LogoRackspace,
  LogoHeroku,
  LogoChargebee,
  LogoDigitalOcean,
  LogoAdyen,
  LogoMailchimp,
  LogoTwilio,
  LogoOkta,
  LogoSendbird,
  LogoShopify,
  LogoStripe,
  LogoZendesk,
} from 'src/components/Svg/Images/Providers';

import type { IProviderItem } from './ProviderItem';

const hostingEnvironmentKeys = [
  'aws',
  'gcp',
  'azure',
  'ibm',
  'oracle',
  'alibaba',
  'rackspace',
  'heroku',
  'digital_ocean',
] as const;
export const hostingEnvironments: Array<IProviderItem & { id: (typeof hostingEnvironmentKeys)[number] }> = [
  {
    id: 'aws',
    icon: LogoAws,
    name: 'Amazon Web Services',
  },
  {
    id: 'gcp',
    icon: LogoGoogleCloud,
    name: 'Google Cloud',
  },
  {
    id: 'azure',
    icon: LogoAzure,
    name: 'Microsoft Azure',
  },
  {
    id: 'ibm',
    icon: LogoIbm,
    name: 'IBM Cloud Services',
  },
  {
    id: 'oracle',
    icon: LogoOracle,
    name: 'Oracle Cloud',
  },
  {
    id: 'alibaba',
    icon: LogoAlibaba,
    name: 'Alibaba Cloud',
  },
  {
    id: 'rackspace',
    icon: LogoRackspace,
    name: 'RackSpace Cloud',
  },
  {
    id: 'digital_ocean',
    icon: LogoDigitalOcean,
    name: 'Digital Ocean',
  },
  {
    id: 'heroku',
    icon: LogoHeroku,
    name: 'Heroku',
  },
];
export const hostingEnvironmentsDict = hostingEnvironments.reduce((acc: { [key: string]: IProviderItem }, item) => {
  acc[item.name] = item;

  return acc;
}, {});

const dependencyServiceKeys = [
  'adyen',
  'mailchimp',
  'twilio',
  'okta',
  'chargebee',
  'sendbird',
  'shopify',
  'stripe',
  'zendesk',
] as const;
export const dependencyServices: Array<IProviderItem & { id: (typeof dependencyServiceKeys)[number] }> = [
  {
    id: 'adyen',
    icon: LogoAdyen,
    name: 'Adyen',
  },
  {
    id: 'mailchimp',
    icon: LogoMailchimp,
    name: 'Mailchimp',
  },
  {
    id: 'twilio',
    icon: LogoTwilio,
    name: 'Twilio',
  },
  {
    id: 'okta',
    icon: LogoOkta,
    name: 'Okta',
  },
  {
    id: 'chargebee',
    icon: LogoChargebee,
    name: 'Chargebee',
  },
  {
    id: 'sendbird',
    icon: LogoSendbird,
    name: 'Sendbird',
  },
  {
    id: 'shopify',
    icon: LogoShopify,
    name: 'Shopify',
  },
  {
    id: 'stripe',
    icon: LogoStripe,
    name: 'Stripe',
  },
  {
    id: 'zendesk',
    icon: LogoZendesk,
    name: 'Zendesk',
  },
];
export const dependencyServicesDict = dependencyServices.reduce((acc: { [key: string]: IProviderItem }, item) => {
  acc[item.name] = item;

  return acc;
}, {});

export const providerList = [...hostingEnvironments, ...dependencyServices];
export const providerListDict = providerList.reduce((acc: { [key: string]: IProviderItem }, item) => {
  acc[item.name] = item;

  return acc;
}, {});
