import { SaasMenu, saasMenuItems } from 'src/components/Saas/Menu';
import { useSaasEscrow } from 'src/context/saas';
import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

const SaasMenuContainer = () => {
  const { environment } = useSaasEscrow();

  let menuItems = [...saasMenuItems];

  if (environment.status === SaasEnvironmentStatusEnum.Active)
    menuItems = menuItems.map((item) => ({ ...item, isDisabled: false }));

  return <SaasMenu basePath="saas-escrows" saasId={environment?.id} items={menuItems} />;
};

export default SaasMenuContainer;
