const CURRENCY_MAP: { readonly [key: string]: string } = { EUR: '€', GBP: '£', USD: '$' } as const;

type IFormatPriceFunc = (
  currency?: (typeof CURRENCY_MAP)[keyof typeof CURRENCY_MAP] | string,
  amount?: number,
  isTotal?: boolean,
) => string;

const formatPrice: IFormatPriceFunc = (currency = '', amount = 1, isTotal = false) => {
  return `${CURRENCY_MAP[currency] || `${currency} `}${
    isTotal ? (amount / 100).toFixed(2).toString() : (amount / 100).toString()
  }`;
};

export default formatPrice;
