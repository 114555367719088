import { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { capitalize } from 'lodash';

import styles from './EscrowContactsForm.module.scss';

export interface ISignatoryForm {
  name: string;
  email: string;
}

type Props = {
  type: 'beneficiary' | 'depositor';
  onSubmit: (data: ISignatoryForm) => void;
  onCancel: () => void;
  initialValues?: ISignatoryForm;
};

const SignatoryForm: React.FunctionComponent<Props> = ({ type, onSubmit, onCancel, initialValues }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<ISignatoryForm>();

  useEffect(() => {
    if (initialValues) {
      const { name, email } = initialValues;
      form.setFieldsValue({
        name,
        email,
      });
    }
  }, [form, initialValues]);

  const handleSubmit = async (e: ISignatoryForm) => {
    setIsLoading(true);

    try {
      await onSubmit(e);
      onCancel();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {capitalize(type)} Signatory Details<sub>Required</sub>
      </h3>
      <p className={styles.text}>
        The legal representative which will sign the escrow agreement. This person will receive an electronic signature
        request.
      </p>
      <p className={styles['secondary-text']}>
        To make the escrow legally binding we will send an email to the listed representative for an electronic
        signature upon approval of the escrow agreement. If an alternative signature method or a hardcopy agreement is
        required, please{' '}
        <a target="_blank" rel="noreferrer" href="https://codekeeper.co/contact.html">
          contact our support
        </a>
        .
      </p>

      <Form form={form} layout="vertical" className="ck-escrow-contacts__form" onFinish={handleSubmit}>
        <Row className={styles['row-md']}>
          <Col span={12} className={styles['col-md']}>
            <Form.Item name="name" label="Signatory Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} className={styles['col-md']}>
            <Form.Item name="email" label="Signatory Email" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div className={styles['action-list']}>
          <div className={styles['action-list-right']}>
            {initialValues && (
              <div className={styles['action-item']}>
                <Button type="default" onClick={onCancel}>
                  Discard
                </Button>
              </div>
            )}
            <div className={styles['action-item']}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save details
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SignatoryForm;
