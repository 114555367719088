import { JourneyMenu, IJourneyMenuItem } from 'src/components/Journey/Menu';

import styles from './CustodianMenu.module.scss';

export type ICustodianMenuItem = {
  isRequired?: boolean;
} & IJourneyMenuItem;

const CustodianMenuItem = ({ isRequired = false, ...rest }: ICustodianMenuItem) => {
  return <JourneyMenu.Item {...rest}>{isRequired && <span className={styles['required-dot']} />}</JourneyMenu.Item>;
};

export default CustodianMenuItem;
