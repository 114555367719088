/* eslint-disable jsx-a11y/aria-role */
import {
  ContactListDetailBlock,
  OrganizationDetailBlock,
  SignatoryDetailBlock,
  IContactDetail,
  IOrganizationDetail,
  ISignatoryDetail,
} from 'src/components/Escrow/Contacts';

import InfoContentBlock from '../InfoBlocks/InfoContentBlock';
import styles from '../EscrowContacts.module.scss';

type Props = {
  role: 'beneficiary' | 'depositor';
  isOwnOrganization?: boolean;
  organization: IOrganizationDetail;
  signatory?: ISignatoryDetail;
  contacts?: IContactDetail[];
};

const contentBlockTextEnum = {
  beneficiary: {
    heading: 'Beneficiary Information',
    text: 'These are the details of the company that will receive the material.',
  },
  depositor: {
    heading: 'Depositor Information',
    text: 'These are the details of the source code owner.',
  },
};

const InfoPreviewByRole: React.FunctionComponent<Props> = ({
  role,
  organization,
  signatory,
  contacts,
  isOwnOrganization = false,
}) => {
  return (
    <div className={styles.container}>
      <InfoContentBlock
        heading={contentBlockTextEnum[role].heading}
        headingLabel={isOwnOrganization ? '(your organization)' : ''}
        text={contentBlockTextEnum[role].text}
      />
      <OrganizationDetailBlock className={styles.block} type={role} {...organization} />
      {signatory && <SignatoryDetailBlock className={styles.block} type={role} {...signatory} />}
      <ContactListDetailBlock className={styles.block} type={role} contacts={contacts} />
    </div>
  );
};

export default InfoPreviewByRole;
