import classNames from 'classnames';

import { useAuth } from 'src/context/auth/use-auth';
import { useForgotPasswordSlice, StepEnum } from 'src/slices';
import {
  ForgotPasswordForm,
  IForgotPasswordForm,
  ResetPasswordConfirmation,
  IResetPasswordForm,
  ResetPasswordForm,
} from 'src/components/Authentication';
import { message } from 'src/components/Misc';

const ForgotPasswordContainer = () => {
  const { sendPasswordResetEmail, confirmPasswordReset } = useAuth();
  const { state, sendCode, setLoading, reset } = useForgotPasswordSlice();
  const { email, loading, activeStep } = state;

  const onRequestCodeFormFinish = async ({ email }: IForgotPasswordForm) => {
    setLoading(true);

    try {
      await sendPasswordResetEmail(email);
      sendCode(email);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const onConfirmationFormFinish = async ({ code, password }: IResetPasswordForm) => {
    setLoading(true);

    try {
      await confirmPasswordReset(email, code, password);
      localStorage.setItem('pswd-reset', 'true');
      reset();
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const stepsDataEnum = {
    [StepEnum.INITIAL]: {
      heading: 'Reset password',
      component: ForgotPasswordForm,
      props: {
        onFinish: onRequestCodeFormFinish,
        isLoading: loading,
      },
    },
    [StepEnum.RESET]: {
      heading: 'Reset password',
      component: ResetPasswordForm,
      props: {
        onFinish: onConfirmationFormFinish,
        email: email,
        isLoading: loading,
      },
    },
    [StepEnum.CONFIRMATION]: {
      heading: 'Reset password successful!',
      component: ResetPasswordConfirmation,
      props: {},
    },
  };

  const StepComponent =
    activeStep && activeStep in stepsDataEnum
      ? stepsDataEnum[activeStep as keyof typeof stepsDataEnum].component
      : null;

  const stepHeading =
    activeStep && activeStep in stepsDataEnum ? stepsDataEnum[activeStep as keyof typeof stepsDataEnum].heading : '';

  return (
    <div
      className={classNames('invite-login-screen', {
        '-success': activeStep === StepEnum.CONFIRMATION,
      })}
    >
      {stepHeading && <h2 className="invite-login-screen__reset-title">{stepHeading}</h2>}
      {/* @ts-expect-error no idea */}
      {StepComponent && <StepComponent {...stepsDataEnum[activeStep].props} />}
    </div>
  );
};

export default ForgotPasswordContainer;
