import { Button } from 'antd';
import { CheckCircleFilled, WarningFilled, CloseCircleFilled, CreditCardOutlined } from '@ant-design/icons';

import { IBillingQuery } from 'src/graphql/schema';
import { ImageVisa, ImageMasterCard } from 'src/components/Svg/Images';

import styles from './BillingPayment.module.scss';

type Props = {
  onUpdate: () => void;
  paymentMethod?: NonNullable<IBillingQuery['billing']>['paymentMethod'];
};

const cardImageMap = {
  visa: ImageVisa,
  mastercard: ImageMasterCard,
};

const statusIconMap = {
  valid: CheckCircleFilled,
  expiring: WarningFilled,
};

export default function BillingPayment({ onUpdate, paymentMethod }: Props) {
  const CardIcon =
    paymentMethod?.cardType && paymentMethod.cardType in cardImageMap
      ? cardImageMap[paymentMethod.cardType.toLowerCase() as keyof typeof cardImageMap]
      : undefined;

  const StatusIcon =
    paymentMethod?.status && paymentMethod.status in statusIconMap
      ? statusIconMap[paymentMethod.status as keyof typeof statusIconMap]
      : CloseCircleFilled;

  return (
    <div className={`d-flex justify-content-between ${styles.payment}`}>
      {paymentMethod && paymentMethod.cardLast4 && (
        <div className="d-flex align-items-center">
          {CardIcon ? (
            <div className={styles.card}>
              <CardIcon data-testid={paymentMethod.cardType} />
            </div>
          ) : (
            <CreditCardOutlined className={styles['card-icon']} />
          )}

          <div className={styles.info}>
            <strong className={styles.name}>
              {paymentMethod.cardType} x-{paymentMethod.cardLast4}
            </strong>
            <div className={`${styles.status} ${styles[paymentMethod.status]}`}>
              <StatusIcon />
              &nbsp;
              {paymentMethod.status}
            </div>
          </div>
        </div>
      )}

      {paymentMethod && !paymentMethod.cardLast4 && (
        <div className={styles.invoice}>
          <strong className={styles.name}>Paid through Invoice</strong>
          <div className={styles['invoice-text']}>Alternative Payment Method</div>
        </div>
      )}

      {!paymentMethod && <div className={styles['no-payment-text']}>No Payment Method added</div>}

      <div className={styles.action}>
        <Button type="primary" ghost onClick={onUpdate}>
          {paymentMethod && paymentMethod.cardLast4 ? 'Update' : 'Add Card'}
        </Button>
      </div>
    </div>
  );
}
