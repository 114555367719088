/* eslint-disable react/display-name */
import { Button, Skeleton } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { LabelText } from 'src/components/Label';
import { bgColors } from 'src/components/Label/Text/LabelText';

import styles from './JourneyModalLayout.module.scss';

export type Props = {
  title: string;
  onClose: () => void;
  isLoading?: boolean;
  statusLabel?: { text: string; bgColor: (typeof bgColors)[number] };
};

const JourneyModalHeaderSkeleton = () => {
  return (
    <div className={styles.header} aria-label="Loading...">
      <Skeleton.Button active size="small" block />
    </div>
  );
};

const JourneyModalHeader = ({ title, onClose, isLoading, statusLabel }: Props) => {
  if (isLoading)
    return (
      <div className={styles.header} aria-label="Loading...">
        <Skeleton.Button active size="small" block />
      </div>
    );

  return (
    <div className={styles.header}>
      <h6 className={`ck-text--ellipsis ${styles.heading}`}>{title}</h6>
      <div className={styles['header-right']}>
        {statusLabel && <LabelText text={statusLabel.text} bgColor={statusLabel.bgColor} />}

        <Button type="text" className={styles['btn-close']} onClick={onClose}>
          <CloseOutlined className={styles['icon-close']} />
        </Button>
      </div>
    </div>
  );
};

JourneyModalHeader.Skeleton = JourneyModalHeaderSkeleton;

export default JourneyModalHeader;
