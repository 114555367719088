import React from 'react';
import classNames from 'classnames';

import styles from './CardWithIcon.module.scss';

export type ICardWithIcon = (
  | { iconType: 'regular' }
  | {
      iconType: 'image';
      size: number; // size of the svg image
    }
) & {
  heading: string;
  text?: string;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  classes?: {
    icon?: string;
  };
};

type Props = {
  className?: string;
  layout?: 'vertical' | 'horizontal';
} & ICardWithIcon;

const CardWithIcon: React.FunctionComponent<Props> = (props) => {
  const { className, icon: Icon, heading, text, layout = 'vertical', classes } = props;
  const cardClasses = classNames(styles.card, className);
  const wrapperClasses = classNames(styles.wrapper, styles[layout]);

  return (
    <div className={cardClasses}>
      <div className={wrapperClasses}>
        <div className={styles['icon-wrapper']}>
          {props.iconType === 'image' ? (
            <Icon width={props.size} height={props.size} />
          ) : (
            <i className={classNames(styles.icon, classes?.icon)}>
              <Icon />
            </i>
          )}
        </div>
        <div className={styles.info}>
          <strong className={styles.heading}>{heading}</strong>
          {text && <div className={styles.text}>{text}</div>}
        </div>
      </div>
    </div>
  );
};

export default CardWithIcon;
