import { useEffect } from 'react';

import { useEscrow } from 'src/context/escrow';
import { suggestChangesVar, writeResetSuggestChanges } from 'src/graphql/client/cache';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';

import EscrowModalFooterContainer from './EscrowModalFooterContainer';
import EscrowAlertBannerContainer from './EscrowAlertBannerContainer';
import EscrowMenuContainer from './EscrowMenuContainer';
import EscrowStatusIndicatorContainer from './EscrowStatusIndicatorContainer';
import EscrowModalHeaderContainer from './EscrowModalHeaderContainer';

const EscrowModalLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const { error } = useEscrow();

  // Clean up suggestChangesVar on unmount
  useEffect(() => {
    return () => {
      writeResetSuggestChanges(suggestChangesVar);
    };
  }, []);

  return (
    <JourneyModalLayout
      header={<EscrowModalHeaderContainer />}
      error={error && { goTo: '/escrows', ...error }}
      statusIndicator={<EscrowStatusIndicatorContainer />}
      menu={<EscrowMenuContainer />}
    >
      <EscrowAlertBannerContainer />
      {children}
      <EscrowModalFooterContainer />
    </JourneyModalLayout>
  );
};

export default EscrowModalLayoutContainer;
