import { CheckOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Skeleton, Divider, Tooltip } from 'antd';
import { Fragment } from 'react';

import CancelPlanItem, { ICancelPlanItem } from '../../Item/CancelPlanItem';
import styles from '../CancelPlanOffer.module.scss';

type Props = {
  plans: ICancelPlanItem[];
  className?: string;
};

const features = [
  'Enterprise-level security',
  '24/7 customer support',
  'Access to multiple disaster recovery solutions',
  'Direct integration with developer workflows',
  'No future setup fee',
];

const FeatureListContent = () => {
  return (
    <Fragment>
      <h6>Keep the features you love</h6>
      <ul className={`ck-custom-list ${styles.features}`}>
        {features.map((feature, index) => (
          <li className="d-flex align-items-center" key={index}>
            <CheckOutlined className={styles['icon-check']} /> {feature}
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

const ExclusiveOfferCardSkeleton = () => {
  return (
    <div className={styles.item}>
      <div className={styles['item-info']}>
        <Skeleton active className="mb-4" />
        <Divider />
        <Skeleton active />
      </div>
      <FeatureListContent />
    </div>
  );
};

const ExclusiveOfferCard = ({ plans, className }: Props) => {
  const itemClasses = classNames(styles.item, className);

  return (
    <div className={itemClasses}>
      {Boolean(plans.length) && (
        <div className={styles['item-info']}>
          <h4>Your current plan at</h4>
          {plans.map((plan) => (
            <Tooltip
              placement="top"
              title={!plan.discounted ? 'This plan is ineligible for a\n discount.' : ''}
              key={plan.id}
              overlayStyle={{ width: 200 }}
            >
              <div>
                <CancelPlanItem
                  {...plan}
                  bgColor={!plan.discounted ? 'grey' : 'white'}
                  withBoxShadow={plan.discounted}
                  className="mb-2"
                />
              </div>
            </Tooltip>
          ))}
        </div>
      )}

      <FeatureListContent />
    </div>
  );
};

ExclusiveOfferCard.Skeleton = ExclusiveOfferCardSkeleton;

export default ExclusiveOfferCard;
