import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFile = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M4 1.2h5.042c.409 0 .799.17 1.076.47l2.958 3.194c.251.271.39.627.39.996v7.473c0 .81-.656 1.466-1.466 1.466H4c-.81 0-1.467-.656-1.467-1.466V2.666c0-.81.657-1.467 1.467-1.467Zm.133 1.6v10.4h7.734V5.912L8.984 2.799h-4.85Zm7.77 3.151h-.001Z"
        clipRule="evenodd"
      />
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M5.2 11.335a.8.8 0 0 1 .8-.8h4a.8.8 0 1 1 0 1.6H6a.8.8 0 0 1-.8-.8ZM5.2 9.335a.8.8 0 0 1 .8-.8h4a.8.8 0 0 1 0 1.6H6a.8.8 0 0 1-.8-.8ZM8.667 1.2a.8.8 0 0 1 .8.8v3.2h3.2a.8.8 0 1 1 0 1.6H9.333c-.81 0-1.466-.657-1.466-1.467V1.999a.8.8 0 0 1 .8-.8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFile);
export default Memo;
