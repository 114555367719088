export const providers = [
  'github',
  'bitbucket',
  'gitlab',
  'azure',
  'azure_devops',
  'gcp',
  'github_server',
  'gitlab_server',
  'bitbucket_server',
  'codecommit',
  'beanstalk',
  'aws',
  'share_file_https',
] as const;

export type IStaticIntegration = {
  provider: (typeof providers)[number];
  name: string;
  __typename: 'OauthIntegration' | 'CustomIntegration' | 'ShareFileIntegration';
  assetSupported?: string;
  depositMethod: 'OAuth' | 'Custom' | 'Manual';
  nonAuth?: boolean;
  keyLabel?: string;
  passwordLabel?: string;
  accountUrlRequired?: boolean;
};

export const staticIntegrations: IStaticIntegration[] = [
  {
    provider: 'github',
    name: 'Github',
    assetSupported: 'Git repositories',
    depositMethod: 'OAuth',
    __typename: 'OauthIntegration',
  },
  {
    provider: 'bitbucket',
    name: 'Bitbucket',
    assetSupported: 'Git repositories',
    depositMethod: 'OAuth',
    __typename: 'OauthIntegration',
  },
  {
    provider: 'gitlab',
    name: 'Gitlab',
    assetSupported: 'Git repositories',
    depositMethod: 'OAuth',
    __typename: 'OauthIntegration',
  },
  {
    provider: 'azure',
    name: 'Azure',
    assetSupported: 'Azure Blob Storage',
    depositMethod: 'OAuth',
    __typename: 'OauthIntegration',
  },
  {
    provider: 'azure_devops',
    name: 'Azure DevOps',
    assetSupported: 'Git repositories, TFVC',
    depositMethod: 'OAuth',
    __typename: 'OauthIntegration',
  },
  {
    provider: 'gcp',
    name: 'Google Cloud Platform',
    assetSupported: 'Google Cloud Storage',
    depositMethod: 'OAuth',
    __typename: 'OauthIntegration',
  },
  {
    provider: 'github_server',
    name: 'GitHub Enterprise',
    assetSupported: 'Git repositories',
    depositMethod: 'Custom',
    nonAuth: true,
    keyLabel: 'Username',
    passwordLabel: 'Personal Access Token',
    accountUrlRequired: true,
    __typename: 'CustomIntegration',
  },
  {
    provider: 'gitlab_server',
    name: 'GitLab Private',
    assetSupported: 'Git repositories',
    depositMethod: 'Custom',
    nonAuth: true,
    keyLabel: 'Username',
    passwordLabel: 'Personal Access Token',
    accountUrlRequired: true,
    __typename: 'CustomIntegration',
  },
  {
    provider: 'bitbucket_server',
    name: 'Bitbucket Server',
    assetSupported: 'Git repositories',
    depositMethod: 'Custom',
    nonAuth: true,
    keyLabel: 'Username',
    passwordLabel: 'App Password',
    accountUrlRequired: true,
    __typename: 'CustomIntegration',
  },
  {
    provider: 'codecommit',
    name: 'Amazon CodeCommit',
    assetSupported: 'Git repositories',
    depositMethod: 'Custom',
    nonAuth: true,
    keyLabel: 'Username',
    passwordLabel: 'Password',
    accountUrlRequired: false,
    __typename: 'CustomIntegration',
  },
  {
    provider: 'beanstalk',
    name: 'Beanstalk',
    assetSupported: 'Git repositories',
    depositMethod: 'Custom',
    nonAuth: true,
    keyLabel: 'Username',
    passwordLabel: 'Password',
    accountUrlRequired: false,
    __typename: 'CustomIntegration',
  },
  {
    provider: 'aws',
    name: 'Amazon S3',
    assetSupported: 'Buckets',
    depositMethod: 'Custom',
    nonAuth: true,
    keyLabel: 'Access Key ID',
    passwordLabel: 'Secret Access Key',
    accountUrlRequired: false,
    __typename: 'CustomIntegration',
  },
  {
    provider: 'share_file_https',
    name: 'ShareFile',
    depositMethod: 'Manual',
    nonAuth: true,
    assetSupported: 'Zip files, documents, etc.',
    __typename: 'ShareFileIntegration',
  },
];

export const staticIntegrationsByProvider = staticIntegrations.reduce<Record<string, unknown>>(
  (acc: { [key: string]: unknown }, integration) => {
    acc[integration.provider] = integration;
    return acc;
  },
  {},
);

export const externalInfoPaths: {
  [key in (typeof providers)[number]]?: string;
} = {
  share_file_https: 'sharefile',
  gcp: 'google_cloud_platform',
};
