import { JourneyStatusIndicator, IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

import { statusIndicatorItems, BackupStatusIndicatorEnum } from './statusIndicator.enum';

type Props = {
  currentStatus: BackupStatusIndicatorEnum;
  isLoading?: boolean;
};

const setCanceledItemStep = <TItem extends { id: string; isHidden?: boolean }, TStatus extends string>(
  item: TItem,
  currentStatus: TStatus,
) => ({
  ...item,
  status: item.id === currentStatus ? 'canceled' : 'completed',
  isHidden: item.id === currentStatus ? false : item.isHidden,
});

const BackupStatusIndicator = ({ currentStatus, isLoading }: Props) => {
  const newStatusIndicatorItems: IJourneyStatusIndicatorItem[] = (() => {
    switch (currentStatus) {
      case BackupStatusIndicatorEnum.REMOVED:
        return statusIndicatorItems.map((item) => setCanceledItemStep(item, currentStatus));
      case BackupStatusIndicatorEnum.STOPPED:
        return statusIndicatorItems.map((item) => setCanceledItemStep(item, currentStatus));
      case BackupStatusIndicatorEnum.BACKUP_INITIATED:
        return statusIndicatorItems.map((item) => ({
          ...item,
          status: 'completed',
        }));
      default: {
        const currentStatusIndex = statusIndicatorItems.findIndex(({ id }) => id === currentStatus);

        return statusIndicatorItems.map((item, index) =>
          index <= currentStatusIndex ? { ...item, status: item.id === currentStatus ? 'active' : 'completed' } : item,
        );
      }
    }
  })();

  return (
    <JourneyStatusIndicator
      isCanceled={
        currentStatus === BackupStatusIndicatorEnum.STOPPED || currentStatus === BackupStatusIndicatorEnum.REMOVED
      }
      isLoading={isLoading}
      skeletonItems={4}
    >
      {newStatusIndicatorItems.map((item) => (
        <JourneyStatusIndicator.Item key={item.id} {...item} />
      ))}
    </JourneyStatusIndicator>
  );
};

export default BackupStatusIndicator;
