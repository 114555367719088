import React from 'react';
import { Radio } from 'antd';
import classNames from 'classnames';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CheckCircleFilled } from '@ant-design/icons';

import styles from './CustomRadioWithIcon.module.scss';

export type ICustomRadioWithIcon = (
  | { iconType: 'regular' }
  | {
      iconType: 'image';
      size: number; // size of the svg image
    }
) & {
  value: string;
  heading: string;
  text?: string;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  disabled?: boolean;
};

type Props = ICustomRadioWithIcon & {
  selected?: boolean;
  className?: string;
  onChange?: (value: string) => void;
  layout?: 'vertical' | 'horizontal';
  classes?: {
    icon?: string;
  };
};

const CustomRadioWithIcon: React.FunctionComponent<Props> = (props) => {
  const {
    onChange,
    className,
    icon: Icon,
    heading,
    text,
    selected = false,
    disabled = false,
    value,
    layout = 'vertical',
    classes,
  } = props;
  const checkboxClasses = classNames('ck-custom-radio--with-icon', className, {
    [styles.selected]: selected,
    [styles.disabled]: disabled,
  });
  const wrapperClasses = classNames(styles.wrapper, styles[layout]);
  const handleChange = (e: RadioChangeEvent) => onChange && onChange(e.target.value);

  return (
    <Radio className={checkboxClasses} value={value} disabled={disabled} onChange={handleChange}>
      <div className={styles.checkbox}>{selected ? <CheckCircleFilled /> : <span className={styles.circle} />}</div>
      <div className={wrapperClasses}>
        <div className={styles['icon-wrapper']}>
          {props.iconType === 'image' ? (
            <Icon width={props.size} height={props.size} />
          ) : (
            <i className={classNames(styles.icon, classes?.icon)}>
              <Icon />
            </i>
          )}
        </div>
        <div className={styles.info}>
          <strong className={styles.heading}>{heading}</strong>
          {text && <div className={styles.text}>{text}</div>}
        </div>
      </div>
    </Radio>
  );
};

export default CustomRadioWithIcon;
