import fetchTokenStrategy from '../fetch-token-strategy';

export const downloadFile = async (url: string, type: 'pdf' | 'docx', name: string): Promise<void> => {
  const token = await fetchTokenStrategy();
  const headers = {
    Authorization: `Bearer ${token}`,
  } as Record<string, string>;
  const impersonatedUserID = localStorage.getItem('impersonatedUserID');

  if (impersonatedUserID) {
    headers['X-Impersonated-User-ID'] = impersonatedUserID;
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers(headers),
  });

  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = `${name}.${type}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadInstantFile = (url: string, type: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}.${type}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
