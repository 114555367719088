import { Button } from 'antd';
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';

import styles from './JourneyNavigation.module.scss';

type Props = {
  isPrevHidden?: boolean;
  isNextHidden?: boolean;
  isNextDisabled?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
};

const JourneyNavigation = ({
  isPrevHidden = false,
  isNextHidden = false,
  isNextDisabled = false,
  onPrev,
  onNext,
}: Props) => {
  return (
    <div className={styles['float-group']}>
      {!isPrevHidden && (
        <Button type="text" onClick={onPrev} aria-label="previous">
          <UpCircleOutlined />
        </Button>
      )}
      {!isNextHidden && (
        <Button type="text" disabled={isNextDisabled} onClick={onNext} aria-label="next">
          <DownCircleOutlined />
        </Button>
      )}
    </div>
  );
};

export default JourneyNavigation;
