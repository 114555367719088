import { Button } from 'antd';
import classNames from 'classnames';

import { ImageEmptyBox, ImageHiddenDeposits } from 'src/components/Svg/Images';

import styles from './EmptyDepositAssets.module.scss';

type Props = {
  isAssetDisplayDisabled?: boolean;
  isTerminated?: boolean;
  isEmpty?: boolean;
  currentCompanyRole: 'depositor' | 'beneficiary';
  onMakeDeposit?: () => void;
  className?: string;
};

const EmptyDepositAssets: React.FunctionComponent<Props> = ({
  currentCompanyRole,
  isTerminated,
  isEmpty,
  isAssetDisplayDisabled,
  onMakeDeposit,
  className,
}) => {
  const isHiddenDeposit = isAssetDisplayDisabled && currentCompanyRole === 'beneficiary';
  let infoText = '';

  if (isTerminated) infoText = 'Any deposited assets for this escrow have been removed.';
  if (currentCompanyRole === 'beneficiary' && isEmpty && !isTerminated)
    infoText = 'The deposited assets will be shown here once they are configured by the depositor.';
  if (isHiddenDeposit)
    infoText =
      'Escrow asset display has been disabled by the depositor. Please contact your depositor for more information.';

  return (
    <div className={classNames(styles.block, className)}>
      {isHiddenDeposit ? <ImageHiddenDeposits className={styles.image} /> : <ImageEmptyBox className={styles.image} />}

      {currentCompanyRole === 'depositor' && isEmpty && !isTerminated && (
        <h6 className={styles.title}>Make your first deposit</h6>
      )}

      {infoText && <p className={styles.text}>{infoText}</p>}

      {currentCompanyRole === 'depositor' && isEmpty && !isTerminated && (
        <Button type="primary" onClick={onMakeDeposit}>
          Make deposit
        </Button>
      )}
    </div>
  );
};

export default EmptyDepositAssets;
