import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconPrivacyOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.3 28.309V19.7h-9.6v8.609l4.8 3.572zm1.7.854L18.5 34 12 29.163V18h13z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m17.853 24.726-1.025-1.025L15.5 25.03l2.353 2.353 4.054-4.054L20.579 22zM14 12.765h9.412v1.882H14zM14 9h9.412v1.882H14z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M29.3 24.063V5.7H7.7v18.363L18.5 31.9zm1.7.867L18.5 34 6 24.93V4h25z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconPrivacyOperation);
export default Memo;
