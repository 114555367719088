import { IUserEmailPreferencesForm } from './Forms';

export const emailPreferencesOptions: {
  label: string;
  name: keyof IUserEmailPreferencesForm;
  text: string;
}[] = [
  {
    label: 'Approved',
    name: 'approvedMail',
    text: 'Receive email when escrow is approved.',
  },
  {
    label: 'Edited',
    name: 'editedMail',
    text: 'Receive email when escrow is edited.',
  },
  {
    label: 'Feedback',
    name: 'feedbackMail',
    text: 'Receive email when receive feedback.',
  },
  {
    label: 'Deposit',
    name: 'depositMail',
    text: 'Receive email when deposit is made.',
  },
];

export const profileTabsItems: { label: React.ReactNode; key: string; children?: React.ReactNode }[] = [
  {
    label: 'Profile',
    key: '/profile',
  },
  {
    label: 'Activity',
    key: '/activity',
  },
  {
    label: 'Feedback',
    key: '/feedback',
  },
];
