import { DisruptionNotificationEnum, DisruptionNotificationGracePeriodEnum } from 'src/graphql/schema';

import { setGracePeriodOption } from './setGracePeriodOption';

type ISettingsFieldsChangeFunc = <T extends { setFieldsValue: (data: Record<string, unknown>) => void }>(
  form: T,
) => <U, V extends Array<{ name: string[]; value?: string }>>(_: U[], allFields: V) => void;

export const settingsFieldsChange: ISettingsFieldsChangeFunc =
  (form) =>
  (_, allFields): void => {
    const gracePeriodValue = allFields.find((field) => field.name[0] === 'disruptionNotificationGracePeriod')
      ?.value as DisruptionNotificationGracePeriodEnum;
    const disruptionNotificationValue = allFields.find((field) => field.name[0] === 'disruptionNotification')
      ?.value as DisruptionNotificationEnum;

    form.setFieldsValue({
      disruptionNotificationGracePeriod: setGracePeriodOption(disruptionNotificationValue, gracePeriodValue),
    });
  };
