import { useMemo } from 'react';
import { get } from 'lodash';

import {
  useMarkAsViewedNotificationMutation,
  useActivitiesQuery,
  ProfileDocument,
  IActivitiesQuery,
} from 'src/graphql/schema';
import { DropdownNotificationMenu } from 'src/components/Layout';

const DropdownNotificationContainer: React.FunctionComponent = () => {
  const { data: activityData, loading } = useActivitiesQuery({
    variables: {
      page: 1,
      perPage: 5,
    },
  });
  const [markAsViewed] = useMarkAsViewedNotificationMutation({
    refetchQueries: [ProfileDocument],
  });

  const activities: IActivitiesQuery['activities']['nodes'] = get(activityData, 'activities.nodes', []);
  const unreadNotifications = useMemo(() => activities.filter((item) => !item.viewedAt), [activities]);

  const markAsRead = async (isVisible: boolean) => {
    if (isVisible && unreadNotifications.length > 0) {
      const notificationIds = unreadNotifications.map((item) => item.id);

      try {
        await markAsViewed({
          variables: {
            notificationIds,
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <DropdownNotificationMenu
      activities={activities}
      isLoading={loading}
      markAsRead={markAsRead}
      notificationsCount={unreadNotifications.length}
    />
  );
};

export default DropdownNotificationContainer;
