import { BackupAssetStatusEnum, BackupVersionStatusEnum } from 'src/graphql/schema';

export const versionTextOptions = {
  [BackupAssetStatusEnum.Pending]: 'Scheduled',
  [BackupAssetStatusEnum.Completed]: 'Latest',
  [BackupAssetStatusEnum.Stopped]: 'Stopped',
  [BackupAssetStatusEnum.Failed]: 'Backup failed',
};

export const noBackupText =
  "We’ve checked that there are no changes from your latest backup session, no backup necessary at this point. We'll continue to check for changes according to your backup schedule.";

export const resultItemStatus = {
  [BackupAssetStatusEnum.Pending]: 'scheduled',
  [BackupAssetStatusEnum.Completed]: 'latest',
  [BackupAssetStatusEnum.Stopped]: 'stopped',
  [BackupAssetStatusEnum.Failed]: 'failed',
  [BackupVersionStatusEnum.Finished]: 'latest',
  [BackupVersionStatusEnum.Skipped]: 'skipped',
} as const;
