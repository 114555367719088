import { InfoDetailBlock } from 'src/components/InfoDetailBlock';

export interface IOrganizationDetail {
  name: string;
  website?: string;
  registrationNumber?: string;
  address: {
    street: string;
    streetNumber?: string;
    city: string;
    postalCode: string;
    region: string;
    country: string;
  };
  suggestionList?: React.ReactNode;
}

type Props = {
  type: 'beneficiary' | 'depositor';
  className?: string;
} & IOrganizationDetail;

const OrganizationDetailBlock: React.FunctionComponent<Props> = ({
  type,
  name,
  website,
  registrationNumber,
  address,
  className,
  suggestionList,
}) => {
  const fullAddress = Object.entries(address)
    .filter(([, value]) => Boolean(value))
    .reduce((acc, [key, value], index) => {
      if (index === 0) return `${acc}`;
      if (index === 1 && key === 'streetNumber') return `${acc} ${value}`;
      else return `${acc}, ${value}`;
    }, address.street);

  return (
    <InfoDetailBlock
      className={className}
      title={`${type} Organization Details`}
      text={`The ${type}'s organization details.`}
      items={[
        { label: 'Organization Name', value: name },
        { label: 'Website', value: website || '-' },
        { label: 'Registration Number', value: registrationNumber || '-' },
        { label: 'Address', value: fullAddress },
      ]}
      suggestionList={suggestionList}
    />
  );
};

export default OrganizationDetailBlock;
