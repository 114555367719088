import {
  LogoAzure,
  LogoGithub,
  LogoGitlab,
  LogoBitbucket,
  LogoCodecommit,
  LogoSharefile,
  LogoGcp,
  LogoAws,
  LogoBeanstalk,
  LogoAzureDevops,
} from 'src/components/Svg/Images/Integrations';

export const logoMap = {
  azure: LogoAzure,
  github: LogoGithub,
  github_server: LogoGithub,
  gitlab: LogoGitlab,
  gitlab_server: LogoGitlab,
  bitbucket: LogoBitbucket,
  bitbucket_server: LogoBitbucket,
  codecommit: LogoCodecommit,
  share_file_https: LogoSharefile,
  gcp: LogoGcp,
  aws: LogoAws,
  beanstalk: LogoBeanstalk,
  azure_devops: LogoAzureDevops,
};

export const renderLogo = (provider: keyof typeof logoMap) => {
  return logoMap[provider];
};
