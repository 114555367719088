import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoOracle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMid"
    viewBox="0 0 231 30"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      d="M99.61 19.52h15.24l-8.05-13L92 30h-6.73l18-28.17a4.29 4.29 0 0 1 7-.05L128.32 30h-6.73l-3.17-5.25H103l-3.36-5.23m69.93 5.23V.28h-5.72v26.88a2.76 2.76 0 0 0 .85 2 2.9 2.9 0 0 0 2.08.87h26l3.39-5.25h-26.63M75 20.38a10 10 0 0 0 0-20.1H50V30h5.71V5.54h18.94a4.81 4.81 0 0 1 0 9.62H58.54L75.6 30h8.29l-11.46-9.62zM14.88 30h17.27a14.86 14.86 0 0 0 0-29.71H14.88a14.86 14.86 0 1 0 0 29.71m16.88-5.23h-16.5a9.62 9.62 0 0 1 0-19.23h16.5a9.62 9.62 0 1 1 0 19.23M140.25 30h17.63l3.34-5.23h-20.58a9.62 9.62 0 1 1 0-19.23h16.75l3.38-5.25h-20.52a14.86 14.86 0 1 0 0 29.71m69.87-5.23a9.62 9.62 0 0 1-9.26-7h24.42l3.36-5.24h-27.78a9.61 9.61 0 0 1 9.26-7h16.76l3.35-5.25h-20.5a14.86 14.86 0 0 0 0 29.71h17.63l3.35-5.23h-20.6"
      style={{
        fill: '#c74634',
      }}
      transform="translate(-.02)"
    />
  </svg>
);
const Memo = memo(SvgLogoOracle);
export default Memo;
