import Bitbucket1 from 'src/assets/images/integrations/bitbucket1.svg';
import Gitlab2 from 'src/assets/images/integrations/gitlab2.svg';
import Gitlab3 from 'src/assets/images/integrations/gitlab3.svg';

import Step from './Step';

export function GithubInfoContent() {
  return (
    <>
      <Step stepNumber={1} icon={Bitbucket1}>
        Go to your GitHub account and go to{' '}
        <strong>Settings -&gt; Developer settings -&gt; Personal access tokens.</strong>
      </Step>

      <Step stepNumber={2} icon={Gitlab2}>
        Click the <strong>Generate new token</strong> button, set <strong>Expiration</strong> to{' '}
        <strong>No expiration</strong>, select scopes <strong>repo, read:user, user:email </strong>and then click the{' '}
        <strong>Generate token</strong> button.
      </Step>

      <Step stepNumber={3} icon={Gitlab3}>
        Copy your new Personal Access Token.
      </Step>
    </>
  );
}
