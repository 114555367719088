import { createContext, useContext } from 'react';

export const createRequiredContext = <T,>(): [() => T, React.Provider<T | null>] => {
  const context = createContext<T | null>(null);

  const useNewContext = () => {
    const contextValue = useContext(context);

    if (contextValue === null) {
      throw new Error('Context value is null');
    }

    return contextValue;
  };

  return [useNewContext, context.Provider];
};
