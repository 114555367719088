import { Outlet } from 'react-router-dom';

import { CreateNewEnvironmentContainer } from 'src/containers/Saas/Environments';
import { SaasEscrowsContainer } from 'src/containers/Saas/Escrows';
import { Main } from 'src/components/Layout';

const SaasEscrows = () => {
  return (
    <Main title="SaaS Escrow" headerActions={<CreateNewEnvironmentContainer />}>
      <SaasEscrowsContainer />

      <Outlet />
    </Main>
  );
};

export default SaasEscrows;
