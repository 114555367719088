import { Modal } from 'antd';

type RejectModalProps = {
  visible: boolean;
  showModal: () => void;
  rejectInvitation: () => void;
};

export default function RejectModal({ visible, showModal, rejectInvitation }: RejectModalProps) {
  return (
    <Modal
      className="feedback"
      title="Are you sure you want to reject the invitation of this escrow?"
      open={visible}
      centered
      onOk={rejectInvitation}
      onCancel={showModal}
      cancelText="Cancel"
      okText="Reject"
    >
      <p className="feedback__subtext">
        We will notify the initiator of this escrow that you have rejected the invitation. The escrow can be restarted
        by the original party.
      </p>
    </Modal>
  );
}
