import { useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client/errors';
import { useState } from 'react';
import { useSuspenseQuery } from '@apollo/client';

import { BackupDocument, BackupAssetStatusEnum, BackupMemberRoleEnum, BackupStatusEnum } from 'src/graphql/schema';

import { createRequiredContext } from '../createRequiredContext';

import type { IBackup, IBackupQuery } from 'src/graphql/schema';

const [useBackup, BackupProvider] = createRequiredContext<{
  backup: IBackup;
  me: { id: string };
  currentUserRole: BackupMemberRoleEnum;
  currentAssetStatus?: BackupAssetStatusEnum;
  error?: ApolloError;
  ui: IUiState;
  setActiveEnablingAssetId: (id: string | null) => void;
  setEditSettingsMode: (mode: boolean) => void;
  setEditAccessMode: (mode: boolean) => void;
  setRemoveOpen: (open: boolean) => void;
}>();

type IUiState = {
  activeEnablingAssetId: string | null;
  isEditSettingsMode: boolean;
  isEditAccessMode: boolean;
  isRemoveOpen: boolean;
};

const priorityAssetStatus = {
  [BackupAssetStatusEnum.Stopped]: 0,
  [BackupAssetStatusEnum.Completed]: 1,
  [BackupAssetStatusEnum.Pending]: 2,
  [BackupAssetStatusEnum.Failed]: 3,
};

const BackupContextProvider = ({ children }: React.PropsWithChildren) => {
  const [ui, setUi] = useState<IUiState>({
    activeEnablingAssetId: null,
    isEditSettingsMode: false,
    isEditAccessMode: false,
    isRemoveOpen: false,
  });
  const { id } = useParams();
  const { data, error } = useSuspenseQuery<IBackupQuery>(BackupDocument, {
    variables: {
      backupId: id as string,
    },
    skip: !id,
  });

  const currentAssetStatus =
    data?.backup.status === BackupStatusEnum.Active
      ? data?.backup.backupAssets.reduce(
          (acc, { status }) =>
            status in priorityAssetStatus && priorityAssetStatus[status] > priorityAssetStatus[acc] ? status : acc,
          BackupAssetStatusEnum.Stopped,
        )
      : undefined;

  const currentUserRole =
    data?.backup.backupMembers.find(({ user }) => user.id === data?.me?.id)?.role ?? BackupMemberRoleEnum.Administrator;

  const setActiveEnablingAssetId = (id: string | null) => setUi((ui) => ({ ...ui, activeEnablingAssetId: id }));

  const setEditSettingsMode = (isEditSettingsMode: boolean) => setUi((ui) => ({ ...ui, isEditSettingsMode }));

  const setEditAccessMode = (isEditAccessMode: boolean) => setUi((ui) => ({ ...ui, isEditAccessMode }));

  const setRemoveOpen = (isRemoveOpen: boolean) => setUi((ui) => ({ ...ui, isRemoveOpen }));

  const providerValue = {
    backup: data?.backup as IBackup,
    me: { id: data?.me.id ?? '' },
    currentUserRole,
    currentAssetStatus,
    error,
    ui,
    setActiveEnablingAssetId,
    setEditSettingsMode,
    setEditAccessMode,
    setRemoveOpen,
  };

  return <BackupProvider value={providerValue}>{children}</BackupProvider>;
};

export { useBackup, BackupContextProvider, BackupProvider };
