import { EscrowsActionBar, EscrowsTable, IEscrowTableRow } from '../';

export interface IEscrowsTabContent {
  isLoading?: boolean;
  order: string;
  onOrderChange: () => void;
  onSearchChange: (value: string) => void;
  data: IEscrowTableRow[];
  pagination: {
    current: number;
    total: number;
    onChange: (page: number) => void;
  };
  actions: (data: { id: string; invitationId?: string; isDraft: boolean }) => JSX.Element;
}

const EscrowsTabContent: React.FunctionComponent<IEscrowsTabContent> = ({
  order,
  onOrderChange,

  data,
  pagination,
  isLoading = false,
  onSearchChange,
  actions,
}) => {
  return (
    <div className="active-deposits">
      <EscrowsActionBar order={order} onOrderChange={onOrderChange} onSearchChange={onSearchChange} />
      <EscrowsTable data={data} pagination={pagination} isLoading={isLoading} actions={actions} />
    </div>
  );
};

export default EscrowsTabContent;
