import { Tabs } from 'antd';

import EmptyEscrows from 'src/pages/Escrows/EmptyEscrows';

import { escrowTabs } from './escrowsTabs.enum';

type Props = {
  onTabChange: (key: string) => void;
  activeTabKey: string;
  showEmptyState: boolean;
  isLoading?: boolean;
};

const EscrowsTabs: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  onTabChange,
  activeTabKey,
  showEmptyState,
  isLoading,
  children,
}) => {
  const items = escrowTabs.map((item) => ({
    label: item.name,
    key: item.key,
    children: !isLoading && showEmptyState ? <EmptyEscrows status={item.key !== 'all' ? item.name : ''} /> : children,
  }));

  return <Tabs onChange={onTabChange} activeKey={activeTabKey} items={items} />;
};

export default EscrowsTabs;
