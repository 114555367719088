import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NewBackupAction = () => {
  const navigate = useNavigate();

  const handleCreateBackup = () => navigate('/backups/new');

  return (
    <Button type="primary" onClick={handleCreateBackup}>
      + New Backup
    </Button>
  );
};

export default NewBackupAction;
