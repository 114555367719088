import { Suspense } from 'react';

import { useEscrow } from 'src/context/escrow';
import { EscrowStatusEnum, AgreementFormEnum } from 'src/graphql/schema';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { NotificationsContextProvider } from 'src/context/suggest-changes';
import Loader from 'src/components/Loader/Loader';

import NotificationsSuggestChangesContainer from './SuggestChanges/NotificationsSuggestChangesContainer';
import NotificationsSuggestChangesActions from './SuggestChanges/NotificationsSuggestChangesActions';
import EscrowNotificationsViewContainer from './EscrowNotificationsViewContainer';
import EscrowNotificationsEditContainer from './EscrowNotificationsEditContainer';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowNotificationsContainer = () => {
  const { escrow } = useEscrow();

  const isTripartite = escrow?.agreement?.agreementForm === AgreementFormEnum.Tripartite;
  const isBipartite = escrow?.agreement?.agreementForm === AgreementFormEnum.Bipartite;
  const isTripartiteOnboarding =
    isTripartite && escrow.status === EscrowStatusEnum.Onboarding && escrow.depositor && escrow.beneficiary;
  const isBipartiteOnboarding = isBipartite && escrow.status === EscrowStatusEnum.Onboarding;
  const shouldEnableSuggestChanges = (() => {
    return [
      !escrow.escrowTermination,
      (isTripartite && escrow.status === EscrowStatusEnum.Active) ||
        (isTripartite && escrow.status === EscrowStatusEnum.Signed) ||
        isTripartiteOnboarding ||
        isBipartiteOnboarding,
    ].every(Boolean);
  })();

  // Suggest Changes Mode
  if (shouldEnableSuggestChanges) {
    return (
      <Suspense fallback={<Loader />}>
        <NotificationsContextProvider>
          <EscrowModalMain>
            <NotificationsSuggestChangesContainer />
          </EscrowModalMain>
          <NotificationsSuggestChangesActions />
        </NotificationsContextProvider>
      </Suspense>
    );
  }

  // New/Edit Mode
  if (escrow.status === EscrowStatusEnum.Draft) {
    return (
      <EscrowModalMain>
        <EscrowNotificationsEditContainer />
      </EscrowModalMain>
    );
  }

  // Preview Mode
  return (
    <EscrowModalMain>
      <EscrowNotificationsViewContainer />
    </EscrowModalMain>
  );
};

export default EscrowNotificationsContainer;
