import { EXCLUSIVE_OFFER } from 'src/shared/constants';

import IconPlanOffer from '../Icon/IconPlanOffer';
import styles from '../CancelPlanOffer.module.scss';

type Props = {
  offerCard: React.ReactNode;
};

const ExclusiveOfferContent = ({ offerCard }: Props) => {
  return (
    <div className={`d-flex mb-32 ${styles['offer-block']}`}>
      <div className={styles['info-col']}>
        <IconPlanOffer className="mb-3" />
        <h6>Exclusive offer</h6>
        <h1>
          Stay and get your current plan for <strong>{EXCLUSIVE_OFFER}% off</strong>
        </h1>
        <p>
          To show our appreciation for your business, we are pleased to offer you an exclusive loyalty discount on your
          current plan.
          <br />
          <br /> By taking advantage of this special offer, you can continue to enjoy all the benefits and features you
          love at a reduced rate.
        </p>
      </div>

      <div className={styles['offer-col']}>{offerCard}</div>
    </div>
  );
};

export default ExclusiveOfferContent;
