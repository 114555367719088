import { JourneyStatusIndicator } from 'src/components/Journey/StatusIndicator';

import type { IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

import { statusIndicatorItems, CustodianStatusIndicatorEnum } from './model/statusIndicator.enum';

type Props = {
  currentStatus: string;
};

const CustodianStatusIndicator = ({ currentStatus }: Props) => {
  const currentStatusIndex = statusIndicatorItems.findIndex(({ id }) => id === currentStatus);
  let newStatusIndicatorItems: IJourneyStatusIndicatorItem[] = [...statusIndicatorItems].map((item, index) =>
    index <= currentStatusIndex ? { ...item, status: item.id === currentStatus ? 'active' : 'completed' } : item,
  );

  if (currentStatus === CustodianStatusIndicatorEnum.LIFECYCLE)
    newStatusIndicatorItems = newStatusIndicatorItems.map((item) => ({
      ...item,
      status: 'completed',
    }));

  if (currentStatus === CustodianStatusIndicatorEnum.TERMINATION_PENDING) {
    newStatusIndicatorItems = newStatusIndicatorItems.map((item) => ({
      ...item,
      status: item.id === currentStatus ? 'pending-cancellation' : 'completed',
      isHidden: item.id === currentStatus ? false : item.isHidden,
    }));
  }

  if (currentStatus === CustodianStatusIndicatorEnum.TERMINATED) {
    newStatusIndicatorItems = newStatusIndicatorItems.map((item) => ({
      ...item,
      status: item.id === currentStatus ? 'canceled' : 'completed',
      isHidden: item.id === currentStatus ? false : item.isHidden,
    }));
  }

  return (
    <JourneyStatusIndicator
      isCanceled={
        currentStatus === CustodianStatusIndicatorEnum.TERMINATION_PENDING ||
        currentStatus === CustodianStatusIndicatorEnum.TERMINATED
      }
    >
      {newStatusIndicatorItems.map((item) => (
        <JourneyStatusIndicator.Item key={item.id} {...item} />
      ))}
    </JourneyStatusIndicator>
  );
};

export default CustodianStatusIndicator;
