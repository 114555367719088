import { useState } from 'react';
import { Form, Modal, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

import {
  useCreateShareFileIntegrationMutation,
  IntegrationsDocument,
  IShareFileIntegrationInputAttributes,
} from 'src/graphql/schema';
import { renderLogo } from 'src/utils/renderLogo';
import { message } from 'src/components/Misc';

import styles from './CredentialsForm/styles.module.scss';

type Props = {
  isVisible: boolean;
  toggleModal: () => void;
  name: string;
};

export default function ShareFileModal({ isVisible, toggleModal, name }: Props) {
  const navigate = useNavigate();

  const [createShareFileIntegration] = useCreateShareFileIntegrationMutation({
    refetchQueries: [IntegrationsDocument],
  });

  const [isConnected, setIsConnected] = useState(false);

  const [form] = Form.useForm();

  const handleSubmission = (values: IShareFileIntegrationInputAttributes) => {
    createShareFileIntegration({
      variables: {
        shareFileIntegrationInput: {
          email: values.email,
        },
      },
    })
      .then(() => {
        setIsConnected(true);
      })
      .catch((error) => {
        console.log(error);
        message.error("Integration wasn't connected!");
      });
  };

  const Icon = renderLogo('share_file_https');

  return (
    <Modal
      className="non-auth-modal"
      open={isVisible}
      onCancel={toggleModal}
      footer={null}
      destroyOnClose={true}
      afterClose={() => setIsConnected(false)}
    >
      <Icon className="non-auth-modal__logo" />
      <p className="non-auth-modal__title">{name}</p>

      {!isConnected && <p className="non-auth-modal__details">Enter your email address for ShareFile</p>}

      {isConnected ? (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <CheckCircleFilled
            className="mb-3"
            style={{
              fontSize: '72px',
              color: '#33c99c',
            }}
          />

          <p className="non-auth-modal__text-small">
            Your email has been submitted, we will send you a setup invite in a moment.
          </p>

          <Button className="non-auth-modal__btn" type="primary" onClick={() => navigate('/escrows')}>
            Go to Escrows
          </Button>
        </div>
      ) : (
        <Form form={form} className={styles.CredentialsForm} layout="vertical" onFinish={handleSubmission}>
          <Form.Item
            className={styles.customFormItem}
            label="Email address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Add your email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Button className="non-auth-modal__btn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      )}
    </Modal>
  );
}
