import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSaasCredentials = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M40 7.059h-4.706V2.353H16.926L16.142 0H3.529v24.114A8.794 8.794 0 0 0 0 31.177C0 36.041 3.958 40 8.824 40H22.94a8.79 8.79 0 0 0 5.992-2.353H40V7.06Zm-7.059-2.353v2.353H18.495l-.784-2.353h15.23ZM14.446 2.353 16.8 9.412h20.848v2.353H5.882V2.353h8.564Zm8.495 35.294H8.824a6.478 6.478 0 0 1-6.471-6.47 6.453 6.453 0 0 1 4.007-5.985l.807-.333c-.07-.765-.108-.98-.108-1.33a4.711 4.711 0 0 1 4.706-4.706 4.71 4.71 0 0 1 3.528 1.595l.687.779.858-.586A3.508 3.508 0 0 1 18.823 20a3.533 3.533 0 0 1 3.53 3.516l-.012 1.125 1.099.084c3.348.254 5.972 3.088 5.972 6.451a6.478 6.478 0 0 1-6.47 6.471Zm7.802-2.353a8.77 8.77 0 0 0 1.022-4.117 8.827 8.827 0 0 0-7.147-8.665 5.892 5.892 0 0 0-5.794-4.865c-.856 0-1.689.183-2.454.535a7.055 7.055 0 0 0-10.488 1.45v-5.514h31.765v21.176h-6.904Z"
      />
      <path
        fill="currentColor"
        d="m9.757 27.403 1.664 1.664 1.521-1.521v7.748h2.353v-7.748l1.521 1.521 1.664-1.664-4.361-4.361-4.362 4.361Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSaasCredentials);
export default Memo;
