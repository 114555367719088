import { EscrowPartyTypeEnum, IEscrowInvitation, InvitationStatusEnum } from 'src/graphql/schema';
import { InfoPreviewByRole } from 'src/components/Escrow/Contacts';
import { getPartySignatory, getPartyContacts } from 'src/utils/destructs/escrow.destructs';
import { useEscrow } from 'src/context/escrow';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';

import EscrowMainPartyEditableContainer from '../Main/EscrowMainPartyEditableContainer';

import SecondPartyInvitationContainer from './SecondPartyInvitationContainer';

const EscrowTripartiteEditableContainer: React.FunctionComponent = () => {
  const { escrow } = useEscrow();
  const currentEscrowRole = escrow.currentCompanyRole;
  const currentEscrowOrganization = escrow[currentEscrowRole!];
  const escrowInvitation = escrow.escrowInvitation as IEscrowInvitation;
  const isInvitationAccepted = escrow.escrowInvitation?.status === InvitationStatusEnum.Accepted;
  const secondPartyRole =
    currentEscrowRole === EscrowPartyTypeEnum.Beneficiary
      ? EscrowPartyTypeEnum.Depositor
      : EscrowPartyTypeEnum.Beneficiary;
  const secondPartyOrganization = escrow[secondPartyRole];

  if (!currentEscrowRole) return null;

  return (
    <div className={styles.main}>
      {(escrow.status === 'onboarding' || escrow.status === 'admin_review') &&
      escrow.startedBy === currentEscrowRole ? (
        <InfoPreviewByRole
          role={currentEscrowRole}
          isOwnOrganization
          organization={{
            name: currentEscrowOrganization?.companyName || '',
            address: {
              street: currentEscrowOrganization?.street || '',
              streetNumber: currentEscrowOrganization?.streetNumber || '',
              city: currentEscrowOrganization?.city || '',
              postalCode: currentEscrowOrganization?.postalCode || '',
              region: currentEscrowOrganization?.region || '',
              country: currentEscrowOrganization?.country || '',
            },
            registrationNumber: currentEscrowOrganization?.companyRegistrationNumber || '',
            website: currentEscrowOrganization?.companyWebsite || '',
          }}
          signatory={getPartySignatory(escrow, currentEscrowRole)}
          contacts={getPartyContacts(escrow, currentEscrowRole)}
        />
      ) : (
        <EscrowMainPartyEditableContainer role={currentEscrowRole} />
      )}

      {isInvitationAccepted && (
        <InfoPreviewByRole
          role={secondPartyRole}
          organization={{
            name: secondPartyOrganization?.companyName || '',
            address: {
              street: secondPartyOrganization?.street || '',
              streetNumber: secondPartyOrganization?.streetNumber || '',
              city: secondPartyOrganization?.city || '',
              postalCode: secondPartyOrganization?.postalCode || '',
              region: secondPartyOrganization?.region || '',
              country: secondPartyOrganization?.country || '',
            },
            registrationNumber: secondPartyOrganization?.companyRegistrationNumber || '',
            website: secondPartyOrganization?.companyWebsite || '',
          }}
          signatory={getPartySignatory(escrow, secondPartyRole)}
          contacts={getPartyContacts(escrow, secondPartyRole)}
        />
      )}
      {!isInvitationAccepted && (
        <SecondPartyInvitationContainer
          role={secondPartyRole}
          escrowId={escrow.id}
          escrowInvitation={escrowInvitation}
          escrowStatus={escrow.status}
        />
      )}
    </div>
  );
};

export default EscrowTripartiteEditableContainer;
