import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { StepsEnum } from '../../InviteSteps.enum';

type Props = {
  setStep: (step: StepsEnum) => void;
};

export default function RedirectOption({ setStep }: Props) {
  const handleSetStep = (step: StepsEnum) => () => setStep(step);

  return (
    <div className="redirect-option">
      <h1>Would you like to invite team member?</h1>

      <Button type="primary" onClick={handleSetStep('invite-form')}>
        Invite new member
      </Button>

      <p role="button" onClick={handleSetStep('invitations')}>
        Next time, take me to dashboard <RightOutlined />
      </p>
    </div>
  );
}
