import React from 'react';

import { IconEditCircle } from 'src/components/Svg/Icons';
import { JourneyMenu, IJourneyMenuItem } from 'src/components/Journey/Menu';

import styles from './EscrowMenu.module.scss';

export type IEscrowMenuItem = {
  isRequired?: boolean;
  statusChanges?: 'accepted' | 'pending';
} & IJourneyMenuItem;

const EscrowMenuItem: React.FunctionComponent<IEscrowMenuItem> = ({ isRequired = false, statusChanges, ...rest }) => {
  return (
    <JourneyMenu.Item {...rest}>
      {isRequired && !statusChanges && <span className={styles['required-dot']} />}
      {statusChanges && <IconEditCircle className={`${styles['icon-edit']} ${styles[statusChanges]}`} />}
    </JourneyMenu.Item>
  );
};

export default EscrowMenuItem;
