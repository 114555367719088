import { message, ModalProceed } from 'src/components/Misc';
import { useSaasEscrow } from 'src/context/saas';
import { useRemoveSaasProviderMutation, SaasEnvironmentDocument } from 'src/graphql/schema';
import { getApiError, ClientError } from 'src/utils/errors';

const RemoveProviderContainer = () => {
  const { ui: { provider } = {}, hideProvider } = useSaasEscrow();

  const [removeSaasProvider, { loading }] = useRemoveSaasProviderMutation({
    refetchQueries: [SaasEnvironmentDocument],
  });

  const onRemoveProvider = async () => {
    try {
      const { data } = await removeSaasProvider({
        variables: {
          id: provider?.id || '',
        },
      });

      if (data?.removeSaasProvider?.success) {
        hideProvider();
      } else {
        throw new ClientError(data?.removeSaasProvider?.errors?.[0]);
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  return (
    <ModalProceed
      isOpen={provider?.modalType === 'remove'}
      title="Are you sure you want to remove
      this provider?"
      text="All the details will be removed."
      proceedText="Remove"
      proceedButtonProps={{ danger: true, type: 'default' }}
      onProceed={onRemoveProvider}
      onCancel={hideProvider}
      isLoading={loading}
    />
  );
};

export default RemoveProviderContainer;
