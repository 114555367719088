import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { Form, Input } from 'antd';

import { TextBlock } from 'src/components/Misc';
import { CustomCheckboxWithIconGroup } from 'src/components/Form';

import { escrowLegalContextOptions } from '../escrowAgreement.enum';
import sharedStyles from '../../Escrow.module.scss';

import styles from './EscrowAgreementEdit.module.scss';

type Props = {
  onChange: (value: CheckboxValueType[]) => void;
  onOtherChange: (value: string) => void;
  selectedValues?: string[];
  legalContextOther?: string;
};

const LegalContextSelect: React.FunctionComponent<Props> = ({
  selectedValues = [],
  onChange,
  legalContextOther,
  onOtherChange,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLegalOtherChange = useCallback(
    debounce((value: { legalContextOther: string }) => onOtherChange(value.legalContextOther), 500),
    [],
  );

  return (
    <div className={`${sharedStyles['bb--dashed']} ${sharedStyles['mb--40']}`}>
      <TextBlock
        className={sharedStyles['mb--20']}
        title="Legal Context"
        titleColor="dark-blue"
        as="h4"
        text="Choose the legal context that best describes your arrangement with your counterparty. This will be reflected in the escrow agreement terms."
        segment={!selectedValues.length && <i className={sharedStyles.segment}>Required</i>}
      />
      <CustomCheckboxWithIconGroup
        selectedValues={selectedValues || []}
        className={`${sharedStyles['container--990']} ${sharedStyles['mb--40']}`}
        classes={{
          row: sharedStyles['card-list__row'],
        }}
        col={5}
        options={escrowLegalContextOptions}
        onChange={onChange}
      />

      {selectedValues?.includes('other') && (
        <div className={sharedStyles['mb--40']}>
          <Form
            className="ck-agreement__form-other"
            onValuesChange={handleLegalOtherChange}
            initialValues={{
              legalContextOther,
            }}
            layout="vertical"
          >
            <Form.Item
              className={`ck-agreement__form-item ${styles['form-item--420']}`}
              name="legalContextOther"
              label="Please describe your other agreement type"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of your agreement.',
                },
              ]}
            >
              <Input placeholder="Your other agreement type" />
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default LegalContextSelect;
