import { Select } from 'antd';
import React from 'react';

import { useHomeActions } from 'src/context/home';
import ActiveEscrows from 'src/pages/Escrows/ActiveEscrows';

import HomeCarousel from '../Carousel/HomeCarousel';
import { dateOptions } from '../home.enum';

const { Option } = Select;

const HomeContent: React.FunctionComponent = () => {
  const { escrows, currentPage, setCurrentPage, loadingData, onDateChange } = useHomeActions();

  return (
    <div className="active-homepage__contents">
      <div className="d-flex justify-content-between align-items-center">
        <p className="active-homepage__content-title">Action Items</p>

        <Select defaultValue="6" style={{ width: 120 }} onChange={onDateChange}>
          {dateOptions.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </div>

      <HomeCarousel />

      <ActiveEscrows data={escrows} setCurrentPage={setCurrentPage} currentPage={currentPage} isLoading={loadingData} />
    </div>
  );
};

export default React.memo(HomeContent);
