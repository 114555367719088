import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

export const labelStatusTypeEnum = {
  active: {
    text: 'Active',
    bgColor: 'green',
  },
  draft: {
    text: 'Draft',
    bgColor: 'orange',
  },
  pending: {
    text: 'Pending',
    bgColor: 'orange',
  },
  terminated: {
    text: 'Terminated',
    bgColor: 'red',
  },
  'terminated-requested': {
    text: 'Termination Requested',
    bgColor: 'red',
  },
} as const;

export const actionOptions = [
  {
    label: 'All time',
    value: 'all',
  },
  {
    label: 'Active',
    value: SaasEnvironmentStatusEnum.Active,
  },
  {
    label: 'Pending',
    value: SaasEnvironmentStatusEnum.Pending,
  },
  {
    label: 'Draft',
    value: SaasEnvironmentStatusEnum.Draft,
  },
  {
    label: 'Terminated',
    value: SaasEnvironmentStatusEnum.Terminated,
  },
];
