import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconRegulatoryOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M31.301 8.186 25.115 2H8.757v3.82H5V34h22.544v-3.82h3.757zm-5.636-2.979L28.157 7.7h-2.492zm0 26.914H6.88V7.7h1.878V30.18h16.908zm-15.029-3.82V3.88h13.15v5.698h5.637v18.724z"
      />
      <path
        fill="currentColor"
        d="M18.265 12h-1.883v1.882H14.5v1.883h1.882v1.882h1.883v-1.882h7.53v-1.883h-7.53zM23.912 17.647h-1.883v1.882H14.5v1.883h7.53v1.882h1.882v-1.882h1.882v-1.883h-1.882z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconRegulatoryOperation);
export default Memo;
