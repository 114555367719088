import { useLocation, useParams } from 'react-router-dom';
import { cloneDeep } from '@apollo/client/utilities';
import { useSuspenseQuery } from '@apollo/client';
import { useEffect } from 'react';

import { EscrowMenu } from 'src/components/Escrow/Menu';
import { escrowMenuItems } from 'src/components/Escrow/Menu/escrowMenu.enum';
import { useEscrow } from 'src/context/escrow';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import {
  useClientChangesRequestedQuery,
  EscrowStatusEnum,
  SuggestedChangeStatusEnum,
  EscrowPartyTypeEnum,
  SuggestedChangesNotificationsDocument,
} from 'src/graphql/schema';
import { suggestChangesVar, writeInitialSuggestChangesData } from 'src/graphql/client/cache';

import type { IClientChangesRequested, ISuggestedChangesNotificationsQuery } from 'src/graphql/schema';
import type { IEscrowMenuItem } from 'src/components/Escrow/Menu';

const EscrowMenuContainer = () => {
  const {
    escrow,
    ui: { isSuggestChangesMode },
  } = useEscrow();
  const { escrowId } = useParams();
  const location = useLocation();
  const { data } = useSuspenseQuery<ISuggestedChangesNotificationsQuery>(SuggestedChangesNotificationsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      escrowId: escrowId || '',
    },
    skip:
      !escrowId ||
      [
        EscrowStatusEnum.Draft,
        EscrowStatusEnum.AdminReview,
        EscrowStatusEnum.PendingTermination,
        EscrowStatusEnum.Terminated,
      ].includes(escrow?.status),
  });

  useEffect(() => {
    const onCompleted = ({
      agreementSuggestedChanges,
      contactSuggestedChanges,
      notificationSuggestedChanges,
      depositSuggestedChanges,
    }: ISuggestedChangesNotificationsQuery) => {
      const isRequested =
        Boolean(contactSuggestedChanges.nodesCount) ||
        Boolean(depositSuggestedChanges.nodesCount) ||
        Boolean(notificationSuggestedChanges.nodesCount) ||
        Boolean(agreementSuggestedChanges.nodesCount);
      let allChanges = cloneDeep(suggestChangesVar());

      if (!isRequested) return;

      if (contactSuggestedChanges.nodesCount) {
        allChanges = {
          ...allChanges,
          parties: {
            id: contactSuggestedChanges.nodes[0].id,
            status: contactSuggestedChanges.nodes[0].partyStatus as SuggestedChangeStatusEnum,
            creator: contactSuggestedChanges.nodes[0].creatorType,
            receiver: contactSuggestedChanges.nodes[0].receiverType as EscrowPartyTypeEnum,
            payload: null,
          },
        };
      }

      if (depositSuggestedChanges.nodesCount) {
        allChanges = {
          ...allChanges,
          deposits: {
            id: depositSuggestedChanges.nodes[0].id,
            status: depositSuggestedChanges.nodes[0].partyStatus as SuggestedChangeStatusEnum,
            creator: depositSuggestedChanges.nodes[0].creatorType,
            receiver: depositSuggestedChanges.nodes[0].receiverType as EscrowPartyTypeEnum,
            payload: null,
          },
        };
      }

      if (notificationSuggestedChanges.nodesCount) {
        allChanges = {
          ...allChanges,
          notifications: {
            id: notificationSuggestedChanges.nodes[0].id,
            status: notificationSuggestedChanges.nodes[0].partyStatus as SuggestedChangeStatusEnum,
            creator: notificationSuggestedChanges.nodes[0].creatorType,
            receiver: notificationSuggestedChanges.nodes[0].receiverType as EscrowPartyTypeEnum,
            payload: null,
          },
        };
      }

      if (agreementSuggestedChanges.nodesCount) {
        allChanges = {
          ...allChanges,
          agreement: {
            id: agreementSuggestedChanges.nodes[0].id,
            status: agreementSuggestedChanges.nodes[0].partyStatus as SuggestedChangeStatusEnum,
            creator: agreementSuggestedChanges.nodes[0].creatorType,
            receiver: agreementSuggestedChanges.nodes[0].receiverType as EscrowPartyTypeEnum,
            payload: null,
          },
        };
      }

      writeInitialSuggestChangesData(suggestChangesVar)(allChanges);
    };

    if (data) onCompleted(data);
  }, [data]);

  const { data: changesRequestedData } = useClientChangesRequestedQuery();

  let menuItems = [...escrowMenuItems];

  if (isSuggestChangesMode) {
    const activePath = location.pathname.split('/').at(-1);

    menuItems = menuItems.map((item) => ({
      ...item,
      isRequired: false,
      isDisabled: item.path !== activePath,
    }));
  }

  if (
    !isSuggestChangesMode &&
    [EscrowStatusEnum.Draft, EscrowStatusEnum.AdminReview, EscrowStatusEnum.Onboarding].includes(escrow.status)
  ) {
    menuItems[0] = {
      ...menuItems[0],
      isRequired: false,
    };
    menuItems[1] = {
      ...menuItems[1],
      isRequired: !escrow.escrowPartiesConfigured,
      isDisabled: false,
    };
    menuItems[2] = {
      ...menuItems[2],
      isDisabled: false,
    };
    menuItems[3] = {
      ...menuItems[3],
      isRequired: !escrow.agreementSettingsConfigured,
      isDisabled: false,
    };
  }

  if (!isSuggestChangesMode && escrow.status === EscrowStatusEnum.Onboarding) {
    menuItems = menuItems.map((item, index) => {
      if (index < 5) {
        return {
          ...item,
          isDisabled: false,
        };
      }

      return item;
    });
  }

  if (
    !isSuggestChangesMode &&
    [
      EscrowStatusEnum.Active,
      EscrowStatusEnum.Signed,
      EscrowStatusEnum.PendingTermination,
      EscrowStatusEnum.Terminated,
    ].includes(escrow.status)
  ) {
    menuItems = menuItems.map((item, index) => {
      if (index < 6) {
        return {
          ...item,
          isDisabled: false,
          isRequired:
            item.path === EscrowRoutesEnum.Deposits && escrow.depositStatus === 'no_configuration' ? true : false,
        };
      } else return item;
    });
  }

  // render escrow Certificate/Reports menu item only if escrow is active
  if (!isSuggestChangesMode && EscrowStatusEnum.Active === escrow.status) {
    menuItems[6] = { ...menuItems[6], isDisabled: false };
    menuItems[7] = { ...menuItems[7], isDisabled: false };
  }

  if (changesRequestedData?.clientChangesRequested.isRequested && !escrow.escrowTermination) {
    const activeSuggestedChanges = changesRequestedData.clientChangesRequested as unknown as Record<
      string,
      IClientChangesRequested
    >;

    menuItems = menuItems.map((item) => {
      const statusChanges =
        item.path in activeSuggestedChanges && activeSuggestedChanges[item.path]?.status !== null
          ? activeSuggestedChanges[item.path]?.status
          : '';

      return statusChanges
        ? {
            ...item,
            statusChanges: statusChanges as IEscrowMenuItem['statusChanges'],
          }
        : { ...item };
    });
  } else {
    menuItems = menuItems.map(({ statusChanges, ...item }) => ({
      ...item,
    }));
  }

  if (
    escrow.currentCompanyRole === EscrowPartyTypeEnum.Beneficiary &&
    (!escrow.reportsEnabled || !escrow.showDepositInformation)
  ) {
    menuItems = menuItems.filter((item) => item.path !== EscrowRoutesEnum.Reports);
  }

  return <EscrowMenu basePath="escrows" items={menuItems} escrowId={escrow?.id} />;
};

export default EscrowMenuContainer;
