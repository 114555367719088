import { Button, Divider } from 'antd';

type Props = React.PropsWithChildren<{
  membersCount: number;
  onAdd: () => void;
  isOwner: boolean;
}>;

const TeamMembers: React.FunctionComponent<Props> = ({ membersCount = 0, onAdd, isOwner, children }) => {
  return (
    <div className="profile__team mt-0">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 className="plan-card__active-title mb-2">Team members</h2>
          <p className="profile__team-subtitle">
            {membersCount ? 'These people have access to your Codekeeper account.' : 'Add members'}
          </p>
        </div>
        {isOwner && (
          <Button type="primary" onClick={onAdd}>
            + Add
          </Button>
        )}
      </div>

      <Divider className="mt-12 mb-0" />

      {children}
    </div>
  );
};

export default TeamMembers;
