import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { useSaasEscrow } from 'src/context/saas';

import SaasModalFooterContainer from './SaasModalFooterContainer';
import SaasModalHeaderContainer from './SaasModalHeaderContainer';
import SaasMenuContainer from './SaasMenuContainer';
import SaasStatusIndicatorContainer from './SaasStatusIndicatorContainer';
import SaasAlertBannerContainer from './SaasAlertBannerContainer';

const SaasModalLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const { error } = useSaasEscrow();

  return (
    <JourneyModalLayout
      header={<SaasModalHeaderContainer />}
      error={error && { goTo: '/saas-escrows', ...error }}
      statusIndicator={<SaasStatusIndicatorContainer />}
      menu={<SaasMenuContainer />}
    >
      <SaasAlertBannerContainer />
      {children}
      <SaasModalFooterContainer />
    </JourneyModalLayout>
  );
};

export default SaasModalLayoutContainer;
