import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFinanceOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path fill="currentColor" d="M7.648 28.353h1.883v1.882H7.648zM11.412 28.353h1.882v1.882h-1.882z" />
      <path
        fill="currentColor"
        d="M23.318 17.059a8.46 8.46 0 0 0 3.153-6.588c0-4.671-3.8-8.471-8.47-8.471S9.53 5.8 9.53 10.47a8.46 8.46 0 0 0 3.153 6.589h-8.8V34h28.235V17.059zM11.412 10.47A6.596 6.596 0 0 1 18 3.882a6.596 6.596 0 0 1 6.589 6.589A6.596 6.596 0 0 1 18 17.059a6.596 6.596 0 0 1-6.589-6.588m18.824 17.882H18v1.882h12.236v1.883H5.766V26.47h24.47zm-24.47-3.765v-5.647h24.47v1.883H18v1.882h12.236v1.882z"
      />
      <path
        fill="currentColor"
        d="M20.5 11.715q0 .91-.53 1.46-.529.55-1.282.724v.748q0 .41-.082.573a.4.4 0 0 1-.224.222 1 1 0 0 1-.388.058q-.247 0-.4-.058a.36.36 0 0 1-.2-.21q-.082-.2-.082-.596v-.725a2.7 2.7 0 0 1-.87-.35q-.39-.246-.542-.432l-.153-.2q-.247-.314-.247-.548 0-.233.4-.62.236-.22.494-.221.27 0 .741.467.365.444.824.444.87 0 .87-.713 0-.256-.329-.397a4.7 4.7 0 0 0-.8-.245 7 7 0 0 1-.941-.304 1.8 1.8 0 0 1-.8-.643q-.33-.455-.33-1.156t.447-1.262q.448-.56 1.236-.724v-.654q0-.397.07-.573.119-.28.624-.28.318 0 .47.14.165.13.189.28.023.153.023.444v.655q.33.07.6.198.27.129.377.21l.106.094.047.035q.294.27.294.444 0 .176-.283.584-.27.41-.6.409a.63.63 0 0 1-.364-.105 5 5 0 0 1-.224-.175 2 2 0 0 0-.153-.117.9.9 0 0 0-.506-.129.8.8 0 0 0-.505.152.46.46 0 0 0-.2.397q0 .258.235.42.247.153.6.211.353.047.776.175.424.117.777.28.353.165.588.585.247.409.247 1.028M7.648 20.824h1.883v1.882H7.648zM11.412 20.824h1.882v1.882h-1.882z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFinanceOperation);
export default Memo;
