import { InfoDetailBlock } from 'src/components/InfoDetailBlock';

export interface IInviteDetail {
  name: string;
  email: string;
  phone?: string;
}

type Props = {
  type: 'beneficiary' | 'depositor';
  className?: string;
} & IInviteDetail;

const InvitationDetailBlock: React.FunctionComponent<Props> = ({ type, name, email, phone, className }) => {
  return (
    <InfoDetailBlock
      className={className}
      title={`${type} Contact Details`}
      text="The representative who will monitor the entire escrow configuration process. Some roles may have restrictions."
      items={[
        { label: 'Name', value: name },
        { label: 'Email', value: email },
        { label: 'Phone Number', value: phone || '-' },
      ]}
    />
  );
};

export default InvitationDetailBlock;
