import { TextBlock } from 'src/components/Misc';
import { CardWithIcon, ICardWithIcon } from 'src/components/Card';
import { IconBeneficiary, IconDepositor } from 'src/components/Svg/Images';
import { IconBipartite, IconTripartite } from 'src/components/Svg/Icons';
import { EscrowPartyTypeEnum, AgreementFormEnum } from 'src/graphql/schema';

import styles from './EscrowRole.module.scss';

type Props = {
  role: EscrowPartyTypeEnum;
  agreement: AgreementFormEnum;
};

const escrowActiveRoleData: { [key: string]: ICardWithIcon } = {
  [EscrowPartyTypeEnum.Beneficiary]: {
    heading: 'Beneficiary',
    text: 'Depositor and Codekeeper are signatories on the escrow agreement.',
    iconType: 'image',
    icon: IconBeneficiary,
    size: 52,
  },
  [EscrowPartyTypeEnum.Depositor]: {
    heading: 'Depositor',
    text: 'The party that owns the material that will be deposited into escrow.',
    iconType: 'image',
    icon: IconDepositor,
    size: 52,
  },
};

const escrowActiveAgreementData: { [key: string]: ICardWithIcon } = {
  [AgreementFormEnum.Bipartite]: {
    heading: 'Bipartite form',
    text: 'An agreement between two parties.',
    iconType: 'regular',
    icon: IconBipartite,
  },
  [AgreementFormEnum.Tripartite]: {
    heading: 'Tripartite form',
    text: 'Beneficiary will also be a signatory on the escrow agreement.',
    iconType: 'regular',
    icon: IconTripartite,
  },
};

const EscrowRolePreview: React.FunctionComponent<Props> = ({ role, agreement }) => {
  const escrowActiveRole = escrowActiveRoleData[role];
  const escrowActiveAgreement = escrowActiveAgreementData[agreement];

  return (
    <div className={styles.main}>
      <div className={styles.block}>
        <TextBlock
          className={styles['info-block']}
          title="Escrow Role"
          text="The role you have for the process of establishing this escrow."
        />
        <CardWithIcon className={styles['card-item']} {...escrowActiveRole} />
      </div>

      <div className={styles.block}>
        <TextBlock className={styles['info-block']} title="Agreement Form" text="The agreement type for this escrow." />
        <CardWithIcon className={styles['card-item']} {...escrowActiveAgreement} />
      </div>
    </div>
  );
};

export default EscrowRolePreview;
