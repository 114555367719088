import { Button, Dropdown, Typography } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';

import { IStaticIntegration, externalInfoPaths } from 'src/shared/entities';
import { renderLogo } from 'src/utils/renderLogo';
import { PROVIDER_KEY_TO_NAME } from 'src/shared/constants';

import IntegrationsTag from '../IntegrationsTag/IntegrationTag';

import styles from './StaticIntegrationCard.module.scss';

const { Text } = Typography;

type Props = {
  integration: IStaticIntegration;
  loading?: boolean;
  handleOnClick: () => void;
};

const StaticIntegrationCard = ({ integration, loading, handleOnClick }: Props) => {
  const isCustom = integration.__typename === 'CustomIntegration';
  const isManual = integration.__typename === 'ShareFileIntegration';

  const onMoreInfoClick = () => {
    const path = externalInfoPaths[integration.provider] ?? integration.name.toLowerCase().split(' ').join('_');

    window.open(`https://codekeeper.co/integrations/${path}.html`, '_blank');
  };

  const Icon = renderLogo(integration.provider);

  return (
    <div className="active-integrations__item">
      <IntegrationsTag
        isManual={isManual}
        isCustom={isCustom}
        text={integration.depositMethod}
        color={isCustom || isManual ? '#DCEAFF' : '#C8F7FF'}
      />

      <Dropdown
        menu={{
          items: [
            {
              label: (
                <div onClick={onMoreInfoClick}>
                  <Text>More info</Text>
                </div>
              ),
              key: 'more',
            },
          ],
        }}
        trigger={['click']}
        placement="bottomRight"
      >
        <MoreOutlined className="active-integrations__more" rotate={90} />
      </Dropdown>

      <div className="d-flex align-items-center flex-column mb-3">
        <Icon className="active-integrations__item-logo" />
        <div className="active-integrations__item-name">
          {PROVIDER_KEY_TO_NAME[integration.provider] || capitalize(integration.provider)}
        </div>
        <div className={styles['asset-supported']}>
          Asset supported:
          <div className={styles['asset-name']}>{integration.assetSupported}</div>
        </div>
      </div>

      <Button
        className="active-integrations__item-inactive"
        type="primary"
        data-testid={`${integration.provider}-btn`}
        onClick={handleOnClick}
        loading={loading}
      >
        <PlusOutlined className="mr-2" />
        Activate
      </Button>
    </div>
  );
};

export default StaticIntegrationCard;
