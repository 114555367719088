import { redirect } from 'react-router-dom';

import Invite from 'src/pages/Invite/Invite';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';
import InviteLayout from 'src/pages/Invite/InviteLayout';
import { CompanyDocument, ICompanyQuery } from 'src/graphql/schema';
import { apolloClient } from 'src/graphql';

import type { RouteObject } from 'react-router-dom';

export const invitationsRoutes: RouteObject = {
  element: (
    <ProtectedRoute>
      <InviteLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      async loader() {
        const { data } = await apolloClient.query<ICompanyQuery>({
          query: CompanyDocument,
          fetchPolicy: 'network-only',
        });

        const requiredFields = [
          data.company.companyName,
          data.company.street,
          data.company.city,
          data.company.postalCode,
          data.company.country,
        ];

        if (requiredFields.some((field) => !field)) return null;

        return redirect('/');
      },
      path: 'invite',
      element: <Invite />,
    },
  ],
};
