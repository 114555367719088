import { EscrowStatusIndicator, EscrowStatusIndicatorEnum } from 'src/components/Escrow/StatusIndicator';
import { useEscrow } from 'src/context/escrow';
import { EscrowStatusEnum } from 'src/graphql/schema';

const EscrowStatusIndicatorContainer = () => {
  const { escrow } = useEscrow();

  let currentStatus = EscrowStatusIndicatorEnum.CONFIG;

  if (escrow.status === EscrowStatusEnum.Onboarding) currentStatus = EscrowStatusIndicatorEnum.REVIEW_SIGNING;

  if (escrow.status === EscrowStatusEnum.Signed) currentStatus = EscrowStatusIndicatorEnum.DEPOSIT_ASSETS;

  if (escrow.status === EscrowStatusEnum.Active) currentStatus = EscrowStatusIndicatorEnum.ACTIVE;

  const isTerminationRequested =
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow.status) &&
    escrow.escrowTermination;

  if (escrow.status === EscrowStatusEnum.PendingTermination)
    currentStatus = EscrowStatusIndicatorEnum.CANCELLATION_INITIATED;

  if (isTerminationRequested) currentStatus = EscrowStatusIndicatorEnum.TERMINATED_REQUESTED;

  if (escrow.status === EscrowStatusEnum.Terminated) currentStatus = EscrowStatusIndicatorEnum.CANCELED;

  return <EscrowStatusIndicator currentStatus={currentStatus} />;
};

export default EscrowStatusIndicatorContainer;
