import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { Form, Input } from 'antd';

import { TextBlock } from 'src/components/Misc';
import { CustomCheckboxWithIconGroup } from 'src/components/Form';

import { escrowDepositTypeOptions } from '../escrowAgreement.enum';
import sharedStyles from '../../Escrow.module.scss';

import styles from './EscrowAgreementEdit.module.scss';

type Props = {
  onChange: (values: CheckboxValueType[]) => void;
  onOtherChange: (value: string) => void;
  selectedValues?: string[];
  depositTypeOther?: string;
};

const DepositTypeSelect: React.FunctionComponent<Props> = ({
  onChange,
  onOtherChange,
  selectedValues = [],
  depositTypeOther,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOtherTypeChange = useCallback(
    debounce((value: { depositTypeOther: string }) => onOtherChange(value.depositTypeOther), 500),
    [],
  );

  return (
    <div className={`${sharedStyles['bb--dashed']} ${sharedStyles['mb--40']}`}>
      <TextBlock
        className={sharedStyles['mb--20']}
        title="Deposit Type"
        titleColor="dark-blue"
        as="h4"
        text="Which materials will be deposited into escrow? Please select all that apply."
        segment={!selectedValues.length && <i className={sharedStyles.segment}>Required</i>}
      />

      <CustomCheckboxWithIconGroup
        selectedValues={selectedValues || []}
        className={`${sharedStyles['container--990']} ${sharedStyles['mb--40']}`}
        classes={{
          row: sharedStyles['card-list__row'],
        }}
        col={5}
        options={escrowDepositTypeOptions}
        onChange={onChange}
      />

      {selectedValues?.includes('other') && (
        <div className={sharedStyles['mb--40']}>
          <Form
            className="ck-agreement__form-other"
            onValuesChange={handleOtherTypeChange}
            initialValues={{
              depositTypeOther,
            }}
            layout="vertical"
          >
            <Form.Item
              className={`ck-agreement__form-item ${styles['form-item--420']}`}
              name="depositTypeOther"
              label="Please describe your other deposit type"
              rules={[
                {
                  required: true,
                  message: 'Please enter the other deposit type.',
                },
              ]}
            >
              <Input placeholder="Your other deposit type" />
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default DepositTypeSelect;
