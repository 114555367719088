import fetchTokenStrategy from '../fetch-token-strategy';

export const getFile = async (url: string) => {
  const token = await fetchTokenStrategy();
  const headers = {
    Authorization: `Bearer ${token}`,
  } as Record<string, string>;
  const impersonatedUserID = localStorage.getItem('impersonatedUserID');

  if (impersonatedUserID) {
    headers['X-Impersonated-User-ID'] = impersonatedUserID;
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers(headers),
  });

  return response;
};
