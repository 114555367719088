import { EscrowStatusEnum } from 'src/graphql/schema';

export enum OtherEscrowStatusEnum {
  TerminationRequested = 'terminationRequested',
  TerminationRequestedSuggester = 'terminationRequestedSuggester',
  TerminationRequestedReceiver = 'terminationRequestedReceiver',
  SignedNoDepositsDepositor = 'signedNoDepositsDepositor',
  SignedNoDepositsBeneficiary = 'signedNoDepositsBeneficiary',
  SignedWithDeposits = 'signedWithDeposits',
  RequestingChanges = 'requestingChanges',
  ChangesRequested = 'changesRequested',
  ChangesRequestedSuggester = 'changesRequestedSuggester',
  ChangesRequestedReceiver = 'changesRequestedReceiver',
  AwaitingSignature = 'awaitingSignature',
  ActivationProcess = 'activationProcess',
}

export const labelStatusTypeEnum = {
  [EscrowStatusEnum.Active]: {
    text: 'Active',
    bgColor: 'green',
  },
  [EscrowStatusEnum.Draft]: {
    text: 'Draft',
    bgColor: 'orange',
  },
  [EscrowStatusEnum.Signed]: {
    text: 'Signed',
    bgColor: 'light-blue',
  },
  [EscrowStatusEnum.AdminReview]: {
    text: 'Codekeeper review',
    bgColor: 'gray',
  },
  [EscrowStatusEnum.Onboarding]: {
    text: 'Review',
    bgColor: 'purple',
  },
  [EscrowStatusEnum.PendingTermination]: {
    text: 'Pending Termination',
    bgColor: 'red',
  },
  [EscrowStatusEnum.Terminated]: {
    text: 'Terminated',
    bgColor: 'red',
  },
  [OtherEscrowStatusEnum.TerminationRequested]: {
    text: 'Termination Requested',
    bgColor: 'red',
  },
  [OtherEscrowStatusEnum.RequestingChanges]: {
    text: 'Requesting changes',
    bgColor: 'orange',
  },
  [OtherEscrowStatusEnum.ChangesRequested]: {
    text: 'Changes requested',
    bgColor: 'orange',
  },
  [OtherEscrowStatusEnum.AwaitingSignature]: {
    text: 'Awaiting signature',
    bgColor: 'gray',
  },
  [OtherEscrowStatusEnum.ActivationProcess]: {
    text: 'Activation process',
    bgColor: 'light-blue',
  },
} as const;

export const alertMessagesTypeEnum = {
  [EscrowStatusEnum.Active]: {
    iconType: 'success',
    bgColor: 'green',
    text: 'This escrow is active.',
  },
  [EscrowStatusEnum.Draft]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Please complete all the required selections to generate the escrow agreement.',
  },
  [EscrowStatusEnum.AdminReview]: {
    iconType: undefined,
    bgColor: 'gray',
    text: "The Codekeeper team is reviewing your configurations and will generate the agreement. You'll be notified when it's ready for your review and approval. You can only view the submitted information.",
  },
  [EscrowStatusEnum.Onboarding]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Please review the entire escrow configuration and the generated agreement before approving the agreement.',
  },
  [OtherEscrowStatusEnum.SignedNoDepositsDepositor]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Deposit your assets to activate this escrow.',
  },
  [OtherEscrowStatusEnum.SignedNoDepositsBeneficiary]: {
    iconType: undefined,
    bgColor: 'gray',
    text: 'The depositor is currently configuring the deposit. We will notify you once they activate the escrow.',
  },
  [OtherEscrowStatusEnum.SignedWithDeposits]: {
    iconType: 'success',
    bgColor: 'green',
    text: 'This escrow can now be activated.',
  },
  [OtherEscrowStatusEnum.RequestingChanges]: {
    iconType: undefined,
    bgColor: 'orange',
    text: 'You are in edit mode for this escrow configuration. Use the ‘Send Requests’ button when you are finished editing the escrow.',
  },
  [OtherEscrowStatusEnum.ChangesRequestedSuggester]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'Your change requests are in review.',
  },
  [OtherEscrowStatusEnum.ChangesRequestedReceiver]: {
    iconType: 'warn',
    bgColor: 'yellow',
    text: 'The counterparty has requested some changes to this escrow. Please review the highlighted sections and accept or reject these requests.',
  },
  [EscrowStatusEnum.PendingTermination]: {
    iconType: undefined,
    bgColor: 'red',
    color: 'red',
    text: 'You are viewing an escrow that is now in its termination notice period. This escrow will be terminated in {date}.',
  },
  [EscrowStatusEnum.Terminated]: {
    iconType: undefined,
    bgColor: 'red',
    color: 'red',
    text: 'You are viewing an escrow that was terminated. The data deposited under this escrow was removed.',
  },
  [OtherEscrowStatusEnum.TerminationRequestedReceiver]: {
    iconType: undefined,
    bgColor: 'red',
    color: 'red',
    text: 'Termination of this escrow has been requested.',
  },
  [OtherEscrowStatusEnum.TerminationRequestedSuggester]: {
    iconType: undefined,
    bgColor: 'red',
    color: 'red',
    text: 'A termination request has been sent to the counterparty for review.',
  },
  [OtherEscrowStatusEnum.AwaitingSignature]: {
    iconType: undefined,
    bgColor: 'gray',
    text: 'An invitation to sign this agreement was sent to all signatories. All edits and configuration settings are disabled.',
  },
  [OtherEscrowStatusEnum.ActivationProcess]: {
    iconType: undefined,
    bgColor: 'light-blue',
    text: "Your escrow activation is in progress. You'll be notified once it’s activated. You can only view the submitted information.",
  },
} as const;
