import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconDeleteFile = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M32.118 8.198 25.92 2H7.648v3.765H3.883V34H26.47v-3.765h5.647zm-5.647-2.985 2.434 2.434H26.47zm-1.882 26.905H5.765V7.648h1.883v22.587h16.94zm-7.53-17.883h1.883v1.883h-1.883zM20.824 18v-3.765h2.54l3.107 2.35V28.29H13.295V14.235h1.882V18zm7.53 10.353V15.649l-4.36-3.296H11.413v16H9.53V3.883h15.059v5.646h5.647v18.824z"
      />
      <path
        fill="currentColor"
        d="m21.398 20-1.536 1.536L18.326 20 17 21.326l1.536 1.536L17 24.398l1.326 1.326 1.536-1.536 1.536 1.536 1.326-1.326-1.536-1.536 1.536-1.536zM11.412 5.765h1.882v1.882h-1.882zM15.178 5.765h1.882v1.882h-1.882zM18.942 5.765h1.882v1.882H18.94z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconDeleteFile);
export default Memo;
