import { Outlet, useParams } from 'react-router-dom';

import { SaasEscrowContextProvider } from 'src/context/saas';
import { withSuspense } from 'src/hoc';
import { SaasLayoutSkeleton } from 'src/components/Saas/Layout';

import SaasModalLayoutContainer from './Layout/SaasModalLayoutContainer';
import SaasNavigationContainer from './Layout/SaasNavigationContainer';

const SaasModalContainer = () => {
  const { id = '' } = useParams();

  return (
    <SaasEscrowContextProvider environmentId={id}>
      <SaasModalLayoutContainer>
        <Outlet />
        <SaasNavigationContainer />
      </SaasModalLayoutContainer>
    </SaasEscrowContextProvider>
  );
};

export default withSuspense(SaasModalContainer, SaasLayoutSkeleton);
