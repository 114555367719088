import { useState } from 'react';

import Invitations from '../../components/Invitations/Invitations';

import CompanyForm from './components/CompanyForm/CompanyForm';
import TeamMemberForm from './components/TeamMemberForm/TeamMemberForm';
import InviteForm from './components/InviteForm/InviteForm';
import RedirectOption from './components/RedirectOption/RedirectOption';
import { StepsEnum } from './InviteSteps.enum';

export default function Invite() {
  const [step, setStep] = useState<StepsEnum>('company');

  const renderInviteComponent = () => {
    switch (step) {
      case 'password':
      case 'account':
        return <InviteForm setStep={(val: StepsEnum) => setStep(val)} step={step} />;
      case 'company':
        return <CompanyForm setStep={(val: StepsEnum) => setStep(val)} />;
      case 'invite':
        return <RedirectOption setStep={(val: StepsEnum) => setStep(val)} />;
      case 'invite-form':
        return <TeamMemberForm setStep={(val: StepsEnum) => setStep(val)} />;
      case 'invitations':
        return <Invitations />;
      default:
        return <></>;
    }
  };

  return <div className={step !== 'invitations' ? 'invite-screen' : ''}>{renderInviteComponent()}</div>;
}
