import { Form, Input, Button, Spin } from 'antd';

import { PASSWORD_PATTERN } from 'src/utils/validation/validation-patterns';

export type ISetPasswordForm = {
  password: string;
};

type Props = {
  onFinish: (data: ISetPasswordForm) => Promise<void>;
  isLoading?: boolean;
};

const SetPasswordForm: React.FunctionComponent<Props> = ({ onFinish, isLoading = false }) => {
  const [form] = Form.useForm();

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <span className="invite-login-screen__info-text">
        Your password must be at least 8 characters, and include at least one lowercase letter, one uppercase letter and
        a special character.
      </span>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please input your password' },
          {
            pattern: PASSWORD_PATTERN,
            message: 'New password does not meet password policy!',
          },
        ]}
      >
        <Input type="password" disabled={isLoading} />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirm password"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                // eslint-disable-next-line no-undef
                return Promise.resolve();
              }
              // eslint-disable-next-line no-undef
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input type="password" disabled={isLoading} />
      </Form.Item>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <Form.Item className="d-flex justify-content-center">
          <Button type="primary" className="invite-login-screen__btn" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default SetPasswordForm;
