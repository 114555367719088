import { useState, useCallback } from 'react';

import { IStaticIntegration, staticIntegrations } from 'src/shared/entities';
import { renderProviderInfoText } from 'src/utils/renderProviderInfoText';

import OauthModal from './OauthModal';
import CustomModal from './CustomModal';
import CredentialsModal from './CredentialsModal';
import ShareFileModal from './ShareFileModal';
import StaticIntegrationCard from './StaticIntegrationCard';

export default function StaticIntegrations() {
  const [integration, setIntegration] = useState<IStaticIntegration>();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isCredentialsModalVisible, setCredentialsModalVisible] = useState(false);
  const [isShareFileModalVisible, setShareFileModalVisible] = useState(false);
  const [isCustomModalVisible, setCustomModalVisibility] = useState(false);

  const toggleCredentialsModal = () => {
    setCredentialsModalVisible((value) => !value);
  };

  const toggleModal = useCallback(() => {
    setModalVisibility(!isModalVisible);
  }, [isModalVisible]);

  const toggleShareFileModal = () => {
    setShareFileModalVisible(!isShareFileModalVisible);
  };

  const toggleCustomModalVisibility = () => {
    setCustomModalVisibility(!isCustomModalVisible);
  };

  const handleIntegrationModal = (item: IStaticIntegration) => {
    setIntegration(item);
    if (item.nonAuth) {
      setCustomModalVisibility(!isCustomModalVisible);
    } else {
      setModalVisibility(!isModalVisible);
    }
  };

  return (
    <div className="d-flex active-integrations__content">
      {staticIntegrations.map((integration: IStaticIntegration) => (
        <StaticIntegrationCard
          key={integration.provider}
          integration={integration}
          handleOnClick={() => handleIntegrationModal(integration)}
        />
      ))}

      {integration && (
        <>
          <OauthModal toggleVisibility={toggleModal} visible={isModalVisible} integration={integration} />

          {/* Used for Amazon s3, for example */}
          {isCustomModalVisible && (
            <CustomModal
              toggleModal={toggleCustomModalVisibility}
              isVisible={isCustomModalVisible}
              integration={integration}
              toggleCredentialsModal={toggleCredentialsModal}
              toggleShareFileModal={toggleShareFileModal}
              modalInfoText={renderProviderInfoText(integration.provider)}
            />
          )}
          {isCredentialsModalVisible && (
            <CredentialsModal
              isVisible={isCredentialsModalVisible}
              toggleModal={toggleCredentialsModal}
              integration={integration}
            />
          )}
          <ShareFileModal
            isVisible={isShareFileModalVisible}
            toggleModal={toggleShareFileModal}
            name={integration.name}
          />
        </>
      )}
    </div>
  );
}
