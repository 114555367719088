import { makeVar, ReactiveVar } from '@apollo/client';

import { EscrowPartyTypeEnum, Scalars, SuggestedChangeStatusEnum } from 'src/graphql/schema';

export type ISuggestChange = {
  id: string;
  // TODO: Add a type for payload, currently it's any from the schema
  payload: Scalars['JSON']['output'] | null;
  status: SuggestedChangeStatusEnum;
  creator: EscrowPartyTypeEnum;
  receiver: EscrowPartyTypeEnum;
};

type IClientChangesRequested = {
  isRequested: boolean;
  parties: Partial<ISuggestChange> | null;
  deposits: Partial<ISuggestChange> | null;
  notifications: Partial<ISuggestChange> | null;
  agreement: Partial<ISuggestChange> | null;
};

const initialSuggestChanges: IClientChangesRequested = {
  isRequested: false,
  parties: null,
  deposits: null,
  notifications: null,
  agreement: null,
};

export const suggestChangesVar = makeVar(initialSuggestChanges);

// Insert data into cache
export const writeInitialSuggestChangesData =
  (suggestChangesVar: ReactiveVar<IClientChangesRequested>) => (data: IClientChangesRequested) => {
    const isRequested = Object.keys(data).some(
      (key) => key !== 'isRequested' && data[key as keyof IClientChangesRequested] !== null,
    );
    suggestChangesVar({
      ...suggestChangesVar(),
      ...data,
      isRequested,
    });
  };

export const writeNewSuggestChanges =
  (suggestChangesVar: ReactiveVar<IClientChangesRequested>) =>
  (key: keyof Omit<IClientChangesRequested, 'isRequested'>, data: Partial<ISuggestChange>) => {
    suggestChangesVar({
      ...suggestChangesVar(),
      [key]: {
        ...suggestChangesVar()[key],
        ...data,
      },
      isRequested: true,
    });
  };

export const writeAcceptSuggestChanges =
  (suggestChangesVar: ReactiveVar<IClientChangesRequested>) =>
  (key: keyof Omit<IClientChangesRequested, 'isRequested'>) => {
    suggestChangesVar({
      ...suggestChangesVar(),
      [key]: {
        ...suggestChangesVar()[key],
        partyStatus: 'accepted',
      },
      isRequested: true,
    });
  };

export const writeRejectSuggestChanges =
  (suggestChangesVar: ReactiveVar<IClientChangesRequested>) =>
  (activeKey: keyof Omit<IClientChangesRequested, 'isRequested'>) => {
    const isRequested = Object.keys(suggestChangesVar()).some(
      (key: string) =>
        key !== 'isRequested' &&
        key !== activeKey &&
        suggestChangesVar()[key as keyof IClientChangesRequested] !== null,
    );

    suggestChangesVar({
      ...suggestChangesVar(),
      [activeKey]: null,
      isRequested,
    });
  };

export const writeResetSuggestChanges = (suggestChangesVar: ReactiveVar<IClientChangesRequested>) =>
  suggestChangesVar(initialSuggestChanges);
