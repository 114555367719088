import classNames from 'classnames';

import styles from './BackupResult.module.scss';

const BackupResultItemCard = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => {
  const itemClasses = classNames(styles.item, className);

  return <div className={itemClasses}>{children}</div>;
};

export default BackupResultItemCard;
