import { Skeleton, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { LabelText } from 'src/components/Label';
import { bgColors } from 'src/components/Label/Text/LabelText';
import { Breadcrumb, IBreadcrumbItem } from 'src/components/Misc';

import styles from './JourneyHeader.module.scss';

export type Props = {
  statusLabel?: { text: string; bgColor: (typeof bgColors)[number] };
  breadcrumbItems: IBreadcrumbItem[];
  onBack: () => void;
};

const JourneyHeaderSkeleton = () => (
  <div className={styles.header} aria-label="Loading...">
    <Skeleton.Button active size="small" block />
  </div>
);

const JourneyHeader = ({ statusLabel, breadcrumbItems, onBack }: Props) => {
  return (
    <div className={`d-flex align-items-center justify-content-between ${styles.header}`}>
      <Breadcrumb items={breadcrumbItems} className="ck-journey__breadcrumb" />

      <div className={`d-flex align-items-center ${styles['header-right']}`}>
        {statusLabel && <LabelText text={statusLabel.text} bgColor={statusLabel.bgColor} />}

        <Button type="text" className={styles['btn-close']} onClick={onBack} aria-label="back">
          <CloseOutlined className={styles['icon-close']} />
        </Button>
      </div>
    </div>
  );
};

JourneyHeader.Skeleton = JourneyHeaderSkeleton;

export default JourneyHeader;
