import classNames from 'classnames';

import { IconDiscount } from 'src/components/Svg/Icons';

import styles from './IconPlanOffer.module.scss';

type Props = {
  size?: 'medium' | 'large';
  className?: string;
};

const IconPlanOffer = ({ size = 'medium', className }: Props) => {
  const iconClasses = classNames(styles.image, styles[size], className);

  return (
    <div className={iconClasses}>
      <IconDiscount className={styles.icon} />
    </div>
  );
};

export default IconPlanOffer;
