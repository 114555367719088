import { useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { IUserInput } from 'src/graphql/schema';

const { Option } = Select;

export type IAddMemberForm = {
  name: string;
  email: string;
  phone?: string;
  role: string;
};

type Props = {
  form: FormInstance;
  onFinish: (data: Partial<IUserInput>) => Promise<void>;
  onCancel: () => void;
  isEdit: boolean;
  btnSubmitText: string;
};

const AddMemberForm: React.FunctionComponent<Props> = ({
  form,
  onFinish,
  onCancel,
  isEdit = false,
  btnSubmitText = 'Save',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = async (data: Partial<IUserInput>) => {
    setIsLoading(true);

    try {
      await onFinish(data);
      form.resetFields();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form layout="vertical" name="basic" onFinish={handleFinish} form={form}>
      <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please add the member's name!" }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please add the member's email!" }]}>
        <Input disabled={isEdit} />
      </Form.Item>
      <Form.Item label="Phone" name="phone">
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        className="w-100"
        rules={[{ required: true, message: 'Please choose a role!' }]}
      >
        <Select className="w-100" placeholder="Role">
          <Option value="administrator">Administrator</Option>
          <Option value="legal">Legal</Option>
          <Option value="technical">Technical</Option>
        </Select>
      </Form.Item>

      <div className="d-flex justify-content-end">
        <Button onClick={onCancel} className="mr-2 ant-btn-default--primary">
          Cancel
        </Button>
        <Form.Item colon={false} className="mb-0">
          <Button type="primary" className="w-100 m-0" htmlType="submit" loading={isLoading}>
            {btnSubmitText}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddMemberForm;
