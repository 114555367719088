import { Fragment, useState } from 'react';
import { Button } from 'antd';

import { ContactDetailBlock, AddContactForm, IContactDetail, IAddContactForm, IAddExistingContactForm } from '..';

import styles from './EscrowContactsEditable.module.scss';

type Props = (
  | {
      withExistingSelect: true;
      onSubmitExistingContact: (data: IAddExistingContactForm) => void;
      existingUserOptions: { label: string; value: string }[];
    }
  | {
      withExistingSelect?: false;
    }
) & {
  type: 'beneficiary' | 'depositor';
  data: IContactDetail;
  onSubmitContact: (data: IAddContactForm) => void;
  onRemoveContact?: () => void;
  withRemoveAction?: boolean;
  isDynamic?: boolean;
};

const EditableContactDetail: React.FunctionComponent<Props> = (props) => {
  const { type, data, onSubmitContact, onRemoveContact, withExistingSelect, withRemoveAction, isDynamic } = props;
  const [isEdit, setIsEdit] = useState(false);

  const toggleEdit = () => setIsEdit((prevIsEdit) => !prevIsEdit);
  const handleRemove = async () => {
    if (!onRemoveContact) return;
    await onRemoveContact();
    setIsEdit(false);
  };

  return (
    <div className={styles['contact-item']}>
      {isEdit ? (
        <AddContactForm
          type={type}
          onCancel={toggleEdit}
          onSubmitContact={onSubmitContact}
          onRemoveContact={handleRemove}
          withRemoveAction={withRemoveAction}
          initialValues={data}
          isDynamic={isDynamic}
          {...(withExistingSelect
            ? {
                withExistingSelect: true,
                onSubmitExistingContact: props.onSubmitExistingContact,
                existingContacts: props.existingUserOptions,
              }
            : { withExistingSelect: false })}
        />
      ) : (
        <Fragment>
          <ContactDetailBlock className={styles['mb-20']} type={type} {...data} />

          <div className={styles['edit-row']}>
            <Button ghost className={styles['edit-btn']} onClick={toggleEdit}>
              Edit details
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default EditableContactDetail;
