import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSaasAgreement = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 45"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M34.941 26.706A7.067 7.067 0 0 0 42 19.647c0-3.527-2.6-6.458-5.985-6.977-.537-2.842-3.12-4.952-6.12-4.778A8.239 8.239 0 0 0 22 2a8.239 8.239 0 0 0-7.896 5.892c-3.004-.174-5.583 1.94-6.12 4.778C4.6 13.189 2 16.12 2 19.647a7.067 7.067 0 0 0 7.059 7.059h11.765v2.353H9.059v4.706h-3.53V42h9.412v-8.235h-3.53v-2.353h9.412v2.353h-3.53V42h9.413v-8.235h-3.53v-2.353h9.412v2.353h-3.53V42h9.412v-8.235h-3.53v-4.706H23.177v-2.353H34.94zM12.588 39.647H7.882v-3.53h4.706v3.53zm11.765 0h-4.706v-3.53h4.706v3.53zm11.765 0h-4.706v-3.53h4.706v3.53zm-31.765-20a4.711 4.711 0 0 1 4.706-4.706h1.176v-1.176c0-2.315 2.21-4.023 4.467-3.403l1.287.353.189-1.321c.41-2.874 2.914-5.041 5.822-5.041s5.412 2.167 5.822 5.041l.19 1.321 1.287-.353c2.258-.62 4.466 1.09 4.466 3.403v1.176h1.176a4.711 4.711 0 0 1 4.706 4.706 4.711 4.711 0 0 1-4.706 4.706H9.06a4.711 4.711 0 0 1-4.706-4.706z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSaasAgreement);
export default Memo;
