import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageUploadAsset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 69 72"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#D6EAED"
      d="M23.125 6A4.137 4.137 0 0 0 19 10.125v57.75A4.137 4.137 0 0 0 23.125 72h41.25a4.137 4.137 0 0 0 4.125-4.125V22.5L52 6H23.125Z"
    />
    <path fill="#D6EAED" d="M56.125 22.5H68.5L52 6v12.375a4.137 4.137 0 0 0 4.125 4.125Z" />
    <path
      fill="#fff"
      stroke="#009CB7"
      d="M13.5 4.125A3.637 3.637 0 0 1 17.125.5h28.668L62 16.707v45.168a3.637 3.637 0 0 1-3.625 3.625h-41.25a3.637 3.637 0 0 1-3.625-3.625V4.125Z"
    />
    <path fill="#009CB7" d="M50.125 16.5H62.5L46 0v12.375a4.137 4.137 0 0 0 4.125 4.125Z" />
    <circle cx={12.5} cy={34.5} r={12.5} fill="#009CB7" />
    <path
      fill="#fff"
      d="M13.136 29.25a.89.89 0 0 0-.066-.056l-.032-.023-.038-.027-.04-.022-.036-.02c-.013-.008-.027-.014-.04-.02l-.04-.017-.04-.014-.043-.014-.04-.01c-.015-.004-.03-.008-.045-.01l-.048-.007c-.013-.002-.026-.005-.04-.006a.942.942 0 0 0-.177 0l-.04.006-.047.006-.044.01-.041.01-.043.015-.04.014-.04.017c-.013.006-.027.012-.04.02-.013.006-.024.013-.036.02l-.04.022-.038.027-.032.023a.864.864 0 0 0-.065.056h-.001l-3.6 3.43a.829.829 0 0 0 0 1.212.932.932 0 0 0 1.272 0l2.064-1.966v8.217c0 .473.403.857.9.857s.9-.384.9-.857v-8.217l2.064 1.966a.932.932 0 0 0 1.272 0 .829.829 0 0 0 0-1.213l-3.6-3.428Z"
    />
    <path fill="#D6EAED" d="M31 25h23v2H31zM31 37h23v2H31zM31 31h23v2H31zM31 43h23v2H31z" />
  </svg>
);
const Memo = memo(SvgImageUploadAsset);
export default Memo;
