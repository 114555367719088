import { MinusCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

type Props = {
  item: {
    name: string;
    email: string;
  };
  index: number;
  removeUser: (email: string) => void;
};

export default function TeamMember({ item, index, removeUser }: Props) {
  return (
    <>
      <div className="invite-screen__card">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="invite-screen__card-title">User {index + 1}</p>

          <MinusCircleOutlined className="invite-screen__card-icon" onClick={() => removeUser(item.email)} />
        </div>

        <p className="invite-screen__card-info">
          Name: <span className="invite-screen__card-value">{item.name}</span>
        </p>
        <p className="invite-screen__card-info">
          Email: <span className="invite-screen__card-value">{item.email}</span>
        </p>
      </div>
      <Divider />
    </>
  );
}
