import { useState } from 'react';
import { Button, Form, Select, Col } from 'antd';

import { confirmMessageOptions } from './escrow-contacts.enum';
import styles from './EscrowContactsForm.module.scss';

export interface IConfirmationMessageForm {
  message: boolean;
}

type Props = {
  onSubmit: (data: IConfirmationMessageForm) => Promise<void>;
  onCancel: () => void;
  label: string;
  value?: boolean;
};

const ConfirmationMessageForm: React.FunctionComponent<Props> = ({ onSubmit, onCancel, label, value }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: IConfirmationMessageForm) => {
    setIsLoading(true);

    try {
      await onSubmit(data);
      onCancel();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Confirmation Message</h3>

      <Form
        layout="vertical"
        className="ck-escrow-contacts__form"
        onFinish={handleSubmit}
        initialValues={{
          message: value,
        }}
      >
        <Col span={12}>
          <Form.Item label={label} name="message" rules={[{ required: true }]}>
            <Select placeholder="Select an option" options={confirmMessageOptions} />
          </Form.Item>
        </Col>

        <div className={styles['action-list']}>
          <div className={styles['action-list-right']}>
            <div className={styles['action-item']}>
              <Button type="default" onClick={onCancel}>
                Discard
              </Button>
            </div>
            <div className={styles['action-item']}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save details
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ConfirmationMessageForm;
