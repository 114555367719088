import { PROVIDER_KEY_TO_NAME } from 'src/shared/constants';

import { AmazonInfoContent } from './AmazonInfoContent';
import { CodecommitInfoContent } from './CodecommitInfoContent';
import { GitlabInfoContent } from './GitlabInfoContent';
import { ShareFileInfoContent } from './ShareFileInfoContent';
import { GithubInfoContent } from './GithubInfoContent';
import { BitbucketServerInfoContent } from './BitbucketServerInfoContent';
import BeanstalkInfoContent from './BeanstalkInfoContent';

export default function Steps({ provider }: { provider: keyof typeof PROVIDER_KEY_TO_NAME }) {
  const shouldRenderContent = () => {
    switch (provider) {
      case 'gitlab_server':
        return <GitlabInfoContent />;
      case 'github_server':
        return <GithubInfoContent />;
      case 'codecommit':
        return <CodecommitInfoContent />;
      case 'aws':
        return <AmazonInfoContent />;
      case 'share_file_https':
        return <ShareFileInfoContent />;
      case 'bitbucket_server':
        return <BitbucketServerInfoContent />;
      case 'beanstalk':
        return <BeanstalkInfoContent />;
      default:
        return <></>;
    }
  };

  return shouldRenderContent();
}
