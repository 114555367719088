import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconCustomerOperation = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m26.733 13.216-4.195 4.424L20 15.51l1.136-1.354 1.264 1.062L25.451 12zM5 6h6.476l2.108 2.177-1.268 1.229-1.588-1.64H6.767v1.237H5zM6 25h24v1.7H6z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 13.85c-1.405 0-2.483 1.085-2.483 2.35s1.079 2.35 2.483 2.35 2.483-1.085 2.483-2.35-1.078-2.35-2.483-2.35M9.817 16.2c0-2.27 1.906-4.05 4.183-4.05s4.183 1.78 4.183 4.05-1.906 4.05-4.183 4.05-4.183-1.78-4.183-4.05m.19 5.821c1.06-.679 2.475-1.071 3.993-1.071s2.933.392 3.994 1.071c1.051.673 1.856 1.706 1.856 2.979v.85h-1.7V25c0-.495-.315-1.062-1.073-1.547-.748-.479-1.834-.803-3.077-.803s-2.329.324-3.077.803c-.758.485-1.073 1.052-1.073 1.547v.85h-1.7V25c0-1.273.805-2.306 1.856-2.979"
        clipRule="evenodd"
      />
      <path fill="currentColor" fillRule="evenodd" d="M29.3 9.7H6.7v18.6h22.6zM5 8v22h26V8z" clipRule="evenodd" />
    </svg>
  );
};
const Memo = memo(SvgIconCustomerOperation);
export default Memo;
