import { Fragment } from 'react/jsx-runtime';

import { JourneyModalHeader, JourneyStatusIndicator, JourneyMenu, JourneyFooter } from 'src/components/Journey';
import { Loader } from 'src/components/Loader';

import styles from './EscrowLayoutSkeleton.module.scss';

const EscrowLayoutSkeleton = () => {
  return (
    <Fragment>
      <JourneyModalHeader.Skeleton />
      <div className={`d-flex ${styles.main}`}>
        <JourneyStatusIndicator.Skeleton items={4} />
        <JourneyMenu.Skeleton items={7} />
        <div className={`d-flex ${styles.content}`}>
          <Loader className={styles.spin} />
          <JourneyFooter.Skeleton />
        </div>
      </div>
    </Fragment>
  );
};

export default EscrowLayoutSkeleton;
