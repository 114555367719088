import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IEscrowInvitation, useEscrowInvitationsQuery } from 'src/graphql/schema/index.ts';

import Invitation from './components/Invitation';

export default function Invitations() {
  const navigate = useNavigate();
  const [successInvitation, setSuccessInvitation] = useState<IEscrowInvitation | null>(null);
  const [invitations, setInvitations] = useState<IEscrowInvitation[]>([] as IEscrowInvitation[]);
  const { data, loading } = useEscrowInvitationsQuery({
    variables: {
      page: 1,
      perPage: 50,
    },
    onCompleted: (data) => {
      if (data?.escrowInvitations?.nodes.length === 0) {
        navigate('/');
      }
    },
  });

  useEffect(() => {
    if (!loading) {
      if (data?.escrowInvitations) {
        setInvitations(data.escrowInvitations.nodes as IEscrowInvitation[]);
      }
    }

    if (successInvitation) {
      const newInvitations = [...invitations];
      newInvitations.find((item) => {
        if (item.id === successInvitation.id) {
          const newItem = { ...item };
          newItem.status = successInvitation.status;

          return newItem;
        }
      });
      setInvitations(newInvitations);
    }
  }, [data?.escrowInvitations, loading, successInvitation]);

  return (
    <div className="invitations-screen">
      <p className="invitations-screen__title">Escrow Invitations</p>

      {invitations.map((item) => (
        <Invitation key={item.id} item={item} passSuccessInvitation={(item) => setSuccessInvitation(item)} />
      ))}

      <p className="invitations-screen__link" onClick={() => navigate('/')}>
        Skip invitations
      </p>
    </div>
  );
}
