import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconAppLock = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.343 3.858h6.685c.616 0 1.115.499 1.115 1.114v6.686a1.114 1.114 0 0 1-2.229 0V6.087h-5.571a1.114 1.114 0 1 1 0-2.23m5.571 14.487a1.114 1.114 0 0 1 2.229 0v6.685c0 .616-.5 1.115-1.115 1.115h-6.685a1.114 1.114 0 0 1 0-2.229h5.571zm-8.913-10.03a3.343 3.343 0 0 0-3.342 3.343v1.114c-1.231 0-2.23.998-2.23 2.229v4.457c0 1.23.999 2.229 2.23 2.229h6.685c1.231 0 2.229-.998 2.229-2.23v-4.456c0-1.231-.998-2.229-2.229-2.229v-1.114a3.343 3.343 0 0 0-3.343-3.343m0 2.229c-.616 0-1.115.498-1.115 1.114v1.114h2.229v-1.114c0-.615-.5-1.114-1.115-1.114m3.341 8.914h-6.685V15h6.685zm-12.256-7.8a1.114 1.114 0 0 1-2.228 0V4.972c0-.615.499-1.114 1.114-1.114h6.686a1.114 1.114 0 1 1 0 2.229H6.086zm5.572 12.258H6.087v-5.571a1.114 1.114 0 0 0-2.229 0v6.685c0 .616.499 1.115 1.114 1.115h6.686a1.114 1.114 0 0 0 0-2.229M15 15.857a.857.857 0 0 0-.857.857v.857a.857.857 0 1 0 1.714 0v-.857a.857.857 0 0 0-.857-.857"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconAppLock);
export default Memo;
