import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconSaas = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currenColor"
        fillRule="evenodd"
        d="M3.346 5.309A5.35 5.35 0 0 1 8 2.582c2.493 0 4.588 1.72 5.188 4.048A3.408 3.408 0 0 1 16 10c0 1.887-1.514 3.416-3.381 3.416H4.04C1.81 13.415 0 11.587 0 9.332c0-2.016 1.445-3.69 3.346-4.023ZM8 4.082a3.874 3.874 0 0 0-3.506 2.245.742.742 0 0 1-.623.427c-1.332.088-2.386 1.21-2.386 2.578 0 1.427 1.144 2.583 2.556 2.583h8.578A1.907 1.907 0 0 0 14.515 10c0-1.059-.849-1.917-1.896-1.917h-.034a.745.745 0 0 1-.748-.643C11.569 5.54 9.952 4.082 8 4.082Zm-.742 3.985v2.598c0 .415.332.75.742.75s.743-.335.743-.75V8.067l.825.556a.738.738 0 0 0 1.03-.208.755.755 0 0 0-.207-1.04L8.42 6.045a.733.733 0 0 0-.84.003l-1.97 1.327a.755.755 0 0 0-.206 1.04.738.738 0 0 0 1.03.208l.825-.556Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconSaas);
export default Memo;
