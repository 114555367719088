import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoGitlab = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 236"
    role="img"
    className={props.className}
    {...props}
  >
    <path fill="#e24329" d="m128.075 236.075 47.104-144.97H80.97z" />
    <path fill="#fc6d26" d="M128.075 236.074 80.97 91.104H14.956z" />
    <path fill="#fca326" d="M14.956 91.104.642 135.16a9.75 9.75 0 0 0 3.542 10.903l123.891 90.012z" />
    <path fill="#e24329" d="M14.956 91.105H80.97L52.601 3.79c-1.46-4.493-7.816-4.492-9.275 0z" />
    <path fill="#fc6d26" d="m128.075 236.074 47.104-144.97h66.015z" />
    <path fill="#fca326" d="m241.194 91.104 14.314 44.056a9.75 9.75 0 0 1-3.543 10.903l-123.89 90.012z" />
    <path fill="#e24329" d="M241.194 91.105h-66.015l28.37-87.315c1.46-4.493 7.816-4.492 9.275 0z" />
  </svg>
);
const Memo = memo(SvgLogoGitlab);
export default Memo;
