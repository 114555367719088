import { useEscrow } from 'src/context/escrow';

import EditableNotificationsChangesContainer from './EditableNotificationsChangesContainer';
import ViewNotificationsChangesContainer from './ViewNotificationsChangesContainer';

const NotificationsSuggestChanges = () => {
  const { ui } = useEscrow();

  if (ui.isSuggestChangesMode) return <EditableNotificationsChangesContainer />;

  return <ViewNotificationsChangesContainer />;
};

export default NotificationsSuggestChanges;
