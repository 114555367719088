import { cloneDeep } from '@apollo/client/utilities';
import { gql } from '@apollo/client';

import { SaasEnvironmentsDocument } from 'src/graphql/schema';

import { apolloClient as client, apolloCache as cache } from '../apollo-client';

import type {
  ISaasEnvironmentsQuery,
  ISaasEnvironmentPolicy,
  ISaasEnvironmentsQueryVariables,
} from 'src/graphql/schema';

export const updateSaasEscrowsCache = (id: string, variables?: ISaasEnvironmentsQueryVariables) => {
  const cacheData = cloneDeep(
    client.readQuery<ISaasEnvironmentsQuery | null>({ query: SaasEnvironmentsDocument, variables }),
  );

  if (!cacheData) return;

  const node = cacheData.saasEnvironments.nodes.find((node) => node.id === id);

  if (!node) return;

  cache.modify({
    id: cache.identify(node),
    fields(_fieldValue, { DELETE }) {
      return DELETE;
    },
  });
};

const SaasEnvironmentPolicyFragment = gql`
  fragment SaasEnvironmentPolicyFragment on SaasEnvironment {
    policy {
      remove
      requestActivation
      requestTermination
      update
    }
  }
`;

export const updateSaasEnvironmentPolicyCache = (id: string, newPolicy: Partial<ISaasEnvironmentPolicy>) => {
  const currentPolicy = client.readFragment({
    id: `SaasEnvironment:${id}`,
    fragment: SaasEnvironmentPolicyFragment,
  }).policy as ISaasEnvironmentPolicy;

  client.writeFragment({
    id: `SaasEnvironment:${id}`,
    fragment: SaasEnvironmentPolicyFragment!,
    data: {
      policy: {
        ...currentPolicy,
        ...newPolicy,
      },
    },
  });
};
