import { providers } from 'src/shared/entities';

type ProviderInfoTextType = Partial<Record<(typeof providers)[number], string>>;

const providerInfoTexts: ProviderInfoTextType = {
  bitbucket_server:
    'Use an App Password to authorize Codekeeper to sync to your list of Bitbucket Server repositories,\n and take deposits of the ones you want to add into escrow. This will also monitor the connection status.',
  beanstalk:
    'Use a Password to authorize Codekeeper to sync to your list of Beanstalk repositories, and take deposits of the ones you want to add into escrow. This will also monitor the connection status.',
  github_server:
    'Use an Access Token to authorize Codekeeper to sync to your list of GitHub Server repositories, and take deposits of the ones you want to add into escrow. This will also monitor the connection status.',
  share_file_https: 'Use SFTP to transfer your files into escrow.',
  gitlab_server:
    'Use an Access Token to authorize Codekeeper to sync to your list of GitLab Server repositories, and take deposits of the ones you want to add into escrow. This will also monitor the connection status.',
  codecommit:
    'Use a Password to authorize Codekeeper to sync to your list of Amazon CodeCommit repositories, and take deposits of the ones you want to add into escrow. This will also monitor the connection status.',
  aws: 'Use an Access Policy to authorize Codekeeper to sync to your bucket, and take deposits of the contents into escrow. This will also monitor the connection status.',
};

export const renderProviderInfoText = (provider: (typeof providers)[number]) => {
  return providerInfoTexts[provider] || '';
};
