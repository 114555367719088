import { useSuspenseQuery } from '@apollo/client';
import { useState } from 'react';

import { LegacyReposDocument, QueryOrderEnum } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { useLocalStorage } from 'src/hooks';
import { useMigrationsUiSlice } from 'src/slices';
import { LEGACY_REPOS_LIMIT } from 'src/shared/constants';

import { createRequiredContext } from '../createRequiredContext';

import type { ILegacyReposQuery, ILegacyRepo, ILegacyReposQueryVariables } from 'src/graphql/schema';

const [useMigrations, MigrationsProvider] = createRequiredContext<{
  legacy: {
    count: number;
    repos: ILegacyRepo[];
    variables?: ILegacyReposQueryVariables;
  };
  refetch: () => void;
  ui: {
    isInfoModalVisible: boolean;
  } & ReturnType<typeof useMigrationsUiSlice>;
  setIsInfoModalVisible: (isVisible: boolean) => void;
  onLegacyVariablesChange: (variables: Partial<ILegacyReposQueryVariables>) => void;
}>();

const MigrationsContextProvider = withSuspense(({ children }: { children: React.ReactNode }) => {
  const [legacyVariables, setLegacyVariables] = useState<ILegacyReposQueryVariables>({
    perPage: LEGACY_REPOS_LIMIT,
    order: QueryOrderEnum.Desc,
  });
  const { data, refetch } = useSuspenseQuery<ILegacyReposQuery>(LegacyReposDocument, {
    variables: legacyVariables,
  });
  const migrationsUi = useMigrationsUiSlice();
  const [isInfoModalVisible, setIsInfoModalVisible] = useLocalStorage('migrations-info-modal-displayed', true);

  const onLegacyVariablesChange = (variables: Partial<ILegacyReposQueryVariables>) => {
    setLegacyVariables((prev) => ({ ...prev, ...variables }));
  };

  const providerValue = {
    legacy: {
      count: data.legacyRepos.nodesCount,
      repos: data.legacyRepos.nodes as ILegacyRepo[],
      variables: legacyVariables,
    },
    refetch,
    ui: {
      isInfoModalVisible,
      ...migrationsUi,
    },
    setIsInfoModalVisible,
    onLegacyVariablesChange,
  };

  return <MigrationsProvider value={providerValue}>{children}</MigrationsProvider>;
});

export { useMigrations, MigrationsContextProvider };
