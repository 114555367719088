import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ProviderActionEnum {
  VIEW = 'provider_view',
  CREATE = 'provider_create',
  EDIT = 'provider_edit',
  REMOVE = 'provider_remove',
  HIDE = 'provider_hide',
}

enum UiActionEnum {
  OPEN_SETUP_MODAL = 'open_setup_modal',
  CLOSE_SETUP_MODAL = 'close_setup_modal',
}

type IActionReducer =
  | IAction<ProviderActionEnum.VIEW, { id: string }>
  | IAction<ProviderActionEnum.CREATE, { id: string }>
  | IAction<ProviderActionEnum.EDIT, { id: string }>
  | IAction<ProviderActionEnum.REMOVE, { id: string }>
  | IAction<ProviderActionEnum.HIDE>
  | IAction<UiActionEnum.OPEN_SETUP_MODAL>
  | IAction<UiActionEnum.CLOSE_SETUP_MODAL>;

export type IState = {
  ui: {
    provider?: {
      id: string;
      modalType: 'view' | 'create' | 'edit' | 'remove';
    };
    isSetupModalOpen: boolean;
  };
};

const initialState: IState = {
  ui: {
    provider: undefined,
    isSetupModalOpen: false,
  },
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ProviderActionEnum.VIEW:
      return {
        ...state,
        ui: {
          ...state.ui,
          provider: {
            id: action.payload.id,
            modalType: 'view',
          },
        },
      };
    case ProviderActionEnum.CREATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          provider: {
            id: action.payload.id,
            modalType: 'create',
          },
          isSetupModalOpen: false,
        },
      };
    case ProviderActionEnum.EDIT:
      return {
        ...state,
        ui: {
          ...state.ui,
          provider: {
            id: action.payload.id,
            modalType: 'edit',
          },
        },
      };
    case ProviderActionEnum.REMOVE:
      return {
        ...state,
        ui: {
          ...state.ui,
          provider: {
            id: action.payload.id,
            modalType: 'remove',
          },
        },
      };
    case ProviderActionEnum.HIDE:
      return {
        ...state,
        ui: {
          ...state.ui,
          provider: undefined,
        },
      };
    case UiActionEnum.OPEN_SETUP_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSetupModalOpen: true,
        },
      };
    case UiActionEnum.CLOSE_SETUP_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSetupModalOpen: false,
        },
      };
    default:
      return state;
  }
};

export const useSaasEscrowSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const viewProvider = (id: string) => dispatch({ type: ProviderActionEnum.VIEW, payload: { id } });

  const createProvider = (id: string) => dispatch({ type: ProviderActionEnum.CREATE, payload: { id } });

  const editProvider = (id: string) => dispatch({ type: ProviderActionEnum.EDIT, payload: { id } });

  const removeProvider = (id: string) => dispatch({ type: ProviderActionEnum.REMOVE, payload: { id } });

  const hideProvider = () => dispatch({ type: ProviderActionEnum.HIDE, payload: {} });

  const openSetupModal = () => dispatch({ type: UiActionEnum.OPEN_SETUP_MODAL, payload: {} });

  const closeSetupModal = () => dispatch({ type: UiActionEnum.CLOSE_SETUP_MODAL, payload: {} });

  return {
    state,
    viewProvider,
    createProvider,
    editProvider,
    removeProvider,
    hideProvider,
    openSetupModal,
    closeSetupModal,
  };
};
