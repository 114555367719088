import React from 'react';

import { EscrowRolePreview } from 'src/components/Escrow/Role';
import { EscrowPartyTypeEnum, AgreementFormEnum } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowRolePreviewContainer: React.FunctionComponent = () => {
  const { escrow } = useEscrow();

  const role = escrow?.currentCompanyRole as EscrowPartyTypeEnum;
  const agreement = escrow?.agreement?.agreementForm as AgreementFormEnum;

  return (
    <EscrowModalMain>
      <EscrowRolePreview role={role} agreement={agreement} />
    </EscrowModalMain>
  );
};

export default EscrowRolePreviewContainer;
