import { useAgreementSuggestChanges } from 'src/context/suggest-changes';
import { useEscrow } from 'src/context/escrow';
import { TextBlock } from 'src/components/Misc';
import {
  LegalContextSelect,
  DepositTypeSelect,
  ReleaseConditionsSelect,
  EscrowJurisdictionSelect,
  EscrowLiabilitySelect,
} from 'src/components/Escrow/Agreement';
import styles from 'src/components/Escrow/Escrow.module.scss';

// should return true or false if there are changes
const hasChanges = <T extends string | string[]>(newValue: T, oldValue: T) => {
  if (Array.isArray(newValue)) {
    if (newValue.length === oldValue.length) {
      if (newValue.every((value) => oldValue.includes(value))) return false;
      return true;
    }
    return true;
  } else {
    if (newValue === oldValue) return false;
    return true;
  }
};

const EditableAgreementChangesContainer = () => {
  const { escrow } = useEscrow();
  const {
    state,
    updateContext,
    updateOtherContext,
    updateReleaseConditions,
    updateDeposit,
    updateOtherDeposit,
    updateCondition,
    updateJurisdiction,
    updateLiability,
  } = useAgreementSuggestChanges();

  return (
    <div className={styles.block}>
      <TextBlock
        className={styles['mb--30']}
        title="Escrow Agreement Details"
        text="These settings will configure the escrow agreement, which you can review and edit in the next steps. "
      />
      <LegalContextSelect
        selectedValues={state.data.context}
        legalContextOther={state.data.otherContext}
        onOtherChange={(value) =>
          updateOtherContext({ value, hasChanges: hasChanges(value, escrow.agreement?.legalContextOther || '') })
        }
        onChange={(values) =>
          updateContext({
            values,
            hasChanges: hasChanges(values as string[], escrow.agreement?.legalContext || []),
          } as {
            values: string[];
            hasChanges: boolean;
          })
        }
      />
      <DepositTypeSelect
        selectedValues={state.data.deposit}
        depositTypeOther={state.data.otherDeposit}
        onChange={(values) =>
          updateDeposit({ values, hasChanges: hasChanges(values as string[], escrow.agreement?.depositType || []) } as {
            values: string[];
            hasChanges: boolean;
          })
        }
        onOtherChange={(value) =>
          updateOtherDeposit({ value, hasChanges: hasChanges(value, escrow.agreement?.depositTypeOther || '') })
        }
      />
      <ReleaseConditionsSelect
        releaseConditions={state.data.releaseConditions}
        releaseCustomConditions={state.data.condition}
        onChangeConditions={(values) => {
          // @ts-expect-error dynamic key
          const hasChanges = Object.entries(values).some(([key, value]) => escrow.agreement?.[key] !== value);

          updateReleaseConditions({
            data: values,
            hasChanges,
          });
        }}
        onChangeCustomCondition={({ releaseCustomConditions: value }) =>
          updateCondition({
            value,
            hasChanges: hasChanges(value, escrow.agreement?.releaseCustomConditions || ''),
          })
        }
      />
      <EscrowJurisdictionSelect selectedValue={state.data.jurisdiction} onChange={updateJurisdiction} />
      <EscrowLiabilitySelect selectedValue={state.data.liability} onChange={updateLiability} />
    </div>
  );
};

export default EditableAgreementChangesContainer;
