import { IContactDetail } from 'src/components/Escrow/Contacts';

const organizationLabelEnum = {
  company_name: 'Organization name',
  company_registration_number: 'Organization reg. number',
  city: 'Organization city',
  country: 'Organization country',
  region: 'Organization region',
  company_website: 'Organization website',
  postal_code: 'Organization postal code',
  street: 'Organization street',
  street_number: 'Organization street number',
};

const signatoryLabelEnum = {
  signature_email: 'Signature email',
  signature_name: 'Signature name',
};

const getSuggestedChanges = <
  TRole extends string,
  TChanges extends Record<TRole, { [key: string]: string }>,
  TLabels extends Record<string, string>,
>(
  changes: TChanges,
  currentRole: TRole,
  labelEnum: TLabels,
): { [key in TRole]: { label: string; value: string }[] } | undefined => {
  if (!changes) return;

  const dataChanges: Record<string, never> | { [key in TRole]: { label: string; value: string }[] } = Object.entries<
    Record<string, string>
  >(changes).reduce((acc, [role, entities]) => {
    if (!entities) return {};

    const values = Object.keys(entities)
      .filter((entity) => entity in labelEnum)
      .map((entity) => ({
        label: labelEnum[entity],
        value: entities[entity] || 'None',
      }));

    return {
      ...acc,
      [role]: values,
    };
  }, {});

  return dataChanges[currentRole];
};

export const getOrganizationSuggestedChanges = <
  TRole extends string,
  TChanges extends Record<TRole, { [key: string]: string }>,
>(
  changes: TChanges,
  role: TRole,
) => getSuggestedChanges(changes, role, organizationLabelEnum);

export const getSignatorySuggestedChanges = <
  TRole extends string,
  TChanges extends Record<TRole, { [key: string]: string }>,
>(
  changes: TChanges,
  role: TRole,
) => getSuggestedChanges(changes, role, signatoryLabelEnum);

export const getNewSuggestedContacts = <
  TRole extends string,
  TChanges extends Record<
    string,
    {
      new_contacts: Array<{
        user_id: string;
        contact_type: string;
        name: string;
        email: string;
        phone?: string;
        user?: { name: string; email: string; phone?: string };
      }>;
    }
  >,
  TExistingUsers extends IContactDetail[],
>(
  changes: TChanges,
  currentRole: TRole,
  existingUsers: TExistingUsers,
): IContactDetail[] => {
  if (!changes) return [];

  const dataChanges = Object.entries(changes).reduce<Record<string, IContactDetail[]>>((acc, curr) => {
    const [role, entities] = curr;
    if (!entities?.new_contacts?.length) return {} as Record<string, never>;

    const contacts: IContactDetail[] = entities.new_contacts.map(
      ({ user_id, name, email, phone, user, contact_type }, index) => {
        const findExistingUser = existingUsers.find(({ id }) => id === user_id);

        if (findExistingUser) return { ...findExistingUser, role: contact_type };

        return {
          id: String(Date.now() + index),
          name: user?.name || name,
          email: user?.email || email,
          phone: user?.phone || phone,
          role: contact_type,
        };
      },
    );

    return {
      ...acc,
      [role]: contacts,
    };
  }, {});

  return dataChanges?.[currentRole];
};

export const getContactsSuggestedChanges = (
  changes: Record<
    string,
    {
      edited_contacts?: { id: string; contact_type: string }[];
      deleted_contacts?: { id: string }[];
    }
  >,
  currentRole: string,
  id: string,
): { label: string; value: string }[] | undefined => {
  if (!changes?.[currentRole]) return;

  const currentChanges = changes[currentRole];
  const map = new Map<string, [{ label: string; value: string }]>();

  currentChanges.deleted_contacts?.forEach(({ id }) =>
    map.set(id, [{ label: 'Suggested', value: 'to remove the contact' }]),
  );

  currentChanges.edited_contacts?.forEach(({ id, contact_type }) =>
    map.set(id, [{ label: 'Suggested new role', value: contact_type }]),
  );

  return map.has(id) ? map.get(id) : undefined;
};
