import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageConnectPlatform = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 114 82"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#fff"
      d="M96.477 17.386c0-7.447-6.002-13.484-13.406-13.484H36.505C29.101 3.902 23.1 9.94 23.1 17.386v46.838c0 7.447 6.002 13.484 13.405 13.484h46.566c7.404 0 13.406-6.037 13.406-13.484z"
    />
    <path
      fill="#C2D6E0"
      fillRule="evenodd"
      d="M22.6 17.386c0-7.72 6.223-13.984 13.905-13.984h46.566c7.683 0 13.906 6.264 13.906 13.984v46.838c0 7.72-6.223 13.984-13.906 13.984H36.505c-7.682 0-13.905-6.264-13.905-13.984zM36.505 4.402c-7.125 0-12.905 5.81-12.905 12.984v46.838c0 7.174 5.78 12.984 12.905 12.984h46.566c7.125 0 12.906-5.81 12.906-12.984V17.386c0-7.173-5.78-12.984-12.906-12.984z"
      clipRule="evenodd"
    />
    <path
      fill="#45B4C8"
      d="M32 44.523c0-4.404-3.563-7.974-7.958-7.974H7.958C3.563 36.549 0 40.119 0 44.523v16.116c0 4.404 3.563 7.974 7.958 7.974h16.084c4.395 0 7.958-3.57 7.958-7.974z"
    />
    <path
      fill="#417BD2"
      d="M113.283 20.71c0-3.92-3.159-7.097-7.055-7.097h-9.172c-3.897 0-7.056 3.178-7.056 7.097v9.226c0 3.919 3.159 7.096 7.055 7.096h9.173c3.896 0 7.055-3.177 7.055-7.096z"
    />
    <rect width={17.992} height={8.161} fill="#9CD7EB" rx={4.081} transform="matrix(1 0 0 -1 33.68 8.162)" />
    <rect width={22.225} height={8.161} fill="#417BD2" rx={4.081} transform="matrix(1 0 0 -1 59.787 81.613)" />
    <rect width={11.441} height={8.22} fill="#FFC634" rx={4.11} transform="matrix(1 0 0 -1 90.832 62.832)" />
    <path
      fill="#E6EEF8"
      d="M58.022 25.423c0-2.283-1.836-4.134-4.1-4.134h-9.793c-2.264 0-4.1 1.851-4.1 4.134v9.828c0 2.284 1.836 4.135 4.1 4.135h9.793c2.264 0 4.1-1.851 4.1-4.135z"
    />
    <path
      fill="#D2E2F6"
      d="M79.191 25.423c0-2.283-1.835-4.134-4.099-4.134h-9.793c-2.264 0-4.1 1.851-4.1 4.134v9.828c0 2.284 1.836 4.135 4.1 4.135h9.793c2.264 0 4.1-1.851 4.1-4.135v-9.828ZM58.022 46.714c0-2.283-1.836-4.134-4.1-4.134h-9.793c-2.264 0-4.1 1.851-4.1 4.134v9.828c0 2.284 1.836 4.135 4.1 4.135h9.793c2.264 0 4.1-1.851 4.1-4.135z"
    />
    <path
      fill="#E6EEF8"
      d="M79.191 46.714c0-2.283-1.835-4.134-4.099-4.134h-9.793c-2.264 0-4.1 1.851-4.1 4.134v9.828c0 2.284 1.836 4.135 4.1 4.135h9.793c2.264 0 4.1-1.851 4.1-4.135v-9.828Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M102.612 18.12a1.93 1.93 0 0 0-1.94 0l-4.747 2.757c-.6.349-.97.993-.97 1.69v5.513c0 .697.37 1.342.97 1.69l4.747 2.756c.6.349 1.34.349 1.94 0l4.746-2.756c.601-.348.97-.993.97-1.69v-5.513c0-.697-.369-1.341-.97-1.69zm-1.058 1.537a.18.18 0 0 1 .176 0l4.746 2.757c.055.031.089.09.089.153v5.513a.18.18 0 0 1-.089.154l-4.746 2.756a.18.18 0 0 1-.176 0l-4.747-2.756a.18.18 0 0 1-.088-.154v-5.513c0-.063.034-.122.088-.153zm3.414 3.735-3.327-1.931-3.326 1.931v3.863l3.326 1.932 3.327-1.932zm-4.889 1.024 1.562-.907 1.563.907v1.815l-1.563.907-1.562-.907z"
      clipRule="evenodd"
    />
    <rect width={4} height={16} x={14} y={44.613} fill="#fff" rx={2} />
    <rect width={4} height={16} x={24} y={50.613} fill="#fff" rx={2} transform="rotate(90 24 50.613)" />
  </svg>
);
const Memo = memo(SvgImageConnectPlatform);
export default Memo;
