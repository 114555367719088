import { useReducer } from 'react';

import {
  DepositNotificationEnum,
  DisruptionNotificationEnum,
  DisruptionNotificationGracePeriodEnum,
  IDepositSettingsInput,
  InputMaybe,
} from 'src/graphql/schema';
import { IAction } from 'src/utils/ts-utilities';

export enum ActionEnum {
  SET_INITIAL_DATA = 'set_initial_data',
  UPDATE_DEPOSIT_DISPLAY = 'update_deposit_display',
  UPDATE_BENEFICIARY_DEPOSIT_NOTIFICATION = 'update_beneficiary_deposit_notification',
  UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION = 'update_beneficiary_disrupt_notification',
  UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION_GRACE = 'update_beneficiary_disrupt_notification_grace',
  UPDATE_DEPOSITOR_DEPOSIT_NOTIFICATION = 'update_depositor_deposit_notification',
  UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION = 'update_depositor_disrupt_notification',
  UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION_GRACE = 'update_depositor_disrupt_notification_grace',
  UPDATE_STORAGE_REGION = 'update_storage_region',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA, { data: IDepositSettingsInput }>
  | IAction<ActionEnum.UPDATE_DEPOSIT_DISPLAY, boolean>
  | IAction<ActionEnum.UPDATE_BENEFICIARY_DEPOSIT_NOTIFICATION, InputMaybe<DepositNotificationEnum>>
  | IAction<ActionEnum.UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION, InputMaybe<DisruptionNotificationEnum>>
  | IAction<
      ActionEnum.UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION_GRACE,
      InputMaybe<DisruptionNotificationGracePeriodEnum> | 'none'
    >
  | IAction<ActionEnum.UPDATE_DEPOSITOR_DEPOSIT_NOTIFICATION, InputMaybe<DepositNotificationEnum>>
  | IAction<ActionEnum.UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION, InputMaybe<DisruptionNotificationEnum>>
  | IAction<
      ActionEnum.UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION_GRACE,
      InputMaybe<DisruptionNotificationGracePeriodEnum> | 'none'
    >
  | IAction<ActionEnum.UPDATE_STORAGE_REGION, string>;

const initialState: IDepositSettingsInput = {
  showDepositInformation: false,
  beneficiaryDepositNotification: undefined,
  beneficiaryDisruptionNotification: undefined,
  beneficiaryDisruptionNotificationGracePeriod: undefined,
  depositorDepositNotification: undefined,
  depositorDisruptionNotification: undefined,
  depositorDisruptionNotificationGracePeriod: undefined,
  storageRegion: '',
};

const reducer = (state: IDepositSettingsInput, action: IActionReducer): IDepositSettingsInput => {
  switch (action.type) {
    case ActionEnum.SET_INITIAL_DATA:
      return action.payload.data;
    case ActionEnum.UPDATE_DEPOSIT_DISPLAY:
      return { ...state, showDepositInformation: action.payload };
    case ActionEnum.UPDATE_BENEFICIARY_DEPOSIT_NOTIFICATION:
      return { ...state, beneficiaryDepositNotification: action.payload };
    case ActionEnum.UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION:
      return { ...state, beneficiaryDisruptionNotification: action.payload };
    case ActionEnum.UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION_GRACE: {
      const value = action.payload === 'none' ? null : action.payload;
      return { ...state, beneficiaryDisruptionNotificationGracePeriod: value };
    }
    case ActionEnum.UPDATE_DEPOSITOR_DEPOSIT_NOTIFICATION:
      return { ...state, depositorDepositNotification: action.payload };
    case ActionEnum.UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION:
      return { ...state, depositorDisruptionNotification: action.payload };
    case ActionEnum.UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION_GRACE: {
      const value = action.payload === 'none' ? null : action.payload;
      return { ...state, depositorDisruptionNotificationGracePeriod: value };
    }
    case ActionEnum.UPDATE_STORAGE_REGION:
      return { ...state, storageRegion: action.payload };
    default:
      return state;
  }
};

export const useNotificationsSuggestChangesSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setInitialData = (data: IDepositSettingsInput) => {
    dispatch({
      type: ActionEnum.SET_INITIAL_DATA,
      payload: {
        data,
      },
    });
  };

  const updateDepositDisplay = (showDepositInformation: boolean) =>
    dispatch({ type: ActionEnum.UPDATE_DEPOSIT_DISPLAY, payload: showDepositInformation });

  const updateBeneficiaryDepositNotification = (depositNotification: InputMaybe<DepositNotificationEnum>) =>
    dispatch({
      type: ActionEnum.UPDATE_BENEFICIARY_DEPOSIT_NOTIFICATION,
      payload: depositNotification,
    });

  const updateBeneficiaryDisruptNotification = (disruptNotification: InputMaybe<DisruptionNotificationEnum>) =>
    dispatch({
      type: ActionEnum.UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION,
      payload: disruptNotification,
    });

  const updateBeneficiaryDisruptNotificationGrace = (
    disruptGraceNotification: InputMaybe<DisruptionNotificationGracePeriodEnum> | 'none',
  ) =>
    dispatch({
      type: ActionEnum.UPDATE_BENEFICIARY_DISRUPT_NOTIFICATION_GRACE,
      payload: disruptGraceNotification,
    });

  const updateDepositorDepositNotification = (depositNotification: InputMaybe<DepositNotificationEnum>) =>
    dispatch({
      type: ActionEnum.UPDATE_DEPOSITOR_DEPOSIT_NOTIFICATION,
      payload: depositNotification,
    });

  const updateDepositorDisruptNotification = (disruptNotification: InputMaybe<DisruptionNotificationEnum>) =>
    dispatch({
      type: ActionEnum.UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION,
      payload: disruptNotification,
    });

  const updateDepositorDisruptNotificationGrace = (
    disruptGraceNotification: InputMaybe<DisruptionNotificationGracePeriodEnum> | 'none',
  ) =>
    dispatch({
      type: ActionEnum.UPDATE_DEPOSITOR_DISRUPT_NOTIFICATION_GRACE,
      payload: disruptGraceNotification,
    });

  const updateStorageRegion = (storageRegion: string) =>
    dispatch({
      type: ActionEnum.UPDATE_STORAGE_REGION,
      payload: storageRegion,
    });

  return {
    state,
    setInitialData,
    updateDepositDisplay,
    updateBeneficiaryDepositNotification,
    updateBeneficiaryDisruptNotification,
    updateBeneficiaryDisruptNotificationGrace,
    updateDepositorDepositNotification,
    updateDepositorDisruptNotification,
    updateDepositorDisruptNotificationGrace,
    updateStorageRegion,
  };
};
