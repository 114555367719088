import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconRepositorySize = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.333 9.333v16h21.333v-16zM3.999 6.666h22.667a2.667 2.667 0 0 1 2.667 2.667v16a2.667 2.667 0 0 1-2.667 2.666H5.333a2.667 2.667 0 0 1-2.667-2.666V7.999a1.333 1.333 0 0 1 1.333-1.333"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.725 13.725c.52-.521 1.364-.521 1.885 0l2.667 2.666c.52.52.52 1.365 0 1.886l-2.667 2.666a1.333 1.333 0 1 1-1.886-1.885l1.724-1.724-1.724-1.724a1.333 1.333 0 0 1 0-1.885M14.277 13.725c.52.52.52 1.364 0 1.885l-1.724 1.724 1.724 1.724a1.333 1.333 0 0 1-1.886 1.886l-2.666-2.667a1.333 1.333 0 0 1 0-1.886l2.666-2.666c.52-.521 1.365-.521 1.886 0M2.666 6.667A2.667 2.667 0 0 1 5.333 4h7.447c.708 0 1.386.28 1.886.781l2.276 2.276a1.333 1.333 0 1 1-1.886 1.886L12.78 6.667H5.333V8a1.333 1.333 0 1 1-2.667 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconRepositorySize);
export default Memo;
