import { ProfileLayoutContainer, UserFeedbackContainer, ProfileActivityContainer } from 'src/containers/Profile';

import Profile from '../pages/Profile/Profile';
import Invitations from '../components/Invitations/Invitations';

import type { RouteObject } from 'react-router-dom';

export const profileRoutes: RouteObject = {
  element: <ProfileLayoutContainer />,
  children: [
    {
      path: '/profile',
      element: <Profile />,
    },
    {
      path: '/activity',
      element: <ProfileActivityContainer />,
    },
    {
      path: '/invitations',
      element: <Invitations />,
    },
    {
      path: '/feedback',
      element: <UserFeedbackContainer />,
    },
  ],
};
