import { AddMemberModal } from 'src/components/Account';
import { DestructuredUser } from 'src/components/Account/TeamMembers/TeamMembersTable';
import { CompanyDocument, IUserInput, useAddUserMutation, useEditUserMutation } from 'src/graphql/schema';
import { message } from 'src/components/Misc';

type Props = {
  isOpen: boolean;
  editUserId: string;
  member: DestructuredUser | null;
  onCloseModal: () => void;
  type: 'edit' | 'invite';
};

const companyQuery = [CompanyDocument];

const AddMemberModalContainer: React.FunctionComponent<Props> = ({
  isOpen,
  editUserId,
  member,
  onCloseModal,
  type,
}) => {
  const [addMember] = useAddUserMutation({
    refetchQueries: companyQuery,
  });
  const [editMember] = useEditUserMutation({
    refetchQueries: companyQuery,
  });

  const onAddMember = async (values: Partial<IUserInput>) => {
    try {
      const { data } = await addMember({
        variables: {
          users: [
            {
              name: values.name!,
              email: values.email!,
              phone: values.phone,
              role: values.role,
            },
          ],
        },
      });

      if (data?.addUser?.success) {
        onCloseModal();
        message.success('Team member added!');
      } else {
        data?.addUser?.errors && message.error(data?.addUser?.errors[0]);
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  const onEditMember = async ({ name, phone, role }: Partial<IUserInput>) => {
    try {
      const { data } = await editMember({
        variables: {
          user: {
            name: name!,
            phone,
            role,
          },
          userId: editUserId,
        },
      });

      if (data?.editUser?.success) {
        onCloseModal();
        message.success('Team member edited!');
      } else {
        data?.editUser?.errors && message.error(data?.editUser?.errors[0]);
      }
    } catch {
      message.error('Something went wrong');
    }
  };

  const onFinish = (values: Partial<IUserInput>) => (editUserId ? onEditMember(values) : onAddMember(values));

  return (
    <AddMemberModal isOpen={isOpen} onFinish={onFinish} type={type} member={member!} onCloseModal={onCloseModal} />
  );
};

export default AddMemberModalContainer;
