import {
  IconFilter,
  IconBell,
  IconFile,
  IconSearch,
  IconUpload,
  IconParties,
  IconCertificate,
  IconChartPie,
} from 'src/components/Svg/Icons';
import { EscrowRoutesEnum } from 'src/pages/Escrow';

import { IEscrowMenuItem } from './EscrowMenuItem';

export const escrowMenuItems: IEscrowMenuItem[] = [
  {
    path: EscrowRoutesEnum.Basics,
    label: 'Basics',
    icon: IconFilter,
    isRequired: true,
  },
  {
    path: EscrowRoutesEnum.Parties,
    label: 'Parties',
    icon: IconParties,
    isDisabled: true,
  },
  {
    path: EscrowRoutesEnum.Notifications,
    label: 'Notifications',
    icon: IconBell,
    isDisabled: true,
  },
  {
    path: EscrowRoutesEnum.Agreement,
    label: 'Agreement',
    icon: IconFile,
    isDisabled: true,
  },
  {
    path: EscrowRoutesEnum.Preview,
    label: 'Preview',
    icon: IconSearch,
    isDisabled: true,
  },
  {
    path: EscrowRoutesEnum.Deposits,
    label: 'Deposits',
    icon: IconUpload,
    isDisabled: true,
  },
  {
    path: EscrowRoutesEnum.Certificate,
    label: 'Certificate',
    icon: IconCertificate,
    isDisabled: true,
  },
  {
    path: EscrowRoutesEnum.Reports,
    label: 'Reports',
    icon: IconChartPie,
    isDisabled: true,
  },
];
