import { Modal } from 'antd';

import { useAddDeposit } from 'src/context/deposit';

import OAuthDepositContainer from './Methods/OAuthDepositContainer';
import CustomDepositContainer from './Methods/CustomDepositContainer';
import SecureUploadContainer from './Methods/SecureUploadContainer';
import SelectDepositMethodContainer from './Steps/SelectDepositMethodContainer';
import SelectEscrowContainer from './Steps/SelectEscrowContainer';
import AssetAddedContainer from './Steps/AssetAddedContainer';
import OAuthDepositConfirmContainer from './Steps/OAuthDepositConfirmContainer';
import CustomDepositConfirmContainer from './Steps/CustomDepositConfirmContainer';

const addStepMap = {
  'select-escrow': {
    modalWidth: 550,
    component: SelectEscrowContainer,
  },
  'select-method': { modalWidth: 1008, component: SelectDepositMethodContainer },
  custom: { modalWidth: 1008, component: CustomDepositContainer },
  oauth: { modalWidth: 1008, component: OAuthDepositContainer },
  secure: { modalWidth: 550, component: SecureUploadContainer },
  success: {
    modalWidth: 440,
    component: AssetAddedContainer,
  },
  'oauth-confirm': {
    modalWidth: 550,
    component: OAuthDepositConfirmContainer,
  },
  'custom-confirm': {
    modalWidth: 550,
    component: CustomDepositConfirmContainer,
  },
};

const AddDepositContainer = () => {
  const {
    ui: { step, setInitialData },
    refetch,
  } = useAddDeposit();

  const activeStep = addStepMap[step as keyof typeof addStepMap];
  const StepComponent = activeStep?.component;

  const onCancel = () => {
    if (step === 'success') refetch?.();
    setInitialData();
  };

  return (
    <Modal
      className="ck-add-deposit__modal"
      open={Boolean(step)}
      onCancel={onCancel}
      footer={null}
      width={activeStep?.modalWidth}
      centered
      destroyOnClose
    >
      {StepComponent && <StepComponent />}
    </Modal>
  );
};

export default AddDepositContainer;
