type ProvidersType = [React.ElementType, Record<string, unknown>?];

export const buildProvidersTree = (componentsWithProps: ProvidersType[]) => {
  const initialComponent = ({ children }: { children: React.ReactNode[] }) => <>{children}</>;

  return componentsWithProps.reduce(
    (AccumulatedComponents: React.ElementType, [Provider, props = {}]: ProvidersType) => {
      // eslint-disable-next-line react/display-name
      return ({ children }: { children: React.ReactNode[] }) => {
        return (
          <AccumulatedComponents>
            <Provider {...props}>{children}</Provider>
          </AccumulatedComponents>
        );
      };
    },
    initialComponent,
  );
};
