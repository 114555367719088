import { JourneyMenu } from 'src/components/Journey/Menu';

import BackupMenuItem, { IBackupMenuItem } from './BackupMenuItem';

type Props = {
  basePath: string;
  backupId: string;
  isLoading?: boolean;
  items: IBackupMenuItem[];
};

const BackupMenu = ({ items, backupId, ...rest }: Props) => {
  return (
    <JourneyMenu>
      {items.map((item) => (
        <BackupMenuItem key={item.path} slug={backupId} {...item} {...rest} />
      ))}
    </JourneyMenu>
  );
};

export default BackupMenu;
