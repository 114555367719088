import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageSaasEscrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 28"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#417BD2"
      d="M.39 15.952V2.453C.39 1.313 1.316.39 2.456.39h19.097c1.14 0 2.064.924 2.064 2.064v13.499a7.742 7.742 0 0 1-3.647 6.57l-6.874 4.283a2.064 2.064 0 0 1-2.183 0l-6.874-4.282a7.742 7.742 0 0 1-3.647-6.571"
    />
    <path
      fill="#316ABF"
      fillRule="evenodd"
      d="M12 27.116V.386h9.55c1.14 0 2.065.925 2.065 2.065V15.95a7.742 7.742 0 0 1-3.648 6.57l-6.873 4.283a2.063 2.063 0 0 1-1.094.313"
      clipRule="evenodd"
    />
    <path
      fill="#417BD2"
      fillRule="evenodd"
      d="M2.452.77C1.525.77.774 1.517.774 2.438v13.426a7.306 7.306 0 0 0 3.466 6.209l6.873 4.26a1.685 1.685 0 0 0 1.774 0l6.873-4.26a7.306 7.306 0 0 0 3.466-6.209V2.438c0-.921-.751-1.668-1.678-1.668zM0 2.438A2.445 2.445 0 0 1 2.452 0h19.096A2.445 2.445 0 0 1 24 2.438v13.426a8.075 8.075 0 0 1-3.83 6.863l-6.874 4.259c-.793.492-1.799.492-2.592 0l-6.874-4.26A8.075 8.075 0 0 1 0 15.865z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.498 9.38a5.366 5.366 0 0 1 9.88 1.327 3.353 3.353 0 0 1-.51 6.665H8.152a4.023 4.023 0 0 1-.654-7.993m4.677-1.395a4.023 4.023 0 0 0-3.64 2.306.67.67 0 0 1-.562.382 2.682 2.682 0 0 0 .179 5.358h8.716a2.011 2.011 0 1 0-.036-4.022.67.67 0 0 1-.675-.576 4.024 4.024 0 0 0-3.982-3.448"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageSaasEscrow);
export default Memo;
