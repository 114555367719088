import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconParties = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.555 10.636a.8.8 0 0 1 .962-.595c1.692.399 3.283 1.573 3.283 3.294a.8.8 0 1 1-1.6 0c0-.602-.635-1.403-2.05-1.737a.8.8 0 0 1-.595-.962ZM3.616 12.114c-.581.387-.816.834-.816 1.22a.8.8 0 0 1-1.6 0c0-1.087.661-1.973 1.528-2.551C3.6 10.2 4.76 9.867 6 9.867c1.24 0 2.4.334 3.272.916.867.578 1.528 1.464 1.528 2.55a.8.8 0 0 1-1.6 0c0-.385-.234-.832-.815-1.22-.575-.383-1.416-.646-2.385-.646-.968 0-1.81.263-2.384.647ZM9.2 3.335a.8.8 0 0 1 .8-.8 3.467 3.467 0 1 1 0 6.933.8.8 0 1 1 0-1.6 1.867 1.867 0 0 0 0-3.733.8.8 0 0 1-.8-.8Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 4.135a1.867 1.867 0 1 0 0 3.733 1.867 1.867 0 0 0 0-3.733ZM2.533 6.002a3.467 3.467 0 1 1 6.934 0 3.467 3.467 0 0 1-6.934 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconParties);
export default Memo;
