export const optionsRole: { label: string; value: string }[] = [
  {
    label: 'Administrative',
    value: 'administrative',
  },
  {
    label: 'Legal',
    value: 'legal',
  },
  {
    label: 'Technical',
    value: 'technical',
  },
];

export const confirmMessageOptions: { label: string; value: boolean }[] = [
  {
    label: 'Send',
    value: true,
  },
  {
    label: 'Don’t send',
    value: false,
  },
];
