import { Button, Dropdown } from 'antd';
import { CheckCircleFilled, MoreOutlined, WarningOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';

import { renderLogo } from 'src/utils/renderLogo';
import { OauthIntegrationProviderEnum } from 'src/graphql/schema';
import { PROVIDER_KEY_TO_NAME } from 'src/shared/constants';

import { IActiveIntegration } from '../ActiveIntegrations';
import IntegrationsTag from '../IntegrationsTag/IntegrationTag';
import useIntegrationAuth from '../../hooks/use-integration-auth';

import type { MenuProps } from 'antd';

type Props = {
  integration: IActiveIntegration;
  loading?: boolean;
  dropdownItems: MenuProps['items'];
  handleOnClick: () => void;
};

const ActiveIntegrationCard = ({ integration, loading, dropdownItems, handleOnClick }: Props) => {
  const isCustom = integration.__typename === 'CustomIntegration';
  const isManual = integration.__typename === 'ShareFileIntegration';
  const { initAuth } = useIntegrationAuth(integration.provider as OauthIntegrationProviderEnum);

  const handleInitAuth = () =>
    initAuth(
      integration?.tenantId
        ? {
            tenantId: integration.tenantId,
          }
        : undefined,
    );
  const Icon = renderLogo(integration.provider as keyof typeof PROVIDER_KEY_TO_NAME);

  return (
    <div className="active-integrations__item">
      <IntegrationsTag
        isManual={isManual}
        isCustom={isCustom}
        text={isManual ? 'Manual' : isCustom ? 'Custom' : 'OAuth'}
        color={isCustom || isManual ? '#DCEAFF' : '#C8F7FF'}
      />

      <Dropdown
        menu={{
          items: dropdownItems,
        }}
        trigger={['click']}
        placement="bottomRight"
      >
        <MoreOutlined className="active-integrations__more" rotate={90} />
      </Dropdown>

      <div className="d-flex align-items-center flex-column mb-3">
        <Icon className="active-integrations__item-logo" />
        <div className="active-integrations__item-name">
          {PROVIDER_KEY_TO_NAME[integration.provider as keyof typeof PROVIDER_KEY_TO_NAME] ||
            capitalize(integration.provider)}
          <div className="active-integrations__account-name">{integration.accountName}</div>
        </div>
      </div>

      {integration.active ? (
        <div className="active-integrations__item-active">
          <CheckCircleFilled className="mr-2" />
          Activated
        </div>
      ) : (
        <Button
          className="active-integrations__item-inactive"
          type="primary"
          data-testid={`${integration.accountName}-btn`}
          // different call for reconnecting custom vs oauth integrations
          onClick={() => (isCustom || isManual ? handleOnClick() : handleInitAuth())}
          loading={loading}
        >
          <WarningOutlined className="mr-2" />
          Re-connect
        </Button>
      )}
    </div>
  );
};

export default ActiveIntegrationCard;
