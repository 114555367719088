import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageCoupon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 22"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#009CB7"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v4.857c1.698.393 2.976 2.1 2.976 4.147 0 2.046-1.278 3.753-2.976 4.146V20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M18.663 15.089h-1.655l5.355-9.026h1.656zm-1.5-3.65q-1.125 0-1.76-.742-.633-.755-.633-2.023 0-1.28.633-2.01.647-.742 1.76-.742 1.125 0 1.759.755.634.743.634 1.997 0 1.268-.634 2.023-.634.743-1.76.743m0-1.19q.388 0 .595-.371.22-.372.22-1.204 0-.845-.22-1.19a.66.66 0 0 0-.595-.346.67.67 0 0 0-.608.346q-.207.333-.207 1.19 0 .858.207 1.217.207.358.608.358m6.7 4.98q-1.125 0-1.759-.755t-.634-2.023q0-1.28.634-2.01.647-.73 1.76-.73 1.137 0 1.759.743.634.742.634 1.997 0 1.268-.634 2.023-.62.756-1.76.756m0-1.216q.401 0 .608-.358.22-.372.22-1.204 0-.845-.22-1.19a.66.66 0 0 0-.608-.359.69.69 0 0 0-.607.346q-.207.346-.207 1.203t.207 1.217q.22.345.607.345"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.434 2.54c.226 0 .41.212.41.475v.999c0 .263-.184.476-.41.476-.227 0-.41-.213-.41-.476v-.999c0-.263.183-.476.41-.476m0 2.993c.226 0 .41.214.41.477v.998c0 .263-.184.476-.41.476-.227 0-.41-.213-.41-.476V6.01c0-.263.183-.477.41-.477m0 2.995c.226 0 .41.213.41.476v.999c0 .263-.184.476-.41.476-.227 0-.41-.213-.41-.476v-.999c0-.263.183-.476.41-.476m0 2.994c.226 0 .41.214.41.477v.998c0 .263-.184.476-.41.476-.227 0-.41-.213-.41-.476v-.998c0-.263.183-.477.41-.477m0 2.995c.226 0 .41.213.41.476v.998c0 .263-.184.477-.41.477-.227 0-.41-.214-.41-.477v-.998c0-.263.183-.476.41-.476m0 2.994c.226 0 .41.213.41.477v.998c0 .263-.184.476-.41.476-.227 0-.41-.213-.41-.476v-.998c0-.264.183-.477.41-.477"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageCoupon);
export default Memo;
