import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconEscrows = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.335 1.25c-.783 0-1.417.634-1.417 1.417v.583H2.667c-.783 0-1.417.634-1.417 1.417v8.666c0 .783.634 1.417 1.417 1.417h6c.782 0 1.416-.634 1.416-1.417v-.583h3.252c.782 0 1.416-.634 1.416-1.417v-6.39c0-.376-.149-.736-.415-1.002L12.06 1.665a1.416 1.416 0 0 0-1.001-.415h-.391zm2.583 1.5h-2.5v.94l2.25 2.251c.266.266.415.626.415 1.002v4.307h3.168V6.083h-1.916a1.417 1.417 0 0 1-1.417-1.416zm2.94 1.833h-1.44V3.144zM2.75 13.25v-8.5h2.5v1.917c0 .782.634 1.416 1.417 1.416h1.916v5.167zm4-6.667V5.144l1.44 1.44z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconEscrows);
export default Memo;
