import { useState } from 'react';
import { Button } from 'antd';

import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';

import { IAddContactForm, AddContactForm, IAddExistingContactForm } from '../';

export type Props = (
  | {
      withExistingSelect: true;
      onSubmitExistingContact: (data: IAddExistingContactForm) => void;
      existingContacts: { label: string; value: string }[];
    }
  | {
      withExistingSelect?: false;
    }
) & {
  type: 'beneficiary' | 'depositor';
  onSubmitContact: (data: IAddContactForm) => void;
};

const ToggleAddNewContactForm: React.FunctionComponent<Props> = (props) => {
  const { type, onSubmitContact, withExistingSelect } = props;
  const [isVisibleForm, setIsVisibleForm] = useState(false);
  const onCancel = () => setIsVisibleForm(false);
  const hanldeAddClick = () => setIsVisibleForm(true);

  return (
    <div className={styles.block}>
      {isVisibleForm ? (
        <AddContactForm
          type={type}
          onCancel={onCancel}
          onSubmitContact={onSubmitContact}
          {...(withExistingSelect
            ? {
                withExistingSelect: true,
                existingContacts: props.existingContacts,
                onSubmitExistingContact: props.onSubmitExistingContact,
              }
            : { withExistingSelect: false })}
        />
      ) : (
        <div className={styles['action-row']}>
          <Button ghost className={styles['btn-outlined-primary']} onClick={hanldeAddClick}>
            + Add new contact
          </Button>
        </div>
      )}
    </div>
  );
};

export default ToggleAddNewContactForm;
