import { Button, Form, Select } from 'antd';
import { forwardRef } from 'react';

import type { FormInstance } from 'antd/lib/form';

import styles from './MigrationConnect.module.scss';

export type IMigrationConnectForm = {
  integrationId: string;
  oauthRepoId?: string;
};

type Props = {
  integrationOptions: {
    value: string;
    label: string;
  }[];
  repositoryOptions: {
    value: string;
    label: string;
  }[];
  onSubmit: (data: IMigrationConnectForm) => void;
  isLoading?: boolean;
  loadingOptions?: boolean;
  showRepositoryField?: boolean;
};

type Ref = FormInstance;

const MigrationConnectForm = forwardRef<Ref, Props>(
  (
    {
      integrationOptions,
      repositoryOptions,
      onSubmit,
      isLoading = false,
      loadingOptions = false,
      showRepositoryField = false,
    },
    ref,
  ) => {
    const [form] = Form.useForm<IMigrationConnectForm>();
    const integrationField = Form.useWatch('integrationId', form);
    const repositoryField = Form.useWatch('oauthRepoId', form) && showRepositoryField;
    const type = showRepositoryField ? 'repository' : 'integration';
    const disabledConnect = [!integrationField, !showRepositoryField ? false : !repositoryField, loadingOptions].some(
      Boolean,
    );

    return (
      <Form onFinish={onSubmit} form={form} ref={ref}>
        <h3 className={styles.title}>Select the {type} to connect the asset to:</h3>
        <Form.Item
          name="integrationId"
          hidden={showRepositoryField}
          rules={[
            {
              required: true,
              message: 'Please select an integration',
            },
          ]}
        >
          <Select placeholder="Select integration" options={integrationOptions} size="large" />
        </Form.Item>

        {showRepositoryField && (
          <Form.Item
            name="oauthRepoId"
            rules={[
              {
                required: true,
                message: 'Please select a repository',
              },
            ]}
          >
            <Select placeholder="Select repository" options={repositoryOptions} size="large" loading={loadingOptions} />
          </Form.Item>
        )}

        <div className="text-center">
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={disabledConnect}>
            Connect
          </Button>
        </div>
      </Form>
    );
  },
);

MigrationConnectForm.displayName = 'MigrationConnectForm';

export default MigrationConnectForm;
