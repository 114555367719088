import { JourneyStatusIndicator } from 'src/components/Journey/StatusIndicator';

import { statusIndicatorItems, SaasStatusIndicatorEnum } from './saasStatusIndicator.enum';

type Props = {
  currentStatus: SaasStatusIndicatorEnum;
};

const SaasStatusIndicator = ({ currentStatus }: Props) => {
  const isCanceled =
    currentStatus === SaasStatusIndicatorEnum.TERMINATED_REQUESTED ||
    currentStatus === SaasStatusIndicatorEnum.TERMINATED;
  let newStatusIndicatorItems: typeof statusIndicatorItems = [];

  switch (currentStatus) {
    case SaasStatusIndicatorEnum.ACCESS_SETUP: {
      const currentStatusIndex = statusIndicatorItems.findIndex(({ id }) => id === currentStatus);

      newStatusIndicatorItems = statusIndicatorItems.map((item, index) =>
        index <= currentStatusIndex ? { ...item, status: item.id === currentStatus ? 'active' : 'completed' } : item,
      );
      break;
    }
    case SaasStatusIndicatorEnum.ACTIVE: {
      newStatusIndicatorItems = statusIndicatorItems.map((item) => ({
        ...item,
        status: 'completed',
      }));
      break;
    }
    case SaasStatusIndicatorEnum.TERMINATED_REQUESTED: {
      newStatusIndicatorItems = statusIndicatorItems.map((item) => ({
        ...item,
        status: item.id === currentStatus ? 'pending-cancellation' : 'completed',
        isHidden: item.id === currentStatus ? false : item.isHidden,
      }));
      break;
    }
    case SaasStatusIndicatorEnum.TERMINATED: {
      newStatusIndicatorItems = statusIndicatorItems.map((item) => ({
        ...item,
        status: item.id === currentStatus ? 'canceled' : 'completed',
        isHidden: item.id === currentStatus ? false : item.isHidden,
      }));
      break;
    }
    default:
      newStatusIndicatorItems = [...statusIndicatorItems];
      break;
  }

  return (
    <JourneyStatusIndicator isCanceled={isCanceled} skeletonItems={statusIndicatorItems.length}>
      {newStatusIndicatorItems.map((item) => (
        <JourneyStatusIndicator.Item key={item.id} {...item} />
      ))}
    </JourneyStatusIndicator>
  );
};

export default SaasStatusIndicator;
