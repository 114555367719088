import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconProvider = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.192 1.702a1.49 1.49 0 0 0-1.49 1.49v2.959c0 .823.667 1.49 1.49 1.49H6.15a1.49 1.49 0 0 0 1.49-1.49V3.192a1.49 1.49 0 0 0-1.49-1.49zm2.948 1.5H3.202V6.14H6.14zM3.192 8.36a1.49 1.49 0 0 0-1.49 1.49v2.958c0 .823.667 1.49 1.49 1.49H6.15a1.49 1.49 0 0 0 1.49-1.49V9.849a1.49 1.49 0 0 0-1.49-1.49zm.01 4.438V9.86H6.14v2.938zM8.36 3.192c0-.823.667-1.49 1.49-1.49h2.959c.823 0 1.49.667 1.49 1.49v2.959a1.49 1.49 0 0 1-1.49 1.49H9.849a1.49 1.49 0 0 1-1.49-1.49zm1.5.01V6.14h2.938V3.202zM9.85 8.36a1.49 1.49 0 0 0-1.49 1.49v2.958c0 .823.667 1.49 1.49 1.49h2.959a1.49 1.49 0 0 0 1.49-1.49V9.849a1.49 1.49 0 0 0-1.49-1.49zm.01 4.438V9.86h2.938v2.938z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconProvider);
export default Memo;
