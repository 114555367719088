import React from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';

import JourneyStatusIndicatorItem from './JourneyStatusIndicatorItem';
import styles from './JourneyStatusIndicator.module.scss';

type Props = {
  isLoading?: boolean;
  isCanceled?: boolean;
  skeletonItems?: number;
};

const JourneyStatusIndicatorSkeleton = ({ items }: { items: number }) => {
  return (
    <div className={styles.block}>
      <Skeleton
        active
        title={false}
        paragraph={{
          rows: items,
          width: '100%',
        }}
      />
    </div>
  );
};

const JourneyStatusIndicator = ({
  isLoading = false,
  isCanceled = false,
  skeletonItems = 0,
  children,
}: React.PropsWithChildren<Props>) => {
  if (isLoading) return <JourneyStatusIndicatorSkeleton items={skeletonItems} />;

  return (
    <div
      className={classNames(styles.block, {
        [styles.canceled]: isCanceled,
      })}
    >
      <ul className="ck-custom-list">{children}</ul>
    </div>
  );
};

JourneyStatusIndicator.Item = JourneyStatusIndicatorItem;
JourneyStatusIndicator.Skeleton = JourneyStatusIndicatorSkeleton;

export default JourneyStatusIndicator;
