import classNames from 'classnames';
import { CheckCircleFilled } from '@ant-design/icons';
import { capitalize } from 'lodash';

import { TextBlock } from 'src/components/Misc';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';

import styles from '../EscrowContacts.module.scss';

type Props = {
  role: EscrowPartyTypeEnum;
  value?: boolean;
  className?: string;
};

const ConfirmationMessageBlock: React.FunctionComponent<Props> = ({ role, value, className }) => {
  return (
    <div className={classNames(styles['confirm-message'], className)}>
      <TextBlock
        className={styles['confirm-message-block']}
        title={`${capitalize(role)} Confirmation Message`}
        text={`Would you like to notify the ${role} when this escrow is active?`}
        as="h4"
        titleColor="dark-blue"
      />
      <div className={styles['selected-option']}>
        <i>
          <CheckCircleFilled />
        </i>
        <strong>{value ? 'Send' : 'Don’t send'}</strong>
      </div>
    </div>
  );
};

export default ConfirmationMessageBlock;
