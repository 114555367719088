import { useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';

import { message } from 'src/components/Misc';
import { downloadFile } from 'src/utils/file';
import env from 'env';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';

import styles from './GeneratedAgreement.module.scss';

export type Props = {
  currentCompanyRole: EscrowPartyTypeEnum;
  approvedBy: {
    beneficiary: boolean;
    codekeeper: boolean;
    depositor: boolean;
  };
  updatedAt?: Date;
  download: {
    docxUrl?: string;
    pdfUrl?: string;
    signedPdfUrl?: string;
  };
  fileName: string;
  isTripartite: boolean;
};

const GeneratedAgreementSidebar = ({
  currentCompanyRole,
  approvedBy,
  updatedAt,
  download,
  fileName,
  isTripartite,
}: Props) => {
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingDocx, setIsLoadingDocx] = useState(false);
  const [isLoadingSignedPdf, setIsLoadingSignedPdf] = useState(false);
  const approvalItems = [
    {
      title: 'Codekeeper',
      isApproved: approvedBy.codekeeper,
    },
    {
      title: 'Depositor' + (currentCompanyRole === EscrowPartyTypeEnum.Depositor ? ' (you)' : ''),
      isApproved: approvedBy.depositor,
    },
    ...(isTripartite
      ? [
          {
            title: 'Beneficiary' + (currentCompanyRole === EscrowPartyTypeEnum.Beneficiary ? ' (you)' : ''),
            isApproved: approvedBy.beneficiary,
          },
        ]
      : []),
  ];

  const onDownloadPdf = async () => {
    if (!download.pdfUrl) return;

    setIsLoadingPdf(true);
    try {
      await downloadFile(download.pdfUrl, 'pdf', fileName);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const onDownloadDocx = async () => {
    if (!download.docxUrl) return;

    setIsLoadingDocx(true);
    try {
      await downloadFile(download.docxUrl, 'docx', fileName);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    } finally {
      setIsLoadingDocx(false);
    }
  };

  const onDownloadSignedPdf = async () => {
    if (!download.signedPdfUrl) return;

    setIsLoadingSignedPdf(true);
    try {
      await downloadFile(download.signedPdfUrl, 'pdf', fileName);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong');
    } finally {
      setIsLoadingSignedPdf(false);
    }
  };

  return (
    <aside className={styles.sidebar}>
      <div className={styles['sidebar-main']}>
        <div className={styles['sidebar-wrapper']}>
          <div className={styles.date}>
            <span>Last updated:</span>
            {updatedAt && <strong>{dayjs(updatedAt).format('DD MMMM YYYY, hh:mmA')}</strong>}
          </div>

          <div className={styles.approve}>
            <strong className={styles.heading}>Status</strong>
            <ul className={`ck-custom-list ${styles['approve-list']}`}>
              {approvalItems.map(({ title, isApproved }) => {
                return (
                  <li key={title} className={styles['approve-item']}>
                    <div className={styles['approve-status']}>
                      {isApproved ? <CheckCircleFilled /> : <span className={styles['empty-dot']} />}
                    </div>
                    <strong className={styles['approve-item__title']}>{title}</strong>
                    <span className={styles['approve-item__text']}>
                      {isApproved ? 'Approved this version' : 'Awaiting approval'}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className={styles.main}>
          <strong className={styles.heading}>Changes to the legal terms</strong>
          <p className={styles['main-text']}>
            Need specific terms? Download the agreement using the links below, and send us a marked up version at{' '}
            <a href={`mailto: ${env.CODEKEEPER_SERVICE_EMAIL}`}>{env.CODEKEEPER_SERVICE_EMAIL}</a>
          </p>
          <ul className={`ck-custom-list ${styles['download-list']}`}>
            <li className={styles['download-item']}>
              <Button loading={isLoadingPdf} type="link" disabled={!download.pdfUrl} onClick={onDownloadPdf}>
                Download PDF
              </Button>
            </li>
            <li className={styles['download-item']}>
              <Button loading={isLoadingDocx} type="link" disabled={!download.docxUrl} onClick={onDownloadDocx}>
                Download Docx
              </Button>
            </li>
          </ul>
          <p className={styles['main-text']}>
            Make sure to keep ‘track changes’ on when changing the agreement content.
          </p>
        </div>
      </div>

      {download.signedPdfUrl && (
        <div className={styles['signed-btn']}>
          <Button type="primary" loading={isLoadingSignedPdf} onClick={onDownloadSignedPdf}>
            Download Signed PDF
          </Button>
        </div>
      )}
    </aside>
  );
};

export default GeneratedAgreementSidebar;
