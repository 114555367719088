import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { IContactDetail, IAddContactForm } from '..';

import EditableContactDetail from './EditableContactDetail';

type Props = {
  type: 'beneficiary' | 'depositor';
  contacts: Array<IContactDetail & { isDynamic?: boolean }>;
  onSubmitContact: (id: string, data: IAddContactForm & { isDynamic?: boolean }) => void;
  onRemoveContact: (id: string) => void;
  className?: string;
};

const EditableContactList: React.FunctionComponent<Props> = ({
  type,
  contacts = [],
  onSubmitContact,
  onRemoveContact,
  className,
}) => {
  if (isEmpty(contacts)) return null;

  return (
    <div className={classNames(className)}>
      {contacts.map(({ isDynamic, ...contact }) => {
        const handleSubmitContact = (data: IAddContactForm) => onSubmitContact(contact.id, { ...data, isDynamic });
        const handleRemoveContact = () => onRemoveContact(contact.id);

        return (
          <EditableContactDetail
            key={contact.id}
            type={type}
            data={contact}
            onSubmitContact={handleSubmitContact}
            onRemoveContact={handleRemoveContact}
            withRemoveAction={contacts.length > 1}
            isDynamic={isDynamic}
          />
        );
      })}
    </div>
  );
};

export default EditableContactList;
