const colors = {
  primary: '#009CB7',
  darkGreenBlue: '#1A5261',
  lightGrey: '#E8E8E8',
  slateGrey: '#6A8493',
  colorError: '#EE5E5E',
};

export const theme = {
  hashed: false,
  token: {
    colorPrimary: colors.primary,
    colorBgContainerDisabled: colors.lightGrey,
    fontFamily: 'Mulish',
    borderRadius: 5,
    borderRadiusLG: 8,
    boxShadow: '0 3px 10px 0 rgba(0, 48, 120, 0.10)',
    colorBgContainer: '#fff',
    colorBorder: '#C2D6E0',
    colorError: colors.colorError,
    colorLink: colors.primary,
    colorTextDisabled: '#fff',
    colorIcon: colors.primary,
    colorTextPlaceholder: colors.slateGrey,
  },
  components: {
    Button: {
      borderColorDisabled: colors.lightGrey,
      defaultBorderColor: colors.slateGrey,
      defaultColor: colors.slateGrey,
      defaultShadow: 'none',
      dangerShadow: 'none',
      fontWeight: 700,
      primaryShadow: 'none',
      primaryBorderColor: colors.primary,
      contentFontSizeSM: 12,
    },
    Modal: {
      titleColor: colors.primary,
      titleFontSize: 18,
      titleLineHeight: 1.2,
    },
    Form: {
      itemMarginBottom: 14,
      labelColor: colors.darkGreenBlue,
    },
    Input: {
      paddingInline: 10,
      hoverBorderColor: colors.primary,
      activeBorderColor: colors.primary,
    },
    Table: {
      bodySortBg: 'transparent',
      headerSortActiveBg: 'transparent',
      headerSortHoverBg: 'transparent',
      headerBg: 'transparent',
      rowSelectedHoverBg: '#dff7f7',
    },
  },
};
