import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconDatabase = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 45"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33.235 12.588c0 3.323-1.54 6.293-3.941 8.235h11V42H5V20.823h11a10.572 10.572 0 0 1-3.942-8.235C12.058 6.75 16.808 2 22.646 2c5.839 0 10.588 4.75 10.588 10.588zM22.647 4.353c-4.541 0-8.235 3.694-8.235 8.235 0 4.541 3.694 8.235 8.235 8.235 4.54 0 8.235-3.694 8.235-8.235 0-4.54-3.694-8.235-8.235-8.235zm0 30.588h15.294v-2.353H7.353v7.059h30.588v-2.353H22.647V34.94zM7.353 23.176v7.059h30.588v-2.353H22.647v-2.353h15.294v-2.353H7.353zM21.471 7.882h2.353v3.53h3.53v2.352h-3.53v3.53h-2.353v-3.53h-3.53v-2.353h3.53v-3.53zm-9.41 27.055H9.706v2.353h2.353v-2.353zm2.351 0h2.353v2.353h-2.353v-2.353zM9.707 25.53h2.353v2.353H9.707V25.53zm7.058 0h-2.353v2.353h2.353V25.53z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconDatabase);
export default Memo;
