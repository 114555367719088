import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoAws = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" role="img" className={props.className} {...props}>
    <path fill="#8c3123" d="m21.54 23.23-5.46 2.73v47.97l5.46 2.73 13.4-26.72z" />
    <path fill="#e05243" d="m50 69.76-28.46 6.89V23.23L50 30.12l13.45 19.9z" />
    <path fill="#8c3123" d="m50 69.76 28.45 6.89 4.92-26.03-4.92-27.39L50 30.12z" />
    <path fill="#e05243" d="m78.45 23.23 5.47 2.73v47.97l-5.47 2.73z" />
    <path fill="#8c3123" d="M37.59 58.78 50 60.37l9.84-10.35L50 39.68l-12.41 1.56z" />
    <path fill="#5e1f18" d="M62.41 32.75 50 35.01l-12.41-2.26L50 9z" />
    <path fill="#f2b0a9" d="M62.41 67.25 50 64.97l-12.41 2.28L50 91z" />
    <path fill="#e05243" d="M62.41 32.75 50 29.68V9l12.41 6.2z" />
    <path fill="#8c3123" d="M37.59 32.75 50 29.68V9l-12.41 6.2z" />
    <path fill="#e05243" d="m50 91 12.41-6.2V67.25L50 70.32z" />
    <path fill="#8c3123" d="m50 91-12.41-6.2V67.25L50 70.32z" />
    <path fill="#e05243" d="M62.41 58.78 50 60.37V39.68l12.41 1.56z" />
  </svg>
);
const Memo = memo(SvgLogoAws);
export default Memo;
