import { InfoDetailBlock } from 'src/components/InfoDetailBlock';

export interface IContactDetail {
  id: string;
  name: string;
  email: string;
  phone?: string;
  role: string;
}

type Props = {
  type: 'beneficiary' | 'depositor';
  className?: string;
  suggestionList?: React.ReactNode;
} & IContactDetail;

const ContactDetailBlock: React.FunctionComponent<Props> = ({
  type,
  name,
  email,
  phone,
  role,
  className,
  suggestionList,
}) => {
  return (
    <InfoDetailBlock
      className={className}
      title={`${type} Contact Details`}
      text="The representative who will monitor the entire escrow configuration process. Some roles may have restrictions."
      items={[
        { label: 'Name', value: name },
        { label: 'Email', value: email },
        { label: 'Phone Number', value: phone || '-' },
        { label: 'Role', value: role },
      ]}
      suggestionList={suggestionList}
    />
  );
};

export default ContactDetailBlock;
