import { useImpersonateUserMutation } from 'src/graphql/schema/index.ts';

function useImpersonate() {
  const [impersonateUser] = useImpersonateUserMutation();

  const impersonate = async (userId: string) => {
    localStorage.removeItem('impersonatedUserID');

    const { data } = await impersonateUser({ variables: { userId } });

    if (data?.impersonateUser?.success) {
      localStorage.setItem('impersonatedUserID', userId);
    }

    window.location.replace('/');
  };

  const stopImpersonating = () => {
    localStorage.removeItem('impersonatedUserID');
    window.location.replace('/');
  };

  const isImpersonating = !!localStorage.getItem('impersonatedUserID');

  return {
    impersonate,
    stopImpersonating,
    isImpersonating,
  };
}

export default useImpersonate;
