import { PartialDeep } from 'type-fest';
import classNames from 'classnames';

import { IAddon } from 'src/graphql/schema';
import formatPrice from 'src/utils/currency-code-to-symbol';

import styles from './BillingAddon.module.scss';

type Props = {
  addon: PartialDeep<IAddon>;
  className?: string;
};

export default function BillingAddon({ addon, className }: Props) {
  const addonClasses = classNames(styles.addon, className);

  return (
    <div className={addonClasses}>
      <div>
        <p className={styles['addon-name']}>
          {addon.name} <span>({addon.quantity} units)</span>
        </p>
        <p className={styles['addon-price']}>
          {formatPrice(addon.currencyCode, addon.price)}
          <sub> /{addon.periodUnit}</sub>
        </p>
      </div>
      <div className={styles['addon-badge']}>ADD ON</div>
    </div>
  );
}
