import { Modal, ModalProps } from 'antd';

import { useUpdateCustomIntegrationMutation } from 'src/graphql/schema';
import { IStaticIntegration, providers, staticIntegrationsByProvider } from 'src/shared/entities';
import { renderLogo } from 'src/utils/renderLogo';
import { message } from 'src/components/Misc';

import { IActiveIntegration } from '../ActiveIntegrations';
import CredentialsForm from '../CredentialsForm';
import { ICustomIntegrationValues } from '../CredentialsForm/CredentialsForm';

import styles from './styles.module.scss';

interface Props extends ModalProps {
  integration: IActiveIntegration;
  onFinish: () => void;
}

export default function UpdateCredentialsModal({ integration, onFinish, ...rest }: Props) {
  const integrationSettings = staticIntegrationsByProvider[integration.provider];

  const [updateCustomIntegration, { loading }] = useUpdateCustomIntegrationMutation();

  const handleSubmission = async (values: ICustomIntegrationValues) => {
    try {
      const { data } = await updateCustomIntegration({
        variables: { customIntegrationInput: values, integrationId: integration.id },
      });
      if (data?.updateCustomIntegration?.success) {
        message.success('Integration updated!');
        onFinish();
      } else {
        message.error(data?.updateCustomIntegration?.errors?.[0] || 'Error');
      }
    } catch (err) {
      console.log(err);
      onFinish();
    }
  };

  const Icon = renderLogo(integration.provider as (typeof providers)[number]);

  return (
    <Modal className="non-auth-modal" destroyOnClose footer={null} centered closable {...rest}>
      <div className={styles.UpdateCredentialsModal}>
        <div className={styles.header}>
          <Icon className="non-auth-modal__logo" />
          <h2>{integration?.accountName}</h2>
          <p>Enter your new {integration.accountName} Credentials</p>
        </div>

        <CredentialsForm
          loading={loading}
          integration={integrationSettings as IStaticIntegration} // Add type assertion here
          onFinish={handleSubmission}
          initialValues={{ accountName: integration.accountName || '', accountUrl: integration.accountUrl || '' }}
          submitButtonText="Update Credentials"
        />
      </div>
    </Modal>
  );
}
