import { Popover } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import Gitlab2 from 'src/assets/images/integrations/gitlab2.svg';
import Amazon1 from 'src/assets/images/integrations/amazon1.svg';
import Amazon3 from 'src/assets/images/integrations/amazon3.svg';
import Amazon4 from 'src/assets/images/integrations/amazon4.svg';
import Amazon5 from 'src/assets/images/integrations/amazon5.svg';
import Amazon6 from 'src/assets/images/integrations/amazon6.svg';
import Amazon7 from 'src/assets/images/integrations/amazon7.svg';

import Step from './Step';

export function CodecommitInfoContent() {
  return (
    <>
      <Step stepNumber={1} icon={Amazon1}>
        Navigate to IAM / Users / Policies / Create Policy.
      </Step>

      <Step stepNumber={2} icon={Gitlab2}>
        Create an access policy with codecommit:GetRepository and codecommit:GitPull access level.&nbsp;
        <Popover
          trigger="hover"
          overlayStyle={{ width: 360 }}
          content={
            <code>
              Example policy:
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;&ldquo;Version&ldquo;: &ldquo;2012-10-17&ldquo;,
              <br />
              &nbsp;&nbsp;&ldquo;Statement&ldquo;: [<br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Sid&ldquo;: &ldquo;VisualEditor0&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Effect&ldquo;: &ldquo;Allow&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Action&ldquo;: [<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;codecommit:GetRepository&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;codecommit:GitPull&ldquo;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;],
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Resource&ldquo;: [<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;arn:aws:codecommit:YOUR-REGION:YOUR-ACCOUNT-ID:YOUR-REPOSITORY&ldquo;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <br />
              &nbsp;&nbsp;]
              <br />
              {'}'}
            </code>
          }
        >
          <InfoCircleFilled style={{ color: '#00A0B6' }} />
        </Popover>
      </Step>

      <Step stepNumber={3} icon={Amazon3}>
        Navigate to IAM / Users / Policies / Add users.
      </Step>

      <Step stepNumber={4} icon={Amazon4}>
        Create a user with the &ldquo;Access key - Programmatic access&ldquo; credential type. You can copy the policy
        attached above.
      </Step>

      <Step stepNumber={5} icon={Amazon5}>
        Attach previously created access policy to the user.
      </Step>

      <Step stepNumber={6} icon={Amazon6}>
        On the user details page choose the <strong>“Security Credentials”</strong> tab, and in HTTPS Git credentials
        for AWS CodeCommit, choose <strong>“Generate”.</strong>
      </Step>

      <Step stepNumber={7} icon={Amazon7}>
        Copy the username and password that IAM generated for you, either by showing, copying, and then pasting this
        information into a secure file on your local computer, or by choosing <strong>“Download credentials”</strong> to
        download this information as a .CSV file.
      </Step>
    </>
  );
}
