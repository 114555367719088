import { useState, useRef } from 'react';
import { Button, Switch } from 'antd';
import { capitalize } from 'lodash';

import { IContactDetail } from '../';

import AddNewContactForm, { IAddContactForm } from './AddNewContactForm';
import AddExistingContactForm, { IAddExistingContactForm } from './AddExistingContactForm';
import styles from './EscrowContactsForm.module.scss';

export type Props = (
  | {
      withExistingSelect: true;
      onSubmitExistingContact: (data: IAddExistingContactForm) => void;
      existingContacts: { label: string; value: string }[];
    }
  | {
      withExistingSelect?: false;
    }
) & {
  type: 'beneficiary' | 'depositor';
  withRemoveAction?: boolean;
  onSubmitContact: (data: IAddContactForm) => void;
  onRemoveContact?: () => void;
  onCancel?: () => void;
  initialValues?: IContactDetail;
  hideDiscard?: boolean;
  isDynamic?: boolean;
};

const AddContactForm: React.FunctionComponent<Props> = (props) => {
  const [isExistingActive, setIsExistingActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<{ onSubmit: () => Promise<void> }>(null);

  const {
    type,
    withRemoveAction = false,
    withExistingSelect,
    onSubmitContact,
    onRemoveContact,
    onCancel,
    hideDiscard = false,
    isDynamic,
  } = props;

  const toggleSwitch = () => setIsExistingActive((prevExistingActive) => !prevExistingActive);

  const onClick = () => formRef.current && formRef.current.onSubmit();

  const handleSubmitContact = async (e: IAddContactForm) => {
    setIsLoading(true);

    try {
      await onSubmitContact(e);
      if (onCancel) onCancel();
    } finally {
      setIsLoading(false);
    }
  };

  const handleExistingSubmitContact = async (e: IAddExistingContactForm) => {
    setIsLoading(true);

    if (withExistingSelect) {
      try {
        await props.onSubmitExistingContact(e);
        if (onCancel) onCancel();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRemoveContact = async () => {
    if (!onRemoveContact) return;

    setIsLoading(true);

    try {
      await onRemoveContact();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {capitalize(type)} Contact Details<sub>Required</sub>
      </h3>
      <p className={styles.text}>
        The representative who will monitor the entire escrow configuration process. Some roles may have restrictions.
      </p>

      {withExistingSelect && (
        <div className={styles['switch-row']}>
          <Switch onChange={toggleSwitch} />
          <span className={styles['switch-label']}>Select Existing Contact</span>
        </div>
      )}

      {isExistingActive && withExistingSelect ? (
        <AddExistingContactForm options={props.existingContacts} onSubmit={handleExistingSubmitContact} ref={formRef} />
      ) : (
        <AddNewContactForm
          onSubmit={handleSubmitContact}
          ref={formRef}
          initialValues={props.initialValues}
          isDynamic={isDynamic}
        />
      )}

      <div className={styles['action-list']}>
        {withRemoveAction && onRemoveContact && (
          <div className={styles['action-list-left']}>
            <Button type="default" onClick={handleRemoveContact} loading={isLoading}>
              Remove contact
            </Button>
          </div>
        )}

        <div className={styles['action-list-right']}>
          {!hideDiscard && (
            <div className={styles['action-item']}>
              <Button type="default" onClick={onCancel}>
                Discard
              </Button>
            </div>
          )}
          <div className={styles['action-item']}>
            <Button type="primary" onClick={onClick} loading={isLoading}>
              Save details
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContactForm;
