/* eslint-disable jsx-a11y/aria-role */
import { Suspense } from 'react';

import { ConfirmationMessageBlock } from 'src/components/Escrow/Contacts';
import { EscrowPartyTypeEnum, EscrowStatusEnum } from 'src/graphql/schema';
import {
  getPartyOrganization,
  getPartyContacts,
  getPartySignatory,
  getExistingUsers,
} from 'src/utils/destructs/escrow.destructs';
import { ContactsSuggestChangesProvider } from 'src/context/suggest-changes';
import { Loader } from 'src/components/Loader';
import { useEscrow } from 'src/context/escrow';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';

import ContactsSuggestChangesActions from '../SuggestChanges/ContactsSuggestChangesActions';
import BipartiteSuggestChangesContainer from '../SuggestChanges/BipartiteSuggestChangesContainer';
import EscrowPartyPreviewContainer, { IPartyPreview } from '../Main/EscrowPartyPreviewContainer';

import EscrowBipartiteEditableContainer from './EscrowBipartiteEditableContainer';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowBipartiteContainer: React.FunctionComponent = () => {
  const { escrow, ui } = useEscrow();

  // New/Edit Mode
  if (escrow.status === EscrowStatusEnum.Draft)
    return (
      <EscrowModalMain>
        <EscrowBipartiteEditableContainer />
      </EscrowModalMain>
    );

  // Preview Mode
  const currentEscrowRole = escrow.currentCompanyRole as EscrowPartyTypeEnum;
  const secondPartyRole =
    currentEscrowRole === EscrowPartyTypeEnum.Beneficiary
      ? EscrowPartyTypeEnum.Depositor
      : EscrowPartyTypeEnum.Beneficiary;
  const parties = {
    [currentEscrowRole]: {
      isOwnOrganization: true,
      organization: getPartyOrganization(escrow, currentEscrowRole),
      signatory: getPartySignatory(escrow, currentEscrowRole),
      contacts: getPartyContacts(escrow, currentEscrowRole),
    },
    [secondPartyRole]: {
      organization: getPartyOrganization(escrow, secondPartyRole),
      signatory: getPartySignatory(escrow, secondPartyRole),
      contacts: getPartyContacts(escrow, secondPartyRole),
    },
  } as { [key in EscrowPartyTypeEnum]: IPartyPreview };

  // Suggest Changes Mode
  if (
    !escrow.escrowTermination &&
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Active, EscrowStatusEnum.Signed].includes(escrow.status)
  ) {
    const existingUsers = getExistingUsers(escrow, currentEscrowRole);
    return (
      <Suspense fallback={<Loader />}>
        <ContactsSuggestChangesProvider
          isSuggestChangesMode={ui.isSuggestChangesMode}
          escrowId={escrow.id}
          parties={parties}
          mainPartyRole={currentEscrowRole}
          existingUsers={{ [currentEscrowRole]: existingUsers }}
        >
          <EscrowModalMain>
            <BipartiteSuggestChangesContainer />
          </EscrowModalMain>
          <ContactsSuggestChangesActions />
        </ContactsSuggestChangesProvider>
      </Suspense>
    );
  }

  // Preview Mode
  return (
    <EscrowModalMain>
      <EscrowPartyPreviewContainer parties={parties} />

      <div className={styles['container']}>
        <ConfirmationMessageBlock
          className={styles.block}
          role={EscrowPartyTypeEnum.Beneficiary}
          value={escrow.activationNotification}
        />
      </div>
    </EscrowModalMain>
  );
};

export default EscrowBipartiteContainer;
