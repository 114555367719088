import { Form, Checkbox, Input, Button } from 'antd';
import { Fragment } from 'react';

import styles from './CancelPlanForms.module.scss';

export type ICancelPlanForm = {
  reasons: string[];
  other: string;
};

type Props = {
  onSubmit: (data: ICancelPlanForm) => void;
  onCancel: () => void;
  isLoading?: boolean;
};

const options = [
  'No longer needed',
  'Not the right product for me',
  'Found alternative solution',
  'Subscription costs',
  'Technical issue',
  'Other (please specify)',
];

const CancelPlanForm = ({ onSubmit, onCancel, isLoading }: Props) => {
  const [form] = Form.useForm<ICancelPlanForm>();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Fragment>
      <h1 className={`mr-4 ${styles.heading}`}>What’s the reason for canceling?</h1>
      <Form form={form} onFinish={onSubmit} layout="vertical" className="ck-cancel-plan__form">
        <div className="mb-12">
          <Form.Item
            name="reasons"
            rules={[
              {
                required: true,
                message: 'Please select a reason for canceling',
              },
            ]}
          >
            <Checkbox.Group options={options} />
          </Form.Item>
        </div>
        <Form.Item label="Comments (optional)" name="other">
          <Input.TextArea placeholder="Please tell us more on why you’re canceling" />
        </Form.Item>
        <div className={`d-flex justify-content-end ${styles['btn-group']}`}>
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit Feedback
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default CancelPlanForm;
