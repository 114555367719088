import React, { useMemo } from 'react';
import { Table } from 'antd';
import { capitalize } from 'lodash';

import { ESCROWS_LIMIT } from 'src/shared/constants';
import { LabelText } from 'src/components/Label';
import { labelStatusTypeEnum as statusTypeEnum } from 'src/components/Escrow/escrow.enum';

export type IDestructuredEscrow = {
  id: string;
  status: string;
  escrowInvitation: {
    status: string;
    id: string;
  };
  startedBy: string;
  currentCompanyRole: string;
};

export interface IEscrowTableRow {
  depositor?: string;
  beneficiary?: string;
  status: string;
  sync: string;
  action: {
    id: string;
    invitationId?: string;
  };
}

type Props = {
  data: IEscrowTableRow[];
  isLoading?: boolean;
  pagination: {
    current: number;
    total: number;
    onChange: (page: number) => void;
  };
  actions: (data: { id: string; invitationId?: string; isDraft: boolean }) => JSX.Element;
};

const EscrowsTable: React.FunctionComponent<Props> = ({
  data,
  pagination,
  isLoading = false,
  actions: TableActions,
}) => {
  const renderDepositClassName = (text: string) => {
    if (text === 'Awaiting Deposit') return '-unpaid';
    if (text === 'No Configuration') return '-no-config';
  };

  const columns = useMemo(
    () => [
      {
        title: () => <strong>Depositor</strong>,
        dataIndex: 'depositor',
      },
      {
        title: () => <strong>Beneficiary</strong>,
        dataIndex: 'beneficiary',
      },
      {
        title: () => <strong>Deposit Status</strong>,
        dataIndex: 'status',
        render: (text: string) =>
          text && (
            <span className={`active-homepage__content-status ${renderDepositClassName(text)}`}>
              {capitalize(text)}
            </span>
          ),
      },
      {
        title: () => <strong>Escrow Status</strong>,
        dataIndex: 'sync',
        render: (status: string) => {
          const statusType: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
            status && status in statusTypeEnum ? statusTypeEnum[status as keyof typeof statusTypeEnum] : undefined;

          if (!statusType) return null;

          return <LabelText text={statusType.text} bgColor={statusType.bgColor} />;
        },
      },
      {
        title: () => <strong>Action</strong>,
        dataIndex: 'action',
        render: ({ id, invitationId }: { id: string; invitationId: string }, row: Partial<IEscrowTableRow>) => (
          <TableActions id={id} isDraft={row.sync === 'draft'} invitationId={invitationId} />
        ),
      },
    ],
    [],
  );

  return (
    <Table
      scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
      onChange={(e) => pagination.onChange(e.current!)}
      className="mt-12"
      pagination={{
        current: pagination.current,
        pageSize: ESCROWS_LIMIT,
        total: pagination.total,
        hideOnSinglePage: true,
        position: ['bottomRight'],
        showSizeChanger: false,
      }}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      rowKey={(record) => record.action.id}
    />
  );
};

export default EscrowsTable;
