import { Row, Col, Skeleton } from 'antd';

import type { ICustodianItem } from './Item/CustodianItem';

import CustodianItem from './Item/CustodianItem';
import styles from './CustodianList.module.scss';
import itemStyles from './Item/CustodianItem.module.scss';

type Props = {
  items: ICustodianItem[];
  dropdownItemClick: (type: string, id: string) => void;
};

const CustodianListSkeleton = ({ items = 5 }) => {
  return (
    <Row gutter={[16, 16]} aria-label="Loading...">
      {Array.from({ length: items }).map((_, index) => (
        <Col span={6} key={index} className={styles.item}>
          <div className={itemStyles.item}>
            <Skeleton avatar paragraph={{ rows: 4 }} className={itemStyles['item-wrapper']} />
          </div>
        </Col>
      ))}
    </Row>
  );
};

const CustodianList = ({ items, dropdownItemClick }: Props) => {
  return (
    <Row gutter={[20, 20]}>
      {items.map((item) => {
        const handleClick = (type: string) => dropdownItemClick(type, item.id);

        return (
          <Col key={item.id} span={6} className={styles.item}>
            <CustodianItem data={item} dropdownItemClick={handleClick} />
          </Col>
        );
      })}
    </Row>
  );
};

CustodianList.Skeleton = CustodianListSkeleton;

export default CustodianList;
