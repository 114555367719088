import classNames from 'classnames';

import { IconFolderCode } from 'src/components/Svg/Icons';

import styles from './CustodianIcon.module.scss';

type Props = {
  className?: string;
};

const CustodianIcon = ({ className }: Props) => {
  const iconClasses = classNames(styles.figure, className);

  return (
    <div className={iconClasses}>
      <IconFolderCode className={styles['icon-code']} />
    </div>
  );
};

export default CustodianIcon;
