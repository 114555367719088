import Github from 'src/assets/images/migrations/logo_github.png';
import Bitbucket from 'src/assets/images/migrations/logo_bitbucket.png';
import Gitlab from 'src/assets/images/migrations/logo_gitlab.png';
import Azure from 'src/assets/images/migrations/logo_azure.png';
import Amazon from 'src/assets/images/migrations/logo_codecommit.png';
import Amazon2 from 'src/assets/images/migrations/logo_S3.png';
import Sharefile from 'src/assets/images/migrations/logo_sharefile.png';
import Google from 'src/assets/images/migrations/logo_GCP.png';
import { LegacyRepoMigrationStatusEnum } from 'src/graphql/schema';

export const methodLogoMap = {
  github: Github,
  github_server: Github,
  bitbucket: Bitbucket,
  bitbucket_server: Bitbucket,
  gitlab: Gitlab,
  gitlab_server: Gitlab,
  azure: Azure,
  azure_devops: Azure,
  codecommit: Amazon,
  aws: Amazon2,
  share_file_https: Sharefile,
  gcp: Google,
};

export const MIGRATION_STATUS_TEXT_MAP = {
  [LegacyRepoMigrationStatusEnum.Awaiting]: 'Awaiting Deposit',
  [LegacyRepoMigrationStatusEnum.Migrated]: 'Migrated',
};
