import { useState, useCallback } from 'react';

import {
  EscrowPartyTypeEnum,
  SuggestedChangeStatusEnum,
  ISuggestedChangesQuery,
  SuggestedChangeChangeTypeEnum,
  useSuggestedChangesQuery,
} from 'src/graphql/schema';

export interface ISuggestedChanges {
  creator: EscrowPartyTypeEnum;
  receiver: EscrowPartyTypeEnum;
  status: SuggestedChangeStatusEnum;
  items: ISuggestedChangesQuery['suggestedChanges']['nodes'];
}

export const useSuggestedChanges = ({
  escrowId,
  changeType,
  skip,
  onCompleted,
}: {
  escrowId: string;
  changeType: SuggestedChangeChangeTypeEnum;
  skip?: boolean;
  onCompleted?: (data: ISuggestedChangesQuery['suggestedChanges']) => void;
}) => {
  const [suggestedChanges, setSuggestedChanges] = useState<ISuggestedChanges | null>(null);

  const onCompletedHandler = useCallback(({ suggestedChanges }: ISuggestedChangesQuery) => {
    const availableChanges = suggestedChanges?.nodes.filter(
      ({ partyStatus }) =>
        partyStatus === SuggestedChangeStatusEnum.Pending || partyStatus === SuggestedChangeStatusEnum.Accepted,
    );

    if (onCompleted) onCompleted(suggestedChanges);

    if (availableChanges.length) {
      const status = availableChanges[0].partyStatus as SuggestedChangeStatusEnum;
      const creator = availableChanges[0].creatorType;
      const receiver = availableChanges[0].receiverType as EscrowPartyTypeEnum;

      setSuggestedChanges({
        status,
        creator,
        receiver,
        items: availableChanges,
      });
    } else {
      setSuggestedChanges(null);
    }
  }, []);

  const { loading, refetch } = useSuggestedChangesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      escrowId,
      changeType,
    },
    skip: skip ? !escrowId && skip : !escrowId,
    onCompleted: onCompletedHandler,
  });

  const refetchSuggestedChanges = useCallback(async () => {
    const { data } = await refetch({
      escrowId,
      changeType,
    });

    if (data) onCompletedHandler(data);
  }, []);

  return { suggestedChanges, loading, refetch: refetchSuggestedChanges };
};
