import React from 'react';
import { capitalize } from 'lodash';

import { TextBlock } from 'src/components/Misc';
import { CustomRadioWithIconGroup } from 'src/components/Form';

import { escrowNotificationsTypeOptions } from '../escrowNotifications.enum';
import styles from '../../Escrow.module.scss';

import type { EscrowPartyTypeEnum } from 'src/graphql/schema';

type Props = {
  partyType: EscrowPartyTypeEnum;
  selectedType?: string;
  onChange: (value: string) => void;
  textBlock: {
    as?: 'h2' | 'h4';
    titleColor: 'light-blue' | 'dark-blue';
  };
};

const DepositNotificationsTypeSelect: React.FunctionComponent<Props> = ({
  onChange,
  selectedType,
  textBlock,
  partyType,
}) => {
  return (
    <div className={`${styles['block-types']} ${styles['mb--40']}`}>
      <TextBlock
        className={styles['mb--20']}
        as={textBlock.as}
        title={`${capitalize(partyType)} Notification Type`}
        text={`What confirmations should be sent to the ${partyType} when deposits are made?`}
        titleColor={textBlock.titleColor}
        segment={!selectedType && <i className={styles.segment}>Required</i>}
      />
      <div className={styles['container--752']}>
        <CustomRadioWithIconGroup
          selectedValue={selectedType}
          col={3}
          onChange={onChange}
          options={escrowNotificationsTypeOptions(partyType)}
        />
      </div>
    </div>
  );
};

export default DepositNotificationsTypeSelect;
