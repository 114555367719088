import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconBipartite = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M40 0H18.694L13.32 5.374v.157H5.374L0 10.905V40h26.68v-5.53H40V0ZM19.471 2.537v3.614h-3.613l3.613-3.614ZM6.151 8.068v3.614H2.537l3.614-3.614Zm18.185 29.588H2.344v-23.63h6.15V7.875h4.826v8.494H5.716v2.344h7.604v2.344H5.716v2.344h7.604v2.343H5.716v2.344h7.604v6.381h11.016v3.187Zm13.32-5.53H15.664V8.494h6.151V2.344h15.841v29.781Z"
      />
      <path
        fill="currentColor"
        d="M19.037 10.839h15.247v2.344H19.037v-2.344ZM19.037 15.526h15.247v2.344H19.037v-2.344ZM19.037 20.214h15.247v2.344H19.037v-2.344Z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconBipartite);
export default Memo;
