/* eslint-disable react/display-name */
import { Row, Col, Skeleton } from 'antd';

import BackupItem, { IBackupItem } from './Item/BackupItem';
import styles from './BackupList.module.scss';
import itemStyles from './Item/BackupItem.module.scss';

type Props = {
  items: IBackupItem[];
  dropdownItemClick: (type: string, id: string) => void;
};

const BackupList = ({ items, dropdownItemClick }: Props) => {
  return (
    <Row gutter={[20, 20]}>
      {items.map((item) => {
        const handleClick = (type: string) => dropdownItemClick(type, item.id);

        return (
          <Col key={item.id} span={6} className={styles.item} data-testid="backup-item">
            <BackupItem data={item} dropdownItemClick={handleClick} />
          </Col>
        );
      })}
    </Row>
  );
};

BackupList.Skeleton = function ({ items = 5 }) {
  return (
    <Row gutter={[20, 20]} aria-label="Loading...">
      {Array.from({ length: items }).map((_, index) => (
        <Col span={6} key={index} className={styles.item}>
          <div className={itemStyles.item}>
            <Skeleton avatar paragraph={{ rows: 4 }} className={itemStyles['item-wrapper']} />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default BackupList;
