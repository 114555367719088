import OrganizationDetailsInfoRow from './OrganizationDetailsInfoRow';

export interface IOrganizationInfo {
  companyName: string;
  street: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  companyWebsite: string;
  companyRegistration: string;
}

type Props = {
  company: IOrganizationInfo;
};

const OrganizationDetailsInfo: React.FunctionComponent<Props> = ({ company }) => {
  const { companyName, street, streetNumber, city, postalCode, region, country, companyWebsite, companyRegistration } =
    company;
  const fullAddress =
    `${street} ` + [streetNumber, city, postalCode, region, country].filter((value) => value).join(',\n');

  return (
    <div>
      <OrganizationDetailsInfoRow label="Name" value={<strong>{companyName}</strong>} />
      <OrganizationDetailsInfoRow label="Address" value={fullAddress} />
      {companyWebsite && <OrganizationDetailsInfoRow label="Website" value={companyWebsite} />}
      {companyRegistration && <OrganizationDetailsInfoRow label="Reg. Number" value={companyRegistration} />}
    </div>
  );
};

export default OrganizationDetailsInfo;
