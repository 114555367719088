import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconLockChecked = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        d="M256.096 61.438c-23.293 0-39.935 17.781-39.935 37.009v37.008h79.87V98.447c0-19.227-16.642-37.008-39.935-37.008zM154.723 98.447C154.723 42.865 201.347 0 256.096 0s101.373 42.864 101.373 98.447v45.868h59.806c32.57 0 53.759 28.211 53.759 56.239v255.205c0 28.029-21.188 56.24-53.759 56.24H94.725c-32.571 0-53.758-28.212-53.758-56.24V200.554c0-28.029 21.188-56.239 53.758-56.239h59.998zm-52.318 107.307v244.809h307.19V205.754zm235.1 110.378c12.846-11.082 14.275-30.481 3.193-43.327s-30.481-14.275-43.327-3.192l-61.851 53.361-20.892-18.024c-12.846-11.082-32.244-9.653-43.326 3.192s-9.653 32.244 3.192 43.326l40.959 35.335c11.529 9.949 28.605 9.949 40.133 0z"
      />
    </svg>
  );
};
const Memo = memo(SvgIconLockChecked);
export default Memo;
