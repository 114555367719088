import { Button } from 'antd';
import { Fragment } from 'react';

import { AddDepositContainer } from 'src/containers/Deposits';
import { useAddDeposit } from 'src/context/deposit';

const MakeDeposit = () => {
  const {
    ui: { setStep },
  } = useAddDeposit();

  const onClick = () => setStep('select-escrow');

  return (
    <Fragment>
      <Button data-testid="make-deposit" type="primary" onClick={onClick}>
        Make Deposit
      </Button>

      <AddDepositContainer />
    </Fragment>
  );
};

export default MakeDeposit;
