import { EXCLUSIVE_OFFER } from 'src/shared/constants';

import styles from '../CancelPlanOffer.module.scss';
import IconPlanOffer from '../Icon/IconPlanOffer';

import OtherOfferCard from './OtherOfferCard';

const features = ['Automated backup process', 'Unified backup management', 'Unlimited storage space'];

const OtherOfferContent = () => {
  return (
    <div className={`d-flex mb-5 align-items-start ${styles['offer-block']}`}>
      <div className={styles['info-col']}>
        <IconPlanOffer className="mb-4" />
        <h1>Looking to lower costs? Scale down to Software Backup for {EXCLUSIVE_OFFER}% off</h1>
        <p>
          Your plan does not have to stop. Access and manage all your backups in one centralized location.
          <br />
          <br /> Need something different for your unique enterprise needs?{' '}
          <a href="https://codekeeper.co/solutions.html" target="_blank" rel="noreferrer">
            Explore other plans
          </a>
        </p>
      </div>

      <OtherOfferCard
        title="Software Backup"
        description="Effortless, automated backups for all your software source code repositories and databases across major
        developer platforms."
        features={features}
        className={styles['offer-col']}
      />
    </div>
  );
};

export default OtherOfferContent;
