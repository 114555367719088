import { SaasEnvironmentsContainer, CreateNewEnvironmentContainer } from 'src/containers/Saas/Environments';
import { Main } from 'src/components/Layout';

const SaasEnvironments = () => {
  return (
    <Main title="SaaS Escrow" headerActions={<CreateNewEnvironmentContainer />}>
      <SaasEnvironmentsContainer />
    </Main>
  );
};

export default SaasEnvironments;
