import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconMultiFolderCheck = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.893 3h6.476l2.054 2.12H30.62v14.487h-9.893V29.5H2V12.893h6.476l2.053 2.12h1.364V3Zm1.767 2.12h4.303l-.342-.353H13.66v.353Zm0 9.893h15.193V6.887H13.66v8.126Zm15.193 1.767h-8.126v1.06h8.126v-1.06Zm-8.422-2.852 4.195-4.425-1.282-1.215-3.051 3.218-1.265-1.062-1.136 1.354 2.539 2.13Zm-9.894 9.893 4.195-4.424-1.282-1.216L10.4 21.4l-1.265-1.061-1.136 1.353 2.538 2.13ZM8.07 15.013l-.342-.353H3.767v.353H8.07Zm-4.303 11.66v1.06H18.96v-1.06H3.767Zm0-1.766V16.78H18.96v8.127H3.767Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconMultiFolderCheck);
export default Memo;
