import dayjs from 'dayjs';
import { Fragment } from 'react';

import styles from './InvoicesLayout.module.scss';

type Props = {
  date?: dayjs.ConfigType;
};

const InvoicesLayout = ({ date, children }: React.PropsWithChildren<Props>) => {
  return (
    <Fragment>
      <div className={`d-flex justify-content-between ${styles.row}`}>
        <span className={styles['row-label']}>Next billing date</span>
        {date && <strong className={styles['row-value']}>{dayjs(date).format('MMMM DD, YYYY')}</strong>}
      </div>

      {children}
    </Fragment>
  );
};

export default InvoicesLayout;
