import { Suspense } from 'react';

import { Loader } from 'src/components/Loader';

export const withSuspense =
  // eslint-disable-next-line react/display-name
  (Component: React.ElementType, fallback?: React.ElementType) => (props?: Record<string, unknown>) => {
    const FallbackComponent = fallback || Loader;

    return (
      <Suspense fallback={<FallbackComponent />}>
        <Component {...props} />
      </Suspense>
    );
  };
