import { Fragment } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import styles from './LostBenefit.module.scss';

type Props = {
  title: string;
  text: string;
};

const LostBenefitItem = ({ title, text }: Props) => {
  return (
    <Fragment>
      <CloseOutlined className={styles['item-icon']} />
      <h4 className={styles['item-title']}>{title}</h4>
      <p className={styles['item-text']}>{text}</p>
    </Fragment>
  );
};

export default LostBenefitItem;
