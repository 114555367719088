import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoZendesk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 195"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#03363d"
      d="M118.25 51.233v142.773H0zm0-51.233c0 32.644-26.481 59.125-59.125 59.125S0 32.645 0 0zm19.5 194.006c0-32.677 26.449-59.125 59.125-59.125S256 161.361 256 194.006zm0-51.266V0H256z"
    />
  </svg>
);
const Memo = memo(SvgLogoZendesk);
export default Memo;
