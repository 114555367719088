import { EscrowStatusFilterEnum } from 'src/graphql/schema';

import type { IEscrowsCountQuery } from 'src/graphql/schema';

export const getEscrowCount = (key: EscrowStatusFilterEnum, count: IEscrowsCountQuery) => {
  switch (key) {
    case EscrowStatusFilterEnum.All:
      return count.allEscrows.nodesCount;
    case EscrowStatusFilterEnum.DraftAndOnboarding:
      return count.onboardingEscrows.nodesCount;
    case EscrowStatusFilterEnum.Signed:
      return count.signedEscrows.nodesCount;
    case EscrowStatusFilterEnum.ChangesSuggested:
      return count.changesEscrows.nodesCount;
    case EscrowStatusFilterEnum.Error:
      return count.errorEscrows.nodesCount;
    case EscrowStatusFilterEnum.TerminationRequested:
      return count.terminatedEscrows.nodesCount;
    case EscrowStatusFilterEnum.Invited:
      return count.invited.nodesCount;
    default:
      return 0;
  }
};
