import { Fragment } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';

export type IForgotPasswordForm = {
  email: string;
};

type Props = {
  onFinish: (data: IForgotPasswordForm) => Promise<void>;
  isLoading?: boolean;
};

const ForgotPasswordForm: React.FunctionComponent<Props> = ({ onFinish, isLoading = false }) => {
  const [requestCodeform] = Form.useForm();

  return (
    <Fragment>
      <p className="contents">
        Enter the email address associated with your account, and we&lsquo;ll send you a link to reset your password.
      </p>

      <Form layout="vertical" form={requestCodeform} onFinish={onFinish}>
        <Form.Item
          name="email"
          label="Email address"
          rules={[
            { required: true, message: 'Please input your email address!' },
            { type: 'email', message: 'Email address is invalid' },
          ]}
        >
          <Input placeholder="example@email.com" type="email" disabled={isLoading} />
        </Form.Item>

        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spin />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Button type="primary" className="invite-login-screen__btn mt-0" htmlType="submit">
              Send confirmation code
            </Button>
          </div>
        )}
      </Form>

      <p>
        <Link className="invite-login-screen__account invite-login-screen__forgot2" to="/login">
          Return to sign in
        </Link>
      </p>
    </Fragment>
  );
};

export default ForgotPasswordForm;
