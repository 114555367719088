import { Modal, Progress, Button } from 'antd';

import { ImageUploadAsset } from 'src/components/Svg/Images';

import styles from './DepositAssetProgressModal.module.scss';

type Props = {
  isOpen: boolean;
  onDiscard: () => void;
  total?: number;
  currentCount?: number;
  percent?: number;
};

const DepositAssetUploadProgress = ({ isOpen, onDiscard, percent = 0, currentCount = 0, total = 0 }: Props) => {
  return (
    <Modal
      className="ck-deposit-progress__modal"
      open={isOpen}
      centered
      title="Deposit in progress"
      width={550}
      footer={null}
      onCancel={onDiscard}
      maskClosable={false}
    >
      <p>Upload might take a while to complete.</p>
      <div className={styles.wrapper}>
        <ImageUploadAsset className={styles.icon} />
        <Progress trailColor="#E8E8E8" strokeColor="#45B4C8" percent={percent} showInfo={false} />
        <div className={styles['progress-text']}>
          Uploading{' '}
          <span>
            {currentCount}/{total}
          </span>
        </div>
      </div>
      <div className={styles.footer}>
        <Button onClick={onDiscard}>Discard</Button>
      </div>
    </Modal>
  );
};

export default DepositAssetUploadProgress;
