import { QuestionCircleFilled } from '@ant-design/icons';
import { Dropdown } from 'antd';

import HelpMenuItems from './HelpMenuItems';

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="nav">
      <Dropdown menu={{ items: HelpMenuItems() }} placement="bottomRight" arrow trigger={['click']}>
        <QuestionCircleFilled className="cursor-pointer nav__icon" />
      </Dropdown>

      {children}
    </div>
  );
};

export default Header;
