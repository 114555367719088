import { Fragment, useState } from 'react';

import { Uploader } from 'src/components/Deposit/Uploader';
import { useAddDeposit } from 'src/context/deposit';

const SecureUploadContainer = () => {
  const [isUploading, setIsUploading] = useState(false);
  const {
    ui: { selectedEscrowId, setInitialData, getBack },
    refetch,
  } = useAddDeposit();

  const handleReset = () => {
    setInitialData();
    refetch?.();
  };

  return (
    <Fragment>
      <h2 className="text-light-blue mb-5">Secure Upload</h2>

      <Uploader
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        escrowId={selectedEscrowId}
        resetModal={handleReset}
        back={getBack}
      />
    </Fragment>
  );
};

export default SecureUploadContainer;
