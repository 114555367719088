import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconFolderRemove = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 4h8.488l2.296 2.616H29v20.598H3V4Zm10.076 4.297-.041-.046h-8.39V22.31h22.71V8.25H13.13l-.054.047ZM27.354 25.58v-1.634H4.646v1.634h22.708ZM10.74 5.636l.86.98H4.646v-.98h6.094ZM16 13.797l2.38-2.365 1.164 1.156-2.38 2.365 2.38 2.365-1.164 1.156L16 16.109l-2.38 2.365-1.164-1.156 2.38-2.365-2.38-2.364 1.164-1.157L16 13.797Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconFolderRemove);
export default Memo;
