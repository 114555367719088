import { Button, Form, Input } from 'antd';

export type ITwoFAForm = {
  code: string;
};

type Props = {
  onSubmit: (values: ITwoFAForm) => void;
  isLoading?: boolean;
};

const TwoFAForm = ({ onSubmit, isLoading }: Props) => {
  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item name="code" rules={[{ required: true, message: 'Please add authenticator code' }]}>
        <Input placeholder="Authenticator code" type="number" size="large" className="ck-input--no-controls" />
      </Form.Item>

      <div className="text-center">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default TwoFAForm;
