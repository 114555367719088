export const storageRegionOptions = [
  {
    value: 'default',
    label: 'Default (Multi-Region)',
  },
  {
    value: 'us-east-1',
    label: 'US East (N. Virginia)',
  },
  {
    value: 'us-east-2',
    label: 'US East (Ohio)',
  },
  {
    value: 'us-west-1',
    label: 'US West (N. California)',
  },
  {
    value: 'us-west-2',
    label: 'US West (Oregon)',
  },
  {
    value: 'af-south-1',
    label: 'Africa (Cape Town)',
  },
  {
    value: 'ap-east-1',
    label: 'Asia Pacific (Hong Kong)',
  },
  {
    value: 'ap-south-1',
    label: 'Asia Pacific (Mumbai)',
  },
  {
    value: 'ap-northeast-3',
    label: 'Asia Pacific (Osaka-Local)',
  },
  {
    value: 'ap-northeast-2',
    label: 'Asia Pacific (Seoul)',
  },
  {
    value: 'ap-southeast-1',
    label: 'Asia Pacific (Singapore)',
  },
  {
    value: 'ap-southeast-2',
    label: 'Asia Pacific (Sydney)',
  },
  {
    value: 'ap-northeast-1',
    label: 'Asia Pacific (Tokyo)',
  },
  {
    value: 'ca-central-1',
    label: 'Canada (Central)',
  },
  {
    value: 'cn-north-1',
    label: 'China (Beijing)',
  },
  {
    value: 'cn-northwest-1',
    label: 'China (Ningxia)',
  },
  {
    value: 'eu-central-1',
    label: 'Europe (Frankfurt)',
  },
  {
    value: 'eu-central-2',
    label: 'Europe (Zurich)',
  },
  {
    value: 'eu-west-1',
    label: 'Europe (Ireland)',
  },
  {
    value: 'eu-west-2',
    label: 'Europe (London)',
  },
  {
    value: 'eu-south-1',
    label: 'Europe (Milan)',
  },
  {
    value: 'eu-west-3',
    label: 'Europe (Paris)',
  },
  {
    value: 'eu-north-1',
    label: 'Europe (Stockholm)',
  },
  {
    value: 'sa-east-1',
    label: 'South America (São Paulo)',
  },
  {
    value: 'me-south-1',
    label: 'Middle East (Bahrain)',
  },
];

export const storageRegionDictionary: { [key: string]: string } = storageRegionOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item.label }),
  {},
);
