import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgImageEscrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 28"
    role="img"
    className={props.className}
    {...props}
  >
    <path
      fill="#45B4C8"
      d="M.39 15.952V2.453C.39 1.313 1.316.39 2.456.39h19.097c1.14 0 2.064.924 2.064 2.064v13.499a7.742 7.742 0 0 1-3.647 6.57l-6.874 4.283a2.064 2.064 0 0 1-2.183 0l-6.874-4.282a7.742 7.742 0 0 1-3.647-6.571"
    />
    <path
      fill="#22A5BB"
      fillRule="evenodd"
      d="M12 27.116V.386h9.55c1.14 0 2.065.925 2.065 2.065V15.95a7.742 7.742 0 0 1-3.648 6.57l-6.873 4.283a2.063 2.063 0 0 1-1.094.313"
      clipRule="evenodd"
    />
    <path
      fill="#45B4C8"
      fillRule="evenodd"
      d="M2.452.77C1.525.77.774 1.517.774 2.438v13.426a7.306 7.306 0 0 0 3.466 6.209l6.873 4.26a1.685 1.685 0 0 0 1.774 0l6.873-4.26a7.306 7.306 0 0 0 3.466-6.209V2.438c0-.921-.751-1.668-1.678-1.668zM0 2.438A2.445 2.445 0 0 1 2.452 0h19.096A2.445 2.445 0 0 1 24 2.438v13.426a8.075 8.075 0 0 1-3.83 6.863l-6.874 4.259c-.793.492-1.799.492-2.592 0l-6.874-4.26A8.075 8.075 0 0 1 0 15.865z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.848 4.904H7.877a1.42 1.42 0 0 0-1.42 1.42v11.359c0 .784.636 1.42 1.42 1.42h8.519a1.42 1.42 0 0 0 1.42-1.42V9.926a.768.768 0 0 0 0-.105v-.095a1.42 1.42 0 0 0-.378-.965l-3.15-3.402a1.42 1.42 0 0 0-1.042-.455zm-.71 1.42H7.877v11.359h8.519v-7.1h-2.838a1.42 1.42 0 0 1-1.42-1.42zm3.737 2.84h-2.317V6.66zm-1.817 3.05a.71.71 0 1 0-1.004 1.004l.918.918-.918.917a.71.71 0 0 0 1.004 1.004l1.42-1.42a.71.71 0 0 0 0-1.003zm-2.836 0a.71.71 0 0 1 0 1.004l-.918.918.918.917a.71.71 0 0 1-1.004 1.004l-1.42-1.42a.71.71 0 0 1 0-1.003l1.42-1.42a.71.71 0 0 1 1.004 0"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgImageEscrow);
export default Memo;
