import { useMemo } from 'react';
import { Table, ConfigProvider, Button } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { LabelText } from 'src/components/Label';
import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';
import { SAAS_ESCROWS_LIMIT } from 'src/shared/constants';

import { labelStatusTypeEnum } from '../';

import type { TableColumnsType } from 'antd';

import styles from './SaasEscrowsTable.module.scss';

export interface ISaasEscrowTableRow {
  name: string;
  providers: number;
  status: SaasEnvironmentStatusEnum | 'terminated-requested';
  date?: Date;
  action: {
    id: string;
  };
}

type Props = {
  data: ISaasEscrowTableRow[];
  pagination: {
    current: number;
    total: number;
    onChange: (page: number) => void;
  };
  isLoading?: boolean;
  onRemove: (id: string) => void;
};

const SaasEscrowsTable = ({ data, pagination, isLoading = false, onRemove }: Props) => {
  const columns = useMemo(
    (): TableColumnsType<ISaasEscrowTableRow> => [
      {
        title: 'SaaS Escrow Name',
        dataIndex: 'name',
      },
      {
        title: 'Providers',
        dataIndex: 'providers',
      },
      {
        title: 'SaaS Escrow Status',
        dataIndex: 'status',
        render: (status: SaasEnvironmentStatusEnum) => {
          const label: (typeof labelStatusTypeEnum)[keyof typeof labelStatusTypeEnum] =
            labelStatusTypeEnum[status as keyof typeof labelStatusTypeEnum] || null;

          if (!label) return null;

          return <LabelText text={label.text} bgColor={label.bgColor} />;
        },
      },
      {
        title: 'Activation Date',
        dataIndex: 'date',
        render: (date?: Date) => (date ? dayjs(date).format('MM/DD/YYYY') : '-'),
      },
      {
        title: ' ',
        dataIndex: 'action',
        render: ({ id }: { id: string }, { status }: { status: ISaasEscrowTableRow['status'] }) => {
          const handleRemove = () => onRemove(id);

          return (
            <div className="d-flex">
              {status === SaasEnvironmentStatusEnum.Draft && (
                <div className={styles['btn-item']}>
                  <Button type="text" className={`${styles.btn} ${styles['btn-remove']}`} onClick={handleRemove}>
                    <CloseOutlined />
                  </Button>
                </div>
              )}

              <div className={styles['btn-item']}>
                <Link to={`/saas-escrows/${id}/providers`}>
                  <Button type="text" className={`${styles.btn} ${styles['btn-next']}`}>
                    <RightOutlined />
                  </Button>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );
  return (
    <div className={styles.container}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerSplitColor: '#fff',
              headerColor: '#1A5261',
              borderColor: 'transparent',
              cellPaddingBlock: 15,
              cellPaddingInline: 12,
              rowHoverBg: 'transparent',
            },
          },
        }}
      >
        <Table
          className="ck-saas-escrows__table"
          scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
          onChange={(e) => pagination.onChange(e.current!)}
          pagination={{
            current: pagination.current,
            pageSize: SAAS_ESCROWS_LIMIT,
            total: pagination.total,
            hideOnSinglePage: true,
            position: ['bottomRight'],
            showSizeChanger: false,
          }}
          rowHoverable={false}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={({ action }) => action.id}
        />
      </ConfigProvider>
    </div>
  );
};

export default SaasEscrowsTable;
