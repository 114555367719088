import { Fragment } from 'react';

import Bitbucket1 from 'src/assets/images/integrations/bitbucket1.svg';
import Bitbucket2 from 'src/assets/images/integrations/bitbucket2.svg';
import Bitbucket4 from 'src/assets/images/integrations/bitbucket4.svg';
import Bitbucket5 from 'src/assets/images/integrations/bitbucket5.svg';
import Amazon3 from 'src/assets/images/integrations/amazon3.svg';

import Step from './Step';

export function BitbucketServerInfoContent() {
  const steps = [
    {
      step: 1,
      text: (
        <Fragment>
          In your Bitbucket account, go to
          <strong className="break-spaces">
            {'\n'}Settings -&gt; Personal Settings -&gt; App Passwords -&gt; Select Create app password.
          </strong>
        </Fragment>
      ),
      image: Bitbucket1,
    },
    {
      step: 2,
      text: 'Create a name for your App password in the label box.',
      image: Bitbucket2,
    },
    {
      step: 3,
      text: (
        <Fragment>
          Set the permissions for the app password. Tick the boxes for:{' '}
          <strong>Account (email), Workspace membership (read), Projects (read) and Repositories (read)</strong>
        </Fragment>
      ),
      image: Amazon3,
    },
    {
      step: 4,
      text: (
        <Fragment>
          Click on <strong>Create</strong>. The page will display the New app password dialog.
        </Fragment>
      ),
      image: Bitbucket4,
    },
    {
      step: 5,
      text: 'Copy your app password and enter it in the Codekeeper app using the button below.',
      image: Bitbucket5,
    },
  ];

  return steps.map(({ step, text, image }, index) => (
    <Step key={index} stepNumber={step} icon={image}>
      {text}
    </Step>
  ));
}
