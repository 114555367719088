import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

export type ILoginForm = {
  email: string;
  password: string;
};

type Props = {
  onFinish: (data: ILoginForm) => Promise<void>;
  isLoading?: boolean;
};

const LoginForm: React.FunctionComponent<Props> = ({ onFinish, isLoading = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name="email"
        label="Email address"
        rules={[
          { required: true, message: 'Please input your email address!' },
          { type: 'email', message: 'Email address is invalid' },
        ]}
      >
        <Input placeholder="example@email.com" type="email" disabled={isLoading} />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please input your new password' }]}
      >
        <Input.Password
          placeholder="Your password"
          disabled={isLoading}
          iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>

      <div className="d-flex flex-column">
        <Button type="primary" className="invite-login-screen__signin-btn" htmlType="submit" loading={isLoading}>
          Sign In
        </Button>

        <Button type="link" className="invite-login-screen__reset-btn" onClick={() => navigate('/forgot-password')}>
          Reset Password
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
