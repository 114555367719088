import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgLogoAlibaba = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    className={props.className}
    {...props}
  >
    <g fill="#ed6b1e">
      <path d="M15.21 11.248H8.805v1.44h6.406z" />
      <path d="M18.405 6h-4.233l1.022 1.456 3.1.944c.574.176.942.72.926 1.28v4.64a1.34 1.34 0 0 1-.927 1.28l-3.083.944L14.172 18h4.233a3.196 3.196 0 0 0 3.195-3.2V9.2c.016-1.76-1.422-3.2-3.195-3.2M5.595 6h4.232L8.805 7.456 5.722 8.4c-.575.176-.942.72-.926 1.28v4.64c0 .576.367 1.104.926 1.28l3.083.944L9.827 18H5.595A3.207 3.207 0 0 1 2.4 14.8V9.2C2.4 7.44 3.838 6 5.595 6" />
    </g>
  </svg>
);
const Memo = memo(SvgLogoAlibaba);
export default Memo;
