import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

import type { IOauthAssetCreateInput, ICustomAssetCreateInput } from 'src/graphql/schema';

enum ActionEnum {
  SET_STEP = 'set-step',
  SET_INITIAL_DATA = 'set-initial-data',
  SELECT_METHOD = 'select-method',
  SELECT_ESCROW = 'select-escrow',
  GET_BACK = 'get-back',
  SET_OAUTH_DATA = 'set-oauth-data',
  SET_CUSTOM_DATA = 'set-custom-data',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA>
  | IAction<
      ActionEnum.SET_STEP,
      {
        step: (typeof steps)[number];
        integration?: IState['integration'];
      }
    >
  | IAction<
      ActionEnum.SELECT_METHOD,
      { method: (typeof methods)[number]; integration?: { id: string; platform: string; name: string } }
    >
  | IAction<ActionEnum.SELECT_ESCROW, { escrowId: string }>
  | IAction<ActionEnum.GET_BACK>
  | IAction<ActionEnum.SET_OAUTH_DATA, { data: IOauthAssetCreateInput[] }>
  | IAction<ActionEnum.SET_CUSTOM_DATA, { data: ICustomAssetCreateInput[] }>;

const steps = [
  'select-escrow',
  'select-method',
  'oauth',
  'custom',
  'secure',
  'success',
  'oauth-confirm',
  'custom-confirm',
] as const;
const methods = ['custom', 'oauth', 'secure'] as const;

type IState = {
  step: (typeof steps)[number] | null;
  method: (typeof methods)[number] | null;
  integration: { id: string; platform: string; name: string } | null;
  selectedEscrowId: string;
  history: (typeof steps)[number][];
  oauthData: IOauthAssetCreateInput[];
  customData: ICustomAssetCreateInput[];
};

const initialState: IState = {
  method: null,
  integration: null,
  selectedEscrowId: '',
  step: null,
  history: [],
  oauthData: [],
  customData: [],
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_STEP: {
      const integration = action.payload.integration;

      return {
        ...state,
        step: action.payload.step,
        history: [...state.history, action.payload.step],
        ...(integration && { integration }),
      };
    }
    case ActionEnum.SET_INITIAL_DATA:
      return initialState;
    case ActionEnum.SELECT_METHOD: {
      return {
        ...state,
        method: action.payload.method,
        integration: action.payload.integration || null,
      };
    }
    case ActionEnum.SELECT_ESCROW: {
      return {
        ...state,
        step: 'select-method',
        selectedEscrowId: action.payload.escrowId,
        history: [...state.history, 'select-method'],
      };
    }
    case ActionEnum.GET_BACK: {
      const history = [...state.history];

      history.pop();

      return {
        ...state,
        oauthData: state.step !== 'oauth-confirm' ? [] : state.oauthData,
        customData: state.step !== 'custom-confirm' ? [] : state.customData,
        step: history[history.length - 1] ?? null,
        history,
      };
    }
    case ActionEnum.SET_OAUTH_DATA:
      return {
        ...state,
        step: 'oauth-confirm',
        oauthData: action.payload.data,
        history: [...state.history, 'oauth'],
      };
    case ActionEnum.SET_CUSTOM_DATA:
      return {
        ...state,
        step: 'custom-confirm',
        customData: action.payload.data,
        history: [...state.history, 'custom'],
      };
    default:
      return state;
  }
};

export const useAddDepositSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setStep = (step: (typeof steps)[number], integration?: IState['integration']) =>
    dispatch({ type: ActionEnum.SET_STEP, payload: { step, integration } });

  const setInitialData = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });

  const selectMethod = (
    method: (typeof methods)[number],
    integration?: { id: string; platform: string; name: string },
  ) => {
    dispatch({ type: ActionEnum.SELECT_METHOD, payload: { method, integration } });
  };

  const selectEscrow = (escrowId: string) => dispatch({ type: ActionEnum.SELECT_ESCROW, payload: { escrowId } });

  const getBack = () => dispatch({ type: ActionEnum.GET_BACK, payload: {} });

  const setOauthData = (data: IOauthAssetCreateInput[]) =>
    dispatch({ type: ActionEnum.SET_OAUTH_DATA, payload: { data } });

  const setCustomData = (data: ICustomAssetCreateInput[]) =>
    dispatch({ type: ActionEnum.SET_CUSTOM_DATA, payload: { data } });

  return {
    ...state,
    setStep,
    setInitialData,
    selectMethod,
    selectEscrow,
    getBack,
    setOauthData,
    setCustomData,
  };
};
