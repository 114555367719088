import { Carousel, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

import { useHomeActions } from 'src/context/home';

import { homeTabs } from '../home.enum';
import { getEscrowCount } from '../lib/getEscrowCount';

import type { CarouselRef } from 'antd/es/carousel';

import HomeCarouselItem from './HomeCarouselItem';

const NextArrow = (props: unknown) => {
  const { className, onClick } = props as { className: string; onClick: () => void };

  return (
    <div className={className}>
      <Button onClick={onClick} type="primary" shape="circle" disabled={className.includes('slick-disabled')}>
        <RightOutlined />
      </Button>
    </div>
  );
};

const PrevArrow = (props: unknown) => {
  const { className, onClick } = props as { className: string; onClick: () => void };

  return (
    <div className={className}>
      <Button onClick={onClick} type="primary" shape="circle" disabled={className.includes('slick-disabled')}>
        <LeftOutlined />
      </Button>
    </div>
  );
};

const HomeCarousel = () => {
  const { countLoading: loading, count, handleTabSelection, statusFilter } = useHomeActions();
  const sliderRef = useRef<CarouselRef>(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (window.innerWidth >= 1600) {
        sliderRef?.current?.goTo(0);
      }
    }, 300);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="ck-home-carousel">
      <Carousel
        ref={sliderRef}
        slidesToShow={7}
        slidesToScroll={1}
        dots={false}
        infinite={false}
        initialSlide={0}
        responsive={[
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: true,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: true,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              arrows: true,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              arrows: true,
              initialSlide: 0,
            },
          },
        ]}
        // Hide arrows if there are 7 tabs for bigger screens
        arrows={homeTabs.length !== 7}
        swipe={false}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        {homeTabs.map((item) => (
          <HomeCarouselItem
            key={item.key}
            id={item.key}
            icon={item.icon}
            name={item.name}
            count={loading ? 0 : getEscrowCount(item.key, count)}
            isNew={!loading && item.key === 'invited' && getEscrowCount(item.key, count) > 0}
            isActive={statusFilter === item.key}
            isLoading={loading}
            onClick={handleTabSelection}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
