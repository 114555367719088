import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { EscrowContextProvider } from 'src/context/escrow';
import { EscrowMenu } from 'src/components/Escrow/Menu';
import { EscrowStatusIndicator, EscrowStatusIndicatorEnum } from 'src/components/Escrow/StatusIndicator';
import { escrowMenuItems } from 'src/components/Escrow/Menu/escrowMenu.enum';
import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { JourneyModalLayout, JourneyModalHeader } from 'src/components/Journey/ModalLayout';
import { withSuspense } from 'src/hoc';
import { EscrowLayoutSkeleton } from 'src/components/Escrow/Layout';

import EscrowNavigationContainer from './Layout/EscrowNavigationContainer';
import EscrowModalLayoutContainer from './Layout/EscrowModalLayoutContainer';

const EscrowModalContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClose = () => navigate('/escrows');

  if (location.pathname === '/escrows/new') {
    return (
      <JourneyModalLayout
        header={<JourneyModalHeader title="New Escrow" onClose={onClose} />}
        statusIndicator={<EscrowStatusIndicator currentStatus={EscrowStatusIndicatorEnum.CONFIG} />}
        menu={
          <EscrowMenu
            basePath="escrows"
            items={escrowMenuItems.map((item, index) => (index === 0 ? { ...item, path: '' } : item))}
            escrowId="new"
          />
        }
      >
        <Outlet />
        <JourneyNavigation isNextDisabled isPrevHidden />
      </JourneyModalLayout>
    );
  }

  return (
    <EscrowContextProvider>
      <EscrowModalLayoutContainer>
        <Outlet />
        <EscrowNavigationContainer />
      </EscrowModalLayoutContainer>
    </EscrowContextProvider>
  );
};

export default withSuspense(EscrowModalContainer, EscrowLayoutSkeleton);
