import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import styles from '../MiscModal.module.scss';

export type Props = {
  isOpen: boolean;
  title: string;
  text: string;
  proceedText?: string;
  onProceed: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  proceedButtonProps?: ButtonProps;
  width?: number;
  cancelText?: string;
};

const ModalProceed = ({
  isOpen,
  isLoading,
  title,
  text,
  proceedText = 'Confirm',
  onCancel,
  onProceed,
  proceedButtonProps,
  children,
  width,
  cancelText,
}: React.PropsWithChildren<Props>) => {
  return (
    <Modal
      className="ck-modal__proceed"
      open={isOpen}
      centered
      title={title}
      okText={proceedText}
      cancelText={cancelText}
      onCancel={onCancel}
      onOk={onProceed}
      width={width || 475}
      confirmLoading={isLoading}
      okButtonProps={proceedButtonProps}
    >
      <p dangerouslySetInnerHTML={{ __html: text }} className={styles['proceed-text']} />
      {children}
    </Modal>
  );
};

export default ModalProceed;
