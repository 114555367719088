import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';

type Props = {
  header: React.ElementType;
  sidebar: React.ElementType;
};

const Layout: React.FunctionComponent<Props> = ({ sidebar: Sidebar, header: Header }) => {
  return (
    <div className="d-flex h-100vh">
      <Sidebar />

      <div className={styles.container}>
        <Header />

        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
