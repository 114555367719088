export const escrowTabs = [
  {
    key: 'all',
    name: 'All',
  },
  {
    key: 'active',
    name: 'Active',
  },
  {
    key: 'draft',
    name: 'Draft',
  },
  {
    key: 'admin_review',
    name: 'Codekeeper review',
  },
  {
    key: 'onboarding',
    name: 'Review',
  },
  {
    key: 'signed',
    name: 'Signed',
  },
  {
    key: 'termination_requested',
    name: 'Terminated',
  },
];
