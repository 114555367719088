import {
  useInitOauthIntegrationAuthMutation,
  useFinishOauthIntegrationAuthMutation,
  IntegrationsDocument,
  IIntegrationAuthOptionsInput,
  IInitOauthIntegrationAuthMutationVariables,
  IFinishOauthIntegrationAuthMutationVariables,
  OauthIntegrationProviderEnum,
} from 'src/graphql/schema/index.ts';

const useIntegrationAuth = (provider: OauthIntegrationProviderEnum) => {
  const [initOauthIntegrationAuth] = useInitOauthIntegrationAuthMutation({
    refetchQueries: [IntegrationsDocument],
  });
  const [finishOauthIntegrationAuth] = useFinishOauthIntegrationAuthMutation({
    refetchQueries: [IntegrationsDocument],
  });

  const localStorageKeyPrefix = `IntegrationAuth.${provider}`;

  const initAuth = async (options?: IIntegrationAuthOptionsInput) => {
    const variables: IInitOauthIntegrationAuthMutationVariables = { provider };

    if (options) {
      variables.options = options;
    }

    const { data } = await initOauthIntegrationAuth({ variables });

    if (!data?.initOauthIntegrationAuth?.integrationAuth) {
      throw data?.initOauthIntegrationAuth?.errors?.join(', ') || 'Something went wrong';
    }

    const {
      initOauthIntegrationAuth: { integrationAuth },
    } = data;

    localStorage.setItem(`${localStorageKeyPrefix}.stateToken`, integrationAuth.stateToken);

    if (options) {
      localStorage.setItem(`${localStorageKeyPrefix}.options`, JSON.stringify(options));
    }

    window.open(integrationAuth.url);
  };

  const finishAuth = async (code: string, state: string) => {
    const stateToken = localStorage.getItem(`${localStorageKeyPrefix}.stateToken`);

    if (!stateToken) throw 'State token not found';

    const options = localStorage.getItem(`${localStorageKeyPrefix}.options`);

    const variables: IFinishOauthIntegrationAuthMutationVariables = {
      provider,
      stateToken,
      clientPayload: {
        code,
        state,
      },
    };

    if (options) {
      variables.options = JSON.parse(options);
    }

    const { data } = await finishOauthIntegrationAuth({ variables });

    if (!data?.finishOauthIntegrationAuth?.success) {
      throw data?.finishOauthIntegrationAuth?.errors?.join(', ') || 'Something went wrong';
    }

    localStorage.removeItem(`${localStorageKeyPrefix}.stateToken`);
    localStorage.removeItem(`${localStorageKeyPrefix}.options`);
  };

  return { initAuth, finishAuth };
};

export default useIntegrationAuth;
