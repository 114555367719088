import {
  IconAgreement,
  IconDevelopment,
  IconTransaction,
  IconAddFile,
  IconSaasAgreement,
  IconSourceCode,
  IconDatabase,
  IconInformation,
  IconBarChart,
  IconFactory,
  IconMaintenance,
} from 'src/components/Svg/Icons';
import { ICustomCheckboxWithIcon } from 'src/components/Form';

export const escrowLegalContextOptions: ICustomCheckboxWithIcon[] = [
  {
    heading: 'License\nagreement',
    text: 'An agreement between a software provider and the licensee that uses their software.',
    value: 'license',
    iconType: 'regular',
    icon: IconAgreement,
  },
  {
    heading: 'Development\nagreement',
    text: 'An agreement for outsourcing software development to 3rd-party developers and agencies.',
    value: 'development',
    iconType: 'regular',
    icon: IconDevelopment,
  },
  {
    heading: 'Transaction\nagreement',
    text: 'An agreement for IP or software that is part of a transaction, like an acquisition, investment or financing situation.',
    value: 'transaction',
    iconType: 'regular',
    icon: IconTransaction,
  },
  {
    heading: 'SaaS\nagreement',
    text: 'An agreement for 3rd-party software, including cloud services and SaaS solutions.',
    value: 'saas',
    iconType: 'regular',
    icon: IconSaasAgreement,
  },
  {
    heading: 'Other',
    text: 'Any other legal contexts not listed here, including custom agreements.',
    value: 'other',
    iconType: 'regular',
    icon: IconAddFile,
  },
];

export const escrowLegalContextDictionary: { [key: string]: ICustomCheckboxWithIcon } =
  escrowLegalContextOptions.reduce((acc, item) => ({ ...acc, [item.value]: item }), {});

export const escrowDepositTypeOptions: ICustomCheckboxWithIcon[] = [
  {
    heading: 'Source code',
    text: 'Source code of software that is critical for business continuity.',
    value: 'source',
    iconType: 'regular',
    icon: IconSourceCode,
  },
  {
    heading: 'Data / database',
    text: 'Database content, as well as any and all development data.',
    value: 'data',
    iconType: 'regular',
    icon: IconDatabase,
  },
  {
    heading: 'Information',
    text: 'Documents, licenses and other crucial files.',
    value: 'information',
    iconType: 'regular',
    icon: IconInformation,
  },
  {
    heading: 'SaaS credentials',
    text: 'Any 3rd-party hosted software credentials and permissions.',
    value: 'saas',
    iconType: 'regular',
    icon: IconSaasAgreement,
  },
  {
    heading: 'Other',
    text: 'Other materials not listed here.',
    value: 'other',
    iconType: 'regular',
    icon: IconAddFile,
  },
];

export const escrowDepositTypeDictionary: { [key: string]: ICustomCheckboxWithIcon } = escrowDepositTypeOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item }),
  {},
);

export const escrowReleaseOptions: ICustomCheckboxWithIcon[] = [
  {
    name: 'releaseBankruptcy',
    heading: 'Bankruptcy',
    iconType: 'regular',
    icon: IconBarChart,
    value: 'releaseBankruptcy',
  },
  {
    name: 'releaseInsolvency',
    heading: 'Insolvency',
    iconType: 'regular',
    icon: IconFactory,
    value: 'releaseInsolvency',
  },
  {
    name: 'releaseMaintenance',
    heading: 'Maintenance Obligations not met',
    iconType: 'regular',
    icon: IconMaintenance,
    value: 'releaseMaintenance',
  },
  {
    name: 'releaseCustom',
    heading: 'Custom',
    iconType: 'regular',
    icon: IconAddFile,
    value: 'releaseCustom',
  },
];

export const escrowReleaseDictionary: { [key: string]: ICustomCheckboxWithIcon } = escrowReleaseOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item }),
  {},
);

export const escrowJurisdictionOptions = [
  {
    label: 'Escrow Agent Jurisdiction',
    value: 'escrow_agent_jurisdiction',
  },
  {
    label: 'Depositor Jurisdiction',
    value: 'depositor_jurisdiction',
  },
  {
    label: 'Beneficiary Jurisdiction',
    value: 'beneficiary_jurisdiction',
  },
];

export const escrowJurisdictionDictionary: {
  [key: string]: string;
} = escrowJurisdictionOptions.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {});

export const escrowLiabilityOptions = [
  {
    label: 'No Liability',
    value: 'total_0',
  },
  {
    label: '$50,000 Total aggregate liability $29/month',
    value: 'total_29',
  },
  {
    label: '$100,000 Total aggregate liability $89/month',
    value: 'total_89',
  },
  {
    label: '$250,000 Total aggregate liability $149/month',
    value: 'total_149',
  },
];

export const escrowLiabilityDictionary: {
  [key: string]: string;
} = escrowLiabilityOptions.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {});
