import { Fragment, useState } from 'react';
import { Button } from 'antd';

import { ModalProceed, message } from 'src/components/Misc';
import { useRequestEscrowTerminationMutation } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';

const RequestTerminationActionContainer = () => {
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);

  const { escrow, refetch } = useEscrow();
  const [requestEscrowTermination, { loading }] = useRequestEscrowTerminationMutation();

  const onRequestTermination = async () => {
    try {
      const { data } = await requestEscrowTermination({ variables: { escrowId: escrow.id } });
      if (data?.requestEscrowTermination?.success) {
        await refetch();
        setIsProceedModalOpen(false);
      } else {
        throw new Error(data?.requestEscrowTermination?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message || 'Something went wrong');
      }
    }
  };

  return (
    <Fragment>
      <Button ghost danger onClick={() => setIsProceedModalOpen(true)}>
        Request Termination
      </Button>

      <ModalProceed
        title="Are you sure you want to initiate termination of this escrow?"
        text="The counterparty will be notified to confirm the termination. If termination is approved, and after the legal
        notice period as specified in the agreement, all deposited material in the escrow will be removed."
        isOpen={isProceedModalOpen}
        proceedText="Request Termination"
        onCancel={() => setIsProceedModalOpen(false)}
        onProceed={onRequestTermination}
        isLoading={loading}
        proceedButtonProps={{ danger: true, type: 'default' }}
      />
    </Fragment>
  );
};

export default RequestTerminationActionContainer;
