import { memo } from 'react';
import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import {
  useClientChangesRequestedQuery,
  EscrowStatusEnum,
  EscrowPartyTypeEnum,
  AgreementFormEnum,
  EscrowTerminationStatusEnum,
} from 'src/graphql/schema';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import { useEscrow } from 'src/context/escrow';

import type { IAgreement } from 'src/graphql/schema';

import {
  GenerateAgreementActionContainer,
  ApproveProceedActionContainer,
  ActivateEscrowActionContainer,
  RequestTerminationActionContainer,
  ApproveTerminationActionContainer,
  RejectTerminationActionContainer,
} from './Actions';

const EscrowModalFooter = JourneyModalLayout.Footer;

const actionItemsKeys = [
  'request-changes',
  'approve-proceed',
  'generate-agreement',
  'activate-escrow',
  'close',
  'approve-termination',
  'reject-termination',
] as const;

const EscrowModalFooterContainer = () => {
  const { escrow, ui, setSuggestChangesMode } = useEscrow();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: dataChangesRequested } = useClientChangesRequestedQuery();

  let actionItems: { key: (typeof actionItemsKeys)[number]; component: React.ReactNode }[] = [
    {
      key: 'close',
      component: (
        <Button
          type="default"
          onClick={() => {
            navigate('/escrows');
          }}
        >
          Close
        </Button>
      ),
    },
  ];

  // Hide for Request Changes mode
  if (ui.isSuggestChangesMode) return null;

  // Enable Request Changes action
  const isTripartite = escrow?.agreement?.agreementForm === AgreementFormEnum.Tripartite;
  const isBipartite = escrow?.agreement?.agreementForm === AgreementFormEnum.Bipartite;
  const isTripartiteOnboarding = Boolean(
    isTripartite && escrow.status === EscrowStatusEnum.Onboarding && escrow.depositor && escrow.beneficiary,
  );
  const isBipartiteOnboarding = isBipartite && escrow.status === EscrowStatusEnum.Onboarding;
  const activePath = pathname.split('/').at(-1) || '';
  const routeConditions = {
    [EscrowRoutesEnum.Parties]: () =>
      isTripartiteOnboarding ||
      (isBipartite && escrow.status === EscrowStatusEnum.Onboarding) ||
      escrow.status === EscrowStatusEnum.Active ||
      escrow.status === EscrowStatusEnum.Signed,
    [EscrowRoutesEnum.Agreement]: () =>
      (escrow.status === EscrowStatusEnum.Active ||
        escrow.status === EscrowStatusEnum.Signed ||
        isTripartiteOnboarding ||
        isBipartiteOnboarding) &&
      !escrow.agreement?.thirdPartyAgreement,
    [EscrowRoutesEnum.Notifications]: () =>
      (isTripartite && [EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow.status)) ||
      isTripartiteOnboarding ||
      isBipartiteOnboarding,
    [EscrowRoutesEnum.Deposits]: () =>
      escrow.status === EscrowStatusEnum.Active && escrow.currentCompanyRole === EscrowPartyTypeEnum.Depositor,
  };
  const requestChangesConditions: Array<(path?: EscrowRoutesEnum) => boolean> = [
    () => !ui.isSuggestChangesMode,
    () => !escrow.escrowTermination,
    () =>
      !(
        escrow.status === EscrowStatusEnum.Onboarding &&
        escrow.agreement?.[`approvedBy${capitalize(escrow.currentCompanyRole!)}` as keyof IAgreement]
      ),
    // @ts-expect-error dynamic type
    () => !dataChangesRequested?.clientChangesRequested[activePath],
    // @ts-expect-error dynamic type
    (activePath) => activePath && activePath in routeConditions && routeConditions[activePath](),
  ];
  if (requestChangesConditions.every((condition) => condition(activePath as EscrowRoutesEnum))) {
    actionItems.unshift({
      key: 'request-changes',
      component: (
        <Button
          type="text"
          onClick={() => setSuggestChangesMode(true)}
          style={{
            color: '#EB8D00',
            borderColor: '#EB8D00',
          }}
          title="Request Changes"
        >
          Request Changes
        </Button>
      ),
    });
  } else actionItems = actionItems.filter(({ key }) => key !== 'request-changes');

  // Disable Close action, if there are changes requested for the receiver on the current path
  if (
    // @ts-expect-error dynamic type
    dataChangesRequested?.clientChangesRequested[activePath] &&
    // @ts-expect-error dynamic type
    dataChangesRequested?.clientChangesRequested[activePath].status === 'pending' &&
    // @ts-expect-error dynamic type
    dataChangesRequested.clientChangesRequested[activePath].receiver === escrow.currentCompanyRole &&
    !escrow.escrowTermination
  ) {
    actionItems = actionItems.filter(({ key }) => key !== 'close');
  }

  // Enable Generate Agreement action
  if (escrow.status === EscrowStatusEnum.Draft)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'generate-agreement',
        component: (
          <GenerateAgreementActionContainer
            escrowId={escrow.id}
            isGeneratable={Boolean(escrow.agreement?.generatable)}
          />
        ),
      },
    ];
  else actionItems = actionItems.filter(({ key }) => key !== 'generate-agreement');

  // Enable Approve & Proceed action
  if (
    !dataChangesRequested?.clientChangesRequested.isRequested &&
    escrow.status === EscrowStatusEnum.Onboarding &&
    !escrow.escrowTermination
  )
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'approve-proceed',
        component: (
          <ApproveProceedActionContainer
            escrowId={escrow.id}
            arePartiesConfigured={escrow.escrowPartiesConfigured}
            // @ts-expect-error dynamic type
            isApprovedByCurrentRole={escrow?.agreement?.[`approvedBy${capitalize(escrow.currentCompanyRole!)}`]}
          />
        ),
      },
    ];
  else actionItems = actionItems.filter(({ key }) => key !== 'approve-proceed');

  // Enable Activate escrow action
  if (!dataChangesRequested?.clientChangesRequested.isRequested && escrow.status === EscrowStatusEnum.Signed)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'activate-escrow',
        component: (
          <ActivateEscrowActionContainer
            escrowId={escrow.id}
            isActivatable={escrow.activatable}
            isPendingActivation={escrow.pendingActivation}
          />
        ),
      },
    ];
  else actionItems = actionItems.filter(({ key }) => key !== 'activate-escrow');

  // Enable Approve Termination action
  if (
    escrow.escrowTermination &&
    escrow.escrowTermination.requesterParty !== escrow.currentCompanyRole &&
    escrow.escrowTermination.status === EscrowTerminationStatusEnum.Pending
  )
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'reject-termination',
        component: <RejectTerminationActionContainer />,
      },
      {
        key: 'approve-termination',
        component: <ApproveTerminationActionContainer />,
      },
    ];
  else actionItems = actionItems.filter(({ key }) => key !== 'approve-termination' && key !== 'reject-termination');

  // Enable Request Termination action
  const showRequestTermination =
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow.status) &&
    !escrow.escrowTermination &&
    (escrow.agreement?.agreementForm === AgreementFormEnum.Bipartite || (escrow.depositor && escrow.beneficiary));

  if (!actionItems.length) return null;

  return (
    <EscrowModalFooter justify={showRequestTermination ? 'space-between' : 'end'}>
      {showRequestTermination && (
        <EscrowModalFooter.Item>
          <RequestTerminationActionContainer />
        </EscrowModalFooter.Item>
      )}

      <EscrowModalFooter.Group>
        {actionItems.map(({ key, component }) => (
          <EscrowModalFooter.Item key={key}>{component}</EscrowModalFooter.Item>
        ))}
      </EscrowModalFooter.Group>
    </EscrowModalFooter>
  );
};

export default memo(EscrowModalFooterContainer);
