import { useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

import { IconBin } from 'src/components/Svg/Icons';

import styles from './DepositAssetsTable.module.scss';

enum RemovalStatusEnum {
  pending = 'Removal requested',
  accepted = 'Removal in progress',
}

type Props = (
  | {
      isSuggestedChanges: true;
      onRemoveAsset: (id: string) => void;
      onUndoRemovedAsset: (id: string) => void;
    }
  | {
      isSuggestedChanges?: false;
      type?: string;
      onDestroyAsset?: ((id: string) => Promise<void>) | undefined;
    }
) & {
  isRequestedToRemove?: boolean;
  id: string;
};

const DepositAssetsTableAction: React.FunctionComponent<Props> = (props) => {
  const { id, isSuggestedChanges, isRequestedToRemove = false } = props;

  const [isDestroying, setIsDestroying] = useState(false);

  if (isSuggestedChanges) {
    const { onUndoRemovedAsset, onRemoveAsset } = props;

    if (isRequestedToRemove) {
      const handleUndo = () => onUndoRemovedAsset(id);

      return (
        <div className={styles['removal-cell']}>
          <span className={styles['removal-label']}>Removal requested</span>
          <Button type="link" className={styles['btn-undo']} onClick={handleUndo}>
            Undo
          </Button>
        </div>
      );
    }

    const handleRemove = () => onRemoveAsset(id);

    return (
      <div className={styles['remove-cell']}>
        <Button type="link" onClick={handleRemove} className={styles['btn-remove']}>
          <IconBin className={styles['icon-bin']} />
          <span>Remove</span>
        </Button>
      </div>
    );
  }

  if (props.type)
    return (
      <span className={classNames(styles['removal-status'], styles[`${props.type}`])}>
        {RemovalStatusEnum[props.type as keyof typeof RemovalStatusEnum]}
      </span>
    );

  if (props.onDestroyAsset) {
    const handleDestroy = async () => {
      if (props.onDestroyAsset) {
        setIsDestroying(true);

        try {
          await props.onDestroyAsset(id);
        } finally {
          setIsDestroying(false);
        }
      }
    };

    return (
      <div className={styles['remove-cell']}>
        <Button type="link" onClick={handleDestroy} className={styles['btn-remove']} loading={isDestroying}>
          <IconBin className={styles['icon-bin']} />
          <span>Remove</span>
        </Button>
      </div>
    );
  }

  return null;
};

export default DepositAssetsTableAction;
