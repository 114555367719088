import { useEffect } from 'react';
import { Form, Modal } from 'antd';

import { IUserInput } from 'src/graphql/schema';

import { DestructuredUser } from '../TeamMembersTable';

import AddMemberForm, { IAddMemberForm } from './AddMemberForm';

const modalTypeEnum = ['invite', 'edit'] as const;

type IProps<TType extends (typeof modalTypeEnum)[number], TObj extends Record<string, unknown>> = {
  isOpen: boolean;
  type: TType;
  onFinish: (data: Partial<IUserInput>) => Promise<void>;
  onCloseModal: () => void;
} & {
  [TKey in keyof TObj]: TObj[TKey];
};

type IMember = DestructuredUser;

type Props = IProps<'invite', Record<string, unknown>> | IProps<'edit', { member: IMember }>;

const modalTypeDataEnum: {
  [key in (typeof modalTypeEnum)[number]]: {
    modalTitle: string;
    btnSubmitText: string;
  };
} = {
  edit: {
    modalTitle: 'Edit Team Member',
    btnSubmitText: 'Save',
  },
  invite: {
    modalTitle: 'Invite a new user',
    btnSubmitText: 'Invite',
  },
};

const AddMemberModal: React.FunctionComponent<Props> = (props) => {
  const [form] = Form.useForm<IAddMemberForm>();
  const { isOpen, onFinish, type = 'invite', onCloseModal } = props;

  useEffect(() => {
    const isEdit = props.type === 'edit';

    if (props.isOpen && isEdit && props.member) {
      const { member } = props;

      form.setFieldsValue({
        name: member.name || '',
        email: member.email || '',
        phone: member.phone || '',
        role: member.role || '',
      });
    }
  }, [form, props]);

  const onCancel = () => {
    form.resetFields();
    onCloseModal();
  };

  const modalTitle = modalTypeDataEnum[type].modalTitle;
  const btnSubmitText = modalTypeDataEnum[type].btnSubmitText;

  return (
    <Modal
      title={modalTitle}
      className="add-team-member settings-modal"
      open={isOpen}
      onCancel={onCancel}
      centered
      footer={null}
    >
      <AddMemberForm
        form={form}
        onFinish={onFinish}
        onCancel={onCancel}
        isEdit={type === 'edit'}
        btnSubmitText={btnSubmitText}
      />
    </Modal>
  );
};

export default AddMemberModal;
