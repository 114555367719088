import { Fragment, useState } from 'react';
import { Button } from 'antd';

import { ModalProceed, ModalConfirmation, message } from 'src/components/Misc';
import { useTerminateSaasEnvironmentMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';
import { updateSaasEnvironmentPolicyCache } from 'src/graphql/client/cache';

type Props = {
  id: string;
};

const TerminateSaasEnvironmentContainer = ({ id }: Props) => {
  const [ui, setUi] = useState({
    isConfirmModalOpen: false,
    isProceedModalOpen: false,
  });

  const [terminateSaasEnvironment, { loading }] = useTerminateSaasEnvironmentMutation();

  const onProceed = async () => {
    try {
      const { data } = await terminateSaasEnvironment({ variables: { id } });

      if (data?.terminateSaasEnvironment?.success) setUi({ isConfirmModalOpen: true, isProceedModalOpen: false });
      else throw new ClientError(data?.terminateSaasEnvironment?.errors?.[0]);
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  const onRequestTermination = () => setUi((ui) => ({ ...ui, isProceedModalOpen: true }));

  const onCancel = () => setUi((ui) => ({ ...ui, isProceedModalOpen: false }));

  const onCloseConfirmation = () => {
    updateSaasEnvironmentPolicyCache(id, { requestTermination: false });
    setUi((prevUi) => ({ ...prevUi, isConfirmModalOpen: false }));
  };

  return (
    <Fragment>
      <Button ghost danger onClick={onRequestTermination}>
        Request Termination
      </Button>

      <ModalProceed
        title="Are you sure you want to request termination of this escrow? "
        text="Codekeeper's access to your systems will have to be revoked, and you will no longer have the continuity protection of this SaaS escrow."
        isOpen={ui.isProceedModalOpen}
        proceedText="Request Termination"
        onCancel={onCancel}
        onProceed={onProceed}
        isLoading={loading}
        proceedButtonProps={{ danger: true, type: 'default' }}
        cancelText="Discard"
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Termination request sent"
        text="Please proceed by revoking Codekeeper's access to all the providers protected by this SaaS escrow."
        onClose={onCloseConfirmation}
        okText="Back to Dashboard"
      />
    </Fragment>
  );
};

export default TerminateSaasEnvironmentContainer;
